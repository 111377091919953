import AssessmentCreateModal from "./AssessmentCreateModal";
import AssessmentList from "./AssessmentList";
import LibraryCactus from "./LibraryCactus";
import ModuleList from "./ModuleList";
import {
    AnimatePresence,
    Api,
    Buttons,
    Link,
    OrgLimitsContext,
    Tabs,
    UserContext,
    Utils,
    _,
    classNames,
    moment,
    useContext,
    useEffect,
    useNavigate,
    useParams,
    useSearchParams,
    useState,
} from "./tools";

export default function Library() {
    const { user } = useContext(UserContext);
    const { limits } = useContext(OrgLimitsContext);
    const [showCreateAssessmentModal, setShowCreateAssessmentModal] = useState(false);
    const navigate = useNavigate();

    if (!limits.has_assessments) {
        return (
            <AssessmentCreateModal
                isOpen={true}
                setIsOpen={() => {
                    navigate("/");
                }}
            />
        );
    }

    const tabs = [
        {
            name: "Assessments",
            href: "assessments",
            current: true,
            component: <AssessmentList />,
        },
        {
            name: "Modules",
            href: "modules",
            current: true,
            component: <ModuleList />,
        },
        {
            name: "Cactus Library",
            href: "cactus",
            current: false,
            component: <LibraryCactus />,
        },
    ];

    return (
        <div className="flex flex-col">
            <AssessmentCreateModal
                isOpen={showCreateAssessmentModal}
                setIsOpen={setShowCreateAssessmentModal}
            />
            <main className="flex-1">
                <div className="relative">
                    <div className="pb-16">
                        <div className="flex justify-between px-4 sm:px-6 md:px-0">
                            <div>
                                <h5 className="text-3xl font-bold tracking-tight text-emerald-900">
                                    Library
                                </h5>
                            </div>
                            <div className="flex items-center gap-x-2">
                                <Buttons.Button
                                    onClick={() => setShowCreateAssessmentModal(true)}
                                    color="slate"
                                >
                                    Create Assessment
                                </Buttons.Button>
                                <Buttons.Button href="/library/modules/create" color="slate">
                                    Create Module
                                </Buttons.Button>
                            </div>
                        </div>
                        <Tabs persistSearchParams tabs={tabs} selected={0} />
                    </div>
                </div>
            </main>
        </div>
    );
}
