import { Link } from "react-router-dom";

import LoginForm from "./LoginForm";
import SSOForm from "./SSOForm";
import LogoNew from "./assets/img/cactus-hire-new.svg";

export default function Login() {
    return (
        <>
            <div className="flex flex-col">
                <div>
                    <img src={LogoNew} className="h-8 w-auto" />
                </div>
                <div className="mt-10">
                    <h2 className="text-lg font-semibold text-slate-900">
                        Sign in to your account
                    </h2>
                    <p className="mt-2 text-sm text-slate-700">
                        Don’t have an account?{" "}
                        <Link
                            to="/auth/register"
                            className="font-medium text-emerald-600 hover:underline"
                        >
                            Sign up for a free trial
                        </Link>
                    </p>
                </div>
            </div>
            <div className="mt-10 space-y-2">
                <LoginForm />

                <div className="inline-flex w-full items-center justify-center">
                    <hr className="my-4 h-px w-64 border-0 bg-slate-200 dark:bg-slate-700" />
                    <span className="absolute left-1/2 -translate-x-1/2 bg-white px-3 text-xs font-medium text-slate-600 ">
                        or
                    </span>
                </div>

                <SSOForm
                    className={"grid grid-cols-1 gap-y-2 gap-x-2 md:grid-cols-1"}
                    btnClassName={"bg-slate-50 px-2"}
                    onClick={(provider) => {
                        window.location.href = `/api/sso?provider=${provider}`;
                    }}
                />
            </div>
        </>
    );
}
