import { SparklesIcon } from "@heroicons/react/24/outline";
import React from "react";

import CactusLibraryItemEditForm from "./CactusLibraryItemEditForm";
import LibraryAssessmentCreateForm from "./LibraryAssessmentCreateForm";
import Empty6 from "./assets/img/empty6.png";
import {
    Api,
    Buttons,
    Cards,
    Dialog,
    Fields,
    Link,
    Pagination,
    Pills,
    Spinner,
    UserContext,
    Utils,
    classNames,
    constants,
    useContext,
    useDebounce,
    useEffect,
    useNavigate,
    useState,
} from "./tools";

export default function LibraryCactus() {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [editLibraryItem, setEditLibraryItem] = useState(null);
    const [createAssessmentFromLibraryItem, setCreateAssessmentFromLibraryItem] = useState(null);
    const [category, setCategory] = useState(constants.ASSESSMENT_CATEGORIES[0]);
    const debouncedSearch = useDebounce(search, 500);
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    const {
        data: tags,
        isLoading,
        error,
    } = Api.useGetLibraryCactusTagsQuery(
        { search: null },
        {
            refetchOnFocus: false,
        },
    );

    const {
        data: libraryCactusItems,
        isLoading: isLoadingLibraryCactus,
        error: libraryCactusError,
        refetch: getLibraryCactusItems,
    } = Api.useGetLibraryCactusQuery(
        { page: page, search: debouncedSearch, category: category.name },
        {
            skip: false,
        },
    );

    useEffect(() => {
        getLibraryCactusItems();
    }, [category]);

    if (isLoading) {
        return <Spinner />;
    }

    const categories = () => {
        let cats = [...constants.ASSESSMENT_CATEGORIES];
        cats.forEach((category) => {
            const matchingTag = tags.find((tag) => tag.name === category.name);
            if (matchingTag) {
                category.count = matchingTag.count;
            }
        });
        // Filter out categories without count
        const updatedCategories = [...cats.filter((category) => category.hasOwnProperty("count"))];

        return updatedCategories;
    };

    const renderResults = () => {
        if (isLoadingLibraryCactus) {
            return <Spinner />;
        }

        // if search and empty results
        if (debouncedSearch && libraryCactusItems?.count === 0) {
            return (
                <div className="">
                    <div className="grid grid-cols-2 items-center gap-x-6 p-5">
                        <div className="col-span-1 space-y-4">
                            <div className="prose-md prose">
                                <h2>
                                    No results found for{" "}
                                    <span className="font-bold">{debouncedSearch}</span>
                                </h2>
                                <p>We are continuously adding new assessments to our library.</p>
                                <p>
                                    In the meantime, you can use the the{" "}
                                    <span className="font-bold">Magic Assessment Builder</span> to
                                    create an assessment specific to your job description or{" "}
                                    <span className="font-bold">
                                        create an assessment from scratch.
                                    </span>
                                </p>
                            </div>
                            <div className="flex gap-x-2">
                                <Buttons.Button href="/library/assessments/create/magic">
                                    Use Magic Assessment Builder
                                </Buttons.Button>
                                <Buttons.Button href="/library/assessments/create">
                                    Create Custom Assessment
                                </Buttons.Button>
                            </div>
                        </div>
                        <div
                            style={{
                                backgroundImage: `url(${Empty6})`,
                            }}
                            className="col-span-1 h-96 bg-cover bg-center"
                        />
                    </div>
                </div>
            );
        }

        return (
            <>
                {libraryCactusItems?.results?.map((lCi) => {
                    return (
                        <Cards.CardCactusLibraryItem
                            key={lCi.id}
                            cactusLibraryItem={lCi}
                            onClickAdd={() => {
                                setCreateAssessmentFromLibraryItem(lCi.id);
                            }}
                            toolbar={
                                <div className="flex flex-row justify-end justify-items-end gap-x-2">
                                    {Utils.isStaff(user) && (
                                        <Buttons.Button
                                            color="lblue"
                                            className="bg-blue-300"
                                            onClick={() => {
                                                setEditLibraryItem(lCi.id);
                                            }}
                                        >
                                            Staff Edit
                                        </Buttons.Button>
                                    )}
                                </div>
                            }
                        />
                    );
                })}
            </>
        );
    };

    return (
        <div>
            <Dialog isOpen={editLibraryItem !== null} setIsOpen={() => setEditLibraryItem(null)}>
                <CactusLibraryItemEditForm cliid={editLibraryItem} />
            </Dialog>
            <Dialog
                withScroll={false}
                size="lg"
                isOpen={createAssessmentFromLibraryItem !== null}
                setIsOpen={() => setCreateAssessmentFromLibraryItem(null)}
            >
                <LibraryAssessmentCreateForm
                    onSuccess={(result) => {
                        setCreateAssessmentFromLibraryItem(null);
                        navigate(`/library/assessments/${result?.data?.id}?new=true`);
                    }}
                    cliid={createAssessmentFromLibraryItem}
                />
            </Dialog>
            <div className="grid grid-cols-12 gap-x-4">
                <div className="sticky top-0 col-span-2 space-y-4 self-start">
                    <div className="flex flex-row justify-between">
                        <Fields.TextFieldFree
                            className="w-full"
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            placeholder="Search..."
                        />
                    </div>
                    <div className="">
                        <ul className="">
                            {categories().map((cat) => (
                                <li
                                    key={cat.id}
                                    className={classNames(
                                        "block cursor-pointer p-4 text-xs text-slate-600 hover:bg-slate-100",
                                        cat.id === category.id ? "bg-slate-100" : "bg-white",
                                    )}
                                    onClick={() => {
                                        setCategory(cat);
                                    }}
                                >
                                    <div className="flex gap-x-4">
                                        <div className="flex h-5 w-5 items-center justify-center rounded-full bg-blue-100 text-xs font-light">
                                            <div className="p-1 text-blue-500">{cat.count}</div>
                                        </div>
                                        <div className="truncate">{cat.name}</div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="col-span-10 pb-20">
                    <div className="mt-1 grid grid-cols-2 gap-4">
                        <div className="col-span-full flex items-center justify-between">
                            <Pills.Pill>Assessments</Pills.Pill>
                            <div>
                                <Pagination.PaginationGeneric
                                    page={page}
                                    setPage={setPage}
                                    count={libraryCactusItems?.count}
                                />
                            </div>
                        </div>
                        <div className="col-span-2 space-y-8">{renderResults()}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
