import { javascript } from "@codemirror/lang-javascript";
import CodeMirror from "@uiw/react-codemirror";

import AliceImage from "./assets/img/alice.svg";
import HenryImage from "./assets/img/henry.svg";
import Chime from "./assets/sound/chime.mp3";
import LogoffSound from "./assets/sound/logoff.mp3";
import {
    AnimatePresenceSlideUp,
    Api,
    Avatars,
    Buttons,
    Fields,
    Spinner,
    classNames,
    moment,
    useEffect,
    useParams,
    useState,
} from "./tools";

const BOT_STATUS_WAITING = 1;
const BOT_STATUS_JOINING = 2;
const BOT_STATUS_JOINED = 3;
const BOT_STATUS_LEFT = 4;
const BOT_STATUS_THINKING = 5;
const BOT_STATUS_SPEAKING = 6;

const USER_STATUS_WAITING = 1;
const USER_STATUS_JOINING = 2;
const USER_STATUS_JOINED = 3;
const USER_STATUS_LEFT = 4;
const USER_STATUS_THINKING = 5;
const USER_STATUS_SPEAKING = 6;
const USER_STATUS_TRANSCRIPTION_ERROR = 7;

const CONVERSATION_STATUS_BRIEF = 1;
const CONVERSATION_STATUS_IN_PROGRESS = 2;
const CONVERSATION_STATUS_OVER = 3;

export default function ModulePreviewConversation() {
    const [answer, setAnswer] = useState("");
    const [showSpeakClearly, setShowSpeakClearly] = useState(false);
    const [userStatus, setUserStatus] = useState(USER_STATUS_WAITING);
    const [messages, setMessages] = useState([]);
    const [botStatus, setBotStatus] = useState(BOT_STATUS_WAITING);
    const [conversationStatus, setConversationStatus] = useState(CONVERSATION_STATUS_BRIEF);
    const [modelName, setModelName] = useState("gpt-3.5-turbo");

    const { id } = useParams();
    const { data: module, isLoading, error } = Api.useGetModuleQuery(id);
    const [modulePreviewAction, { isLoading: isPerformingAction }] =
        Api.useModulePreviewActionMutation();

    const conversationModuleItem = module?.items?.find(
        (moduleItem) => moduleItem.module_item_type == module.module_type,
    );

    if (!conversationModuleItem) {
        return (
            <div className="flex w-full flex-col items-center gap-y-4">
                <div className="text-sm italic">Item not found</div>
            </div>
        );
    }

    useEffect(() => {
        if (messages.length >= 1) {
            // start countdown
            const lastMessage = messages[messages.length - 1];
            // is last message by system?
            if (lastMessage.role === "assistant") {
                if (lastMessage.content.includes("I didn't understand that.")) {
                    setShowSpeakClearly(true);
                }
                // check if Good bye is in the conten
                const regex =
                    /\b(good\s*bye|bye|have\s+a?\s+nice\s+day|it\s+was\s+good\s+talking\s+to\s+you|have\s+a\s+good\s+day)\b[\s!?,.;:-]/i;

                if (regex.test(lastMessage.content)) {
                    // set bot status to left
                    setBotStatus(BOT_STATUS_LEFT);
                    const audio = new Audio(LogoffSound);
                    audio.play();
                    setTimeout(() => {
                        endConversation();
                    }, 6000);

                    return;
                }

                setUserStatus(USER_STATUS_TRANSCRIPTION_ERROR);
                setBotStatus(BOT_STATUS_WAITING);
                return;
            }
        }
    }, [messages.length]);

    useEffect(() => {
        if (conversationModuleItem) {
            const content = conversationModuleItem.content;
            const { default_model } = content;
            setModelName(default_model);
        }
        startConversation();
    }, []);

    const parseTextAndIncludeCodeMirror = (text) => {
        if (!text) {
            return null;
        }
        const codeSnippetRegex = /```(?:javascript)?([\s\S]*?)```/g;
        const parts = text.split(codeSnippetRegex);

        return parts.map((part, index) => {
            if (index % 2 === 1) {
                // this is code, set as latest code

                return (
                    <CodeMirror
                        className="text-md my-3 text-left"
                        key={index}
                        value={part}
                        height="auto"
                        extensions={[javascript({ jsx: true })]}
                        theme={"dark"}
                        readOnly={true}
                        autoFocus={false}
                        options={{
                            lineNumbers: true,
                        }}
                    />
                );
            } else {
                return <p key={index}>{part}</p>;
            }
        });
    };

    const endConversation = async () => {
        setBotStatus(BOT_STATUS_LEFT);
        setConversationStatus(CONVERSATION_STATUS_OVER);
    };

    const startConversation = async () => {
        setBotStatus(BOT_STATUS_JOINING);
        setConversationStatus(CONVERSATION_STATUS_IN_PROGRESS);
        const result = await modulePreviewAction({
            id: module.id,
            action: "conversation",
            body: {
                miid: conversationModuleItem.id,
                history: [],
            },
        });
        setBotStatus(BOT_STATUS_JOINED);
        setMessages(result.data.messages);
    };

    const newMessage = async (content) => {
        const messagesNew = [
            ...messages,
            {
                role: "user",
                content: content,
                created_at: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
            },
        ];
        setMessages(messagesNew);
        setUserStatus(USER_STATUS_WAITING);
        setBotStatus(BOT_STATUS_THINKING);
        try {
            const result = await modulePreviewAction({
                id: module.id,
                action: "conversation",
                body: {
                    miid: conversationModuleItem.id,
                    history: messagesNew,
                    model: modelName,
                },
            });
            setMessages(result.data.messages);
        } catch (error) {
            console.error(error);
        }
    };

    const content = conversationModuleItem.content;
    const { bot_name, welcome_message, candidate_brief } = content;

    const userMessages = messages.filter((message) => message.role === "user");
    const latestUserMessage = userMessages[userMessages.length - 1];
    const systemMessages = messages.filter((message) => message.role === "assistant");
    const latestSystemMessage = systemMessages[systemMessages.length - 1];

    if (conversationStatus == CONVERSATION_STATUS_BRIEF) {
        return (
            <div className="flex flex-col items-center justify-center gap-y-8  p-20">
                <div className="grid grid-cols-6 gap-x-8">
                    <div className="col-span-4 max-h-[400px] overflow-y-scroll p-5">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: candidate_brief?.replace(/<p><br><\/p>/g, ""),
                            }}
                            className="prose prose-sm"
                        ></div>
                    </div>
                </div>

                <Buttons.Button
                    color="slate"
                    className="bg-slate-200"
                    onClick={async () => {
                        startConversation();
                    }}
                >
                    Join Call
                </Buttons.Button>

                <div className="text-xs">
                    You have <span className="font-bold">5 minutes</span> to read this brief and
                    take notes, after which we will start the call.
                </div>
            </div>
        );
    }

    if (conversationStatus == CONVERSATION_STATUS_OVER) {
        return (
            <AnimatePresenceSlideUp initial={true} show={true}>
                <div className="flex h-full flex-col items-center justify-center gap-y-4">
                    <div>Conversation over. Thank you for your time.</div>
                    <div className="flex gap-x-4">
                        <Buttons.Button
                            color="slate"
                            onClick={() => {
                                startConversation();
                            }}
                        >
                            Restart
                        </Buttons.Button>
                    </div>
                </div>
            </AnimatePresenceSlideUp>
        );
    }

    return (
        <div className="flex flex-col gap-y-4 py-5">
            {showSpeakClearly && (
                <div className="flex flex-col items-center justify-center bg-orange-200 p-5 text-center">
                    It looks like we have trouble understanding you. Please try to slightly increase
                    the volume of your voice and speak more clearly.
                </div>
            )}

            <div className="flex items-end  justify-end gap-x-4">
                <Fields.SelectFieldFree
                    options={[
                        {
                            label: "gpt-3.5-turbo",
                            value: "gpt-3.5-turbo",
                        },
                        {
                            label: "gpt-4",
                            value: "gpt-4",
                        },
                    ]}
                    value={modelName}
                    onChange={(e) => {
                        setModelName(e.target.value);
                    }}
                />

                <Buttons.Button
                    color="slate"
                    onClick={() => {
                        setConversationStatus(CONVERSATION_STATUS_BRIEF);
                    }}
                >
                    Show Brief
                </Buttons.Button>
                <Buttons.Button
                    onClick={() => {
                        startConversation();
                    }}
                >
                    Reload
                </Buttons.Button>
            </div>
            <div className="grid grid-cols-2 gap-x-4">
                <div
                    className={classNames(
                        "col-span-1 flex items-center justify-center bg-blue-100 p-20 transition-all",
                        userStatus == USER_STATUS_SPEAKING && "bg-blue-300",
                    )}
                >
                    <div className="flex flex-col gap-y-4 text-center">
                        <div className="flex flex-col items-center justify-center gap-y-2">
                            <Avatars.Avatar size="lg" src={HenryImage} />
                            <div>You</div>
                        </div>

                        <div className="flex max-h-[650px] min-h-[150px] flex-col items-center justify-center gap-y-4 text-center">
                            {userStatus == USER_STATUS_THINKING && (
                                <div>
                                    <Spinner />
                                </div>
                            )}

                            {(userStatus == USER_STATUS_TRANSCRIPTION_ERROR && (
                                <div className="flex flex-col gap-y-4">
                                    <Fields.TextFieldFree
                                        rows={5}
                                        type="textarea"
                                        placeholder="Please type your message here"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                newMessage(answer);
                                            }
                                        }}
                                        onChange={(e) => {
                                            setAnswer(e.target.value);
                                        }}
                                    />
                                    <Buttons.Button
                                        color="blue"
                                        className="w-full"
                                        onClick={() => {
                                            newMessage(answer);
                                        }}
                                    >
                                        Send Message
                                    </Buttons.Button>
                                </div>
                            )) || <div>{latestUserMessage?.content}</div>}
                        </div>
                    </div>
                </div>

                <div
                    className={classNames(
                        "col-span-1 flex items-center justify-center bg-blue-100 p-20 transition-all",
                        botStatus == BOT_STATUS_SPEAKING && "animate-pulse bg-blue-300",
                    )}
                >
                    <div className="flex flex-col gap-y-4 text-center">
                        <div className="flex flex-col items-center justify-center gap-y-2">
                            <img src={AliceImage} className="h-16 w-16 rounded-full" />
                            <div>{bot_name}</div>
                        </div>

                        <div className="flex max-h-[650px] min-h-[150px] flex-col items-center justify-center gap-y-4 text-center">
                            {botStatus == BOT_STATUS_THINKING && (
                                <div>
                                    <Spinner />
                                </div>
                            )}
                            <div>
                                {botStatus == BOT_STATUS_JOINING && (
                                    <div>Waiting for {bot_name} to join...</div>
                                )}
                                {botStatus == BOT_STATUS_JOINED && (
                                    <div>{bot_name} joined the call</div>
                                )}
                                {botStatus == BOT_STATUS_LEFT && (
                                    <div className="font-bold text-red-300">
                                        {bot_name} left the call
                                    </div>
                                )}
                                {(botStatus == BOT_STATUS_SPEAKING && (
                                    <div>{bot_name} is speaking...</div>
                                )) || (
                                    <div className="">
                                        {parseTextAndIncludeCodeMirror(
                                            latestSystemMessage?.content,
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
