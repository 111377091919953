import { Dialog, Switch, Tab, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

import AssessmentGeneral from "./AssessmentGeneral";
import AssessmentModules from "./AssessmentModules";
import { AssessmentProvider } from "./AssessmentProvider";
import AssessmentStaff from "./AssessmentStaff";
import { Button } from "./Button";
import { Api, Link, Tabs, UserContext, Utils, classNames, useContext, useParams } from "./tools";

export default function AssessmentEdit() {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { id } = useParams();
    const { data: assessment, isLoading, error } = Api.useGetAssessmentQuery(id);
    const { user } = useContext(UserContext);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    const tabs = [
        {
            name: "General",
            href: "general",
            current: true,
            component: <AssessmentGeneral />,
        },
        {
            name: "Modules",
            href: "modules",
            current: false,
            component: <AssessmentModules />,
        },
        // { name: "Invite Links", href: "links", current: false },
    ];

    if (Utils.isStaff(user)) {
        tabs.splice(2, 0, {
            name: "Staff",
            href: "staff",
            current: false,
            component: <AssessmentStaff />,
        });
    }

    return (
        <div className="flex flex-col">
            <main className="flex-1">
                <div className="relative">
                    <div className="pb-16">
                        <div className="px-4 sm:px-6 md:px-0">
                            <div>
                                <Link className="text-sm text-slate-400" to="/library">
                                    Library
                                </Link>
                            </div>
                            <h5 className="text-3xl font-bold tracking-tight text-emerald-900">
                                {assessment?.name}
                            </h5>
                        </div>
                        <AssessmentProvider aid={id}>
                            <Tabs tabs={tabs} />
                        </AssessmentProvider>
                    </div>
                </div>
            </main>
        </div>
    );
}
