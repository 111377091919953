import { CandidateAssessmentContext } from "./CandidateAssessmentProvider";
import CandidateComments from "./CandidateComments";
import CandidateFeedback from "./CandidateFeedback";
import CandidateSummaryModules from "./CandidateSummaryModules";
import CandidateSummaryTimeline from "./CandidateSummaryTimeline";
import CandidateSummaryWIP from "./CandidateSummaryWIP";
import CandidateSummaryWardenScore from "./CandidateSummaryWardenScore";
import { AnimatePresence, Api, Buttons, Tab, useContext, useParams } from "./tools";

export default function CandidateSummary({}) {
    const { assessment } = useContext(CandidateAssessmentContext);

    return (
        <div>
            <div className="grid grid-cols-4 gap-x-4">
                <div style={{ minHeight: 500 }} className="col-span-3 bg-slate-50 p-5 shadow-md">
                    {assessment.completed_at ? (
                        <div className="space-y-6">
                            <CandidateSummaryModules />
                            <div className="grid grid-cols-2 gap-x-4">
                                <div>
                                    <CandidateComments />
                                </div>
                                <div>
                                    <CandidateFeedback />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <CandidateSummaryWIP />
                    )}
                </div>

                <div className="col-span-1 grid gap-y-4">
                    <div style={{ minHeight: 400 }} className="bg-slate-50 p-5 shadow-md">
                        <CandidateSummaryTimeline />
                    </div>
                    <div className="self-start bg-slate-50 p-5 shadow-md">
                        <CandidateSummaryWardenScore />
                    </div>
                </div>
            </div>
        </div>
    );
}
