import { Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Appear from "./Appear";
import { Button } from "./Button";
import { SelectField, TextField } from "./Fields";
import { useGetModuleQuery, useUpdateModuleMutation } from "./store/api";
import { Forms } from "./tools";
import classNames from "./utils";

export default function ModuleGeneral() {
    const [explain, setExplain] = useState(null);

    const { id } = useParams();

    const { data: module, isLoading, error } = useGetModuleQuery(id);
    const [updateModule, { isLoading: isSaving }] = useUpdateModuleMutation();

    return (
        <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-slate-900">General</h3>
                    <p className="mt-1 text-sm text-slate-600">
                        General information about the module.
                    </p>

                    <Appear show={explain != ""}>
                        <p className="border-1 border-dotted py-5 text-sm italic text-slate-500">
                            {explain}
                        </p>
                    </Appear>
                </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                    <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                        <Forms.GenericForm
                            initialValues={{
                                name: module.name,
                                team_id: module.team.id,
                                time_limit: module.time_limit,
                            }}
                            submitButtonClassname="self-start"
                            submitButtonLabel="Save"
                            submitButtonColor="lgreen"
                            fields={[
                                {
                                    name: "name",
                                    label: "Module name",
                                    type: "text",
                                    placeholder: "Name",
                                    required: true,
                                },
                                {
                                    name: "time_limit",
                                    label: "Time limit",
                                    type: "number",
                                },
                                {
                                    name: "team_id",
                                    label: "Team",
                                    type: "team",
                                },
                            ]}
                            onSubmit={async (values) => {
                                return updateModule({
                                    id: module.id,
                                    body: values,
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
