import { Api, Forms, Spinner, constants } from "./tools";

export default function LibraryAssessmentCreateForm({ cliid, onSuccess }) {
    const { data: libraryCactusItem, isLoading: isLoadingLibraryCactusItem } =
        Api.useGetLibraryCactusItemQuery(cliid);

    const [
        libraryCactusCreateAssessment,
        { isLoading: isCreatingAssessment, error: libraryCactusCreateAssessmentError },
    ] = Api.useLibraryCactusCreateAssessmentMutation();

    if (isLoadingLibraryCactusItem) {
        return <Spinner />;
    }

    return (
        <div className="flex flex-col gap-y-4">
            <Forms.GenericForm
                initialValues={{
                    id: libraryCactusItem.id,
                }}
                onSuccess={(result) => {
                    onSuccess?.(result);
                }}
                submitButtonLabel="Create Assessment"
                fields={[
                    {
                        name: "team_id",
                        label: "Team",
                        type: "team",
                        mine: true,
                    },
                ]}
                onSubmit={async (values) => {
                    return libraryCactusCreateAssessment({
                        id: values.id,
                        body: {
                            team_id: values.team_id,
                        },
                    });
                }}
            />
        </div>
    );
}
