import React from "react";
import { useContext } from "react";

import { CandidateExternalContext } from "./CandidateExternalProvider";
import { ApiCandidate, Badges, Progress, constants, moment, useEffect, useState } from "./tools";

export default function CandidateExternalTimelimit() {
    const { assessment } = useContext(CandidateExternalContext);
    const [performAction, { isLoading: isPerformingAction, error: performActionError }] =
        ApiCandidate.usePerformAssessmentActionMutation();

    // time limit in minutes
    const timeLimit = assessment.current_module.time_limit;
    const startedAt = assessment.current_module.started_at;
    const timeLimitEnd = moment(startedAt).add(timeLimit, "minutes");
    const totalSeconds = timeLimit * 60;
    const [diffInSeconds, setDiffInSeconds] = useState(timeLimitEnd.diff(moment(), "seconds"));

    useEffect(() => {
        const interval = setInterval(() => {
            setDiffInSeconds(timeLimitEnd.diff(moment(), "seconds"));
            // upon expiring, perform skip action
            if (diffInSeconds <= 0) {
                performAction({
                    cid: assessment.candidate_id,
                    caid: assessment.id,
                    action: "skip",
                    payload: {
                        current_module_item_id: assessment.current_module_item.id,
                        skip_reason: constants.SKIP_REASON_TIME_LIMIT,
                    },
                });
            }
        }, 1000);
        // It's important to clear the interval when the component is unmounted,
        // otherwise, the interval will keep running and cause an error if the component is unmounted before it finishes.
        return () => clearInterval(interval);
    }, [timeLimitEnd]);

    const getProgressBarColor = () => {
        const percentage = (diffInSeconds / totalSeconds) * 100;
        if (percentage > 80) {
            return "emerald";
        } else if (percentage > 30) {
            return "blue";
        } else if (percentage > 10) {
            return "yellow";
        } else {
            return "red";
        }
    };

    // if time is up, render time is up message
    if (diffInSeconds <= 0) {
        return (
            <div className="mb-5 rounded-md border-2 border-slate-100 bg-white p-5">
                <div className="text-sm">
                    Time is up. The module will be automatically submitted.
                </div>
            </div>
        );
    }

    return (
        <div className="rounded-md border-2 border-slate-100 bg-white p-5">
            <Progress.ProgressBar
                label={
                    <div className="prose prose-sm flex w-full max-w-full gap-x-2">
                        <div>The module will be automatically submitted when the time is up.</div>
                    </div>
                }
                size="sm"
                color={getProgressBarColor()}
                value={diffInSeconds}
                max={totalSeconds}
                valueLabel={
                    <Badges.Badge
                        showDot={false}
                        className="text-lg  font-semibold"
                        label={moment.utc(diffInSeconds * 1000).format("HH:mm:ss", { trim: true })}
                    />
                }
            />
        </div>
    );
}
