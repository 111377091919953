import React from "react";

import { CandidateAssessmentContext } from "./CandidateAssessmentProvider";
import CandidateReviewModuleConversation from "./CandidateReviewModuleConversation";
import CandidateReviewModuleGoogle from "./CandidateReviewModuleGoogle";
import { Pills, constants, useContext } from "./tools";

const MODULE_TYPE_COMPONENTS = {
    [constants.MODULE_TYPE_GOOGLE_DOC]: CandidateReviewModuleGoogle,
    [constants.MODULE_TYPE_GOOGLE_SHEET]: CandidateReviewModuleGoogle,
    [constants.MODULE_TYPE_GOOGLE_SLIDE]: CandidateReviewModuleGoogle,
    [constants.MODULE_TYPE_GOOGLE_FORM]: CandidateReviewModuleGoogle,
    [constants.MODULE_TYPE_GOOGLE_DRAWING]: CandidateReviewModuleGoogle,
    [constants.MODULE_TYPE_VIDEO]: CandidateReviewModuleGoogle,
    [constants.MODULE_TYPE_SCREENSHARE]: CandidateReviewModuleGoogle,
    [constants.MODULE_TYPE_CONVERSATIONAL]: CandidateReviewModuleConversation,
};

export default function CandidateReviewModules({}) {
    const { assessment } = useContext(CandidateAssessmentContext);
    // we only want to review certain modules
    const modules = assessment.modules.filter((module) => {
        return [
            constants.MODULE_TYPE_GOOGLE_DOC,
            constants.MODULE_TYPE_GOOGLE_SHEET,
            constants.MODULE_TYPE_GOOGLE_SLIDE,
            constants.MODULE_TYPE_GOOGLE_FORM,
            constants.MODULE_TYPE_GOOGLE_DRAWING,
            constants.MODULE_TYPE_VIDEO,
            constants.MODULE_TYPE_SCREENSHARE,
            constants.MODULE_TYPE_CONVERSATIONAL,
        ].includes(module.module_type);
    });

    return (
        <div className="space-y-4">
            <Pills.Pill>Reviews</Pills.Pill>

            <div className="flex w-full flex-col gap-y-5">
                {modules.map((module) => {
                    const ModuleComponent = MODULE_TYPE_COMPONENTS[module.module_type];
                    return <ModuleComponent key={module.id} module={module} />;
                })}
            </div>
        </div>
    );
}
