import AudioDeviceSelector from "./AudioDeviceSelector";
import CandidateExternalChrome from "./CandidateExternalChrome";
import { useConfirm } from "./ConfirmContext";
import ScreenShareSelector from "./ScreenShareSelector";
import VideoPreview from "./VideoPreview";
import LogoNew from "./assets/img/cactus-hire-new.svg";
import VideoConferencingImage from "./assets/img/video-conferencing.svg";
import {
    AnimatePresence,
    AnimatePresenceSlideUp,
    ApiCandidate,
    Buttons,
    Fields,
    Spinner,
    Utils,
    classNames,
    constants,
    useEffect,
    useParams,
    useState,
} from "./tools";
import { useS3Uploader } from "./useS3Uploader";
import { useWarden } from "./useWarden";

const MODULE_STATUS_START = 1;
const MODULE_STATUS_AUDIO_SETUP = 2;
const MODULE_STATUS_AUDIO_SETUP_COMPLETE = 3;
const MODULE_STATUS_READY_FOR_SCREEN_SHARE = 4;
const MODULE_STATUS_COMPLETE = 5;

export default function CandidateExternalModuleItemScreenShare() {
    const { cid, caid } = useParams();
    const [answer, setAnswer] = useState([]);
    const [showAudioSetup, setShowAudioSetup] = useState(false);
    const [inputDeviceId, setInputDeviceId] = useState(null);
    const [audioSetupError, setAudioSetupError] = useState(null);
    const [audioSetupComplete, setAudioSetupComplete] = useState(false);
    const [showChromeWarning, setShowChromeWarning] = useState(false);
    const [showScreenShareSetup, setShowScreenShareSetup] = useState(false);
    const [screenShareSetupError, setScreenShareSetupError] = useState(null);
    const [screenShareSetupComplete, setScreenShareSetupComplete] = useState(false);
    const [stream, setStream] = useState(null);
    const [videoBlob, setVideoBlob] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [moduleStatus, setModuleStatus] = useState(MODULE_STATUS_START);

    const {
        data: assessment,
        isLoading,
        error,
    } = ApiCandidate.useGetAssessmentQuery({ cid, caid }, {});

    const [performAction, { isLoading: isPerformingAction, error: performActionError }] =
        ApiCandidate.usePerformAssessmentActionMutation();

    const { runWarden, stopWarden } = useWarden(cid, caid);
    const confirm = useConfirm();

    const {
        uploading,
        uploaded,
        error: uploadError,
        progress,
        startUpload,
    } = useS3Uploader({
        cid,
        caid,
    });

    useEffect(() => {
        runWarden();
        return () => {
            stopWarden();
        };
    }, []);

    useEffect(() => {
        if (!Utils.canSafelyPlayAndRecordMedia()) {
            setShowChromeWarning(true);
        }
    }, []);

    useEffect(() => {
        let intervalId;
        const originalTitle = document.title;

        if (isRecording) {
            document.title = "Recording...";
            let blink = true;
            intervalId = setInterval(() => {
                document.title = blink ? "Recording..." : "...in progress...";
                blink = !blink;
            }, 1000);
        } else {
            clearInterval(intervalId);
            document.title = originalTitle;
        }

        return () => {
            clearInterval(intervalId);
            document.title = originalTitle;
        };
    }, [isRecording]);

    useEffect(() => {
        if (uploaded) {
            endModule();
        }
    }, [uploaded]);

    const endModule = async () => {
        setModuleStatus(MODULE_STATUS_COMPLETE);

        _.delay(async () => {
            const result = await performAction({
                cid,
                caid,
                action: "answer",
                payload: {
                    current_module_item_id: assessment.current_module_item.id,
                    answer: {
                        type: "video",
                        key: uploaded?.object_key,
                    },
                },
            });
        }, 2000);
    };

    const handleStartScreenShare = (stream) => {
        setScreenShareSetupError(null);
        setStream(stream);
        setVideoBlob(null);
        setIsRecording(true);
    };

    const handleStopScreenShare = (videoBlob) => {
        setVideoBlob(videoBlob);
        setStream(null);
        setIsRecording(false);
    };

    const handleSubmitVideo = async () => {
        if (!videoBlob) {
            return;
        }
        startUpload(videoBlob, "recording.mp4");
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const { current_module_item } = assessment;
    const { content } = current_module_item;
    const { title, candidate_brief, g_file_url, g_file_type } = content;

    // malformed ?
    if (!title || !candidate_brief) {
        return <div>Error loading screen share module item.</div>;
    }

    if (showChromeWarning) {
        return (
            <div className="flex h-full flex-col items-center justify-center">
                <CandidateExternalChrome />
            </div>
        );
    }

    if (moduleStatus === MODULE_STATUS_START) {
        return (
            <div className="w-full border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10">
                <div className="grid grid-cols-2 gap-x-8">
                    <div>
                        <div className="flex flex-col gap-y-5">
                            <div className="prose prose-sm">
                                <h3>Screen Share</h3>
                                <p>
                                    Welcome to the screenshare module! In this module, you'll be
                                    recording your screen, and audio from your microphone. Let's get
                                    started by setting up your microphone.
                                </p>
                            </div>
                            {audioSetupError && (
                                <div className="text-red-500">{audioSetupError}</div>
                            )}
                            <div className="flex gap-x-4">
                                <Buttons.Button
                                    color="slater"
                                    onClick={() => {
                                        setShowAudioSetup(true);
                                        setModuleStatus(MODULE_STATUS_AUDIO_SETUP);
                                    }}
                                >
                                    Setup Audio
                                </Buttons.Button>
                            </div>
                        </div>
                    </div>
                    <div className="flex h-full flex-col items-center justify-center">
                        <AnimatePresence show={true} initial={true}>
                            <img src={VideoConferencingImage} className="h-48" />
                        </AnimatePresence>
                    </div>
                </div>
            </div>
        );
    }

    if (
        moduleStatus === MODULE_STATUS_AUDIO_SETUP ||
        moduleStatus === MODULE_STATUS_AUDIO_SETUP_COMPLETE
    ) {
        return (
            <div className="w-full border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10">
                <div className="grid grid-cols-2 gap-x-8">
                    <div className="col-span-1">
                        <div className="flex flex-col gap-y-5">
                            <div className="prose prose-sm">
                                <h3>Audio Setup</h3>
                                <p>For the best audio experience. Follow these steps:</p>
                                <ol>
                                    <li>
                                        <strong>Access your computer's audio settings</strong>:
                                        <ul>
                                            <li>
                                                Windows: Click 'Start' &gt; 'Settings' (gear icon)
                                                &gt; 'System' &gt; 'Sound.'
                                            </li>
                                            <li>
                                                macOS: Click the Apple menu &gt; 'System
                                                Preferences' &gt; 'Sound.'
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Select the input device</strong>: In the 'Input' or
                                        'Recording devices' tab, choose the microphone you want to
                                        use for the screenshare module.
                                    </li>
                                    <li>
                                        <strong>Adjust the microphone volume</strong>: Set the input
                                        or recording volume to around 70-80% for clear audio without
                                        distortion. Ensure your voice is easily heard and doesn't
                                        clip.
                                    </li>
                                    <li>
                                        <strong>Test your microphone</strong>: Click the 'Test
                                        Microphone' button on the right to test your microphone. If
                                        you don't hear your voice, adjust the volume and try again.
                                    </li>
                                </ol>
                                <p>
                                    With your microphone settings in place, you're ready for the
                                    screenshare module. Click on the button below to continue.
                                </p>
                                {moduleStatus === MODULE_STATUS_AUDIO_SETUP_COMPLETE && (
                                    <Buttons.Button
                                        color="lgreen"
                                        onClick={async () => {
                                            setModuleStatus(MODULE_STATUS_READY_FOR_SCREEN_SHARE);
                                            setShowAudioSetup(false);
                                        }}
                                    >
                                        Continue
                                    </Buttons.Button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <AudioDeviceSelector
                            onlyShowAudioInput
                            onSelectInputDevice={(deviceId) => {
                                setInputDeviceId(deviceId);
                            }}
                            onSetupComplete={() => {
                                setModuleStatus(MODULE_STATUS_AUDIO_SETUP_COMPLETE);
                            }}
                            onNoDevicesFound={() => {
                                setModuleStatus(MODULE_STATUS_START);
                                setAudioSetupError(
                                    "Error: No devices found. Please check your microphone and speakers and try again.",
                                );
                            }}
                            onPermissionError={() => {
                                setModuleStatus(MODULE_STATUS_START);
                                setAudioSetupError(
                                    "Error: Permission to access microphone denied. Please check your browser settings and try again. ",
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    if (moduleStatus == MODULE_STATUS_COMPLETE) {
        return (
            <AnimatePresenceSlideUp initial={true} show={true}>
                <div className="flex h-full flex-col items-center justify-center gap-y-4 border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10">
                    <div>Congratulations! Module completed successfully.</div>
                    <div>
                        <Spinner size="md" />
                    </div>
                </div>
            </AnimatePresenceSlideUp>
        );
    }

    return (
        <div className="w-full border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10">
            <div className="grid grid-cols-2 gap-x-8">
                <div>
                    <div className="flex flex-col gap-y-5">
                        <div className="prose prose-sm">
                            <h3>{title}</h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: candidate_brief?.replace(/<p><br><\/p>/g, ""),
                                }}
                            />
                        </div>

                        {g_file_url && (
                            <div className="space-y-4">
                                <div className="prose prose-sm">
                                    <h4>Additional Documents</h4>
                                    <p>
                                        You have <strong>read-only</strong> access to your prior
                                        work in this module.
                                    </p>
                                </div>
                                <Buttons.Button
                                    className={classNames(
                                        "",
                                        constants.GOOGLE_FILE_COLORS[g_file_type],
                                    )}
                                    target="_blank"
                                    href={g_file_url}
                                    color="slate"
                                >
                                    <img
                                        className="mr-2 h-4 w-4"
                                        src={constants.MODULE_TYPE_ICONS[g_file_type]}
                                    />
                                    <span>
                                        Open in {constants.MODULE_TYPE_LABELS[g_file_type]}s
                                    </span>
                                </Buttons.Button>
                            </div>
                        )}

                        <div className="prose prose-sm">
                            <p>
                                <strong>Screen Recording Instructions:</strong>
                            </p>
                            <ol>
                                <li>
                                    Locate and click the "Share Screen and Start Recording" button.
                                </li>
                                <li>
                                    A prompt will appear for screen sharing. Click "Accept" or
                                    "Allow".
                                </li>
                                <li>Review and follow the provided brief carefully.</li>
                                <li>
                                    Complete all tasks outlined in the brief while recording your
                                    screen.
                                </li>
                                <li>
                                    When finished, return to this page and click the "Stop
                                    Recording" button.
                                </li>
                                <li>
                                    Review your recording and click "Submit Video" to upload your
                                    video. This will complete the module.
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>

                <div
                    className={classNames(
                        "flex h-full",
                        !stream || !videoBlob ? "items-center justify-center" : "",
                    )}
                >
                    <div className="w-full space-y-4">
                        {screenShareSetupError && (
                            <div className="text-red-500">{screenShareSetupError}</div>
                        )}
                        <div>
                            {(videoBlob && (
                                <div className="space-y-4">
                                    <div className="flex justify-between gap-x-4">
                                        <Buttons.ButtonWithConfirmation
                                            varian="solid"
                                            confirm={{
                                                title: "Are you sure?",
                                                description:
                                                    "Your video will be deleted if you restart the recording.",
                                                okLabel: "Yes, restart recording",
                                            }}
                                            onClick={() => {
                                                setVideoBlob(null);
                                            }}
                                        >
                                            Restart Recording
                                        </Buttons.ButtonWithConfirmation>

                                        <Buttons.ButtonWithConfirmation
                                            variant="solid"
                                            confirm={{
                                                title: "Are you sure?",
                                                description:
                                                    "Your video will be uploaded and you will not be able to make changes.",
                                                okLabel: "Yes, submit video",
                                            }}
                                            disabled={!videoBlob || uploaded || uploading}
                                            color="lgreen"
                                            onClick={handleSubmitVideo}
                                        >
                                            Submit Video
                                        </Buttons.ButtonWithConfirmation>
                                    </div>
                                    {uploading && (
                                        <div className="space-y-2">
                                            <div className="flex justify-between">
                                                <span className="text-sm  text-slate-700 ">
                                                    {uploaded ? "Upload complete" : "Uploading..."}
                                                </span>
                                                <span className="text-sm  text-slate-700 ">
                                                    {progress}%
                                                </span>
                                            </div>
                                            <div className="h-2.5 w-full rounded-full bg-gray-200 transition-all">
                                                <div
                                                    className="h-2.5 rounded-full bg-emerald-300 transition-all"
                                                    style={{ width: `${progress}%` }}
                                                ></div>
                                            </div>
                                        </div>
                                    )}

                                    {uploadError && (
                                        <div className="text-red-300">
                                            Error: {uploadError.message}
                                        </div>
                                    )}
                                    <VideoPreview withDownloadButton videoBlob={videoBlob} />
                                </div>
                            )) || (
                                <div className="space-y-4">
                                    <ScreenShareSelector
                                        inputDeviceId={inputDeviceId}
                                        onStartScreenShare={handleStartScreenShare}
                                        onStopScreenShare={handleStopScreenShare}
                                        onPermissionError={() => {
                                            setShowScreenShareSetup(false);
                                            setScreenShareSetupError(
                                                "Error: Permission to access screen denied. Please check your browser settings and try again. ",
                                            );
                                        }}
                                        onSomeOtherError={() => {
                                            setShowScreenShareSetup(false);
                                            setScreenShareSetupError(
                                                "Error: Something went wrong. Please try again.",
                                            );
                                        }}
                                        onNotSupported={() => {
                                            setShowScreenShareSetup(false);
                                            setShowChromeWarning(true);
                                        }}
                                    />
                                    {stream && (
                                        <div>
                                            <VideoPreview stream={stream} />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
