import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    AnimatePresenceGrow,
    Api,
    Avatar,
    Avatars,
    Buttons,
    Dialog,
    Fields,
    Link,
    Spinner,
    Tables,
    Utils,
    _,
    classNames,
    constants,
    moment,
} from "./tools";
import { useAssessmentMagics } from "./useAssessmentMagics";

export default function AssessmentMagiclist({
    filters = {},
    title = "History",
    withSearch = true,
}) {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(null);

    const updatedFilters = {
        search,
        ...filters,
    };

    const { assessmentMagics, isLoading, error } = useAssessmentMagics(page, updatedFilters);

    if (isLoading) {
        return <Spinner size="md" />;
    }

    const renderAssessmentMagic = (am) => {
        const content = am?.content;
        const content_job_post = am?.content_job_post;
        const content_job_post_magic = am?.content_job_post_magic;
        const content_mq = am?.content_mq;
        const content_case_study = am?.content_case_study;
        const assessment = am?.assessment;

        return (
            <Link
                to={`/library/assessments/create/magic/${am.id}`}
                className={classNames(
                    "text-gray-700 hover:bg-gray-50 hover:text-emerald-600",
                    "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                )}
            >
                <div className="flex w-full items-center justify-between text-xs">
                    <div className="flex w-full gap-x-4 truncate">
                        <div className="flex-shrink-0">
                            {content_job_post?.favicon ? (
                                <Avatars.Avatar
                                    size="md"
                                    src={content_job_post?.favicon}
                                    className="my-0 p-2"
                                />
                            ) : (
                                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-gray-100" />
                            )}
                        </div>
                        <div className="space-y-1">
                            <div className="flex gap-x-2 truncate text-slate-400">
                                <span>
                                    {_.truncate(content_job_post_magic?.company_name, {
                                        length: 40,
                                    }) || "Role not found"}
                                </span>
                                <span>{content_job_post_magic?.job_title}</span>
                            </div>
                            <div className="truncate text-slate-900">
                                {_.truncate(am.url, {
                                    length: 40,
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        );
    };

    return (
        <Tables.TablePaginatedServer
            title={title}
            showHeader={false}
            total={assessmentMagics?.count}
            goToPage={setPage}
            page={page}
            withSearch={withSearch && setSearch}
            rows={assessmentMagics?.results.map((am) => {
                return {
                    key: am.id,
                    cells: [
                        {
                            key: "name",
                            content: renderAssessmentMagic(am),
                        },
                    ],
                };
            })}
        />
    );
}
