import React from "react";

import GreenhouseLogo from "./assets/img/greenhouse.png";
import SlackLogo from "./assets/img/slack.png";
import { Badges, Buttons, Cards, Pills, classNames } from "./tools";

const INTEGRATIONS = [
    {
        name: "Greenhouse",
        logo: GreenhouseLogo,
        background: "#24A47F",
        description:
            "Greenhouse is an applicant tracking system and recruiting software designed to help businesses find suitable candidates, conduct focused interviews and optimize their recruiting process.",
    },
    {
        name: "Slack",
        logo: SlackLogo,
        background: "#4A154B",
        description:
            "Slack is a new way to communicate with your team. It’s faster, better organized, and more secure than email.",
    },
];

export default function AccountIntegrations() {
    return (
        <Cards.Card className="space-y-4">
            <Pills.Pill>Integrations</Pills.Pill>
            {INTEGRATIONS.map((integration) => {
                return (
                    <div className="grid grid-cols-4 gap-x-4 rounded-md bg-white p-5 shadow-md">
                        <div className="prose-md prose col-span-2 items-center">
                            <div className="flex h-full flex-col">
                                <div>
                                    <h5 className="mb-0 text-[0.7rem] uppercase">
                                        Featured Partner
                                    </h5>
                                    <h2 className="mt-0">{integration.name}</h2>
                                </div>

                                <p>{integration.description}</p>
                                <Buttons.Button
                                    className="mt-auto self-start"
                                    variant="solid"
                                    href={`mailto:hello@cactushire.com?subject=Connect ${integration.name}`}
                                >
                                    Connect {integration.name}
                                </Buttons.Button>
                            </div>
                        </div>
                        <div
                            className={classNames(
                                "col-span-2 flex h-56 items-center justify-center",
                            )}
                            style={{
                                backgroundColor: integration.background,
                            }}
                        >
                            <img src={integration.logo} className="h-16" />
                        </div>
                    </div>
                );
            })}
        </Cards.Card>
    );
}
