import React from "react";

import AccountUserSSO from "./AccountUserSSO";
import { Buttons, Cards, classNames } from "./tools";

export default function AccountUser() {
    return (
        <div className="space-y-4">
            <div className="grid grid-cols-3 gap-x-4">
                <Cards.Card className="col-span-2">
                    <AccountUserSSO />
                </Cards.Card>

                {/* <Cards.Card className="col-span-1"></Cards.Card> */}
            </div>
            {/* <div className="grid grid-cols-3 gap-x-4">
                <Cards.Card className="col-span-2"></Cards.Card>
            </div>
            <div className="grid grid-cols-3 gap-x-4">
                <Cards.Card className="col-span-2"></Cards.Card>
            </div> */}
        </div>
    );
}
