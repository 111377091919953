import { createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";

import {
    invalidateTagById,
    invalidatesList,
    provideTagById,
    providesList,
    providesListUnpaginated,
} from "../utils";
import { authBaseQuery } from "./auth";

export const API_REDUCER_KEY = "api";
export const apiSlice = createApi({
    reducerPath: API_REDUCER_KEY,
    baseQuery: authBaseQuery,
    tagTypes: [
        "CurrentUser",
        "Module",
        "Assessment",
        "AssessmentMagic",
        "Candidate",
        "CandidateAssessment",
        "CandidateAssessmentWardenEvent",
        "Team",
        "TeamStats",
        "UserTeam",
        "Org",
        "OrgUser",
        "OrgLimits",
        "CactusLibraryItem",
        "CactusLibraryItemTag",
        "Task",
    ],
    endpoints: (builder) => ({
        getCurrentUser: builder.query({
            query: () => ({
                url: "me",
                method: "GET",
            }),
            providesTags: (result, error, body) => provideTagById("CurrentUser", 1),
        }),
        getCurrentUserTasks: builder.query({
            query: (params) => {
                const { page = 1, ...otherParams } = params;
                return {
                    url: `me/tasks`,
                    method: "GET",
                    params: { page, ...otherParams },
                };
            },
            providesTags: (result, error, body) => providesList(result, "Task"),
        }),
        completeCurrentUserTask: builder.mutation({
            query: (id) => ({
                url: `me/tasks/${id}/complete`,
                method: "POST",
            }),
            invalidatesTags: (result, error, body) => invalidatesList("Task"),
        }),
        currentUserSSOAction: builder.mutation({
            query: ({ body }) => ({
                url: "me/sso",
                method: "POST",
                body: { ...body },
            }),
            invalidatesTags: (result, error, body) => invalidateTagById("CurrentUser", result?.id),
        }),
        getCurrentUserTeams: builder.query({
            query: () => ({
                url: "teams",
                method: "GET",
                params: { mine: true },
            }),
            providesTags: (result, error, body) => providesListUnpaginated(result, "UserTeam"),
        }),
        getTeams: builder.query({
            query: ({ search }) => ({
                url: "teams",
                method: "GET",
                params: { search },
            }),
            providesTags: (result, error, body) => providesListUnpaginated(result, "Team"),
        }),
        getTeam: builder.query({
            query: (id) => ({
                url: `teams/${id}`,
                method: "GET",
            }),
            providesTags: (result, error, id) => provideTagById("UserTeam", id),
        }),
        getTeamStats: builder.query({
            query: (id) => ({
                url: `teams/${id}/stats`,
                method: "GET",
            }),
            providesTags: (result, error, id) => provideTagById("TeamStats", id),
        }),
        updateTeam: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `teams/${id}`,
                    method: "PATCH",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { body, id }) => [
                ...invalidateTagById("UserTeam", id),
                ...invalidatesList("Team"),
                ...invalidatesList("UserTeam"),
            ],
        }),
        updateTeamMembersAdd: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `teams/${id}/members`,
                    method: "PATCH",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { body, id }) => [
                ...invalidateTagById("UserTeam", id),
                ...invalidatesList("Team"),
                ...invalidatesList("UserTeam"),
                ...invalidatesList("Assessment"),
                ...invalidatesList("Candidate"),
                ...invalidatesList("CandidateAssessment"),
            ],
        }),
        updateTeamMembersRemove: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `teams/${id}/members`,
                    method: "DELETE",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { body, id }) => [
                ...invalidateTagById("UserTeam", id),
                ...invalidatesList("Team"),
                ...invalidatesList("UserTeam"),
                ...invalidatesList("Assessment"),
                ...invalidatesList("Candidate"),
                ...invalidatesList("CandidateAssessment"),
            ],
        }),
        createTeam: builder.mutation({
            query: (body) => {
                return {
                    url: "teams",
                    method: "POST",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, body) => [
                ...invalidatesList("Team"),
                ...invalidatesList("UserTeam"),
            ],
        }),
        deleteTeam: builder.mutation({
            query: (id) => {
                return {
                    url: `teams/${id}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: (result, error, body) => [
                ...invalidatesList("Team"),
                ...invalidatesList("UserTeam"),
            ],
        }),
        getOrg: builder.query({
            query: () => ({
                url: "org",
                method: "GET",
            }),
            providesTags: (result, error, body) => provideTagById("Org", result?.id),
        }),
        updateOrg: builder.mutation({
            query: (body, formData = false) => {
                return {
                    url: "org",
                    method: "PATCH",
                    body: body,
                    formData: formData,
                    headers: formData && {
                        "Content-Type": "multipart/form-data",
                    },
                };
            },
            invalidatesTags: (result, error, body) => [
                ...invalidateTagById("Org", result?.id),
                ...invalidateTagById("CurrentUser", 1),
            ],
        }),
        getOrgUsers: builder.query({
            query: ({ search }) => ({
                url: `org/users`,
                method: "GET",
                params: { search },
            }),
            providesTags: (result, error, body) => providesListUnpaginated(result, "OrgUser"),
        }),
        getOrgUsersInvites: builder.query({
            query: ({ search }) => ({
                url: `org/users/invites`,
                method: "GET",
                params: { search },
            }),
            providesTags: (result, error, body) => providesListUnpaginated(result, "OrgUserInvite"),
        }),
        deleteOrgUsersInvite: builder.mutation({
            query: (id) => {
                return {
                    url: `org/users/invites`,
                    method: "DELETE",
                    body: {
                        uiid: id,
                    },
                };
            },
            invalidatesTags: (result, error, id) => invalidatesList("OrgUserInvite"),
        }),
        createOrgUser: builder.mutation({
            query: (body) => {
                return {
                    url: "org/users",
                    method: "POST",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, body) => invalidatesList("OrgUserInvite"),
        }),
        getOrgLimits: builder.query({
            query: () => ({
                url: `org/limits`,
                method: "GET",
            }),
            providesTags: (result, error, body) => provideTagById("OrgLimits", 1),
        }),
        createOrgSignedURL: builder.mutation({
            query: (body) => ({
                url: `org/signed`,
                method: "POST",
                body: { ...body },
            }),
        }),
        getModule: builder.query({
            query: (id) => ({
                url: `modules/${id}`,
                method: "GET",
            }),
            providesTags: (result, error, id) => provideTagById("Module", id),
        }),
        getModules: builder.query({
            query: ({ page = 1, search }) => ({
                url: `modules`,
                method: "GET",
                params: { page, search },
            }),
            providesTags: (result, error, body) => providesList(result, "Module"),
        }),
        createModule: builder.mutation({
            query: (body) => {
                return {
                    url: "modules",
                    method: "POST",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, body) => invalidatesList("Module"),
        }),
        updateModule: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `modules/${id}`,
                    method: "PATCH",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { id }) => invalidateTagById("Module", id),
        }),
        deleteModule: builder.mutation({
            query: (id) => {
                return {
                    url: `modules/${id}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: (result, error, { id }) => invalidatesList("Module"),
        }),
        modulePreviewAction: builder.mutation({
            query: ({ id, action, body, responseType = "json" }) => {
                return {
                    url: `modules/${id}/preview_action`,
                    method: "POST",
                    params: { action },
                    body: { ...body },
                    responseType,
                };
            },
            transformResponse: (response, meta) => {
                if (meta.headers.get("content-type")?.includes("audio")) {
                    return {
                        data: response,
                        headers: {
                            "content-type": meta.headers.get("content-type"),
                        },
                    };
                }
                return response;
            },
        }),
        moduleGenerateAction: builder.mutation({
            query: ({ id, action, body }) => {
                return {
                    url: `modules/${id}/generate_action`,
                    method: "POST",
                    params: { action },
                    body: { ...body },
                };
            },
        }),
        openModuleItemExternalFile: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `modules/${id}/open_external_file`,
                    method: "POST",
                    body: { ...body },
                };
            },
        }),
        createModuleItem: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `modules/${id}/items`,
                    method: "POST",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { id }) => invalidateTagById("Module", id),
        }),
        updateModuleItem: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `modules/${id}/items`,
                    method: "PATCH",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { id }) => invalidateTagById("Module", id),
        }),
        deleteModuleItem: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `modules/${id}/items`,
                    method: "DELETE",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { id }) => invalidateTagById("Module", id),
        }),
        aiToolsGenerate: builder.mutation({
            query: (body) => {
                return {
                    url: "ai_tools/generate",
                    method: "POST",
                    body: { ...body },
                };
            },
        }),
        getAssessment: builder.query({
            query: (id) => ({
                url: `assessments/${id}`,
                method: "GET",
            }),
            providesTags: (result, error, id) => provideTagById("Assessment", id),
        }),
        getAssessmentMagic: builder.query({
            query: (id) => ({
                url: `assessments/magic/${id}`,
                method: "GET",
            }),
            providesTags: (result, error, id) => provideTagById("AssessmentMagic", id),
        }),
        getAssessmentMagics: builder.query({
            query: ({ page = 1, search }) => ({
                url: `assessments/magic`,
                method: "GET",
                params: { page, search },
            }),
            providesTags: (result, error, body) => providesList(result, "AssessmentMagic"),
        }),
        getAssessments: builder.query({
            query: (params) => {
                const { page = 1, ...otherParams } = params;
                return {
                    url: `assessments`,
                    method: "GET",
                    params: { page, ...otherParams },
                };
            },
            providesTags: (result, error, body) => providesList(result, "Assessment"),
        }),
        createAssessment: builder.mutation({
            query: (body) => {
                return {
                    url: "assessments",
                    method: "POST",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, body) => [
                ...invalidatesList("Assessment"),
                ...invalidateTagById("OrgLimits", 1),
            ],
        }),
        createAssessmentMagic: builder.mutation({
            query: (body) => {
                return {
                    url: "assessments/magic",
                    method: "POST",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, body) => [...invalidatesList("AssessmentMagic")],
        }),
        restartAssessmentMagic: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `assessments/magic/${id}/restart`,
                    method: "POST",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { id }) => invalidateTagById("AssessmentMagic", id),
        }),
        createAssessmentFromAssessmentMagic: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `assessments/magic/${id}/create_assessment`,
                    method: "POST",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { id }) => [
                ...invalidatesList("Assessment"),
                invalidateTagById("AssessmentMagic", id),
                invalidateTagById("OrgLimits", 1),
            ],
        }),
        createAssessmentInviteLink: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `assessments/${id}/links`,
                    method: "POST",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { id }) => invalidateTagById("Assessment", id),
        }),
        updateAssessmentInviteLink: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `assessments/${id}/links`,
                    method: "PATCH",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { id }) => invalidateTagById("Assessment", id),
        }),
        deleteAssessmentInviteLink: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `assessments/${id}/links`,
                    method: "DELETE",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { id }) => invalidateTagById("Assessment", id),
        }),
        updateAssessment: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `assessments/${id}`,
                    method: "PATCH",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { id }) => invalidateTagById("Assessment", id),
        }),
        updateAssessmentModules: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `assessments/${id}/modules`,
                    method: "PATCH",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { id }) => invalidateTagById("Assessment", id),
        }),
        deleteAssessment: builder.mutation({
            query: (id) => {
                return {
                    url: `assessments/${id}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: (result, error, { id }) => [
                ...invalidateTagById("Assessment", id),
                ...invalidatesList("Assessment"),
                ...invalidateTagById("OrgLimits", 1),
            ],
        }),
        assessmentStaffAction: builder.mutation({
            query: ({ id, action, body }) => {
                return {
                    url: `assessments/${id}/staff_action`,
                    method: "POST",
                    params: { action },
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { id }) => invalidateTagById("Assessment", id),
        }),
        getAssessmentStatsFunnel: builder.query({
            query: ({ aid, range = "90days" }) => ({
                url: `/assessments/${aid}/stats_funnel`,
                params: { range },
            }),
        }),
        getAssessmentStatsScatterPlot: builder.query({
            query: ({ aid, range = "90days" }) => ({
                url: `/assessments/${aid}/stats_scatter_plot`,
                params: { range },
            }),
        }),
        getCandidate: builder.query({
            query: (id) => ({
                url: `candidates/${id}`,
                method: "GET",
            }),
            providesTags: (result, error, id) => provideTagById("Candidate", id),
        }),
        getCandidates: builder.query({
            query: (params) => {
                const { page = 1, ...otherParams } = params;
                return {
                    url: `candidates`,
                    method: "GET",
                    params: { page, ...otherParams },
                };
            },
            providesTags: (result, error, body) => providesList(result, "Candidate"),
        }),
        createCandidate: builder.mutation({
            query: (body) => {
                return {
                    url: "candidates",
                    method: "POST",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { body, id }) => [
                ...invalidatesList("Candidate"),
                ...invalidateTagById("OrgLimits", 1),
            ],
        }),
        deleteCandidate: builder.mutation({
            query: (id) => {
                return {
                    url: `candidates/${id}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: (result, error, { id }) => [
                ...invalidateTagById("Candidate", id),
                ...invalidatesList("Candidate"),
                ...invalidateTagById("OrgLimits", 1),
            ],
        }),
        getCandidateAssessment: builder.query({
            query: (id) => ({
                url: `candidate_assessments/${id}`,
                method: "GET",
            }),
            providesTags: (result, error, id) => provideTagById("CandidateAssessment", id),
        }),
        getCandidateAssessments: builder.query({
            query: (params) => {
                const { page = 1, ...otherParams } = params;
                return {
                    url: `candidate_assessments`,
                    method: "GET",
                    params: { page, ...otherParams },
                };
            },
            providesTags: (result, error, body) => providesList(result, "CandidateAssessment"),
        }),
        getCandidateAssessmentWardenEvents: builder.query({
            query: (params) => {
                const { page = 1, ...otherParams } = params;
                return {
                    url: `candidate_assessments/warden_events`,
                    method: "GET",
                    params: { page, ...otherParams },
                };
            },
            providesTags: (result, error, body) =>
                providesList(result, "CandidateAssessmentWardenEvent"),
        }),
        /* 
        accepts {
            camiid: uuid,
            score: int,
            review_comment: string,
        }
        */
        createCandidateAssessmentModuleItemReview: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `candidate_assessments/${id}/review`,
                    method: "POST",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { id }) =>
                invalidateTagById("CandidateAssessment", id),
        }),
        createCandidateAsessmentComment: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `candidate_assessments/${id}/comments`,
                    method: "POST",
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { id }) =>
                invalidateTagById("CandidateAssessment", id),
        }),

        getLibraryCactus: builder.query({
            query: (params) => {
                const { page = 1, ...otherParams } = params;
                return {
                    url: `library/cactus`,
                    method: "GET",
                    params: { page, ...otherParams },
                };
            },
            providesTags: (result, error, body) => providesList(result, "CactusLibraryItem"),
        }),
        getLibraryCactusItem: builder.query({
            query: (id) => ({
                url: `library/cactus/${id}`,
                method: "GET",
            }),
            providesTags: (result, error, id) => provideTagById("CactusLibraryItem", id),
        }),
        libraryCactusStaffAction: builder.mutation({
            query: ({ id, action, body }) => {
                return {
                    url: `library/cactus/${id}/staff_action`,
                    method: "POST",
                    params: { action },
                    body: { ...body },
                };
            },
            invalidatesTags: (result, error, { id }) => invalidateTagById("CactusLibraryItem", id),
        }),
        getLibraryCactusTags: builder.query({
            query: () => ({
                url: `library/cactus/all_tags`,
                method: "GET",
            }),
            providesTags: (result, error, body) =>
                providesListUnpaginated(result, "CactusLibraryItemTag"),
        }),
        libraryCactusCreateAssessment: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `library/cactus/${id}/generate_assessment`,
                    method: "POST",
                    body: { ...body },
                };
            },
            invalidatesList: (result, error, body) => [
                ...invalidatesList("Assessment"),
                invalidateTagById("OrgLimits", 1),
            ],
        }),
    }),
});

export const {
    useGetCurrentUserQuery,
    useGetCurrentUserTasksQuery,
    useCompleteCurrentUserTaskMutation,
    useCurrentUserSSOActionMutation,
    useGetCurrentUserTeamsQuery,
    useGetOrgQuery,
    useUpdateOrgMutation,
    useGetTeamQuery,
    useGetTeamStatsQuery,
    useGetTeamsQuery,
    useCreateTeamMutation,
    useDeleteTeamMutation,
    useUpdateTeamMutation,
    useUpdateTeamMembersAddMutation,
    useUpdateTeamMembersRemoveMutation,
    useGetOrgUsersQuery,
    useGetOrgUsersInvitesQuery,
    useGetOrgLimitsQuery,
    useCreateOrgSignedURLMutation,
    useCreateOrgUserMutation,
    useDeleteOrgUsersInviteMutation,
    useGetModulesQuery,
    useGetModuleQuery,
    useModulePreviewActionMutation,
    useModuleGenerateActionMutation,
    useCreateModuleMutation,
    useUpdateModuleMutation,
    useDeleteModuleMutation,
    useCreateModuleItemMutation,
    useUpdateModuleItemMutation,
    useDeleteModuleItemMutation,
    useOpenModuleItemExternalFileMutation,
    useAiToolsGenerateMutation,
    useGetAssessmentsQuery,
    useGetAssessmentMagicQuery,
    useGetAssessmentMagicsQuery,
    useGetAssessmentQuery,
    useCreateAssessmentMutation,
    useCreateAssessmentMagicMutation,
    useRestartAssessmentMagicMutation,
    useCreateAssessmentFromAssessmentMagicMutation,
    useCreateAssessmentInviteLinkMutation,
    useUpdateAssessmentInviteLinkMutation,
    useDeleteAssessmentInviteLinkMutation,
    useUpdateAssessmentMutation,
    useUpdateAssessmentModulesMutation,
    useDeleteAssessmentMutation,
    useAssessmentStaffActionMutation,
    useGetAssessmentStatsFunnelQuery,
    useGetAssessmentStatsScatterPlotQuery,
    useGetCandidatesQuery,
    useGetCandidateQuery,
    useGetCandidateAssessmentQuery,
    useGetCandidateAssessmentsQuery,
    useGetCandidateAssessmentWardenEventsQuery,
    useCreateCandidateAssessmentModuleItemReviewMutation,
    useCreateCandidateAsessmentCommentMutation,
    useCreateCandidateMutation,
    useDeleteCandidateMutation,
    useGetLibraryCactusQuery,
    useGetLibraryCactusTagsQuery,
    useGetLibraryCactusItemQuery,
    useLibraryCactusCreateAssessmentMutation,
    useLibraryCactusStaffActionMutation,
    usePrefetch,
    util,
} = apiSlice;
