import React from "react";

import { authApi, authSlice } from "./store/auth";
import {
    Api,
    Buttons,
    Fields,
    Form,
    Formik,
    Forms,
    Yup,
    useDispatch,
    useNavigate,
    usePLG,
    useState,
} from "./tools";
import { useUTMParams } from "./useUTMParams";

export default function RegisterForm() {
    const [nonFieldError, setNonFieldError] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const utmParams = useUTMParams();
    const { capture } = usePLG();

    const [register, { isLoading, error }] = authApi.useRegisterMutation();

    return (
        <Formik
            initialValues={{
                email: "",
                password: "",
                first_name: "",
                last_name: "",
                org_name: "",
                accept_tos: false,
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email("Please enter a valid email address")
                    .required("Please enter your email address"),

                accept_tos: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
            })}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
                setNonFieldError(null);
                try {
                    const result = await register({
                        email: values.email,
                        utm_params: Object.keys(utmParams).length > 0 ? utmParams : null,
                    });
                    setSubmitting(false);
                    if (result.error) {
                        if (result.error.data.detail) {
                            setNonFieldError(result.error.data.detail);
                        }
                        for (const [key, value] of Object.entries(result.error.data)) {
                            setFieldError(key, value[0]);
                        }
                    } else {
                        capture("Sign up", {
                            email: values.email,
                            utm_params: Object.keys(utmParams).length > 0 ? utmParams : null,
                        });
                        navigate("/auth/register/verify");
                    }
                } catch (err) {
                    console.log(err);
                    setSubmitting(false);
                }
            }}
        >
            <Form className="grid grid-cols-1 gap-y-8 gap-x-6 sm:grid-cols-2">
                <Fields.TextField
                    className="col-span-full"
                    label="Email address"
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                />

                <Fields.CheckBoxField
                    className="col-span-full"
                    name="accept_tos"
                    label={
                        <span>
                            By signing up, you agree to our{" "}
                            <a
                                href="https://cactushire.com/terms"
                                className="text-emerald-500 underline"
                            >
                                Terms of Service
                            </a>{" "}
                            and{" "}
                            <a
                                href="https://cactushire.com/privacy"
                                className="text-emerald-500 underline"
                            >
                                Privacy Policy
                            </a>
                            .
                        </span>
                    }
                />

                <div className="col-span-full">
                    <Buttons.Button
                        loading={isLoading}
                        type="submit"
                        variant="solid"
                        color="lgreen"
                        className="w-full"
                    >
                        <span>Sign up</span>
                    </Buttons.Button>
                </div>
            </Form>
        </Formik>
    );
}
