import {
    BookOpenIcon,
    ClipboardDocumentIcon,
    ClipboardDocumentListIcon,
    SparklesIcon,
} from "@heroicons/react/24/outline";
import React from "react";

import { Api, Dialog, Link, Spinner, classNames, useNavigate } from "./tools";

export default function AssessmentCreateModalContent({ className, itemClassName }) {
    const navigate = useNavigate();
    return (
        <div className={classNames("space-y-4", className)}>
            <div
                onClick={() => {
                    navigate("/library/cactus");
                }}
                className={classNames(
                    "col-span-1 flex cursor-pointer items-center justify-center rounded-xl bg-slate-50 shadow-md transition-all duration-150 hover:bg-slate-100",
                    itemClassName,
                    "p-5",
                )}
            >
                <div className="flex items-center gap-y-2 gap-x-6">
                    <div>
                        <BookOpenIcon className="h-5 w-5" />
                    </div>
                    <div className="space-y-1">
                        <div className="font-bold">Cactus Library</div>
                        <p className="">
                            Browse our library of pre-built assessments, curated by our team of HR
                            experts.
                        </p>
                    </div>
                </div>
            </div>

            <Link
                to="/library/assessments/create"
                className={classNames(
                    "col-span-1 flex cursor-pointer items-center justify-center rounded-xl bg-slate-50 shadow-md transition-all duration-150 hover:bg-slate-100",
                    itemClassName,
                    "p-5",
                )}
            >
                <div className="flex items-center gap-y-2 gap-x-6">
                    <div>
                        <ClipboardDocumentListIcon className="h-5 w-5" />
                    </div>
                    <div className="space-y-1">
                        <div className="font-bold">Custom Assessment</div>
                        <p className="">
                            Create your own assessment from scratch, or import your existing ones.
                        </p>
                    </div>
                </div>
            </Link>

            <Link
                to="/library/assessments/create/magic"
                className={classNames(
                    "col-span-1 flex cursor-pointer items-center justify-center rounded-xl bg-slate-50 shadow-md transition-all duration-150 hover:bg-slate-100",
                    itemClassName,
                    "p-5",
                )}
            >
                <div className="flex items-center gap-y-2 gap-x-6">
                    <div>
                        <SparklesIcon className="h-5 w-5" />
                    </div>
                    <div className="space-y-1">
                        <div className="font-bold">Magic Assessment Builder</div>
                        <p className="">
                            Use our AI-powered assessment builder to create custom assessments in
                            minutes.
                        </p>
                    </div>
                </div>
            </Link>
        </div>
    );
}
