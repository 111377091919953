import AssessmentInviteLinks from "./AssessmentInviteLinks";
import { AssessmentProvider } from "./AssessmentProvider";
import AssessmentReports from "./AssessmentReports";
import AssessmentSummary from "./AssessmentSummary";
import CandidateCreateForm from "./CandidateCreateForm";
import CandidateCreateFormDemo from "./CandidateCreateFormDemo";
import CandidateList from "./CandidateList";
import {
    AnimatePresence,
    Api,
    Buttons,
    Dialog,
    Link,
    Tab,
    Tabs,
    classNames,
    useNavigate,
    useParams,
    useState,
} from "./tools";
import useCallbackOnSearchParamMatch from "./useCallbackOnSearchParamMatch";

export default function AssessmentSingle() {
    const { id } = useParams();
    const { data: assessment, isLoading, error } = Api.useGetAssessmentQuery(id);
    const [showNewlyCreatedAssessment, setShowNewlyCreatedAssessment] = useState(false);
    const [showInviteCandidate, setShowInviteCandidate] = useState(false);
    const navigate = useNavigate();
    useCallbackOnSearchParamMatch({ new: true }, () => setShowNewlyCreatedAssessment(true));

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    return (
        <div className="flex flex-col">
            <main className="flex-1">
                <div className="relative">
                    <div className="pb-16">
                        <div className="flex items-center justify-between">
                            <div className="max-w-[50%] truncate">
                                <div>
                                    <Link className="text-sm text-slate-400" to="/library">
                                        Library
                                    </Link>
                                </div>
                                <h5
                                    onClick={() => {
                                        // copy current assessment id to clipboard
                                        navigator.clipboard.writeText(assessment.id);
                                    }}
                                    className="text-3xl font-bold tracking-tight text-emerald-900"
                                >
                                    {assessment?.name}
                                </h5>
                            </div>
                            <div className="flex  gap-x-2">
                                {/* Newly created assessment modal */}
                                <Dialog
                                    withScroll={false}
                                    size="xl4"
                                    isOpen={showNewlyCreatedAssessment}
                                    setIsOpen={() => setShowNewlyCreatedAssessment(false)}
                                >
                                    <div className="grid grid-cols-2 gap-x-8">
                                        <div className="space-y-4">
                                            <div className="prose prose-sm">
                                                <h3>Congratulations</h3>
                                                <p>
                                                    You have successfully created an assessment. Now
                                                    it's time to send out some invites.
                                                </p>
                                                <h3>Try it out</h3>
                                                <p>
                                                    Send yourself a test invite and experience the
                                                    process first hand. Test invites are free and do
                                                    not count towards your assessment quota.
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <CandidateCreateFormDemo
                                                onSuccess={(result) => {
                                                    console.log(result);
                                                    navigate(
                                                        `/candidates/${result?.data?.id}?aid=${assessment.id}`,
                                                    );
                                                }}
                                                assessmentId={assessment.id}
                                            />
                                        </div>
                                    </div>
                                </Dialog>

                                <Dialog
                                    withScroll={false}
                                    size="xl4"
                                    isOpen={showInviteCandidate}
                                    setIsOpen={setShowInviteCandidate}
                                >
                                    <CandidateCreateForm
                                        assessmentId={id}
                                        onSuccess={() => setShowInviteCandidate(false)}
                                    />
                                </Dialog>
                                <Buttons.Button
                                    color="lgreen"
                                    onClick={() => setShowInviteCandidate(true)}
                                >
                                    Invite Candidate
                                </Buttons.Button>
                                <Buttons.Button color="slate">Generate PDF Report</Buttons.Button>
                                <Buttons.ButtonActionsDropdown
                                    actions={[
                                        {
                                            label: "Edit",
                                            onClick: () => {
                                                navigate(`/library/assessments/${id}/edit`);
                                            },
                                        },
                                        {
                                            label: "Send Test Invite",
                                            onClick: () => {
                                                setShowNewlyCreatedAssessment(true);
                                            },
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                        <AssessmentProvider aid={id}>
                            <Tabs
                                tabs={[
                                    {
                                        name: "Summary",
                                        href: "summary",
                                        current: true,
                                        component: <AssessmentSummary />,
                                    },
                                    {
                                        name: "Candidates",
                                        href: "candidates",
                                        current: false,
                                        component: (
                                            <CandidateList
                                                filters={{
                                                    assessment_id: id,
                                                }}
                                            />
                                        ),
                                    },
                                    {
                                        name: "Invite Links",
                                        href: "links",
                                        current: false,
                                        component: <AssessmentInviteLinks />,
                                    },
                                    {
                                        name: "Reports",
                                        href: "reports",
                                        current: false,
                                        component: <AssessmentReports />,
                                    },
                                ]}
                            />
                        </AssessmentProvider>
                    </div>
                </div>
            </main>
        </div>
    );
}
