import React from "react";

import GoogleLogo from "./assets/img/google.svg";
import MicrosoftLogo from "./assets/img/microsoft.svg";
import { Buttons, classNames } from "./tools";

export default function SSOForm({
    className,
    btnClassName,
    onClick,
    actionLabel = "Sign in",
    ...props
}) {
    return (
        <div className={classNames("", className)}>
            <Buttons.Button
                color="slate"
                className={classNames(" text-[.7rem] ", btnClassName)}
                onClick={() => {
                    onClick?.("GoogleOAuth");
                }}
            >
                <img src={GoogleLogo} className="mr-2 h-3 w-3" alt="Google Logo" />
                <span>{actionLabel} with Google</span>
            </Buttons.Button>
            {/* <Buttons.Button
                color="white"
                className={classNames(" text-[.7rem] ", btnClassName)}
                onClick={() => {
                    onClick?.("MicrosoftOAuth");
                }}
            >
                <img src={MicrosoftLogo} className="mr-2 h-3 w-3" alt="Microsoft Logo" />
                <span>{actionLabel} with Microsoft</span>
            </Buttons.Button> */}
        </div>
    );
}
