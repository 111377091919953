import React from "react";

import AccountBranding from "./AccountBranding";
import AccountPrivacySecurity from "./AccountPrivacySecurity";
import AccountSSO from "./AccountSSO";
import AccountSeats from "./AccountSeats";
import AccountTeams from "./AccountTeams";
import AccountUsers from "./AccountUsers";
import { OrgProvider } from "./OrgProvider";
import { Buttons, Cards, UserContext, Utils, classNames, useContext } from "./tools";

export default function AccountOrg() {
    const { user } = useContext(UserContext);

    if (!Utils.isAdmin(user)) {
        return (
            <div className="prose-md prose">
                <p>The account overview is only accessible to admins.</p>
            </div>
        );
    }

    return (
        <div className="space-y-4">
            <div className="grid grid-cols-3 gap-x-4">
                <Cards.Card className="col-span-2">
                    <AccountUsers />
                </Cards.Card>

                <div className="col-span-1 space-y-4">
                    <Cards.Card className="self-start">
                        <AccountSeats />
                    </Cards.Card>
                </div>
            </div>
            <div className="grid grid-cols-3 gap-x-4">
                <Cards.Card className="col-span-2">
                    <AccountTeams />
                </Cards.Card>
            </div>
            <div className="grid grid-cols-3 gap-x-4">
                <Cards.Card className="col-span-3">
                    <AccountBranding />
                </Cards.Card>
            </div>
            <div className="grid grid-cols-3 gap-x-4">
                <Cards.Card className="col-span-3">
                    <AccountSSO />
                </Cards.Card>
            </div>
        </div>
    );
}
