import AssessmentCreateModal from "./AssessmentCreateModal";
import DashboardStats from "./DashboardStats";
import TrialPromotionalBanner from "./TrialPromotionalBanner";
import CactusBlueImage from "./assets/img/cactus-blue.png";
import CactusPurpleImage from "./assets/img/cactus-purple.png";
import CactusTransparentImage from "./assets/img/cactus-transparent.png";
import GhibliImage3 from "./assets/img/ghibli3.png";
import { Buttons, useNavigate, useState } from "./tools";
import useCallbackOnSearchParamMatch from "./useCallbackOnSearchParamMatch";

export default function DashboardNewAccount() {
    const [showTrialStartedBanner, setShowTrialStartedBanner] = useState(false);
    const [showCreateAssessmentModal, setShowCreateAssessmentModal] = useState(false);
    const navigate = useNavigate();

    useCallbackOnSearchParamMatch({ trial: true }, () => setShowTrialStartedBanner(true), false);

    return (
        <div className="grid grid-cols-12 items-center justify-center gap-x-10">
            <div className="col-span-6">
                <TrialPromotionalBanner
                    isOpen={showTrialStartedBanner}
                    setIsOpen={setShowTrialStartedBanner}
                />
                <AssessmentCreateModal
                    isOpen={showCreateAssessmentModal}
                    setIsOpen={setShowCreateAssessmentModal}
                />
                <div className="prose-md prose">
                    <h1 className="font-bold text-slate-900">Welcome to Cactus</h1>
                    <p>
                        We're thrilled to have you on board. You're now part of an innovative
                        community that values real-world skills and fair assessments.
                    </p>
                    <p>Here's what you can look forward to with Cactus:</p>

                    <ul>
                        <li>
                            <span className="font-bold">Real-World Assessments:</span> Say goodbye
                            to generic tests. Start creating job-specific tasks that reflect the
                            actual work your candidates will be doing.
                        </li>
                        <li>
                            <span className="font-bold">AI-Powered Interview Simulations:</span>{" "}
                            Make hiring for roles like Sales and Customer Success easier with our AI
                            simulations. Get a deeper understanding of your candidates' skills and
                            potential.
                        </li>
                        <li>
                            <span className="font-bold">Unbiased Hiring:</span> With Cactus, every
                            candidate gets a fair chance to showcase their skills, promoting
                            diversity and inclusivity in your team.
                        </li>
                    </ul>

                    <div>
                        <h4>Ready to get started?</h4>
                        <p>Head over to the library to create your first real-world assessment.</p>
                    </div>

                    <div>
                        <h4>Have questions?</h4>
                        <p>
                            Check out our{" "}
                            <a className="text-emerald-500" href="https://cactushire.com">
                                Help Center
                            </a>{" "}
                            or{" "}
                            <a className="text-emerald-500" href="mailto:hello@cactushire.com">
                                Contact Us
                            </a>{" "}
                            anytime.
                        </p>
                    </div>
                </div>
            </div>

            <div className="col-span-5 flex items-center justify-center">
                <div className="flex flex-col gap-y-4">
                    <h1 className="text-xl font-bold tracking-tight text-emerald-900">
                        Get the most out of Cactus
                    </h1>
                    <div
                        onClick={() => {
                            setShowCreateAssessmentModal(true);
                        }}
                        className="flex cursor-pointer items-center gap-x-6 rounded-md bg-white p-5 shadow-md transition-all hover:bg-emerald-50"
                    >
                        <img
                            className="h-12 rounded-md bg-blue-300"
                            src={CactusTransparentImage}
                            alt="Assessment"
                        />
                        <div className="">
                            <h2 className="text-md font-bold tracking-tight text-emerald-900">
                                Create an assessment
                            </h2>
                            <p className="text-sm leading-6 text-slate-600">
                                Creating an assessment is the first step to inviting candidates to
                                Cactus.
                            </p>
                        </div>
                    </div>

                    <div
                        onClick={() => {
                            navigate("/account");
                        }}
                        className="flex cursor-pointer items-center gap-x-6 rounded-md bg-white p-5 shadow-md transition-all hover:bg-emerald-50"
                    >
                        <img
                            className="h-12 rounded-md bg-emerald-300"
                            src={CactusTransparentImage}
                            alt="Team"
                        />
                        <div className="">
                            <h2 className="text-md font-bold tracking-tight text-emerald-900">
                                Invite your team
                            </h2>
                            <p className="text-sm leading-6 text-slate-600">
                                Cactus works best if your team is on board. Invite them to join you
                                on your Cactus journey.
                            </p>
                        </div>
                    </div>

                    <div
                        onClick={() => {
                            navigate("/account?tab=integrations");
                        }}
                        className="flex cursor-pointer items-center gap-x-6 rounded-md bg-white p-5 shadow-md transition-all hover:bg-emerald-50"
                    >
                        <img
                            className="h-12 rounded-md bg-orange-300"
                            src={CactusTransparentImage}
                            alt="Team"
                        />
                        <div className="">
                            <h2 className="text-md font-bold tracking-tight text-emerald-900">
                                Setup Integrations
                            </h2>
                            <p className="text-sm leading-6 text-slate-600">
                                Connect your ATS and other tools to make your hiring process
                                seamless.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
