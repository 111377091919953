import { ChatWidget } from "@papercups-io/chat-widget";

import Logo from "./assets/img/logo.svg";
import { UserContext, constants, useContext } from "./tools";

export default function Intercom() {
    const { user } = useContext(UserContext);

    // do not show intercom if not production
    if (process.env.NODE_ENV !== "production") {
        return null;
    }

    return (
        <div>
            <ChatWidget
                token={process.env.REACT_APP_PAPERCUP_TOKEN}
                inbox={process.env.REACT_APP_PAPERCUP_INBOX}
                title="Welcome to Cactus"
                subtitle="Ask us anything in the chat window below"
                primaryColor={constants.TAILWIND_COLORS_TO_HEX["emerald-700"]}
                newMessagePlaceholder="Start typing..."
                showAgentAvailability={true}
                agentAvailableText="We're online right now."
                agentUnavailableText="We're away at the moment."
                requireEmailUpfront={true}
                iconVariant="outlined"
                baseUrl={process.env.REACT_APP_PAPERCUP_URL}
                iframeUrlOverride={process.env.REACT_APP_PAPERCUP_WINDOW_URL}
                customer={{
                    name: user.full_name,
                    email: user.email,
                    external_id: user.id,
                    metadata: {
                        org: user.org.name,
                    },
                }}
            />
        </div>
    );
}
