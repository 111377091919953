import React from "react";

import AssessmentInviteLinkCreateForm from "./AssessmentInviteLinkCreateForm";
import { AssessmentContext } from "./AssessmentProvider";
import { Api, Buttons, Dialog, Forms, Pills, Tables, useContext, useState } from "./tools";

export default function AssessmentInviteLinks() {
    const { assessment } = useContext(AssessmentContext);
    const [showCreateInviteLink, setShowCreateInviteLink] = useState(false);

    const [updateAssessmentInviteLink, { isLoading, error }] =
        Api.useUpdateAssessmentInviteLinkMutation();

    const [deleteAssessmentInviteLink, { isLoading: isDeleting, error: deleteError }] =
        Api.useDeleteAssessmentInviteLinkMutation();

    return (
        <div className="flex flex-col gap-y-4">
            <Dialog size="lg" isOpen={showCreateInviteLink} setIsOpen={setShowCreateInviteLink}>
                <div className="space-y-4">
                    <div>
                        <AssessmentInviteLinkCreateForm
                            onSuccess={() => setShowCreateInviteLink(false)}
                        />
                    </div>
                </div>
            </Dialog>

            <div className="flex items-center justify-between">
                <Pills.Pill>Invite Links</Pills.Pill>
                <Buttons.Button
                    variant="solidXS"
                    color="slater"
                    onClick={() => {
                        setShowCreateInviteLink(true);
                    }}
                >
                    Add Invite Link
                </Buttons.Button>
            </div>

            <Tables.TablePaginated
                showHeader={false}
                pageSize={5}
                rows={assessment.invite_links.map((l) => {
                    return {
                        key: l.id,
                        cells: [
                            {
                                key: "name",
                                content: (
                                    <div className="flex items-center space-x-3">
                                        <div className="space-y-2">
                                            <div
                                                className="text-slate-900"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(l.id);
                                                }}
                                            >
                                                {l.name}
                                            </div>
                                        </div>
                                    </div>
                                ),
                            },
                            {
                                key: "url",
                                content: (
                                    <Buttons.Button
                                        color="slate"
                                        onClick={() => {
                                            const link = `${process.env.REACT_APP_FRONTEND_URL}/l/${l.id}/a/${assessment.id}`;
                                            navigator.clipboard.writeText(link);
                                        }}
                                    >
                                        Copy Link
                                    </Buttons.Button>
                                ),
                            },
                            {
                                key: "status",
                                content: (
                                    <Forms.GenericForm
                                        autosave={true}
                                        autosaveOnValueChange={true}
                                        showSubmitButton={false}
                                        initialValues={{
                                            active: l.active,
                                        }}
                                        onSubmit={async (values) => {
                                            return updateAssessmentInviteLink({
                                                id: assessment.id,
                                                body: {
                                                    lid: l.id,
                                                    ...values,
                                                },
                                            });
                                        }}
                                        fields={[
                                            {
                                                name: "active",
                                                type: "toggle",
                                                label: "Active",
                                            },
                                        ]}
                                    />
                                ),
                            },
                            {
                                key: "actions",
                                content: (
                                    <div className="flex items-center justify-end gap-x-4">
                                        <Buttons.ButtonActionsDropdown
                                            actions={[
                                                {
                                                    label: "Delete Link",
                                                    onClick: () => {
                                                        deleteAssessmentInviteLink({
                                                            id: assessment.id,
                                                            body: {
                                                                lid: l.id,
                                                            },
                                                        });
                                                    },
                                                    confirm: {
                                                        title: "Disable Link",
                                                        description: `Are you sure you want to disable this link? Candidates will no longer be able to use it to 
                                                        register for the assessment.`,
                                                        confirmLabel: "Remove Link",
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                ),
                            },
                        ],
                    };
                })}
            />
        </div>
    );
}
