import React from "react";

import { CandidateAssessmentContext } from "./CandidateAssessmentProvider";
import {
    AnimatePresenceGrow,
    Avatar,
    Avatars,
    Buttons,
    Pills,
    Utils,
    classNames,
    constants,
    useContext,
    useState,
} from "./tools";

// calculate score per module item, then create average
const calculateModuleScoreAverage = (module) => {
    if (module.score_average) {
        return Math.round(module.score_average);
    }

    const moduleItems = module.items;

    // if its mq, count as one item
    const mQItems = moduleItems.filter((item) => {
        return item.module_item_type == constants.MODULE_TYPE_MULTIPLE_CHOICE;
    });

    let items = [
        ...moduleItems.filter((item) => {
            return item.module_item_type != constants.MODULE_TYPE_MULTIPLE_CHOICE;
        }),
    ];

    if (mQItems.length) {
        const mQItemsScore = mQItems.reduce((acc, item) => {
            return acc + item.score;
        }, 0);
        const mQItemsPossibleScore = mQItems.reduce((acc, item) => {
            return acc + item.possible_score;
        }, 0);

        items.push({
            score: mQItemsScore,
            possible_score: mQItemsPossibleScore,
        });
    }

    // get percentage of each item
    const itemsScorePercentage = items.map((item) => {
        return calculateScorePercentage(item.score, item.possible_score);
    });

    // get average of all items
    const averageScorePercentage =
        itemsScorePercentage.reduce((acc, item) => {
            return acc + item;
        }, 0) / itemsScorePercentage.length;

    // convert back to score
    const averageScore = Math.round((averageScorePercentage / 100) * 100);
    return averageScore;
};

const calculateScorePercentage = (score, maxScore) => {
    // round to 0 decimal places
    return Math.round((score / maxScore) * 100);
};

const backgroundColorScore = (percentage) => {
    if (percentage < 50) {
        return "bg-red-300";
    } else if (percentage < 75) {
        return "bg-yellow-300";
    } else {
        return "bg-emerald-300";
    }
};

export default function CandidateSummaryModules({}) {
    const { assessment } = useContext(CandidateAssessmentContext);
    const { reviewers } = assessment;

    return (
        <div className="space-y-4">
            <Pills.Pill>Modules</Pills.Pill>
            <div className="flex flex-col gap-y-5">
                {assessment.modules.map((module) => {
                    return (
                        <div
                            className="w-100 grid grid-cols-2 gap-x-8 rounded-md bg-white p-5 shadow-md"
                            key={module.id}
                        >
                            <div className="col-span-1 text-sm">
                                <div className="flex gap-x-3">
                                    <div className="flex-shrink-0">
                                        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-slate-50">
                                            <img
                                                src={
                                                    constants.MODULE_TYPE_ICONS[module.module_type]
                                                }
                                                className="h-6 w-6"
                                            />
                                        </div>
                                    </div>
                                    <div className="min-w-0">
                                        <div className="truncate">{module.module.name}</div>
                                        <div className="truncate text-slate-400">
                                            {module.reviewed_at
                                                ? "Review Complete"
                                                : "Review Pending"}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {module.score === null ? (
                                <div className="col-span-1 flex items-center">
                                    {/* We'll have to check here if the user is part of reviwers */}

                                    <div className="flex w-full items-center justify-between">
                                        <div className="text-xs italic">Waiting for review...</div>
                                        <div className="flex justify-end ">
                                            {reviewers && reviewers.length > 0 && (
                                                <Avatars.AvatarGroup
                                                    maxDisplay={3}
                                                    items={reviewers.map((reviewer) => {
                                                        return {
                                                            hash: reviewer.d,
                                                            placeholder: reviewer.initials,
                                                            popoverContent: (
                                                                <div className="bg-white p-3 text-xs">
                                                                    <div className="font-bold">
                                                                        Reviewer
                                                                    </div>
                                                                    <div>{reviewer.full_name}</div>
                                                                </div>
                                                            ),
                                                        };
                                                    })}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="col-span-1">
                                    <div className="mb-1 flex justify-between">
                                        <span className="text-sm">Score</span>
                                        <span className="text-sm">
                                            {calculateModuleScoreAverage(module)}%
                                        </span>
                                    </div>
                                    <div
                                        className={classNames(
                                            "h-2.5 w-full rounded-full bg-slate-200",
                                            module.score === 0 && "bg-slate-200",
                                        )}
                                    >
                                        <AnimatePresenceGrow show={true} initial={true}>
                                            <div
                                                className={classNames(
                                                    Utils.backgroundColorScorePercentage(
                                                        calculateModuleScoreAverage(module),
                                                    ),
                                                    " h-2.5 rounded-full",
                                                )}
                                                style={{
                                                    width:
                                                        calculateModuleScoreAverage(module) + "%",
                                                }}
                                            ></div>
                                        </AnimatePresenceGrow>
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
