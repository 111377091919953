import { CheckCircleIcon } from "@heroicons/react/24/outline";
import React from "react";

import { CandidateAssessmentContext } from "./CandidateAssessmentProvider";
import { Buttons, classNames, moment, useContext } from "./tools";

const TimelineItem = ({
    date,
    message,
    actions = [],
    className,
    IconComponent,
    showIcon = false,
    ...props
}) => {
    if (!date) {
        return null;
    }

    return (
        <li className={classNames("pl-5", className)} {...props}>
            {IconComponent && showIcon ? (
                <IconComponent
                    className={classNames(
                        "absolute -left-2.5 z-10 mt-1.5 h-5 w-5 animate-pulse rounded-full bg-emerald-300 text-white",
                    )}
                />
            ) : (
                <div className="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-white bg-emerald-300 " />
            )}

            <time className="mb-1 text-sm font-normal leading-none text-slate-400 dark:text-slate-500">
                {moment(date).format("DD MMMM YYYY HH:mm")}
            </time>
            <h3 className="text-sm font-bold text-slate-600">{message}</h3>
            {actions.length != 0 ? (
                <div className="mt-2 flex gap-x-2">
                    {actions.map((action) => (
                        <Buttons.Button
                            key={action.label}
                            variant="solidXS"
                            color="slate"
                            className="bg-slate-300"
                            onClick={action.onClick}
                        >
                            {action.label}
                        </Buttons.Button>
                    ))}
                </div>
            ) : null}
        </li>
    );
};

export default function CandidateSummaryTimeline({}) {
    const { assessment } = useContext(CandidateAssessmentContext);
    return (
        <div className="">
            <ol className="relative flex flex-col gap-y-5  border-l border-blue-300">
                <TimelineItem
                    date={assessment.created_at}
                    message={`Invited by ${assessment.created_by?.full_name}`}
                />
                <TimelineItem date={assessment.started_at} message="Started Assessment" />
                <TimelineItem
                    date={assessment.completed_at}
                    message="Completed Assessment"
                    IconComponent={CheckCircleIcon}
                    showIcon={!assessment.reviewed_at}
                />
                <TimelineItem
                    date={assessment.reviewed_at}
                    message="Review Completed"
                    IconComponent={CheckCircleIcon}
                    showIcon={true}
                />
            </ol>
        </div>
    );
}
