import React from "react";

import {
    Api,
    Buttons,
    Disclosures,
    Fields,
    classNames,
    constants,
    useEffect,
    useParams,
    useState,
} from "./tools";

function validateModuleItem(mi) {
    let errors = {};
    // check if we have a title
    if (!mi.title) {
        errors.title = "Needs a title";
    }
    // add a "string" property to errors for easy consumption
    errors.string = Object.values(errors).join(", ");
    errors.has_errors = errors.string.length > 0;
    return errors;
}

export default function ModuleItemsGeneric({
    availableModuleTypes = constants.MODULE_TYPES_GENERIC,
}) {
    const [items, setItems] = useState([]);

    const { id } = useParams();
    const { data: module, isLoading, error } = Api.useGetModuleQuery(id);
    const [createModuleItem, { isLoading: isCreating }] = Api.useCreateModuleItemMutation();
    const [updateModuleItem, { isLoading: isUpdating }] = Api.useUpdateModuleItemMutation();
    const [deleteModuleItem, { isLoading: isDeleting }] = Api.useDeleteModuleItemMutation();

    const updateItem = (index, key, value) => {
        items[index][key] = value;
        setItems([...items]);
    };

    const determineOrder = () => {
        if (module.items.length > 0) {
            // Find the highest order
            return module.items.reduce((prev, current) =>
                prev.order > current.order ? prev : current,
            ).order;
        } else {
            return 0;
        }
    };

    const deriveItemsFromModuleItems = (moduleItems) => {
        const derivedItems = moduleItems.map((item) => {
            return {
                id: item.id,
                order: item.order,
                module_item_type: item.module_item_type,
                title: item.content?.title,
                candidate_brief: item.content?.candidate_brief,
            };
        });
        // do not override items without an id
        const stagedItems = items.filter((item) => !item.id);
        // unless they are already part of the derived items
        const filteredStagedItems = stagedItems.filter(
            (stagedItem) =>
                !derivedItems.find((derivedItem) => derivedItem.order == stagedItem.order),
        );

        setItems([...filteredStagedItems, ...derivedItems]);
    };

    useEffect(() => {
        if (module) {
            deriveItemsFromModuleItems(
                module.items.filter((item) =>
                    // create list of ids from available module types
                    availableModuleTypes
                        .map((moduleType) => moduleType.id)
                        .includes(item.module_item_type),
                ),
            );
        }
    }, [module]);

    return (
        <div className="grid grid-cols-3 gap-6">
            <div className="col-span-1">
                <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-slate-900">
                        Open-ended items
                    </h3>
                    <p className="mt-1 text-sm text-slate-600">
                        <strong> {items.length} </strong> item(s) in this module.
                    </p>
                </div>
            </div>
            <div className="col-span-2">
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                    <div className="flex flex-col gap-y-6 bg-white p-6">
                        <div className="flex gap-x-2">
                            {availableModuleTypes.map((moduleType) => {
                                return (
                                    <div
                                        key={moduleType.id}
                                        className="flex w-40 cursor-pointer flex-col items-center justify-center gap-y-2 rounded-md  bg-slate-100 py-4 px-2 text-center shadow-md transition-colors hover:bg-slate-50 "
                                        onClick={() => {
                                            const order = determineOrder() + 1;
                                            setItems([
                                                ...items,
                                                {
                                                    new: true,
                                                    title: "",
                                                    candidate_brief: "",
                                                    module_item_type: moduleType.id,
                                                    order: order,
                                                },
                                            ]);
                                        }}
                                    >
                                        <div>
                                            <img className="h-4 w-4" src={moduleType.icon} />
                                        </div>

                                        <div className="text-xs text-slate-900">
                                            {moduleType.label}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="grid grid-cols-1 gap-y-4">
                            {items
                                .sort((a, b) =>
                                    a.order > b.order ? 1 : b.order > a.order ? -1 : 0,
                                )
                                .map((item, index) => {
                                    return (
                                        <Disclosures.Disclosure
                                            key={index}
                                            open={item.new}
                                            panels={[
                                                {
                                                    title: item.title
                                                        ? `${item.title} ${
                                                              validateModuleItem(item).has_errors
                                                                  ? `(${
                                                                        validateModuleItem(item)
                                                                            .string
                                                                    })`
                                                                  : ""
                                                          }`
                                                        : "Untitled",
                                                    bgColor: item.ai_tool
                                                        ? "bg-purple-100"
                                                        : validateModuleItem(item).has_errors
                                                        ? "bg-orange-100"
                                                        : "bg-slate-50",

                                                    content: (
                                                        <div
                                                            key={index}
                                                            id={`item-${index}`}
                                                            className={classNames(
                                                                item.ai_tool
                                                                    ? "bg-purple-100"
                                                                    : "bg-slate-100",
                                                                " border-1 grid grid-cols-1 gap-y-4 border-slate-600 p-5",
                                                            )}
                                                        >
                                                            <div className="flex justify-end gap-x-3">
                                                                <Buttons.Button
                                                                    loading={
                                                                        isUpdating || isCreating
                                                                    }
                                                                    onClick={() => {
                                                                        const content = {
                                                                            title: item.title,
                                                                            candidate_brief:
                                                                                item.candidate_brief,
                                                                        };

                                                                        if (item.id) {
                                                                            updateModuleItem({
                                                                                id: module.id,
                                                                                body: {
                                                                                    miid: item.id,
                                                                                    content:
                                                                                        content,
                                                                                },
                                                                            });
                                                                        } else {
                                                                            createModuleItem({
                                                                                id: module.id,
                                                                                body: {
                                                                                    module_item_type:
                                                                                        item.module_item_type,
                                                                                    content:
                                                                                        content,
                                                                                    order: item.order,
                                                                                },
                                                                            });
                                                                        }
                                                                    }}
                                                                    variant="solidXS"
                                                                    color="lgreen"
                                                                >
                                                                    Save
                                                                </Buttons.Button>
                                                                <Buttons.Button
                                                                    color="slate"
                                                                    variant="solidXS"
                                                                    className={"bg-slate-200"}
                                                                    loading={isDeleting}
                                                                    onClick={() => {
                                                                        // add a confirmation

                                                                        if (item.id) {
                                                                            const confirm =
                                                                                window.confirm(
                                                                                    `Are you sure you want to delete this item?`,
                                                                                );
                                                                            if (confirm) {
                                                                                deleteModuleItem({
                                                                                    id: module.id,
                                                                                    body: {
                                                                                        miid: item.id,
                                                                                    },
                                                                                });
                                                                            }
                                                                        } else {
                                                                            const newItems = [
                                                                                ...items,
                                                                            ];
                                                                            newItems.splice(
                                                                                index,
                                                                                1,
                                                                            );
                                                                            setItems(newItems);
                                                                        }
                                                                    }}
                                                                >
                                                                    Delete Item
                                                                </Buttons.Button>
                                                            </div>

                                                            <Fields.TextFieldFree
                                                                label="Title"
                                                                type="text"
                                                                rows="3"
                                                                name={`items[${index}].title`}
                                                                value={item.title}
                                                                placeholder="Title, e.g. Screen Recording"
                                                                onChange={(e) => {
                                                                    updateItem(
                                                                        index,
                                                                        "title",
                                                                        e.target.value,
                                                                    );
                                                                }}
                                                                toolbar={
                                                                    <Fields.AIToolBar
                                                                        text={item.title}
                                                                        processResult={(text) => {
                                                                            updateItem(
                                                                                index,
                                                                                "title",
                                                                                text,
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                            />

                                                            <Fields.QuillFieldFree // candidate brief
                                                                label="Candidate Brief"
                                                                name={`items[${index}].candidate_brief`}
                                                                value={item.candidate_brief}
                                                                onChange={(value) => {
                                                                    updateItem(
                                                                        index,
                                                                        "candidate_brief",
                                                                        value,
                                                                    );
                                                                }}
                                                                toolbar={
                                                                    <Fields.AiToolbarGeneric
                                                                        text={item.candidate_brief}
                                                                        processResult={(text) => {
                                                                            updateItem(
                                                                                index,
                                                                                "candidate_brief",
                                                                                text,
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    ),
                                                },
                                            ]}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
