import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/es/integration/react";

import Account from "./Account";
import "./App.css";
import AssessmentCreate from "./AssessmentCreate";
import AssessmentCreateMagic from "./AssessmentCreateMagic";
import AssessmentCreateMagicFollowUp from "./AssessmentCreateMagicFollowUp";
import AssessmentEdit from "./AssessmentEdit";
import AssessmentSingle from "./AssessmentSingle";
import Assessments from "./Assessments";
import CandidateExternal from "./CandidateExternal";
import CandidateExternalLink from "./CandidateExternalLink";
import CandidateSingle from "./CandidateSingle";
import Candidates from "./Candidates";
import { ConfirmProvider } from "./ConfirmContext";
import Dashboard from "./Dashboard";
import DashboardNewAccount from "./DashboardNewAccount";
import ErrorFallback from "./ErrorFallback";
import Layout from "./Layout";
import LayoutAuth from "./LayoutAuth";
import LayoutCandidateExternal from "./LayoutCandidateExternal";
import LayoutSidebar from "./LayoutSidebar";
import Library from "./Library";
import LibraryCactus from "./LibraryCactus";
import Login from "./Login";
import ModuleCreate from "./ModuleCreate";
import ModuleSingle from "./ModuleSingle";
import NotFound from "./NotFound";
import Onboarding from "./Onboarding";
import OnboardingContinue from "./OnboardingContinue";
import OnboardingVerify from "./OnboardingVerify";
import Register from "./Register";
import RegisterVerifyEmail from "./RegisterVerifyEmail";
import ResetPassword from "./ResetPassword";
import ResetPasswordConfirm from "./ResetPasswordConfirm";
import RouteAuthenticated from "./RouteAuthenticated";
import SSO from "./SSO";
import SSOCallback from "./SSOCallback";
import Search from "./Search";
import Tasks from "./Tasks";
import TeamSingle from "./TeamSingle";
import Upgrade from "./Upgrade";
import UserInvite from "./UserInvite";
import { persistor, store } from "./store";

const errorHandler = (error, info) => {
    console.log(error, info);
    if (process.env.NODE_ENV === "production") {
        Sentry.captureException(error, { extra: info });
    }
};

function App() {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
            <ConfirmProvider>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Router>
                            <>
                                <Routes>
                                    <Route
                                        path="/"
                                        element={
                                            <RouteAuthenticated>
                                                <LayoutSidebar />
                                            </RouteAuthenticated>
                                        }
                                    >
                                        <Route exact path="/" element={<Dashboard />} />
                                        <Route exact path="/search" element={<Search />} />
                                        <Route exact path="/upgrade" element={<Upgrade />} />
                                        <Route exact path="/dashboard" element={<Dashboard />} />
                                        <Route exact path="/candidates" element={<Candidates />} />
                                        <Route exact path="/tasks" element={<Tasks />} />
                                        <Route
                                            exact
                                            path="/candidates/:id"
                                            element={<CandidateSingle />}
                                        />
                                        <Route exact path="/library" element={<Library />} />
                                        <Route
                                            exact
                                            path="/library/cactus"
                                            element={<LibraryCactus />}
                                        />
                                        <Route exact path="/account" element={<Account />} />
                                        <Route exact path="/teams/:id" element={<TeamSingle />} />
                                        <Route
                                            exact
                                            path="/assessments"
                                            element={<Assessments />}
                                        />
                                        <Route
                                            exact
                                            path="/library/assessments/create"
                                            element={<AssessmentCreate />}
                                        />
                                        <Route
                                            exact
                                            path="/library/assessments/create/magic"
                                            element={<AssessmentCreateMagic />}
                                        />
                                        <Route
                                            exact
                                            path="/library/assessments/create/magic/:amgrid"
                                            element={<AssessmentCreateMagicFollowUp />}
                                        />
                                        <Route
                                            exact
                                            path="/library/assessments/:id/edit"
                                            element={<AssessmentEdit />}
                                        />
                                        <Route
                                            exact
                                            path="/library/assessments/:id"
                                            element={<AssessmentSingle />}
                                        />

                                        <Route
                                            exact
                                            path="/library/modules/create"
                                            element={<ModuleCreate />}
                                        />
                                        <Route
                                            exact
                                            path="/library/modules/:id"
                                            element={<ModuleSingle />}
                                        />

                                        <Route path="*" element={<NotFound />} />
                                    </Route>
                                    <Route path="/auth" element={<LayoutAuth />}>
                                        <Route exact path="/auth/login" element={<Login />} />
                                        <Route exact path="/auth/register" element={<Register />} />
                                        <Route
                                            exact
                                            path="/auth/register/verify"
                                            element={<RegisterVerifyEmail />}
                                        />

                                        <Route exact path="/auth/sso" element={<SSO />} />
                                        <Route
                                            exact
                                            path="/auth/sso/callback"
                                            element={<SSOCallback />}
                                        />
                                        <Route
                                            exact
                                            path="/auth/invite/:uiid"
                                            element={<UserInvite />}
                                        />
                                        <Route
                                            exact
                                            path="/auth/reset-password"
                                            element={<ResetPassword />}
                                        />
                                        <Route
                                            exact
                                            path="/auth/reset-password/:token"
                                            element={<ResetPasswordConfirm />}
                                        />
                                        <Route path="/auth/*" element={<NotFound />} />
                                    </Route>

                                    <Route
                                        exact
                                        path="/onboarding/:olid" // org lead id
                                        element={<Onboarding />}
                                    />
                                    <Route
                                        exact
                                        path="/onboarding/verify"
                                        element={<OnboardingVerify />}
                                    />
                                    <Route
                                        exact
                                        path="/onboarding/continue"
                                        element={
                                            <RouteAuthenticated>
                                                <OnboardingContinue />
                                            </RouteAuthenticated>
                                        }
                                    />

                                    <Route path="/l" element={<LayoutCandidateExternal />}>
                                        <Route
                                            exact
                                            path="/l/:lid/a/:aid"
                                            element={<CandidateExternalLink />}
                                        />
                                    </Route>

                                    <Route path="/c" element={<LayoutCandidateExternal />}>
                                        <Route
                                            exact
                                            path="/c/:cid/a/:caid"
                                            element={<CandidateExternal />}
                                        />
                                    </Route>
                                </Routes>
                            </>
                        </Router>
                    </PersistGate>
                </Provider>
            </ConfirmProvider>
        </ErrorBoundary>
    );
}

export default App;
