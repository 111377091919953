import React from "react";

import { CandidateAssessmentContext } from "./CandidateAssessmentProvider";
import { Buttons, Cards, Comments, Pills, useContext } from "./tools";

export default function CandidateFeedback() {
    const { assessment } = useContext(CandidateAssessmentContext);

    const candidate = assessment.candidate;
    const feedback = assessment.candidate_feedback;

    if (!feedback) {
        return null;
    }

    return (
        <div className="space-y-4">
            <Pills.Pill>Candidate Feedback</Pills.Pill>
            <Comments.Comment
                className="bg-white"
                createdBy={{
                    d: candidate.d,
                    initials: candidate.initials,
                    first_name: candidate.first_name,
                }}
                createdAt={assessment.completed_at}
                content={feedback}
                toolbarBottom={<div></div>}
            />
        </div>
    );
}
