import { Tab } from "@headlessui/react";
import { Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import Appear from "./Appear";
import {
    AnimatePresence,
    Api,
    Fields,
    Forms,
    Tabs,
    UserContext,
    Utils,
    classNames,
    constants,
    useContext,
} from "./tools";

export default function ModuleCreate() {
    const [explain, setExplain] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    // if not Utils.isStaff(user), available module types is restricted to canCreateCustom = true
    const availableModuleTypes = constants.MODULE_TYPES.filter(
        (type) => Utils.isStaff(user) || type.canCreateCustom,
    ).map((type) => ({
        value: type.id,
        label: type.label,
        icon: <img src={type.icon} className="h-4 w-4" />,
    }));

    console.log(availableModuleTypes);

    return (
        <div className="flex flex-col">
            <main className="flex-1">
                <div className="relative">
                    <div className="pb-16">
                        <div className="px-4 sm:px-6 md:px-0">
                            <h5 className="text-3xl font-bold tracking-tight text-emerald-900">
                                Create new module
                            </h5>
                            <p className="mt-3 text-slate-500">
                                This wizard will guide you through the process of creating a new
                                module.
                            </p>
                        </div>

                        <Tabs
                            tabs={[
                                {
                                    name: "General",
                                    href: "#",
                                    current: true,
                                    component: (
                                        <div className="bg-slate-50 p-5">
                                            <div className="md:grid md:grid-cols-3 md:gap-6">
                                                <div className="md:col-span-1">
                                                    <div className="px-4 sm:px-0">
                                                        <h3 className="text-lg font-medium leading-6 text-slate-900">
                                                            General
                                                        </h3>
                                                        <p className="mt-1 text-sm text-slate-600">
                                                            General information about the module.
                                                        </p>

                                                        <AnimatePresence
                                                            show={explain != ""}
                                                            initial
                                                        >
                                                            <p className="border-1 border-dotted py-5 text-sm italic text-slate-500">
                                                                {explain}
                                                            </p>
                                                        </AnimatePresence>
                                                    </div>
                                                </div>
                                                <div className="mt-5 md:col-span-2 md:mt-0">
                                                    <div className="rounded-md shadow">
                                                        <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                                                            <Forms.GenericForm
                                                                onSuccess={(result) => {
                                                                    navigate(
                                                                        `/library/modules/${result.data?.id}?tab=content`,
                                                                    );
                                                                }}
                                                                mutation={
                                                                    Api.useCreateModuleMutation
                                                                }
                                                                fields={[
                                                                    {
                                                                        name: "name",
                                                                        label: "Module name",
                                                                        type: "text",
                                                                        onFocus: () =>
                                                                            setExplain(
                                                                                EXPLAIN_NAME,
                                                                            ),
                                                                        onBlur: () =>
                                                                            setExplain(""),
                                                                    },
                                                                    {
                                                                        name: "module_type",
                                                                        label: "Module type",
                                                                        initial: 1,
                                                                        type: "select_box",
                                                                        options:
                                                                            availableModuleTypes,
                                                                        onFocus: () =>
                                                                            setExplain(
                                                                                EXPLAIN_TYPE,
                                                                            ),
                                                                        onBlur: () =>
                                                                            setExplain(""),
                                                                    },
                                                                    {
                                                                        name: "time_limit",
                                                                        label: "Time limit",
                                                                        initial: 10,
                                                                        type: "number",
                                                                        onFocus: () =>
                                                                            setExplain(
                                                                                EXPLAIN_TIME_LIMIT,
                                                                            ),
                                                                        onBlur: () =>
                                                                            setExplain(""),
                                                                    },
                                                                    {
                                                                        name: "team_id",
                                                                        label: "Team",
                                                                        type: "team",
                                                                    },
                                                                ]}
                                                                submitButtonClassname="w-auto"
                                                                submitButtonLabel="Create module"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </div>
                </div>
            </main>
        </div>
    );
}

const EXPLAIN_TIME_LIMIT = (
    <>
        The time limit for the module in minutes. Candidates will be given this amount of time to
        complete the module. Leave blank or 0 for no time limit. We strongly recommend setting a
        time limit for benchmarking purposes.
    </>
);

const EXPLAIN_TYPE = (
    <>
        The type of module. Multiple choice modules will have a set of questions with a single
        correct answer. Google Doc will create a template on your behalf.
    </>
);

const EXPLAIN_NAME = (
    <>
        The name of the module. This will be displayed to candidates when they are taking the
        module.
    </>
);
