import {
    ArrowDownIcon,
    ArrowUturnLeftIcon,
    ChevronDownIcon,
    ChevronUpIcon,
} from "@heroicons/react/24/outline";
import React from "react";

import { CandidateAssessmentContext } from "./CandidateAssessmentProvider";
import {
    AnimatePresence,
    Api,
    Avatars,
    Buttons,
    Cards,
    Comments,
    Forms,
    Pills,
    useContext,
    useEffect,
    useRef,
    useState,
} from "./tools";

export default function CandidateComments() {
    const [showCreateComment, setShowCreateComment] = useState(false);
    const [reply, setReply] = useState(null); // commentId
    const { assessment } = useContext(CandidateAssessmentContext);
    const [openThreads, setOpenThreads] = useState({});
    const commentRef = useRef(null);

    const [createComment, { isLoading: isCreatingComment, error: createCommentError }] =
        Api.useCreateCandidateAsessmentCommentMutation();

    const candidate = assessment.candidate;
    const comments = assessment.comments;

    useEffect(() => {
        if (comments.length === 0) {
            setShowCreateComment(true);
        }
    }, [comments]);

    const renderComment = (comment, replies) => {
        return (
            <AnimatePresence initial={true} show={true}>
                <Comments.Comment
                    key={comment.id}
                    className="bg-white"
                    createdBy={comment.created_by}
                    createdAt={comment.created_at}
                    content={comment.comment}
                    toolbar={
                        <div>
                            <div
                                onClick={() => {
                                    setReply(comment.id);
                                    setOpenThreads((prev) => ({ ...prev, [comment.id]: true }));
                                    setTimeout(() => {
                                        commentRef.current?.focus();
                                    }, 100);
                                }}
                                className="flex cursor-pointer items-center gap-x-2 text-xs"
                            >
                                <ArrowUturnLeftIcon
                                    title="Reply"
                                    className="inline-block h-4 w-4"
                                />
                                <span className="">Reply</span>
                            </div>
                        </div>
                    }
                    toolbarBottom={<div>{replies}</div>}
                />
            </AnimatePresence>
        );
    };

    const renderForm = () => {
        return (
            <AnimatePresence initial={true} show={true}>
                <Forms.GenericForm
                    formClassName="space-y-[1rem]"
                    fields={[
                        {
                            labelBottom: (
                                <div className="text-slate-400">
                                    Comments are only visible to you and your team,
                                </div>
                            ),
                            name: "comment",
                            type: "quill",
                            required: true,
                            className: "bg-white",
                            placeholder:
                                comments?.length === 0
                                    ? "Write a comment..."
                                    : "Write a comment...",
                            autoFocus: true,
                            innerRef: commentRef,
                        },
                    ]}
                    onSubmit={async (values) => {
                        return await createComment({
                            id: assessment.id,
                            body: {
                                comment: values.comment,
                                reply_to: reply,
                            },
                        });
                    }}
                    onSuccess={(values, { resetForm }) => {
                        // reset form
                        setShowCreateComment(false);
                        setReply(null);
                        resetForm?.();
                    }}
                    submitButtonClassname=""
                />
            </AnimatePresence>
        );
    };

    const renderRepliesFaces = (comment) => {
        const replies = comments.filter((c) => c.reply_to === comment.id);
        if (replies.length === 0) {
            return null;
        }
        return (
            <div className="flex items-center gap-x-2">
                <Avatars.AvatarGroup
                    size="xs"
                    condensed
                    items={replies.map((r) => {
                        return { hash: r.created_by.d, placeholder: r.created_by.initials };
                    })}
                />
                <div className="text-xs font-bold">
                    {replies.length} {replies.length > 1 ? "Replies" : "Reply"}
                </div>
                {openThreads[comment.id] ? (
                    <div>
                        <ChevronUpIcon className="inline-block h-3 w-3" />
                    </div>
                ) : (
                    <div>
                        <ChevronDownIcon className="inline-block h-3 w-3" />
                    </div>
                )}
            </div>
        );
    };

    const renderComments = (comments, parentId = null) => {
        // reply count, down to 3 levels
        return comments
            .filter((comment) => comment.reply_to === parentId)
            .map((comment) => (
                <div className="space-y-4" key={comment.id}>
                    <div className="space-y-3">
                        <div>
                            {renderComment(
                                comment,
                                <div
                                    className="flex cursor-pointer justify-end"
                                    onClick={() => {
                                        setOpenThreads({
                                            ...openThreads,
                                            [comment.id]: !openThreads[comment.id],
                                        });
                                    }}
                                >
                                    {renderRepliesFaces(comment)}
                                </div>,
                            )}
                        </div>
                    </div>

                    {/* Recursive call to renderComments to show child comments */}
                    {openThreads[comment.id] && (
                        <div className="ml-10">{renderComments(comments, comment.id)}</div>
                    )}
                    {reply === comment.id && (
                        <div className="ml-10">
                            <AnimatePresence initial={true} show={true}>
                                {renderForm()}
                            </AnimatePresence>
                        </div>
                    )}
                </div>
            ));
    };

    return (
        <div className="space-y-4">
            <Pills.Pill>Comments</Pills.Pill>
            <div className="space-y-2">{renderComments(comments)}</div>
            {(showCreateComment && <div>{renderForm()}</div>) ||
                (reply == null && (
                    <div className="flex">
                        <Buttons.Button
                            onClick={() => {
                                setShowCreateComment(true);
                                commentRef.current?.focus();
                            }}
                        >
                            Add Comment
                        </Buttons.Button>
                    </div>
                ))}
        </div>
    );
}
