import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveFunnel } from "@nivo/funnel";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveRadar } from "@nivo/radar";
import { ResponsiveRadialBar } from "@nivo/radial-bar";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { ResponsiveSwarmPlot } from "@nivo/swarmplot";

import { Buttons, Cards, classNames } from "./tools";

export function Chart({
    variant,
    title,
    showTitle = true,
    subtitle,
    showSubtitle = true,
    data,
    className,
    chartClassName,
    download = false,
    ...props
}) {
    const defaultLineProps = {
        colors: { scheme: "nivo" },
        margin: { top: 5, right: 5, bottom: 5, left: 5 },
        xScale: {
            type: "time",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: "month",
        },
        xFormat: "time:%Y-%M",
        yScale: {
            type: "linear",
            stacked: false,
        },
        enableArea: false,
        axisLeft: null,
        axisBottom: {
            format: "%Y-%m",
            tickValues: "every month",
            legend: "",
            legendOffset: -12,
        },
        enablePointLabel: true,
        pointSize: 7,
        pointBorderWidth: 1,
        pointBorderColor: {
            from: "color",
            modifiers: [["darker", 0.3]],
        },
        useMesh: true,
        enableGridX: true,
        enableGridY: true,
        enableSlices: false,
        curve: "linear", // Curve interpolation
        lineWidth: 2, // Line width
        enablePoints: true, // Enable/disable points
        pointSize: 6, // Size of the points
        pointColor: { from: "color" }, // Method to compute points color
        pointBorderWidth: 0, // Width of the points border
        pointBorderColor: { theme: "background" }, // Method to compute points border color
        enablePointLabel: false, // Enable/disable points label
        enableGridX: true, // Enable/disable x grid
        enableGridY: true, // Enable/disable y grid
        isInteractive: true, // Enable/disable interactivity
        animate: true, // Enable/disable transitions
        motionConfig: "default", // Motion config for react-spring
    };

    const defaultBarProps = {
        colors: { scheme: "purple_blue_green" },
        enablePointLabel: false,
        enableLabel: false,
        padding: 0.3,
        indexBy: "id",
        keys: ["value"],
        groupMode: "stacked",
        layout: "vertical",
        valueScale: { type: "linear" },
        indexScale: { type: "band", round: true },
        reverse: false,
        minValue: "auto",
        maxValue: "auto",
        borderRadius: 0,
        borderWidth: 0,
        borderColor: { from: "color" },
        enableLabel: true,
        label: "formattedValue",
        labelSkipWidth: 0,
        labelSkipHeight: 0,
        labelTextColor: { from: "theme", theme: "labels.text.fill" },
        enableGridX: false,
        enableGridY: true,
        isInteractive: true,
        animate: true,
        motionConfig: "default",
    };

    const defaultRadarProps = {
        indexBy: "trait",
        keys: ["score"],
        maxValue: "auto",
        margin: { top: 70, right: 80, bottom: 40, left: 80 },
        curve: "linearClosed",
        borderWidth: 2,
        borderColor: { from: "color" },
        gridLevels: 5,
        gridShape: "circular",
        gridLabelOffset: 16,
        enableDots: true,
        dotSize: 8,
        dotColor: { theme: "background" },
        dotBorderWidth: 0,
        dotBorderColor: { from: "color" },
        enableDotLabel: true,
        dotLabel: "value",
        dotLabelYOffset: -12,
        colors: { scheme: "nivo" },
        fillOpacity: 0.25,
        blendMode: "multiply",
        animate: true,
        motionConfig: "wobbly",
        isInteractive: true,
        // ...rest of your default Radar chart props...
    };

    const defaultFunnelProps = {
        direction: "vertical",
        interpolation: "smooth",
        spacing: 0,
        shapeBlending: 0.66,
        valueFormat: "",
        colors: { scheme: "nivo" },
        fillOpacity: 1,
        borderWidth: 6,
        borderColor: { from: "color" },
        borderOpacity: 0.66,
        enableLabel: true,
        labelColor: { theme: "background" },
        enableBeforeSeparators: true,
        beforeSeparatorLength: 0,
        beforeSeparatorOffset: 0,
        enableAfterSeparators: true,
        afterSeparatorLength: 0,
        afterSeparatorOffset: 0,
        isInteractive: true,
        currentPartSizeExtension: 0,
        animate: true,
        motionConfig: "default",
        // ...rest of your default Funnel chart props...
    };

    const defaultRadialBarProps = {
        maxValue: "auto",
        margin: { top: 70, right: 80, bottom: 40, left: 80 },
        startAngle: 0,
        endAngle: 270,
        innerRadius: 0.3,
        padding: 0.2,
        padAngle: 0,
        cornerRadius: 0,
        colors: { scheme: "nivo" },
        borderWidth: 0,
        borderColor: { from: "color", modifiers: [["darker", 1]] },
        enableTracks: true,
        tracksColor: "rgba(0, 0, 0, .15)",
        enableRadialGrid: true,
        enableCircularGrid: true,
        enableLabels: false,
        labelsSkipAngle: 10,
        labelsRadiusOffset: 0.5,
        labelsTextColor: { theme: "labels.text.fill" },
        isInteractive: true,
        animate: true,
        motionConfig: "default",
    };

    const defaultScatterPlotProps = {
        xScale: { type: "linear", min: 0, max: "auto" },
        yScale: { type: "linear", min: 0, max: "auto" },
        colors: { scheme: "nivo" },
        enableGridX: true,
        enableGridY: true,
        nodeSize: 6,
        pixelRatio: 2,
        isInteractive: true,
        useMesh: true,
        animate: true,
        motionConfig: "default",
    };

    const defaultSwarmPlotProps = {
        valueScale: { type: "linear", min: 0, max: "auto" },
        size: { key: "percentage", values: [0, 100], sizes: [6, 20] },
        colors: { scheme: "nivo" },
        enableGridX: true,
        enableGridY: true,
        isInteractive: true,
        useMesh: true,
        animate: true,
        motionConfig: "default",
    };

    const downloadData = () => {
        // download data
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(data)], { type: "text/plain" });
        element.href = URL.createObjectURL(file);
        element.download = "data.json";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    };

    // render chart if data, else render "not enough data" message
    const renderChart = () => {
        if (data.length === 0) {
            return (
                <div className="flex h-60 flex-col items-center justify-center">
                    <div className="w-1/2 text-center text-sm text-slate-500">
                        Not enough data to display chart
                    </div>
                </div>
            );
        }
        return (
            <div className={classNames("h-60 bg-slate-50", chartClassName)}>
                {variant === "Line" ? (
                    <ResponsiveLine data={data} {...defaultLineProps} {...props} />
                ) : variant === "Bar" ? (
                    <ResponsiveBar data={data} {...defaultBarProps} {...props} />
                ) : variant === "Radar" ? (
                    <ResponsiveRadar data={data} {...defaultRadarProps} {...props} />
                ) : variant === "Funnel" ? (
                    <ResponsiveFunnel data={data} {...defaultFunnelProps} {...props} />
                ) : variant === "RadialBar" ? (
                    <ResponsiveRadialBar data={data} {...defaultRadialBarProps} {...props} />
                ) : variant === "SwarmPlot" ? (
                    <ResponsiveSwarmPlot data={data} {...defaultSwarmPlotProps} {...props} />
                ) : variant === "ScatterPlot" ? (
                    <ResponsiveScatterPlot data={data} {...defaultScatterPlotProps} {...props} />
                ) : null}
            </div>
        );
    };

    return (
        <div className={classNames("space-y-6", className)}>
            <div className="flex justify-between">
                {showTitle && (
                    <div className="chart-header">
                        <div>
                            <div className="font-bold text-slate-500">{title}</div>
                            {showSubtitle && (
                                <div className="text-xs font-bold text-slate-400">{subtitle}</div>
                            )}
                        </div>
                    </div>
                )}
                {download && (
                    <div className="flex justify-start self-start">
                        <Buttons.Button
                            onClick={() => {
                                downloadData();
                            }}
                            variant="solidXS"
                            color="transparent"
                        >
                            <CloudArrowDownIcon className="mr-1 h-4 w-4" />
                        </Buttons.Button>
                    </div>
                )}
            </div>

            {renderChart()}
        </div>
    );
}

const nivoColors = [
    "category10",
    "accent",
    "dark2",
    "paired",
    "pastel1",
    "pastel2",
    "set1",
    "set2",
    "set3",
    "brown_blueGreen",
    "purpleRed_green",
    "pink_yellowGreen",
    "purple_orange",
    "red_blue",
    "red_grey",
    "red_yellow_blue",
    "red_yellow_green",
    "spectral",
    "blues",
    "greens",
    "greys",
    "oranges",
    "purples",
    "reds",
    "blue_green",
    "blue_purple",
    "green_blue",
    "orange_red",
    "purple_blue_green",
    "purple_blue",
    "purple_red",
    "red_purple",
    "yellow_green_blue",
    "yellow_green",
    "yellow_orange_brown",
    "yellow_orange_red",
];
