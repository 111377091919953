import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Button } from "./Button";
import { TextField } from "./Fields";
import { authApi, authSlice } from "./store/auth";
import { Link } from "./tools";

export default function LoginForm() {
    const [nonFieldError, setNonFieldError] = React.useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [login, { isLoading, error }] = authApi.useLoginMutation();

    return (
        <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email("Please enter a valid email address")
                    .required("Please enter your email address"),
                password: Yup.string().required("Please enter your password"),
            })}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
                setNonFieldError(null);
                try {
                    const result = await login({
                        email: values.email,
                        password: values.password,
                    });
                    setSubmitting(false);
                    if (result.error) {
                        if (result.error.data.detail) {
                            setNonFieldError(result.error.data.detail);
                        }
                        for (const [key, value] of Object.entries(result.error.data)) {
                            setFieldError(key, value[0]);
                        }
                    }
                    if (result.data) {
                        dispatch(authSlice.actions.updateTokens(result.data));
                        navigate("/");
                    }
                } catch (err) {
                    console.log(err);
                    setSubmitting(false);
                }
            }}
        >
            {({ isSubmitting }) => (
                <Form className="grid grid-cols-1 gap-y-8">
                    <TextField type="email" name="email" label="Email address" />
                    <TextField
                        type="password"
                        name="password"
                        label={
                            <div className="flex w-full justify-between">
                                <span>Password</span>
                                <span>
                                    <Link className="text-xs" to="/auth/reset-password">
                                        Forgot your password?
                                    </Link>
                                </span>
                            </div>
                        }
                    />
                    <div className="space-y-2">
                        <Button
                            type="submit"
                            variant="solid"
                            color="lgreen"
                            className="w-full"
                            disabled={isSubmitting}
                            loading={isLoading}
                            loadingMessage="Signing in..."
                        >
                            <span>Sign in</span>
                        </Button>

                        {nonFieldError && (
                            <div className="text-sm text-red-600">{nonFieldError}</div>
                        )}
                    </div>
                </Form>
            )}
        </Formik>
    );
}
