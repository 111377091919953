import { Dialog, Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, BellIcon, Cog6ToothIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Link, NavLink, Outlet } from "react-router-dom";

import ErrorFallback from "./ErrorFallback";
import TasksListSidebar from "./TasksListSidebar";
import Logo from "./assets/img/logo.svg";
import { resetStateAction } from "./store/auth";
import {
    Api,
    Audio,
    Avatar,
    Avatars,
    Badges,
    Buttons,
    Fields,
    OrgLimitsContext,
    Spinner,
    Trial,
    UserContext,
    classNames,
    useContext,
    useDispatch,
    useEffect,
    useNavigate,
    usePLG,
    useRef,
} from "./tools";
import { useTasks } from "./useTasks";

const navigation = [
    { name: "Dashboard", to: "/" },
    { name: "Candidates", to: "/candidates" },
    { name: "Library", to: "/library" },
];

const userNavigation = [{ name: "Account", to: "/account" }];

export default function SidebarMain() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const searchInputRef = useRef(null);
    const { user } = useContext(UserContext);
    const { limits } = useContext(OrgLimitsContext);
    const { tasks } = useTasks(1, { search: null }, { refetchOnFocus: false });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { identify, capture, group, posthog } = usePLG();
    const {
        data: teams,
        isLoading,
        isFetching,
        error,
        refetch: getTeams,
    } = Api.useGetCurrentUserTeamsQuery(
        { search: null },
        {
            refetchOnFocus: false,
        },
    );

    useEffect(() => {
        // startup utems
        if (user && user.org && !user.org.onboarding_completed_at) {
            navigate(`/onboarding/continue`);
        }
        if (user && user.org && user.org.needs_verification) {
            navigate(`/onboarding/verify`);
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            identify(user.id, {
                name: user.full_name,
                email: user.email,
            });

            group("company", user.org.id);
        }
    }, [posthog, user]);

    if (isLoading) {
        return <Spinner />;
    }

    const renderBadge = (item) => {
        if (item.name === "Candidates") {
            const count = tasks?.count || 0;
            if (count > 0) {
                return <Badges.Badge label={count} svgClassName="fill-green-500" />;
            }
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        const search = searchInputRef.current.value;
        navigate(`/search?q=${search}`);
    };

    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button
                                            type="button"
                                            className="-m-2.5 p-2.5"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon
                                                className="h-6 w-6 text-white"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </Transition.Child>
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-emerald-400 px-6 pb-4">
                                    <div className="flex h-16 shrink-0 items-center">
                                        <Link to="/">
                                            <img className="h-8 w-auto" src={Logo} alt="Cactus" />
                                        </Link>
                                    </div>
                                    <nav className="flex flex-1 flex-col">
                                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                            <li>
                                                <ul role="list" className="-mx-2 space-y-1">
                                                    {navigation.map((item) => (
                                                        <li key={item.name}>
                                                            <NavLink
                                                                key={item.name}
                                                                to={item.to}
                                                                end={item.to === "/"}
                                                                className={(p) => {
                                                                    return classNames(
                                                                        p.isActive
                                                                            ? "bg-emerald-100 text-emerald-600"
                                                                            : "text-gray-700 hover:bg-emerald-50 hover:text-emerald-600",
                                                                        "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                                                                    );
                                                                }}
                                                            >
                                                                <span className="flex justify-between">
                                                                    <span>{item.name}</span>
                                                                </span>
                                                            </NavLink>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>

                                            <li className="mt-auto">
                                                <NavLink
                                                    to="/account"
                                                    className={(p) => {
                                                        return classNames(
                                                            p.isActive
                                                                ? "bg-emerald-100 text-emerald-600"
                                                                : "text-gray-700 hover:bg-emerald-50 hover:text-emerald-600",
                                                            "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                                                        );
                                                    }}
                                                >
                                                    <Cog6ToothIcon
                                                        className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                                                        aria-hidden="true"
                                                    />
                                                    Account
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                        <Link to="/">
                            <img className="h-8 w-auto" src={Logo} alt="Cactus" />
                        </Link>
                    </div>
                    <nav className="flex flex-1 flex-col">
                        <ul role="list" className="flex flex-1 flex-col gap-y-8">
                            <li>
                                <ul role="list" className="-mx-2 space-y-1">
                                    {navigation.map((item) => (
                                        <li key={item.name}>
                                            <NavLink
                                                key={item.name}
                                                to={item.to}
                                                end={item.to === "/"}
                                                className={(p) => {
                                                    return classNames(
                                                        p.isActive
                                                            ? "bg-gray-50 text-emerald-600"
                                                            : "text-gray-700 hover:bg-gray-50 hover:text-emerald-600",
                                                        "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                                                    );
                                                }}
                                            >
                                                <span className="flex w-full justify-between">
                                                    <span>{item.name}</span>
                                                    {renderBadge(item)}
                                                </span>
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li>
                                <Link
                                    to="/account"
                                    className="text-xs font-semibold leading-6 text-gray-400"
                                >
                                    Your Teams
                                </Link>
                                <ul role="list" className="-mx-2 mt-2 space-y-1">
                                    {teams.map((team) => (
                                        <li
                                            onClick={() => {
                                                navigator.clipboard.writeText(team.id);
                                            }}
                                            key={team.name}
                                            className="cursor-default"
                                        >
                                            <NavLink
                                                to={`/teams/${team.id}`}
                                                className={({ isActive }) => {
                                                    return classNames(
                                                        isActive
                                                            ? "bg-gray-50 text-emerald-600"
                                                            : "text-gray-700 hover:bg-gray-50 hover:text-emerald-600",
                                                        "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                                                    );
                                                }}
                                            >
                                                <Avatars.AvatarTeam size="s" team={team} />
                                                <span className="truncate">{team.name}</span>
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </li>

                            <TasksListSidebar />

                            <li className="mt-auto space-y-4">
                                {/* trial */}
                                {limits?.is_trial && (
                                    <Link to="/upgrade">
                                        <Trial.TrialComponent />
                                    </Link>
                                )}

                                <Link
                                    to="/account"
                                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-emerald-600"
                                >
                                    <Cog6ToothIcon
                                        className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-emerald-600"
                                        aria-hidden="true"
                                    />
                                    Account
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div className="lg:pl-64">
                <div className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                    <button
                        type="button"
                        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    {/* Separator */}
                    <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

                    <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                        <div className="relative flex flex-1 items-center" action="#" method="GET">
                            <form
                                className="relative flex flex-1"
                                action="#"
                                method="GET"
                                onSubmit={handleSearch}
                            >
                                <label htmlFor="search-field" className="sr-only">
                                    Search
                                </label>
                                <MagnifyingGlassIcon
                                    className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                                <input
                                    id="search-field"
                                    className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                    placeholder="Search..."
                                    type="search"
                                    name="search"
                                    ref={searchInputRef}
                                />
                            </form>
                        </div>
                        <div className="flex items-center gap-x-4 lg:gap-x-6">
                            <button
                                type="button"
                                className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                            >
                                <span className="sr-only">View notifications</span>
                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                            </button>

                            {/* Separator */}
                            <div
                                className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                                aria-hidden="true"
                            />

                            {/* Profile dropdown */}
                            <Menu as="div" className="relative">
                                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                    <span className="sr-only">Open user menu</span>
                                    <Avatar placeholder={user.initials} hash={user?.d} />
                                    <span className="hidden lg:flex lg:items-center">
                                        <span
                                            className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                                            aria-hidden="true"
                                        >
                                            {user.first_name}
                                        </span>
                                        <ChevronDownIcon
                                            className="ml-2 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        {userNavigation.map((item) => (
                                            <Menu.Item key={item.name}>
                                                {({ active }) => (
                                                    <NavLink
                                                        key={item.name}
                                                        to={item.to}
                                                        className={(p) => {
                                                            return classNames(
                                                                p.isActive ? "" : "",
                                                                " block px-4 py-2 text-sm text-slate-700 hover:bg-slate-200",
                                                            );
                                                        }}
                                                    >
                                                        {item.name}
                                                    </NavLink>
                                                )}
                                            </Menu.Item>
                                        ))}
                                        <Menu.Item key="logout">
                                            <NavLink
                                                to="#"
                                                className="block px-4 py-2 text-sm text-slate-700"
                                                onClick={() => {
                                                    dispatch(resetStateAction());
                                                }}
                                            >
                                                Sign out
                                            </NavLink>
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>
                <main className="min-h-[calc(100vh-65px)] bg-slate-50 sm:px-6 lg:px-8">
                    {/* Replace with your content */}
                    <div className="px-4 py-8 pb-20 sm:px-0">
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <Outlet />
                        </ErrorBoundary>
                    </div>
                    {/* /End replace */}
                </main>
            </div>
        </>
    );
}
