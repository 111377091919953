import CandidateCreateForm from "./CandidateCreateForm";
import {
    Api,
    Avatars,
    Buttons,
    Dialog,
    Fields,
    Link,
    Spinner,
    Tables,
    useDebounce,
    useEffect,
    useNavigate,
    useState,
} from "./tools";
import { useAssessments } from "./useAssessments";

export default function AssessmentList({ filters = {}, title = "Assessments", withSearch = true }) {
    const [showInviteCandidate, setShowInviteCandidate] = useState(false);
    const [inviteCandidateAssessmentId, setInviteCandidateAssessmentId] = useState(null);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(null);
    const debouncedSearch = useDebounce(search, 200);

    const updatedFilters = {
        search,
        ...filters,
    };

    const { assessments, isLoading, error } = useAssessments(page, updatedFilters);

    const prefetchAssessment = Api.usePrefetch("getAssessment");

    const [deleteAssessment, { isLoading: isDeleting }] = Api.useDeleteAssessmentMutation();
    const navigate = useNavigate();

    if (isLoading) {
        return <Spinner />;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    return (
        <>
            <div className="">
                <Dialog size="xl4" isOpen={showInviteCandidate} setIsOpen={setShowInviteCandidate}>
                    <CandidateCreateForm
                        assessmentId={inviteCandidateAssessmentId}
                        onSuccess={() => setShowInviteCandidate(false)}
                    />
                </Dialog>

                <Tables.TablePaginatedServer
                    title={title}
                    showHeader={false}
                    total={assessments?.count}
                    goToPage={setPage}
                    page={page}
                    withSearch={withSearch && setSearch}
                    rows={assessments?.results.map((assessment) => {
                        return {
                            key: assessment.id,
                            cells: [
                                {
                                    key: "name",
                                    content: (
                                        <Link to={`/library/assessments/${assessment.id}`}>
                                            <div className="flex items-center space-x-3">
                                                <div>
                                                    <div className="text-gray-900">
                                                        {assessment.name}
                                                    </div>
                                                    <div className="text-gray-500"></div>
                                                </div>
                                            </div>
                                        </Link>
                                    ),
                                },
                                {
                                    key: "team",
                                    content: (
                                        <div className="flex items-center space-x-3">
                                            <div className="flex-shrink-0">
                                                <Avatars.AvatarTeam team={assessment.team} />
                                            </div>
                                            <div>
                                                <div className="text-gray-900">
                                                    {assessment.team.name}
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    key: "actions",
                                    content: (
                                        <div className="flex justify-end gap-x-2">
                                            <Buttons.Button
                                                variant="solidXS"
                                                color="lgreen"
                                                onClick={() => {
                                                    setShowInviteCandidate(true);
                                                    setInviteCandidateAssessmentId(assessment.id);
                                                }}
                                                onMouseEnter={() => {
                                                    prefetchAssessment(assessment.id, {
                                                        ifOlderThan: 60,
                                                    });
                                                }}
                                            >
                                                Invite Candidate
                                            </Buttons.Button>
                                            <Buttons.ButtonActionsDropdown
                                                actions={[
                                                    {
                                                        label: "Edit",
                                                        onClick: () => {
                                                            navigate(
                                                                `/library/assessments/${assessment.id}/edit`,
                                                            );
                                                        },
                                                    },
                                                    {
                                                        label: "Delete",
                                                        confirm: {
                                                            title: "Delete Assessment",
                                                            description: `Are you sure you want to delete ${assessment.name}? This action cannot be undone.`,
                                                        },
                                                        onClick: async () => {
                                                            const result = await deleteAssessment(
                                                                assessment.id,
                                                            );
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    ),
                                },
                            ],
                        };
                    })}
                />
            </div>
        </>
    );
}
