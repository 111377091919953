import { Api } from "./tools";
import { useQuery } from "./useQuery";

export function useTasks(page, filters, options) {
    const {
        data: tasks,
        isLoading,
        error,
    } = useQuery(Api.useGetCurrentUserTasksQuery, page, filters, options);

    return {
        tasks,
        isLoading,
        error,
    };
}
