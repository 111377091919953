import React, { createContext, useState } from "react";

import { Api, Spinner } from "./tools";

export const OrgLimitsContext = createContext();

export function OrgLimitsProvider({ children }) {
    const { data: limits, isLoading, error } = Api.useGetOrgLimitsQuery();

    if (isLoading) {
        return <Spinner center />;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    return <OrgLimitsContext.Provider value={{ limits }}>{children}</OrgLimitsContext.Provider>;
}
