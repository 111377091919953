import { classNames } from "./tools";

export function Badge({
    label,
    className,
    svgClassName = "fill-slate-300",
    showDot = true,
    children,
}) {
    const badgeClass = classNames(
        "inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200",
        className,
    );

    const svgClass = classNames("h-1.5 w-1.5", svgClassName);

    return (
        <span className={badgeClass}>
            {showDot && (
                <svg className={svgClass} viewBox="0 0 6 6" aria-hidden="true">
                    <circle cx={3} cy={3} r={3} />
                </svg>
            )}

            {label || children}
        </span>
    );
}
