import React from "react";

import { authApi } from "./store/auth";
import { Buttons, Fields, Forms, classNames } from "./tools";

export default function ResetPasswordForm({ onSuccess }) {
    return (
        <Forms.GenericForm
            initialValues={{
                email: "",
            }}
            fields={[
                {
                    name: "email",
                    label: "Please enter your email address",
                    type: "email",
                },
            ]}
            mutation={authApi.useResetPasswordMutation}
            onSuccess={onSuccess}
        />
    );
}
