import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Papercups } from "@papercups-io/chat-widget";
import { forwardRef } from "react";
import { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { PropTypes, Spinner, classNames, useConfirm } from "./tools";

const baseStyles = {
    solid: "truncate group inline-flex items-center justify-center rounded py-3 px-4 text-xs font-semibold focus:outline-none focus-visible:outline-none uppercase disabled:opacity-50",
    solidXS:
        "truncate group inline-flex items-center justify-center rounded py-2 px-3 text-xs font-semibold focus:outline-none focus-visible:outline-none uppercase disabled:opacity-50",
    outline:
        "group inline-flex ring-1 items-center justify-center rounded py-3 px-4 text-xs focus:outline-none focus-visible:outline-none uppercase",
    leftAligned:
        "group inline-flex items-center justify-start rounded py-3 px-4 text-xs font-semibold focus:outline-none focus-visible:outline-none uppercase disabled:opacity-50",
};

let variantStyles = {
    solid: {
        slate: "bg-slate-100 text-slate-800 hover:bg-slate-300 active:bg-slate-300 focus-visible:outline-slate-900",
        slater: "bg-slate-200 text-slate-800 hover:bg-slate-300 active:bg-slate-300 focus-visible:outline-slate-900",
        blue: "bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100",
        green: "bg-emerald-600 text-white hover:text-slate-100 hover:bg-emerald-500 active:bg-emerald-800 active:text-emerald-100",
        lgreen: "bg-emerald-100 text-emerald-700 hover:text-emerald-900 hover:bg-emerald-300 active:bg-emerald-300 active:text-emerald-900",
        lorange:
            "bg-orange-200 text-orange-700 hover:text-orange-900 hover:bg-orange-300 active:bg-orange-300 active:text-orange-900",
        lpurple:
            "bg-purple-200 text-purple-700 hover:text-purple-900 hover:bg-purple-300 active:bg-purple-300 active:text-purple-900",
        lred: "bg-red-200 text-red-700 hover:text-red-900 hover:bg-red-300 active:bg-red-300 active:text-red-900",
        lblue: "bg-blue-200 text-blue-700 hover:text-blue-900 hover:bg-blue-300 active:bg-blue-300 active:text-blue-900",
        lindigo:
            "bg-indigo-200 text-indigo-700 hover:text-indigo-900 hover:bg-indigo-300 active:bg-indigo-300 active:text-indigo-900",
        white: "bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600",
        lamber: "bg-amber-200 text-amber-700 hover:text-amber-900 hover:bg-amber-300 active:bg-amber-300 active:text-amber-900",
        transparent:
            "bg-transparent text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600",
    },

    outline: {
        slate: "ring-slate-200 text-slate-700 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600",
        green: "ring-emerald-300 text-emerald-700 hover:text-emerald-900 hover:ring-emerald-300 active:bg-emerald-100 active:text-emerald-600",
        white: "ring-slate-700 text-white hover:ring-slate-500 active:ring-slate-700 active:text-slate-400",
    },
};

variantStyles = {
    ...variantStyles,
    solidXS: variantStyles.solid,
    leftAligned: variantStyles.solid,
};

export function Button({
    variant = "solidXS",
    color = "slate",
    loading = false,
    loadingMessage = "Loading...",
    className,
    href,
    hrefExternal,
    ...props
}) {
    className = classNames(baseStyles[variant], variantStyles[variant][color], className);

    return href ? (
        <Link to={href} className={classNames("no-underline", className)} {...props} />
    ) : hrefExternal ? (
        <a
            href={hrefExternal}
            target="_blank"
            className={classNames("no-underline", className)}
            {...props}
        />
    ) : (
        <>
            {(loading && (
                <button disabled className={className} {...props}>
                    <Spinner size="sm" className="mr-1" />
                    <span className="">{loadingMessage}</span>
                </button>
            )) || <button className={className} {...props} />}
        </>
    );
}

export const RefButton = forwardRef((props, ref) => {
    let { variant = "solid", color = "lgreen", className } = props;
    className = classNames(baseStyles[variant], variantStyles[variant][color], className);
    return (
        <button className={className} {...props} ref={ref}>
            {props.children}
        </button>
    );
});

function withConfirmation(WrappedComponent) {
    /**
     * @param {Object} props
     * @param {Object} [props.confirm]
     * @param {string} props.confirm.title - The title of the confirmation dialog.
     * @param {string} props.confirm.description - The description of the confirmation dialog.
     * @param {string} [props.confirm.okLabel] - The label of the confirmation button.
     * @param {string} [props.confirm.cancelLabel] - The label of the cancel button.
     * @param {React.Component} [props.confirm.content] - The content of the confirmation dialog.
     * @param {(event: React.MouseEvent<HTMLElement>) => void} [props.onClick]
     */
    function WrappedWithConfirmation({ confirm: confirmOptions, onClick, ...props }) {
        const confirm = useConfirm();
        const handleClick = async (event) => {
            if (confirmOptions) {
                try {
                    await confirm(confirmOptions);
                    onClick && onClick(event);
                } catch (error) {
                    // Confirmation canceled
                }
            } else {
                onClick && onClick(event);
            }
        };
        return <WrappedComponent onClick={handleClick} {...props} />;
    }

    return WrappedWithConfirmation;
}

const ButtonWithConfirmation = withConfirmation(Button);
export { ButtonWithConfirmation };

export function ButtonActionsDropdown({ actions = [], label = "Actions", ellipsis = false }) {
    // example: actions = [{label: "Edit", onClick: () => {}}, {label: "Delete", onClick: () => {}}]
    const confirm = useConfirm();

    return (
        <div className="relative">
            <Menu as="div" className="relative inline-block">
                {ellipsis ? (
                    <Menu.Button>
                        <EllipsisVerticalIcon className="h-5 w-5 cursor-pointer text-slate-500" />
                    </Menu.Button>
                ) : (
                    <Menu.Button as={RefButton} variant="solidXS" color="slate">
                        {label}
                        <ChevronDownIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                )}

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className={classNames(
                            "absolute right-0 z-10 mt-2 max-h-96 max-w-[250px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
                            actions.length > 5 &&
                                "scrollbar-thin scrollbar-thumb-slate-200 overflow-y-scroll",
                        )}
                    >
                        <div className="px-1 py-1 ">
                            {actions?.map((action, index) => (
                                <Menu.Item key={index}>
                                    {({ active }) => (
                                        <button
                                            className={classNames(
                                                "w-full truncate rounded-md px-2 py-2 text-left text-sm hover:bg-slate-100 hover:text-slate-900",
                                                action.className,
                                            )}
                                            onClick={async (event) => {
                                                if (action.confirm) {
                                                    try {
                                                        await confirm(action.confirm);
                                                        action.onClick && action.onClick(event);
                                                    } catch (error) {
                                                        // Confirmation canceled
                                                    }
                                                } else {
                                                    action.onClick && action.onClick(event);
                                                }
                                            }}
                                        >
                                            {action.label}
                                        </button>
                                    )}
                                </Menu.Item>
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}

export function ButtonSupport({ className, email = false, ...props }) {
    if (email) {
        return (
            <Button
                className={classNames(className)}
                hrefExternal="mailto:hello@cactushire.com"
                {...props}
            >
                Email Support
            </Button>
        );
    }
    return (
        <Button
            onClick={() => {
                Papercups.open();
            }}
            className={classNames(className)}
            {...props}
        >
            {props.children || "Message Support"}
        </Button>
    );
}
