import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AIToolsMQItems from "./AIToolsMQItems";
import Appear from "./Appear";
import { Button } from "./Button";
import { AIToolBar, SelectField, TextField, TextFieldFree } from "./Fields";
import { useGetModuleQuery, useUpdateModuleMutation } from "./store/api";
import {
    Api,
    Buttons,
    Disclosures,
    UserContext,
    Utils,
    classNames,
    constants,
    useContext,
} from "./tools";

function validateModuleItem(mi) {
    let errors = {};
    // check if we have at least 2 answers
    if (mi.answers.length < 2) {
        errors.answers = "Needs at least 2 answers";
    }
    // check if we have at least 1 correct answer
    if (mi.answers.filter((a) => a.correct).length < 1) {
        errors.answers = "Needs at least 1 correct answer";
    }

    // add a "string" property to errors for easy consumption
    errors.string = Object.values(errors).join(", ");
    errors.has_errors = errors.string.length > 0;

    return errors;
}

export default function ModuleItems() {
    const [explain, setExplain] = useState(null);
    const [items, setItems] = useState([]);
    const [showAITools, setShowAITools] = useState(false);
    const [scrollToItem, setScrollToItem] = useState(0);

    const { user } = useContext(UserContext);
    const { id } = useParams();
    const { data: module, isLoading, error } = useGetModuleQuery(id);
    const [createModuleItem, { isLoading: isCreating }] = Api.useCreateModuleItemMutation();
    const [updateModuleItem, { isLoading: isUpdating }] = Api.useUpdateModuleItemMutation();
    const [deleteModuleItem, { isLoading: isDeleting }] = Api.useDeleteModuleItemMutation();

    const updateItem = (index, key, value) => {
        items[index][key] = value;
        setItems([...items]);
    };

    const updateAnswer = (index, answerIndex, key, value) => {
        items[index].answers[answerIndex][key] = value;
        setItems([...items]);
    };

    const determineOrder = () => {
        if (module.items.length > 0) {
            // Find the highest order
            return module.items.reduce((prev, current) =>
                prev.order > current.order ? prev : current,
            ).order;
        } else {
            return 0;
        }
    };

    const parseAIToolsResults = (results, prompt) => {
        // We need to parse the results from the AI tools to match the format of the items
        const latestOrder = determineOrder();
        const newItems = results.map((item, index) => {
            return {
                order: latestOrder + index + 1,
                module_item_type: constants.MODULE_TYPE_MULTIPLE_CHOICE,
                question: item.question,
                explanation: item.explanation,
                ai_tool: true,
                ai_prompt: prompt,
                answers: item.answers.map((answer) => {
                    return {
                        answer: answer.answer,
                        correct: answer.correct,
                    };
                }),
            };
        });
        setItems([...items, ...newItems]);
        setShowAITools(false);
    };

    const copyItemJsonRepresentationToClipboard = (item) => {
        const json = JSON.stringify([
            item.map((item) => {
                return {
                    order: item.order,
                    question: item.question,
                    explanation: item.explanation,
                    answers: item.answers.map((answer) => {
                        return {
                            answer: answer.answer,
                            correct: answer.correct,
                        };
                    }),
                };
            }),
        ]);
        navigator.clipboard.writeText(json);
    };

    const deriveItemsFromModuleItems = (moduleItems) => {
        const derivedItems = moduleItems.map((item) => {
            return {
                id: item.id,
                order: item.order,
                module_item_type: item.module_item_type,
                question: item.content?.question,
                explanation: item.content?.explanation,
                answers: item.content?.answers?.map((answer) => {
                    return {
                        answer: answer.answer,
                        correct: answer.correct,
                    };
                }),
            };
        });
        // do not override items without an id
        const stagedItems = items.filter((item) => !item.id);
        // unless they are already part of the derived items
        const filteredStagedItems = stagedItems.filter(
            (stagedItem) =>
                !derivedItems.find((derivedItem) => derivedItem.order == stagedItem.order),
        );

        setItems([...filteredStagedItems, ...derivedItems]);
    };

    // When a new item is added, scroll to the new item
    useEffect(() => {
        if (items.length > 0 && scrollToItem > 0) {
            const lastItemElement = document.getElementById(`item-${scrollToItem - 1}`);
            lastItemElement?.scrollIntoView();
        }
    }, [scrollToItem]);

    useEffect(() => {
        if (module) {
            deriveItemsFromModuleItems(
                module.items.filter(
                    (item) => item.module_item_type == constants.MODULE_TYPE_MULTIPLE_CHOICE,
                ),
            );
        }
    }, [module]);

    const TBar = () => {
        return (
            <>
                <div className="flex gap-x-3">
                    <Button
                        color="slate"
                        onClick={() => {
                            // get the last order number and add 1
                            const order = determineOrder() + 1;
                            setItems([
                                ...items,
                                {
                                    new: true,
                                    question: "",
                                    explanation: "",
                                    module_item_type: constants.MODULE_TYPE_MULTIPLE_CHOICE,
                                    order: order,
                                    answers: [
                                        { answer: "", correct: false },
                                        { answer: "", correct: false },
                                        { answer: "", correct: false },
                                        { answer: "", correct: false },
                                    ],
                                },
                            ]);
                            setScrollToItem(order);
                        }}
                    >
                        Add Item
                    </Button>
                    {Utils.isStaff(user) ? (
                        <Button
                            color="lpurple"
                            onClick={() => {
                                setShowAITools(!showAITools);
                            }}
                        >
                            {showAITools ? "Hide" : "Show"} AI Tools
                        </Button>
                    ) : null}
                </div>

                <div>
                    <Appear show={showAITools}>
                        <AIToolsMQItems
                            module={module}
                            items={items}
                            processResult={(results, prompt) => {
                                parseAIToolsResults(results, prompt);
                            }}
                        />
                    </Appear>
                </div>
            </>
        );
    };

    return (
        <div className="grid grid-cols-3 gap-6">
            <div className="col-span-1">
                <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-slate-900">
                        Multiple-choice
                    </h3>
                    <p className="mt-1 text-sm text-slate-600">
                        <strong> {items.length} </strong> questions(s) in this module.
                    </p>
                </div>
            </div>
            <div className="col-span-2">
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                    <div className="space-y-6 bg-white p-6">
                        <TBar />

                        <div className="grid grid-cols-1 gap-y-4">
                            {items
                                .sort((a, b) =>
                                    a.order > b.order ? 1 : b.order > a.order ? -1 : 0,
                                )
                                .map((item, index) => {
                                    return (
                                        <Disclosures.Disclosure
                                            key={index}
                                            open={item.new}
                                            panels={[
                                                {
                                                    title: item.question
                                                        ? `${item.question} ${
                                                              validateModuleItem(item).has_errors
                                                                  ? `(${
                                                                        validateModuleItem(item)
                                                                            .string
                                                                    })`
                                                                  : ""
                                                          }`
                                                        : "Untitled",
                                                    bgColor: item.ai_tool
                                                        ? "bg-purple-100"
                                                        : validateModuleItem(item).has_errors
                                                        ? "bg-orange-100"
                                                        : "bg-slate-50",

                                                    content: (
                                                        <div
                                                            key={index}
                                                            id={`item-${index}`}
                                                            className={classNames(
                                                                item.ai_tool
                                                                    ? "bg-purple-100"
                                                                    : "bg-slate-100",
                                                                " border-1 grid grid-cols-1 gap-y-4 border-slate-600 p-5",
                                                            )}
                                                        >
                                                            <div className="flex justify-end gap-x-3">
                                                                <Buttons.Button
                                                                    loading={
                                                                        isUpdating || isCreating
                                                                    }
                                                                    onClick={() => {
                                                                        const content = {
                                                                            question: item.question,
                                                                            explanation:
                                                                                item.explanation,
                                                                            answers:
                                                                                item.answers.map(
                                                                                    (answer) => {
                                                                                        return {
                                                                                            answer: answer.answer,
                                                                                            correct:
                                                                                                answer.correct,
                                                                                        };
                                                                                    },
                                                                                ),
                                                                        };

                                                                        if (item.id) {
                                                                            updateModuleItem({
                                                                                id: module.id,
                                                                                body: {
                                                                                    miid: item.id,
                                                                                    content:
                                                                                        content,
                                                                                },
                                                                            });
                                                                        } else {
                                                                            createModuleItem({
                                                                                id: module.id,
                                                                                body: {
                                                                                    module_item_type:
                                                                                        item.module_item_type,
                                                                                    content:
                                                                                        content,
                                                                                    order: item.order,
                                                                                    ai_generated:
                                                                                        item.ai_tool,
                                                                                    ai_prompt:
                                                                                        item.ai_prompt,
                                                                                },
                                                                            });
                                                                        }
                                                                    }}
                                                                    variant="solidXS"
                                                                    color="lgreen"
                                                                >
                                                                    Save
                                                                </Buttons.Button>
                                                                <Buttons.Button
                                                                    color="slate"
                                                                    variant="solidXS"
                                                                    className={"bg-slate-200"}
                                                                    loading={isDeleting}
                                                                    onClick={() => {
                                                                        // add a confirmation

                                                                        if (item.id) {
                                                                            const confirm =
                                                                                window.confirm(
                                                                                    `Are you sure you want to delete this item?`,
                                                                                );
                                                                            if (confirm) {
                                                                                deleteModuleItem({
                                                                                    id: module.id,
                                                                                    body: {
                                                                                        miid: item.id,
                                                                                    },
                                                                                });
                                                                            }
                                                                        } else {
                                                                            const newItems = [
                                                                                ...items,
                                                                            ];
                                                                            newItems.splice(
                                                                                index,
                                                                                1,
                                                                            );
                                                                            setItems(newItems);
                                                                        }
                                                                    }}
                                                                >
                                                                    Delete Item
                                                                </Buttons.Button>
                                                            </div>

                                                            <TextFieldFree
                                                                label="Question"
                                                                type="textarea"
                                                                rows="3"
                                                                name={`items[${index}].question`}
                                                                value={item.question}
                                                                placeholder="Question"
                                                                explain="The question of the item."
                                                                explainOnFocus={setExplain}
                                                                onChange={(e) => {
                                                                    updateItem(
                                                                        index,
                                                                        "question",
                                                                        e.target.value,
                                                                    );
                                                                }}
                                                                toolbar={
                                                                    <AIToolBar
                                                                        text={item.question}
                                                                        processResult={(text) => {
                                                                            updateItem(
                                                                                index,
                                                                                "question",
                                                                                text,
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                            />

                                                            <div className="grid grid-cols-1 gap-y-4 p-5">
                                                                {item.answers.map(
                                                                    (answer, answerIndex) => {
                                                                        return (
                                                                            <Disclosures.Disclosure
                                                                                key={answerIndex}
                                                                                open={answer.new}
                                                                                panels={[
                                                                                    {
                                                                                        title:
                                                                                            answer.answer ||
                                                                                            "Untitled",
                                                                                        bgColor:
                                                                                            answer.correct
                                                                                                ? "bg-green-100"
                                                                                                : "bg-slate-50",
                                                                                        content: (
                                                                                            <div className="">
                                                                                                <TextFieldFree
                                                                                                    label="Answer"
                                                                                                    type="textarea"
                                                                                                    rows="3"
                                                                                                    name={`items[${index}].answers[${answerIndex}].answer`}
                                                                                                    value={
                                                                                                        answer.answer
                                                                                                    }
                                                                                                    placeholder="Answer"
                                                                                                    onChange={(
                                                                                                        e,
                                                                                                    ) => {
                                                                                                        updateAnswer(
                                                                                                            index,
                                                                                                            answerIndex,
                                                                                                            "answer",
                                                                                                            e
                                                                                                                .target
                                                                                                                .value,
                                                                                                        );
                                                                                                    }}
                                                                                                    toolbar={
                                                                                                        <AIToolBar
                                                                                                            text={
                                                                                                                answer.answer
                                                                                                            }
                                                                                                            processResult={(
                                                                                                                text,
                                                                                                            ) => {
                                                                                                                updateAnswer(
                                                                                                                    index,
                                                                                                                    answerIndex,
                                                                                                                    "answer",
                                                                                                                    text,
                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                    }
                                                                                                />
                                                                                                <div className="flex items-center justify-between">
                                                                                                    <div className="mt-3 flex items-center">
                                                                                                        <input
                                                                                                            id={`items[${index}].answers[${answerIndex}].correct`}
                                                                                                            name={`items[${index}].answers[${answerIndex}].correct`}
                                                                                                            type="checkbox"
                                                                                                            checked={
                                                                                                                answer.correct
                                                                                                            }
                                                                                                            className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                                                                                                            onChange={(
                                                                                                                e,
                                                                                                            ) => {
                                                                                                                updateAnswer(
                                                                                                                    index,
                                                                                                                    answerIndex,
                                                                                                                    "correct",
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .checked,
                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                        <label
                                                                                                            htmlFor={`items[${index}].answers[${answerIndex}].correct`}
                                                                                                            className="ml-3 block text-sm font-medium text-gray-700"
                                                                                                        >
                                                                                                            Correct
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        onClick={() => {
                                                                                                            item.answers.splice(
                                                                                                                answerIndex,
                                                                                                                1,
                                                                                                            );
                                                                                                            setItems(
                                                                                                                [
                                                                                                                    ...items,
                                                                                                                ],
                                                                                                            );
                                                                                                        }}
                                                                                                        className="cursor-pointer text-xs font-bold italic"
                                                                                                    >
                                                                                                        Remove
                                                                                                        Answer
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ),
                                                                                    },
                                                                                ]}
                                                                            />
                                                                        );
                                                                    },
                                                                )}

                                                                <div className="flex items-center justify-between">
                                                                    <div className="flex space-x-2">
                                                                        <Button
                                                                            variant="solidXS"
                                                                            className="bg-slate-200"
                                                                            color="slate"
                                                                            onClick={() => {
                                                                                item.answers.push({
                                                                                    answer: "",
                                                                                    correct: false,
                                                                                    new: true,
                                                                                });
                                                                                setItems([
                                                                                    ...items,
                                                                                ]);
                                                                            }}
                                                                        >
                                                                            Add Answer
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <TextFieldFree
                                                                label="Explanation"
                                                                type="textarea"
                                                                rows="3"
                                                                name={`items[${index}].explanation`}
                                                                value={item.explanation}
                                                                placeholder="Explanation"
                                                                explain="The explanation of the item."
                                                                explainOnFocus={setExplain}
                                                                onChange={(e) => {
                                                                    updateItem(
                                                                        index,
                                                                        "explanation",
                                                                        e.target.value,
                                                                    );
                                                                }}
                                                                toolbar={
                                                                    <AIToolBar
                                                                        text={item.explanation}
                                                                        processResult={(text) => {
                                                                            updateItem(
                                                                                index,
                                                                                "explanation",
                                                                                text,
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    ),
                                                },
                                            ]}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
