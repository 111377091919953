import DocsIcon from "./assets/img/docs.svg";
import SheetsIcon from "./assets/img/sheets.svg";
import SlidesIcon from "./assets/img/slides.svg";
import { Api, Buttons, Spinner, classNames, constants, useParams } from "./tools";

export default function ModuleItemExternalContent() {
    const { id } = useParams();
    const { data: module, isLoading, error } = Api.useGetModuleQuery(id);

    const [openExternalFile, { isLoading: isExternalFileLoading }] =
        Api.useOpenModuleItemExternalFileMutation();

    // get the module item thats the google doc, google sheet or google slide
    const googleDocModuleItem = module?.items?.find(
        (moduleItem) => moduleItem.module_item_type == module.module_type,
    );

    if (!googleDocModuleItem) {
        return (
            <div className="flex w-full flex-col items-center gap-y-4">
                <div className="text-sm italic">
                    Creating {constants.MODULE_TYPE_LABELS[module.module_type]}...
                </div>

                <Spinner />
            </div>
        );
    }

    const googleFileType = constants.MODULE_TYPE_LABELS[googleDocModuleItem.module_item_type];
    const isFileCreated = googleDocModuleItem.content?.g_file_url;

    return (
        <div className="grid grid-cols-3 gap-6">
            <div className="col-span-1">
                <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-slate-900">
                        {googleFileType}
                    </h3>
                </div>
            </div>
            <div className="col-span-2">
                <div className="relative space-y-6 rounded-md bg-slate-50 p-6 shadow">
                    <div className="text-sm italic">
                        We shared a {googleFileType} with you. The file acts as a template.
                        Candidates will receive a unique copy of the template to work on.
                    </div>
                    <div>
                        {isFileCreated ? (
                            <div className="flex gap-x-4">
                                <Buttons.Button
                                    loading={isExternalFileLoading}
                                    className={classNames(
                                        "",
                                        constants.GOOGLE_FILE_COLORS[
                                            googleDocModuleItem.module_item_type
                                        ],
                                    )}
                                    onClick={async () => {
                                        const result = await openExternalFile({
                                            id: module.id,
                                            body: {
                                                miid: googleDocModuleItem.id,
                                            },
                                        });
                                        if (result.data?.url) {
                                            window.open(result.data.url, "_blank");
                                        }
                                    }}
                                    color="slate"
                                >
                                    <img
                                        className="mr-2 h-4 w-4"
                                        src={
                                            constants.GOOGLE_FILE_ICONS[
                                                googleDocModuleItem.module_item_type
                                            ]
                                        }
                                    />
                                    <span>Open in {googleFileType}s</span>
                                </Buttons.Button>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}
