import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const isTokenExpired = (token) => {
    if (token && token.exp) {
        return 1000 * token.exp - new Date().getTime() < 5000;
    }
    return true;
};

const RouteAuthenticated = ({ children }) => {
    const { refresh, access } = useSelector((state) => state.authSlice);
    if (isTokenExpired(refresh)) {
        return <Navigate to="/auth/login" />;
    }
    return children;
};

export default RouteAuthenticated;
