import React, { useEffect } from "react";
import { Fragment } from "react";

import {
    AnimatePresence,
    Api,
    Buttons,
    Cards,
    Link,
    Tab,
    classNames,
    useNavigate,
    useParams,
    useSearchParams,
    useState,
} from "./tools";

export function TabList({ tabs }) {
    return (
        <Tab.List as="nav" className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
                <Tab key={tab.name} as={Fragment} disabled={tab.disabled}>
                    {({ selected }) => (
                        <div
                            className={classNames(
                                selected
                                    ? "border-emerald-500 text-emerald-600"
                                    : "border-transparent text-slate-400",
                                " cursor-pointer whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium focus-visible:outline-0",
                            )}
                        >
                            {tab.name}
                        </div>
                    )}
                </Tab>
            ))}
        </Tab.List>
    );
}

export function Tabs({
    tabs = [],
    selected = 0,
    animateInitial = false,
    persistSearchParams = false,
    card = false,
}) {
    const [selectedIndex, setSelectedIndex] = useState(selected);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const tab_param = searchParams.get("tab");

    useEffect(() => {
        if (tab_param) {
            const index = tabs.findIndex((tab) => tab.href?.toLowerCase() == tab_param);
            if (index > -1 && index != selectedIndex) {
                setSelectedIndex(index);
            }
        }
        return () => {};
    }, [tab_param]);

    // warn if tab has no href
    tabs.forEach((tab, index) => {
        if (!tab.href) {
            console.warn("Tab has no href", tab);
        }
    });

    return (
        <Tab.Group
            manual
            defaultIndex={0}
            selectedIndex={selectedIndex}
            onChange={(i) => {
                setSelectedIndex(i);
                const href = tabs[i].href;
                if (href && href != "#") {
                    if (persistSearchParams) {
                        searchParams.set("tab", href);
                        setSearchParams(searchParams);
                    } else {
                        setSearchParams({
                            tab: href,
                        });
                    }
                } else {
                    searchParams.delete("tab");
                    setSearchParams(searchParams);
                }
            }}
        >
            <div className="px-4 sm:px-6 md:px-0">
                <div className="py-6">
                    <div className="hidden lg:block">
                        <div className="border-b border-gray-200">
                            <TabList tabs={tabs} />
                        </div>
                    </div>
                </div>
            </div>

            <Tab.Panels>
                {tabs.map((tab, index) => (
                    <Tab.Panel key={index}>
                        {({ selected }) => (
                            <AnimatePresence initial={animateInitial} show={selected}>
                                <div className="w-100">
                                    {card ? (
                                        <Cards.Card>{tab.component}</Cards.Card>
                                    ) : (
                                        <>{tab.component}</>
                                    )}
                                </div>
                            </AnimatePresence>
                        )}
                    </Tab.Panel>
                ))}
            </Tab.Panels>
        </Tab.Group>
    );
}
