import React from "react";

import {
    Api,
    Buttons,
    OrgLimitsContext,
    Progress,
    Spinner,
    classNames,
    moment,
    useContext,
} from "./tools";

export function TrialComponent() {
    const { limits } = useContext(OrgLimitsContext);

    if (limits.trial_expired) {
        return (
            <div className=" flex flex-col items-center justify-center space-y-4 rounded-md bg-slate-50 p-5 text-center shadow-md">
                <div className="prose prose-sm">
                    <h5>
                        <span className="font-semibold">Upgrade now</span>
                    </h5>
                    <p>
                        Your free trial has expired. Upgrade to a paid plan to continue using
                        Cactus.
                    </p>
                    <Buttons.Button color="lgreen">Upgrade Account</Buttons.Button>
                </div>
            </div>
        );
    }

    return (
        <div className="prose prose-sm space-y-4">
            <p>
                Your free trial will expire{" "}
                <span className="font-semibold">{moment().to(limits?.trial_ends_at)}.</span>
            </p>
            <div>
                <Progress.ProgressBar
                    color="emerald"
                    size="xs"
                    value={limits?.trial_days_remaining}
                    max={limits?.trial_days_total}
                    labelPosition="hidden"
                />
            </div>
            <div>
                <p>
                    During your free trial, you may invite up to{" "}
                    <span className="font-semibold">
                        {limits?.trial_max_candidates} candidates.
                    </span>{" "}
                    You have invited{" "}
                    <span className="font-semibold">{limits?.candidates_invited}</span> candidates.
                </p>
                <div>
                    <Progress.ProgressBar
                        color="emerald"
                        size="xs"
                        value={limits?.candidates_invited}
                        max={limits?.trial_max_candidates}
                        labelPosition="hidden"
                    />
                </div>
            </div>
        </div>
    );
}
