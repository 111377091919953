import React, { createContext, useState } from "react";

import { ApiCandidate, Spinner, useParams } from "./tools";

export const CandidateExternalContext = createContext();

export function CandidateExternalProvider({ children }) {
    const { cid, caid } = useParams();
    const {
        data: assessment,
        isLoading,
        error,
    } = ApiCandidate.useGetAssessmentQuery({ cid, caid }, {});

    if (isLoading) {
        return <Spinner center />;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    return (
        <CandidateExternalContext.Provider value={{ assessment }}>
            {children}
        </CandidateExternalContext.Provider>
    );
}
