import React from "react";

import {
    Api,
    Buttons,
    Fields,
    Forms,
    Popovers,
    Spinner,
    Tags,
    constants,
    useEffect,
} from "./tools";

export default function CactusLibraryItemEditForm({ cliid }) {
    const {
        data: cactusLibraryItem,
        isLoading,
        error,
    } = Api.useGetLibraryCactusItemQuery(cliid, {
        refetchOnMountOrArgChange: true,
    });
    const [staffAction, { isLoading: isStaffActionLoading, error: staffActionError }] =
        Api.useLibraryCactusStaffActionMutation();

    if (isLoading) {
        return <Spinner size="md" center />;
    }

    return (
        <div>
            <Forms.GenericForm
                submitButtonLabel="Save"
                submitButtonClassname="self-start"
                initialValues={cactusLibraryItem}
                fields={[
                    {
                        name: "name",
                        label: "Name",
                        type: "text",
                    },
                    {
                        name: "description",
                        label: (
                            <div className="flex justify-between">
                                <Fields.Label>Description</Fields.Label>
                                <div>
                                    <Buttons.ButtonWithConfirmation
                                        confirm={{
                                            title: "Generate Summary",
                                            description:
                                                "This action can take a few minutes. You must not make any edits while it is running.",
                                            okLabel: "Generate",
                                        }}
                                        onClick={async () => {
                                            staffAction({
                                                id: cliid,
                                                action: "generate_summary",
                                                body: {},
                                            });
                                        }}
                                    >
                                        Generate Summary
                                    </Buttons.ButtonWithConfirmation>
                                </div>
                            </div>
                        ),
                        type: "quill",
                    },
                    {
                        name: "tags",
                        label: (
                            <Popovers.PopoverLabel label="Tags" position="right" className="mb-0">
                                <div className="w-96 space-y-4 p-5 text-xs">
                                    <div>Main Categories - Include to show up in sidebar</div>
                                    <Tags.TagList
                                        className="flex-wrap gap-y-4"
                                        tags={constants.ASSESSMENT_CATEGORIES.map((t) => {
                                            return {
                                                tag: t.name,
                                                className: "bg-emerald-200 text-xs",
                                                onClick: (tag) => {
                                                    navigator.clipboard.writeText(tag.tag);
                                                },
                                            };
                                        })}
                                    />
                                </div>
                            </Popovers.PopoverLabel>
                        ),
                        type: "tags",
                    },
                    {
                        name: "active",
                        label: "Published",
                        labelSecondary:
                            "Toggling this will immediately hide this item in the library.",
                        type: "toggle",
                    },
                ]}
                onSubmit={async (values) => {
                    return staffAction({
                        id: cliid,
                        action: "update",
                        body: values,
                    });
                }}
            />
        </div>
    );
}
