import {
    Middleware,
    PayloadAction,
    createAction,
    createSlice,
    isRejectedWithValue,
} from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import axios, { AxiosError } from "axios";
import jwtDecode from "jwt-decode";
import localForage from "localforage";
import { persistReducer } from "redux-persist";

const initialState = {};

export const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        updateTokens(state, action) {
            state.access = {
                token: action.payload.access,
                ...jwtDecode(action.payload.access),
            };

            state.refresh = {
                token: action.payload.refresh,
                ...jwtDecode(action.payload.refresh),
            };
        },
    },
});

export const authReducer = persistReducer(
    {
        key: "rtk:auth",
        storage: localForage,
        whitelist: ["access", "refresh"],
    },
    authSlice.reducer,
);

export const RESET_STATE_ACTION_TYPE = "resetState";
export const resetStateAction = createAction(RESET_STATE_ACTION_TYPE, () => {
    return { payload: null };
});

export const AUTH_API_REDUCER_KEY = "authApi";
export const authApi = createApi({
    reducerPath: AUTH_API_REDUCER_KEY,
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
    }),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (body) => {
                return {
                    url: "/api/token/obtain",
                    method: "POST",
                    body: { ...body },
                };
            },
        }),
        resetPassword: builder.mutation({
            query: (body) => {
                return {
                    url: "/api/password",
                    method: "POST",
                    body: { ...body },
                };
            },
        }),
        register: builder.mutation({
            query: (body) => {
                return {
                    url: "/api/register",
                    method: "POST",
                    body: { ...body },
                };
            },
        }),
        getOrgLead: builder.query({
            query: (olid) => ({
                url: "/api/register",
                method: "GET",
                params: { olid },
            }),
        }),
        ssoCallback: builder.mutation({
            query: ({ body }) => {
                return {
                    url: "/api/sso",
                    method: "POST",
                    body: { ...body },
                };
            },
        }),
        getUserInvite: builder.query({
            query: (uiid) => ({
                url: "/api/invite",
                method: "GET",
                params: { uiid },
            }),
        }),
        userInvite: builder.mutation({
            query: (body) => {
                return {
                    url: "/api/invite",
                    method: "POST",
                    body: { ...body },
                };
            },
        }),
    }),
});

const axiosInstance = axios.create({
    baseURL: "/api",
    headers: {
        accept: `application/json`,
    },
});

const mutex = new Mutex();
const axiosBaseQuery =
    () =>
    async (requestOpts, { dispatch, getState }) => {
        await mutex.waitForUnlock();
        try {
            const token = getState().authSlice.access?.token;
            const result = await axiosInstance({
                ...requestOpts,
                headers: {
                    ...requestOpts.headers,
                    Authorization: `Bearer ${token}`,
                },
                data: requestOpts.body,
            });

            return { data: result.data, meta: { headers: result.headers } };
        } catch (axiosError) {
            if (axiosError.response?.status === 401) {
                if (!mutex.isLocked()) {
                    mutex.acquire();
                    try {
                        const result = await axiosInstance.post("/token/refresh", {
                            refresh: getState().authSlice.refresh.token,
                        });

                        const { access, refresh } = result.data;
                        dispatch(authSlice.actions.updateTokens({ access, refresh }));
                        try {
                            const newResult = await axiosInstance({
                                ...requestOpts,
                                headers: {
                                    ...requestOpts.headers,
                                    Authorization: `Bearer ${access}`,
                                },
                                data: requestOpts.body,
                            });
                            return { data: newResult.data, headers: newResult.headers };
                        } catch (axiosError) {
                            return {
                                error: {
                                    status: axiosError.response?.status,
                                    data: axiosError.response?.data,
                                },
                            };
                        }
                    } catch (error) {
                        // TODO: handle more gracefully
                        //dispatch(resetStateAction());
                    } finally {
                        mutex.release();
                    }
                } else {
                    await mutex.waitForUnlock();
                    return axiosBaseQuery()(requestOpts, { getState });
                }
            } else {
                return {
                    error: {
                        status: axiosError.response?.status,
                        data: axiosError.response?.data,
                    },
                };
            }
        }
    };

export const authBaseQuery = axiosBaseQuery();
export const {
    useLoginMutation,
    useRegisterMutation,
    useSsoCallbackMutation,
    useUserInviteMutation,
    useGetUserInviteQuery,
    useGetOrgLeadQuery,
    useResetPasswordMutation,
} = authApi;
export const { updateTokens } = authSlice.actions;
