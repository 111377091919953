import { random } from "lodash";
import React from "react";

import { Buttons, Cards, Fields, classNames, constants, useState } from "./tools";

function DefaultNoResults({
    className,
    title = "No results found",
    description = "Try adjusting your search or filter to find what you're looking for.",
    ...props
}) {
    return (
        <div className={classNames("text-center", className)} {...props}>
            <h3 className="text-lg font-medium text-slate-900">{title}</h3>
            <p className="mt-2 text-sm text-slate-500">{description}</p>
        </div>
    );
}

export function Table({ headers, rows, showHeader = true, ...props }) {
    return (
        <div className="rounded-md bg-white shadow">
            <table className="min-w-full divide-y divide-slate-300">
                {showHeader && (
                    <thead className="bg-slate-50">
                        <tr>
                            {headers.map((header, index) => (
                                <th
                                    key={header.key}
                                    scope="col"
                                    className={classNames(
                                        "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-900 sm:pl-6",
                                        (index == 0 && "pl-6") ||
                                            (index == headers.length - 1 && "pr-6"), // first or last
                                        index != 0 && "table-cell", // not first
                                    )}
                                >
                                    {header.content}
                                </th>
                            ))}
                        </tr>
                    </thead>
                )}

                <tbody className="divide-y divide-slate-200 bg-white">
                    {rows.map((row, index) => (
                        <tr
                            className={classNames(
                                "transition-all hover:bg-slate-50",
                                row.className,
                            )}
                            key={row.key}
                        >
                            {row.cells.map((cell, index) => (
                                <td
                                    key={cell.key}
                                    className={classNames(
                                        "px-3 py-4 text-sm text-slate-500 lg:table-cell",
                                        (index == 0 &&
                                            "w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-slate-900 sm:w-auto sm:max-w-none sm:pl-6") ||
                                            (index == row.cells.length - 1 && "pr-6"), // first or last
                                        index != 0 && "table-cell", // not first
                                        cell.className,
                                    )}
                                >
                                    {cell.content}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export function TablePaginated({ headers, rows = [], pageSize = 10, ...props }) {
    const [page, setPage] = useState(0);
    const totalPages = Math.ceil(rows.length / pageSize);
    const pageRows = rows.slice(page * pageSize, (page + 1) * pageSize);
    const pages = Array.from({ length: totalPages }, (_, i) => i);
    const showPagination = totalPages > 1;

    const goTopage = (page) => {
        setPage(page);
        window.scrollTo(0, 0);
    };

    return (
        <div className="">
            <Table headers={headers} rows={pageRows} {...props} />
            {showPagination && (
                <div className="py-3 ">
                    <div className="flex justify-between gap-x-4">
                        <Buttons.Button
                            color="slate"
                            variant="solidXS"
                            onClick={() => goTopage(page - 1)}
                            disabled={page == 0}
                        >
                            Previous
                        </Buttons.Button>
                        <Buttons.Button
                            color="slate"
                            variant="solidXS"
                            onClick={() => goTopage(page + 1)}
                            disabled={page == totalPages}
                        >
                            Next
                        </Buttons.Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export function TablePaginatedServer({
    headers,
    rows = [],
    page = 1,
    total,
    goToPage,
    withSearch,
    title,
    noResultsComponent = <DefaultNoResults />,
    ...props
}) {
    const totalPages = Math.ceil(total / constants.DEFAULT_PAGE_SIZE);
    const showPagination = totalPages > 1;

    return (
        <div className="flex flex-col gap-y-4">
            <div className="flex flex-row items-center justify-between">
                {title && (
                    <div className="text-left">
                        <div>
                            <h5 className="text-md font-bold tracking-tight text-slate-500">
                                {title}
                            </h5>
                        </div>
                        <div className="text-sm text-slate-400">
                            {total} {total == 1 ? "result" : "results"}
                        </div>
                    </div>
                )}
                {withSearch && (
                    <div>
                        <Fields.TextFieldFree
                            className="w-full"
                            onChange={(e) => {
                                withSearch(e.target.value);
                            }}
                            placeholder="Search..."
                        />
                    </div>
                )}
            </div>

            <Table headers={headers} rows={rows} {...props} />

            {showPagination && (
                <div className="">
                    <div className="flex gap-x-4">
                        <div className="flex">
                            <Buttons.Button
                                color="slate"
                                variant="solidXS"
                                onClick={() => goToPage(page - 1)}
                                disabled={page == 1}
                            >
                                Previous
                            </Buttons.Button>
                            <Buttons.Button
                                color="slate"
                                variant="solidXS"
                                onClick={() => goToPage(page + 1)}
                                disabled={page == totalPages}
                            >
                                Next
                            </Buttons.Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

/**
 * TinyTable component
 *
 * Props:
 * - rows: An array of objects, where each object represents a row in the table. Each object should have the following properties:
 *   - title: A string that will be displayed in the <dt> element.
 *   - description: A string that will be displayed in the <dd> element.
 *   - icon: A Heroicon React component that will be displayed in the <dt> element.
 * - className: A string or an array of strings that will be used as the class name(s) for the <dl> element. This can be used to apply custom styles.
 */
export function TinyTable({ rows, className }) {
    return (
        <dl className={classNames("text-sm/6 border-t border-dashed border-white/10", className)}>
            {rows.map((row, index) => (
                <div key={index} className="border-b border-dashed border-white/10 py-4 sm:flex">
                    <dt className="flex items-start gap-x-3 pr-6 font-semibold text-white sm:w-2/5 sm:flex-none">
                        <div className="flex-none rounded-full bg-zinc-900 ring-1 ring-white/10">
                            <row.icon className="h-6 w-6 fill-white/40" aria-hidden="true" />
                        </div>
                        {row.title}
                    </dt>
                    <dd className="mt-1 pl-9 text-zinc-400 sm:mt-0 sm:w-3/5 sm:flex-none sm:pl-0">
                        {row.description}
                    </dd>
                </div>
            ))}
        </dl>
    );
}
