import ResetPasswordForm from "./ResetPasswordForm";
import { Buttons, Link, useState } from "./tools";

export default function ResetPassword() {
    const [success, setSuccess] = useState(false);

    if (success) {
        return (
            <div className="space-y-4">
                <div className="prose-md prose">
                    <h1>Reset Password </h1>
                    <p>
                        If your email address exists in our system, you will receive a link to reset
                        your password shortly.
                    </p>
                </div>
                <div>
                    <Buttons.Button href="/auth/login">Back to sign in</Buttons.Button>
                </div>
            </div>
        );
    }

    return (
        <div className="space-y-4">
            <div className="prose-md prose">
                <h1>Reset Password</h1>
                <p>Enter your email address and we'll send you a link to reset your password.</p>
            </div>

            <ResetPasswordForm
                onSuccess={() => {
                    setSuccess(true);
                }}
            />
        </div>
    );
}
