import React from "react";

import FriendHRPersonImage from "./assets/img/friendly_hr_person.png";
import { Buttons } from "./tools";

export default function CandidateExternalContact({ assessment }) {
    return null;
    return (
        <div className="grid gap-y-4">
            <div className="rounded-md bg-emerald-200 ">
                <img height={100} src={FriendHRPersonImage} alt="" />
            </div>
            <div>
                Your contact person is <b className="text-emerald-500">Sarah Doe</b>. Please reach
                out to them if you have any questions.
            </div>
            {/* <Buttons.Button>Email Sarah Doe</Buttons.Button> */}
        </div>
    );
}
