import { PauseIcon, PlayIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";

import { Spinner } from "./tools";

export function Player({ children, url }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [progress, setProgress] = useState(0);
    const [blobUrl, setBlobUrl] = useState(null);
    const [currentTime, setCurrentTime] = useState(0);
    const audioRef = React.useRef(null);

    const playSound = () => {
        audioRef.current.play();
        setIsPlaying(true);
    };

    const pauseSound = () => {
        audioRef.current.pause();
        setIsPlaying(false);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    };

    const handleProgressClick = (e) => {
        const audio = audioRef.current;
        const progressContainer = e.currentTarget;
        const boundingRect = progressContainer.getBoundingClientRect();
        const clickX = e.clientX - boundingRect.left;
        const width = boundingRect.width;
        const duration = audio.duration;
        const currentTime = (clickX / width) * duration;
        audio.currentTime = currentTime;
        if (!isPlaying) {
            playSound();
        }
    };

    useEffect(() => {
        const audio = audioRef.current;

        const handlePlay = () => {
            setIsPlaying(true);
        };

        const handlePause = () => {
            setIsPlaying(false);
        };

        const handleProgress = () => {
            const currentTime = audio.currentTime;
            const duration = audio.duration;
            const progress = (currentTime / duration) * 100;
            setProgress(progress);
            setCurrentTime(currentTime);
        };

        if (audio) {
            audio.addEventListener("play", handlePlay);
            audio.addEventListener("pause", handlePause);
            audio.addEventListener("timeupdate", handleProgress);
        }

        return () => {
            if (audio) {
                audio.removeEventListener("play", handlePlay);
                audio.removeEventListener("pause", handlePause);
                audio.removeEventListener("timeupdate", handleProgress);
            }
        };
    }, [audioRef.current]);

    useEffect(() => {
        const fetchAudio = async () => {
            const response = await fetch(url);
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            setBlobUrl(blobUrl);
            setIsLoading(false);
        };

        fetchAudio();

        return () => {
            if (blobUrl) {
                URL.revokeObjectURL(blobUrl);
            }
        };
    }, []);

    return (
        <div className="flex flex-col gap-y-8">
            <div className="w-100 grid grid-cols-2 gap-x-8 rounded-md bg-white p-5 shadow-md">
                <div className="">{children}</div>
                <div className="flex items-center gap-x-4">
                    <div>
                        <button
                            disabled={isLoading}
                            onClick={() => (isPlaying ? pauseSound() : playSound())}
                            className="rounded-full bg-white p-1 shadow-md"
                        >
                            {isLoading ? (
                                <Spinner size="sm" className="h-5 w-5" />
                            ) : isPlaying ? (
                                <PauseIcon className="h-5 w-5 text-slate-500" />
                            ) : (
                                <PlayIcon className="h-5 w-5 text-slate-500" />
                            )}
                        </button>
                    </div>
                    <div
                        onClick={handleProgressClick}
                        className="h-3 cursor-pointer rounded-md bg-gray-200"
                        style={{ width: "100%" }}
                    >
                        <div className="h-3 bg-slate-500" style={{ width: `${progress}%` }}></div>
                    </div>
                    <div className="text-right text-sm">{formatTime(currentTime)}</div>
                </div>
            </div>
            {!isLoading && (
                <audio ref={audioRef}>
                    <source src={blobUrl} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            )}
        </div>
    );
}
