import { ArrowPathIcon, SparklesIcon } from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";

import MQPreview from "./MQPreview";
import CactusWorkInProgressImage from "./assets/img/cactus_work_in_progress.png";
import GhibliImage1 from "./assets/img/ghibli1.png";
import R1Image from "./assets/img/r1.png";
import {
    AnimatePresence,
    AnimatePresenceSlideUp,
    Api,
    Avatars,
    Badges,
    Buttons,
    Cards,
    Dialog,
    Fields,
    Form,
    Formik,
    Forms,
    Link,
    Spinner,
    Tabs,
    Tags,
    _,
    classNames,
    constants,
    useEffect,
    useNavigate,
    useParams,
} from "./tools";

const COMPLETED_AT_REASON_SUCCESS = 1;
const COMPLETED_AT_REASON_ERROR = 2;

export default function AssessmentCreateMagicFollowUp() {
    const [isComplete, setIsComplete] = useState(false);
    const [waitingForAssessmentCreate, setWaitingForAssessmentCreate] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const navigate = useNavigate();
    const { amgrid } = useParams();

    // continously poll for assessment magic
    const {
        data: assessmentMagic,
        isLoading,
        error,
    } = Api.useGetAssessmentMagicQuery(amgrid, {
        pollingInterval: 10000,
        skip: isComplete,
    });

    const [restartAssessmentMagic, { isLoading: isRestarting }] =
        Api.useRestartAssessmentMagicMutation();

    const [createAssessmentFromAssessmentMagic, { isLoading: isCreating }] =
        Api.useCreateAssessmentFromAssessmentMagicMutation();

    useEffect(() => {
        if (waitingForAssessmentCreate && assessmentMagic?.assessment) {
            setWaitingForAssessmentCreate(false);
            setIsComplete(true);
        }
        if (assessmentMagic?.completed_at) {
            setIsComplete(true);
        }
    }, [assessmentMagic]);

    const content = assessmentMagic?.content;
    const content_job_post = assessmentMagic?.content_job_post;
    const content_job_post_magic = assessmentMagic?.content_job_post_magic;
    const content_mq = assessmentMagic?.content_mq;
    const content_case_study = assessmentMagic?.content_case_study;
    const assessment = assessmentMagic?.assessment;

    const renderContent = () => {
        if (isLoading || !assessmentMagic) {
            return <Spinner />;
        }

        if (assessment) {
            return (
                <AnimatePresenceSlideUp initial={true} show={true}>
                    <div className="flex min-h-[calc(100vh-200px)] items-center justify-center">
                        <div className="w-md space-y-6 text-center text-sm ">
                            <div>
                                <img src={R1Image} className="mx-auto w-48 rounded-xl" />
                            </div>
                            <div className="font-bold">{assessment.name}</div>
                            <div className="mx-auto w-96">
                                We did it! Your assessment is ready! You can now customize it, or
                                send it to a candidate.
                            </div>

                            <div>
                                <Buttons.Button
                                    color="lblue"
                                    href={`/library/assessments/${assessment.id}?new=true`}
                                >
                                    View assessment
                                </Buttons.Button>
                            </div>
                        </div>
                    </div>
                </AnimatePresenceSlideUp>
            );
        }

        if (assessmentMagic.completed_reason == COMPLETED_AT_REASON_ERROR) {
            return (
                <div className="flex flex-col items-center justify-center gap-y-4">
                    <div className="space-y-4">
                        <p>
                            We were unable to build your assessment. Usually, this is because the
                            job post URL you entered was not valid, or we are blocked from accessing
                            the job post.
                        </p>
                        <Buttons.Button
                            color="slater"
                            onClick={() => {
                                restartAssessmentMagic({
                                    id: amgrid,
                                    body: {
                                        restart_action: "all",
                                    },
                                });
                            }}
                        >
                            Try again
                        </Buttons.Button>
                    </div>
                </div>
            );
        }

        if (!assessmentMagic.completed_at) {
            return (
                <AnimatePresenceSlideUp initial={true} show={true}>
                    <div className="flex min-h-[calc(100vh-200px)] items-center justify-center">
                        <div className="w-md space-y-6 text-center text-sm ">
                            <div>
                                <img
                                    onClick={() => {
                                        navigator.clipboard.writeText(amgrid);
                                    }}
                                    src={R1Image}
                                    className="mx-auto w-48 rounded-xl"
                                />
                            </div>
                            <div className="font-bold">Building assessment...</div>
                            <div className="prose prose-sm mx-auto flex w-96 flex-col items-center">
                                <p>We are building your assessment. This may take a few minutes.</p>
                                <p>
                                    We will send you an email when your assessment is ready. You can
                                    also check back here later.
                                </p>

                                <Spinner size="md" />

                                <Buttons.Button
                                    className="mt-3"
                                    color="slate"
                                    onClick={() => {
                                        restartAssessmentMagic({
                                            id: amgrid,
                                            body: {
                                                restart_action: "all",
                                            },
                                        });
                                    }}
                                >
                                    Restart
                                </Buttons.Button>
                            </div>
                        </div>
                    </div>
                </AnimatePresenceSlideUp>
            );
        }

        return (
            <div className="gap-y-4">
                {assessmentMagic.completed_at && assessmentMagic?.assessment == null && (
                    <div className="flex w-full gap-x-2">
                        <Dialog
                            size="lg"
                            withScroll={false}
                            isOpen={showCreateModal}
                            setIsOpen={setShowCreateModal}
                        >
                            <Forms.GenericForm
                                fields={[
                                    {
                                        name: "team_id",
                                        label: "Team",
                                        type: "team",
                                    },
                                ]}
                                onSubmit={async (result) => {
                                    await createAssessmentFromAssessmentMagic({
                                        id: amgrid,
                                        body: {
                                            team_id: result.team_id,
                                        },
                                    });
                                    setWaitingForAssessmentCreate(true);
                                    setIsComplete(false);
                                    setShowCreateModal(false);
                                }}
                            />
                        </Dialog>
                        <Buttons.Button
                            onClick={() => {
                                setShowCreateModal(true);
                            }}
                            color="lblue"
                        >
                            Use Assessment
                        </Buttons.Button>
                        <Buttons.ButtonWithConfirmation
                            confirm={{
                                title: "Are you sure?",
                                description:
                                    "This will regenerate your assessment. You will lose any changes you have made.",
                            }}
                            color="slate"
                            onClick={() => {
                                restartAssessmentMagic({
                                    id: amgrid,
                                    body: {
                                        restart_action: "all",
                                    },
                                });
                                setIsComplete(false);
                            }}
                        >
                            Regenerate Assessment
                        </Buttons.ButtonWithConfirmation>
                    </div>
                )}

                <div className="mt-5">
                    <AnimatePresenceSlideUp
                        className="w-full space-y-8 rounded-xl bg-white p-5 shadow-md"
                        show={content_job_post != null && content_job_post_magic != null}
                        initial={true}
                    >
                        <div className="grid grid-cols-12 gap-x-8">
                            <div className="col-span-9 space-y-4">
                                <div className="prose prose-sm max-w-full">
                                    <div className="flex items-center gap-x-2">
                                        <div className="relative flex-shrink-0">
                                            {content_job_post?.favicon ? (
                                                <Avatars.Avatar
                                                    size="md"
                                                    src={content_job_post?.favicon}
                                                    className="my-0 p-2"
                                                />
                                            ) : (
                                                <div className="flex h-12 w-12 items-center justify-center rounded-md bg-gray-100" />
                                            )}
                                        </div>
                                        <div>
                                            <h4 className="mb-0 mt-0">
                                                {content_job_post_magic?.company_name}
                                                <Badges.Badge
                                                    className="ml-2"
                                                    label={content_job_post_magic?.job_location}
                                                    showDot={false}
                                                />
                                            </h4>
                                            <h5 className="font-bolder mb-0">
                                                {content_job_post_magic?.job_title}
                                            </h5>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="pr-5">
                                            {content_job_post_magic?.job_description ||
                                                content_job_post_magic?.job_summary}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-3 space-y-6">
                                <div className="overflow-hidden">
                                    <div className="flex flex-wrap gap-x-2 gap-y-2">
                                        {content_job_post_magic?.required_skills
                                            ?.slice(0, 10)
                                            .map((skill) => {
                                                return (
                                                    <Badges.Badge
                                                        key={skill}
                                                        label={_.truncate(skill, {
                                                            length: 40,
                                                        })}
                                                        showDot={false}
                                                        className="w-40 capitalize"
                                                    />
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-8">
                            {(content_mq &&
                                content_mq.questions &&
                                content_mq.questions.length != 0 && (
                                    <div className="col-span-1 space-y-4">
                                        <div className="flex justify-between">
                                            <div className="flex items-center gap-x-2">
                                                <div className="flex-shrink-0">
                                                    <img
                                                        src={
                                                            constants.MODULE_TYPE_ICONS[
                                                                constants
                                                                    .MODULE_TYPE_MULTIPLE_CHOICE
                                                            ]
                                                        }
                                                        className="h-6 w-6 rounded-md"
                                                    />
                                                </div>
                                                <div className="text-sm">
                                                    {
                                                        constants.MODULE_TYPE_LABELS[
                                                            constants.MODULE_TYPE_MULTIPLE_CHOICE
                                                        ]
                                                    }
                                                </div>
                                            </div>
                                            <Buttons.ButtonWithConfirmation
                                                disabled={assessmentMagic?.completed_at == null}
                                                color="transparent"
                                                confirm={{
                                                    title: "Are you sure?",
                                                    description:
                                                        "This will regenerate your case study. You will lose any changes you have made.",
                                                }}
                                                onClick={() => {
                                                    restartAssessmentMagic({
                                                        id: amgrid,
                                                        body: {
                                                            restart_action: "mq",
                                                        },
                                                    });
                                                    setIsComplete(false);
                                                }}
                                            >
                                                <ArrowPathIcon className="h-4 w-4 text-gray-500" />
                                            </Buttons.ButtonWithConfirmation>
                                        </div>
                                        <MQPreview items={content_mq?.questions} />
                                    </div>
                                )) || <Spinner size="sm" />}

                            {(content_case_study && (
                                <div className="col-span-1 space-y-4">
                                    <div className="flex justify-between">
                                        <div className="flex items-center gap-x-2">
                                            <div className="flex-shrink-0">
                                                <img
                                                    src={
                                                        constants.MODULE_TYPE_ICONS[
                                                            constants.MODULE_TYPE_GOOGLE_DOC
                                                        ]
                                                    }
                                                    className="h-6 w-6 rounded-md"
                                                />
                                            </div>
                                            <div className="text-sm">
                                                <div>
                                                    {
                                                        constants.MODULE_TYPE_LABELS[
                                                            constants.MODULE_TYPE_GOOGLE_DOC
                                                        ]
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <Buttons.ButtonWithConfirmation
                                            disabled={assessmentMagic?.completed_at == null}
                                            color="transparent"
                                            confirm={{
                                                title: "Are you sure?",
                                                description:
                                                    "This will regenerate your case study. You will lose any changes you have made.",
                                            }}
                                            onClick={() => {
                                                restartAssessmentMagic({
                                                    id: amgrid,
                                                    body: {
                                                        restart_action: "case_study",
                                                    },
                                                });
                                                setIsComplete(false);
                                            }}
                                        >
                                            <ArrowPathIcon className="h-4 w-4 text-gray-500" />
                                        </Buttons.ButtonWithConfirmation>
                                    </div>

                                    <div className="prose prose-sm max-w-full pr-5 prose-h1:text-2xl prose-h2:text-sm prose-h3:text-sm prose-h4:text-sm">
                                        <div
                                            dangerouslySetInnerHTML={{ __html: content_case_study }}
                                        />
                                    </div>
                                </div>
                            )) || <Spinner size="sm" />}
                        </div>
                    </AnimatePresenceSlideUp>
                </div>
            </div>
        );
    };

    return renderContent();
}
