import { Tab } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Appear from "./Appear";
import ModuleContent from "./ModuleContent";
import ModuleGeneral from "./ModuleGeneral";
import ModulePreviewConversation from "./ModulePreviewConversation";
import ModulePreviewMQ from "./ModulePreviewMQ";
import { useGetModuleQuery, useUpdateModuleMutation } from "./store/api";
import { Link, Tabs, classNames, constants } from "./tools";

const MODULE_PREVIEW_COMPONENTS = {
    [constants.MODULE_TYPE_MULTIPLE_CHOICE]: ModulePreviewMQ,
    [constants.MODULE_TYPE_CONVERSATIONAL]: ModulePreviewConversation,
};

function DEFAULT_PREVIEW_COMPONENT() {
    return <div>Preview not available for this module type.</div>;
}

export default function ModuleSingle() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { data: module, isLoading, isFetching, error } = useGetModuleQuery(id);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    const PreviewComponent =
        MODULE_PREVIEW_COMPONENTS[module?.module_type] || DEFAULT_PREVIEW_COMPONENT;

    return (
        <div className="flex flex-col">
            <main className="flex-1">
                <div className="relative">
                    <div className="pb-16">
                        <div className="px-4 sm:px-6 md:px-0">
                            <div>
                                <Link className="text-sm text-slate-400" to="/library">
                                    Library
                                </Link>
                            </div>
                            <h5 className="text-3xl font-bold tracking-tight text-emerald-900">
                                {module?.name}
                            </h5>
                        </div>

                        <Tabs
                            tabs={[
                                {
                                    name: "General",
                                    href: "general",
                                    current: true,
                                    component: <ModuleGeneral />,
                                },
                                {
                                    name: "Content",
                                    href: "content",
                                    current: false,
                                    component: <ModuleContent />,
                                },
                                {
                                    name: "Preview",
                                    href: "preview",
                                    current: false,
                                    component: <PreviewComponent />,
                                },
                            ]}
                        />
                    </div>
                </div>
            </main>
        </div>
    );
}
