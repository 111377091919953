import { usePostHog } from "posthog-js/react";

const DEBUG_MODE = false;

export function usePLG() {
    const posthog = usePostHog();

    const isDev = process.env.NODE_ENV === "development";

    // in development, we don't want to send events to PostHog, instead we use console.log
    const identify = (id, properties) => {
        if (isDev) {
            DEBUG_MODE && console.log("identify", id, properties);
            return;
        }
        posthog?.identify(id, properties);
    };

    const group = (groupId, id, properties) => {
        if (isDev) {
            DEBUG_MODE && console.log("group", groupId, id, properties);
            return;
        }
        posthog?.group(groupId, id, properties);
    };

    const capture = (event, properties) => {
        if (isDev) {
            DEBUG_MODE && console.log("capture", event, properties);
            return;
        }
        posthog?.capture(event, properties);
    };

    return { identify, capture, group, posthog };
}
