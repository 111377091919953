import CandidateExternalComplete from "./CandidateExternalComplete";
import CandidateExternalContinue from "./CandidateExternalContinue";
import CandidateExternalHonorCode from "./CandidateExternalHonorCode";
import CandidateExternalModuleItemConversation from "./CandidateExternalModuleItemConversation";
import CandidateExternalModuleItemGoogleFile from "./CandidateExternalModuleItemGoogleFile";
import CandidateExternalModuleItemMQ from "./CandidateExternalModuleItemMQ";
import CandidateExternalModuleItemScreenShare from "./CandidateExternalModuleItemScreenShare";
import CandidateExternalPrepare from "./CandidateExternalPrepare";
import { CandidateExternalProvider } from "./CandidateExternalProvider";
import CandidateExternalSkip from "./CandidateExternalSkip";
import CandidateExternalTimelimit from "./CandidateExternalTimelimit";
import CandidateExternalWarden from "./CandidateExternalWarden";
import CandidateExternalWelcome from "./CandidateExternalWelcome";
import { AnimatePresence, ApiCandidate, Buttons, Fields, constants, useParams } from "./tools";

export default function CandidateExternal() {
    const { cid, caid } = useParams();

    const {
        data: assessment,
        isLoading,
        error,
    } = ApiCandidate.useGetAssessmentQuery({ cid, caid }, {});

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    if (assessment.completed_at) {
        return <CandidateExternalComplete />;
    }

    const current_module = assessment.current_module;
    const current_module_item = assessment.current_module_item;

    const renderModuleInProgress = () => {
        // check what the current module is and render the appropriate component
        // if the current module is a multiple choice module, return the multiple choice component
        if (current_module_item.module_item_type === constants.MODULE_TYPE_MULTIPLE_CHOICE) {
            return <CandidateExternalModuleItemMQ />;
        }

        // if the current module is Google File
        if (current_module_item.module_item_type in constants.GOOGLE_FILE_TYPES) {
            return <CandidateExternalModuleItemGoogleFile />;
        }

        // the current module is a conversation
        if (current_module_item.module_item_type === constants.MODULE_TYPE_CONVERSATIONAL) {
            return <CandidateExternalModuleItemConversation />;
        }

        // the current module is a screen share
        if (current_module_item.module_item_type === constants.MODULE_TYPE_SCREENSHARE) {
            return <CandidateExternalModuleItemScreenShare />;
        }

        // the module is not found
        return <div>Module item not found</div>;
    };

    if (assessment.started_at) {
        if (current_module && current_module_item) {
            // can be null or 0
            const hasTimeLimit =
                current_module.time_limit !== null && current_module.time_limit !== 0;
            return (
                <CandidateExternalProvider>
                    <div className="space-y-4">
                        {hasTimeLimit && <CandidateExternalTimelimit />}
                        {renderModuleInProgress()}
                        <CandidateExternalSkip />
                    </div>
                </CandidateExternalProvider>
            );
        } else {
            return <CandidateExternalContinue />;
        }
    }

    if (assessment.warden_completed_at) {
        return <CandidateExternalPrepare />;
    }

    if (assessment.honor_code_accepted_at) {
        return <CandidateExternalWarden />;
    }

    if (assessment.verified_at) {
        return <CandidateExternalHonorCode />;
    }

    return <CandidateExternalWelcome />;
}
