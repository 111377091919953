import { Storytime } from "@papercups-io/storytime";
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import React from "react";
import ReactDOM from "react-dom/client";
import "react-grid-layout/css/styles.css";
import "react-quill/dist/quill.snow.css";
import "react-resizable/css/styles.css";

import App from "./App";
import "./index.css";

if (process.env.NODE_ENV === "production") {
    posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
        api_host: process.env.REACT_APP_POSTHOG_HOST,
        opt_in_site_apps: true,
        session_recording: {
            maskAllInputs: false,
        },
        enable_recording_console_log: true,
    });
    Storytime.init({
        accountId: process.env.REACT_APP_PAPERCUP_ACCOUNT_ID,
        baseUrl: process.env.REACT_APP_PAPERCUP_URL,
    });

    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
    });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <PostHogProvider client={posthog}>
        <App />
    </PostHogProvider>,
);
