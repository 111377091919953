import { Api } from "./tools";
import { useQuery } from "./useQuery";

export function useAssessments(page, filters) {
    const {
        data: assessments,
        isLoading,
        error,
    } = useQuery(Api.useGetAssessmentsQuery, page, filters);

    return {
        assessments,
        isLoading,
        error,
    };
}
