import { PhotoIcon, UserCircleIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import React from "react";

import AssessmentCreateModalContent from "./AssessmentCreateModalContent";
import AssessmentList from "./AssessmentList";
import C2Image from "./assets/img/c2.png";
import Empty1 from "./assets/img/empty1.png";
import Empty2 from "./assets/img/empty2.png";
import Empty5 from "./assets/img/empty5.png";
import Empty6 from "./assets/img/empty6.png";
import Empty7 from "./assets/img/empty7.png";
import {
    AnimatePresence,
    AnimatePresenceSlideUp,
    Buttons,
    OrgLimitsContext,
    classNames,
    useContext,
} from "./tools";

export default function CandidateInviteTutorial() {
    const { limits } = useContext(OrgLimitsContext);
    return (
        <AnimatePresence show={true} initial={true}>
            <div className="flex min-h-[calc(100vh-200px)] items-center justify-center">
                <div className="grid max-w-7xl grid-cols-2 gap-x-16">
                    <div className="col-span-1 space-y-6 py-5">
                        <div className="prose prose-sm">
                            <h2 className="text-3xl font-bold tracking-tight text-slate-900">
                                This looks empty
                            </h2>
                            <p className="">You haven't invited any candidates yet. </p>
                            <p>
                                You can invite candidates to your assessments by clicking the
                                <span className="font-bold"> Invite Candidate </span>
                                button.
                            </p>
                        </div>
                        {limits?.has_assessments ? (
                            <div>
                                <AssessmentList
                                    title=""
                                    withSearch={false}
                                    filter={{
                                        limit: 1,
                                    }}
                                />
                            </div>
                        ) : (
                            <div className="space-y-4">
                                <div className="text-sm">
                                    <p>You have not created any assessments yet.</p>
                                </div>

                                <AssessmentCreateModalContent className="text-sm" />
                            </div>
                        )}
                    </div>

                    <div
                        style={{
                            backgroundImage: `url(${Empty7})`,
                        }}
                        className="col-span-1 min-h-[500px] rounded-xl bg-cover bg-center shadow-md"
                    />
                </div>
            </div>
        </AnimatePresence>
    );
}
