import { ArrowDownIcon, ArrowDownOnSquareIcon, BarsArrowDownIcon } from "@heroicons/react/20/solid";
import { ArrowDownCircleIcon } from "@heroicons/react/24/outline";
import React from "react";

import { CandidateAssessmentContext } from "./CandidateAssessmentProvider";
import CandidateReviewModal from "./CandidateReviewModal";
import {
    AnimatePresence,
    AnimatePresenceGrow,
    Api,
    Audio,
    Buttons,
    Cards,
    Dialog,
    Disclosures,
    Fields,
    Score,
    Utils,
    classNames,
    constants,
    useContext,
    useState,
} from "./tools";

const SHOW_DISCLAIMER = false;

const calculateOverallOpinionScore = (opinion) => {
    const { criteria } = opinion;
    const totalScore = criteria.reduce((acc, curr) => {
        return acc + new Number(curr.criteria_score);
    }, 0);

    const maxScore = criteria.length * 10;
    return { totalScore, maxScore };
};

export default function CandidateReviewModuleConversation({ module }) {
    const [showReviewDialog, setShowReviewDialog] = useState(false);
    const [reviewFocus, setReviewFocus] = useState(null);
    const { assessment, candidate } = useContext(CandidateAssessmentContext);

    // the conversation item
    const conversationModuleItem = module.items?.find((item) => {
        return module.module_type == item.module_item_type;
    });

    // the generic items
    const genericItems = module.items?.filter((item) => {
        return constants.MODULE_TYPES_GENERIC.map((mg) => mg.id).includes(item.module_item_type);
    });

    // do we have an conversation_opinion on the answer?
    const opinion = conversationModuleItem?.answer?.conversation_opinion;
    const opinionScore = opinion && calculateOverallOpinionScore(opinion).totalScore;
    const opinionMaxScore = opinion && calculateOverallOpinionScore(opinion).maxScore;
    let mergedAudio = conversationModuleItem?.answer?.merged_audio;
    mergedAudio = mergedAudio ? constants.S3_DOMAIN + "/" + mergedAudio : null;

    const conversation = conversationModuleItem?.answer?.conversation;

    const itemsToReview = [conversationModuleItem, ...genericItems];

    const itemsWithAutoReview = itemsToReview?.filter((item) => {
        return [constants.MODULE_TYPE_MULTIPLE_CHOICE].includes(item.module_item_type);
    });

    const itemsWithOpenReviews = itemsToReview?.filter((item) => {
        return item.reviewed_at == null || item.score == null || item.possible_score == null;
    });
    const itemsWithCompletedReviews = itemsToReview?.filter((item) => {
        return item.reviewed_at != null && item.score != null && item.possible_score != null;
    });

    return (
        <div>
            <Dialog size="full" isOpen={showReviewDialog} setIsOpen={setShowReviewDialog}>
                {reviewFocus && (
                    <CandidateReviewModal
                        restrictHeight
                        cami={reviewFocus}
                        onSuccess={() => {
                            setShowReviewDialog(false);
                        }}
                    >
                        <div className="flex flex-col gap-y-4">
                            {opinion ? (
                                <div className="flex flex-col gap-y-4">
                                    {SHOW_DISCLAIMER && (
                                        <div className=" p-3 text-xs italic text-slate-600">
                                            The following review, created by AI, aims to provide a
                                            concise summary of the candidates' conversation. While
                                            we strive for accuracy, it should not be considered your
                                            final decision-making tool. We kindly encourage you to
                                            thoroughly review the candidates' conversation in its
                                            entirety before making any decisions.
                                        </div>
                                    )}

                                    <Disclosures.DisclosureUnstyled
                                        panels={[
                                            {
                                                panelClassName: "bg-slate-100 p-4 rounded-md",
                                                title: (
                                                    <Score.ScoreComponent
                                                        title="Overall Score (Click to Expand)"
                                                        description={opinion.overall_description}
                                                        score={opinionScore}
                                                        maxScore={opinionMaxScore}
                                                    />
                                                ),
                                                content: (
                                                    <>
                                                        {opinion.criteria?.map((criteria) => {
                                                            return (
                                                                <Score.ScoreComponent
                                                                    key={criteria.criteria_title}
                                                                    title={criteria.criteria_title}
                                                                    score={criteria.criteria_score}
                                                                    description={
                                                                        criteria.criteria_result
                                                                    }
                                                                    maxScore={10}
                                                                />
                                                            );
                                                        })}
                                                    </>
                                                ),
                                            },
                                        ]}
                                    />
                                </div>
                            ) : null}

                            {mergedAudio && (
                                <div>
                                    <Audio.Player url={mergedAudio}>
                                        <div className="flex flex-col gap-y-2 text-sm">
                                            <div>Conversation Audio</div>
                                            <div className="text-slate-600">
                                                Play the audio and review the candidate's answers.
                                            </div>
                                        </div>
                                    </Audio.Player>
                                </div>
                            )}

                            {conversation && (
                                <Disclosures.DisclosureUnstyled
                                    panels={[
                                        {
                                            panelClassName: "bg-slate-100 p-6 rounded-md",
                                            title: (
                                                <Cards.CardTwoColumns
                                                    col1={
                                                        <div className="flex flex-col gap-y-2 text-sm">
                                                            <div>Conversation</div>
                                                            <div className="text-slate-600">
                                                                Review the candidates conversation.
                                                            </div>
                                                        </div>
                                                    }
                                                    col2={
                                                        <div className="flex items-center gap-x-4 text-sm">
                                                            <div className="rounded-full bg-white p-1 shadow-md">
                                                                <BarsArrowDownIcon className="h-5 w-5 text-slate-500" />
                                                            </div>
                                                            <div className="text-slate-600">
                                                                Click here to review the candidates
                                                                conversation.
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            ),
                                            content: (
                                                <Cards.Conversation
                                                    conversation={conversation}
                                                    user={{
                                                        initials: candidate.initials,
                                                        d: candidate.d,
                                                    }}
                                                />
                                            ),
                                        },
                                    ]}
                                />
                            )}
                        </div>
                    </CandidateReviewModal>
                )}
            </Dialog>

            <Disclosures.DisclosureUnstyled
                open={itemsWithOpenReviews.length > 0}
                panels={[
                    {
                        title: (
                            <Cards.CardTwoColumns
                                className="bg-white transition-all hover:bg-slate-50"
                                col1={
                                    <div className="flex gap-x-3 text-sm">
                                        <div className="flex-shrink-0">
                                            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-slate-50">
                                                <img
                                                    src={
                                                        constants.MODULE_TYPE_ICONS[
                                                            module.module_type
                                                        ]
                                                    }
                                                    className="h-6 w-6"
                                                />
                                            </div>
                                        </div>
                                        <div className="min-w-0">
                                            <div className="truncate">{module.module.name}</div>
                                            <div className="truncate text-slate-400">
                                                {module.reviewed_at && module.score_average != null
                                                    ? "Review complete"
                                                    : "Needs review"}
                                            </div>
                                        </div>
                                    </div>
                                }
                                col2={
                                    (module.score_average && (
                                        <div>
                                            <div className="mb-1 flex justify-between">
                                                <span className="text-sm">Score</span>
                                                <span className="text-sm">
                                                    {Math.round(module.score_average)}%
                                                </span>
                                            </div>
                                            <div
                                                className={classNames(
                                                    "h-2.5 w-full rounded-full bg-slate-200",
                                                    module.score === 0 && "bg-slate-200",
                                                )}
                                            >
                                                <AnimatePresenceGrow show={true} initial={true}>
                                                    <div
                                                        className={classNames(
                                                            Utils.backgroundColorScorePercentage(
                                                                Math.round(module.score_average),
                                                            ),
                                                            " h-2.5 rounded-full",
                                                        )}
                                                        style={{
                                                            width:
                                                                Math.round(module.score_average) +
                                                                "%",
                                                        }}
                                                    ></div>
                                                </AnimatePresenceGrow>
                                            </div>
                                        </div>
                                    )) || (
                                        <div className="text-sm italic">
                                            The final score will be calculated after all items are
                                            reviewed. Please review the{" "}
                                            {itemsWithOpenReviews.length} open item(s) below.
                                        </div>
                                    )
                                }
                            />
                        ),
                        content: (
                            <div className="bg-white p-5">
                                {module.score != null && module.possible_score != null ? (
                                    <div className="flex flex-col gap-y-4">
                                        {itemsToReview.map((item) => {
                                            return (
                                                <Score.ScoreComponentReview
                                                    key={item.id}
                                                    item={item}
                                                    onClick={(item) => {
                                                        setReviewFocus(item);
                                                        setShowReviewDialog(true);
                                                    }}
                                                />
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className="flex flex-col gap-y-4 ">
                                        {itemsToReview.map((item) => {
                                            return (
                                                <Score.ScoreComponentReview
                                                    key={item.id}
                                                    item={item}
                                                    onClick={(item) => {
                                                        setReviewFocus(item);
                                                        setShowReviewDialog(true);
                                                    }}
                                                />
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        ),
                    },
                ]}
            />
        </div>
    );
}
