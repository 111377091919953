import React, { useEffect, useRef, useState } from "react";

import AudioDeviceInputLevel from "./AudioDeviceInputLevel";
import { Buttons, Utils } from "./tools";

const getSupportedAudioType = () => {
    if (Utils.isSafari()) {
        return "audio/mp4";
    }

    const audio = document.createElement("audio");
    if (audio.canPlayType("audio/webm; codecs=opus")) {
        return "audio/webm; codecs=opus";
    } else if (audio.canPlayType("audio/ogg; codecs=opus")) {
        return "audio/ogg; codecs=opus";
    } else if (audio.canPlayType("audio/mpeg")) {
        return "audio/mpeg";
    } else {
        return "audio/wav";
    }
};

export default function AudioRecorder({ inputDeviceId, outputDeviceId }) {
    const [recording, setRecording] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const mediaRecorder = useRef(null);
    const audioContext = useRef(new AudioContext());
    const recordedAudio = useRef(null);
    const stream = useRef(null);
    const audioType = getSupportedAudioType();

    useEffect(() => {
        return () => {
            // Clean up resources when the component is unmounted
            if (stream.current) {
                stream.current.getTracks().forEach((track) => track.stop());
            }
            if (recordedAudio.current) {
                recordedAudio.current.pause();
                recordedAudio.current.src = "";
            }
        };
    }, []);

    useEffect(() => {
        if (!recording && recordedChunks.length > 0) {
            handlePlay();
        }
    }, [recording, recordedChunks]);

    const waitForMediaRecorderStop = () => {
        return new Promise((resolve) => {
            mediaRecorder.current.onstop = resolve;
        });
    };

    const handleRecordStop = async () => {
        if (recording) {
            await handleStop();
        } else {
            handleRecord();
        }
    };

    const handleRecord = async () => {
        try {
            stream.current = await navigator.mediaDevices.getUserMedia({
                audio: { deviceId: { exact: inputDeviceId } },
            });
            mediaRecorder.current = new MediaRecorder(stream.current, { mimeType: audioType });
            setRecordedChunks([]);

            mediaRecorder.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    setRecordedChunks((prev) => [...prev, event.data]);
                }
            };

            mediaRecorder.current.start();
            setRecording(true);

            // Automatically stop recording after 5 seconds
            setTimeout(async () => {
                await handleStop();
            }, 5000);
        } catch (error) {
            console.error("Error during recording:", error);
        }
    };

    const handleStop = async () => {
        try {
            mediaRecorder.current.stop();

            // Wait for the media recorder to stop
            return new Promise((resolve) => {
                mediaRecorder.current.onstop = () => {
                    setRecording(false);
                    resolve();
                };
            });
        } catch (error) {
            console.error("Error during stopping recording:", error);
        }
    };

    const handlePlay = () => {
        try {
            if (!recordedChunks.length) return;

            const blob = new Blob(recordedChunks, { type: audioType });

            const url = URL.createObjectURL(blob);

            recordedAudio.current = new Audio(url);
            // check if setSinkId is supported
            if (recordedAudio.current.setSinkId) {
                recordedAudio.current.setSinkId(outputDeviceId);
            }
            recordedAudio.current.play();

            recordedAudio.current.addEventListener("ended", () => {
                URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error("Error during playback:", error);
        }
    };

    return (
        <div className="space-y-4">
            <Buttons.Button
                className="bg-slate-200"
                variant="solidXS"
                color="slate"
                disabled={recording}
                onClick={handleRecordStop}
            >
                {recording ? "Recording..." : "Test Microphone"}
            </Buttons.Button>
            {recording ? <AudioDeviceInputLevel inputDeviceId={inputDeviceId} /> : null}
        </div>
    );
}
