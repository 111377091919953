import React from "react";

import { classNames } from "./tools";

export function Pill({ className, children }) {
    return (
        <div className="inline-block rounded-full bg-slate-50 px-3 py-1 text-xs ring-1">
            {children}
        </div>
    );
}
