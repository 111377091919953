import { LinkIcon } from "@heroicons/react/20/solid";

import { CandidateAssessmentProvider } from "./CandidateAssessmentProvider";
import CandidateReview from "./CandidateReview";
import CandidateSummary from "./CandidateSummary";
import {
    AnimatePresence,
    Api,
    Avatar,
    Avatars,
    Buttons,
    Link,
    Tabs,
    _,
    classNames,
    moment,
    useEffect,
    useNavigate,
    useParams,
    useSearchParams,
    useState,
} from "./tools";

export default function CandidateSingle() {
    const [assessment, setAssessment] = useState(null);
    const { id } = useParams();

    const {
        data: candidate,
        isLoading,
        error,
    } = Api.useGetCandidateQuery(id, {
        refetchOnMountOrArgChange: true,
    });
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (candidate) {
            // do we have search param a=assessment_id?
            if (searchParams.get("a")) {
                const assessment = _.find(
                    candidate.assessments,
                    (a) => a.id === searchParams.get("a"),
                );
                if (assessment) {
                    setAssessment(assessment);
                    return;
                }
            }

            const sorted = _.sortBy(candidate.assessments, (a) => moment(a.created_at).unix());
            const assessment = sorted[sorted.length - 1];
            setAssessment(assessment);
        }
    }, [candidate]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    if (!candidate || !candidate.assessments || !candidate.assessments.length || !assessment) {
        return <div>No assessments found for this candidate.</div>;
    }

    const sorted = _.sortBy(candidate.assessments, (a) => moment(a.created_at).unix());

    return (
        <div className="flex flex-col">
            <main className="flex-1">
                <div className="relative">
                    <div className="pb-16">
                        <div className="flex justify-between px-4 sm:px-6 md:px-0">
                            <div className="flex items-center gap-x-4">
                                <div>
                                    <Avatars.Avatar
                                        size="xl"
                                        hash={candidate.d}
                                        placeholder={candidate.initials}
                                    />
                                </div>
                                <div>
                                    <div>
                                        <Link className="text-sm text-slate-400" to="/candidates">
                                            Candidates
                                        </Link>
                                    </div>
                                    <h5
                                        onClick={() => {
                                            // copy current assessment id to clipboard
                                            navigator.clipboard.writeText(candidate.id);
                                        }}
                                        className="text-3xl font-bold tracking-tight text-emerald-900"
                                    >
                                        {candidate?.full_name}
                                    </h5>
                                </div>
                            </div>
                            <div className="flex items-center gap-x-2">
                                <Buttons.ButtonActionsDropdown
                                    label={`${assessment?.assessment?.name}`}
                                    actions={[
                                        ...sorted.map((a) => ({
                                            label: `${a.assessment.name} (${
                                                a.completed_at ? "Complete" : "In Progress"
                                            })`,
                                            onClick: () => {
                                                // navigate(`/c/${candidate.id}/a/${a.id}`);
                                                setAssessment(a);
                                                // also, set query param
                                                setSearchParams({ a: a.id });
                                            },
                                            className: classNames(
                                                a.id === assessment.id && "bg-slate-100",
                                            ),
                                        })),
                                    ]}
                                />
                                <Buttons.Button color="slate">Generate PDF Report</Buttons.Button>

                                <Buttons.ButtonActionsDropdown
                                    actions={[{ label: "Email", onClick: () => {} }]}
                                />
                            </div>
                        </div>

                        <CandidateAssessmentProvider caid={assessment.id} candidate={candidate}>
                            <Tabs
                                persistSearchParams
                                tabs={[
                                    {
                                        name: "Summary",
                                        href: "summary",
                                        current: true,
                                        component: <CandidateSummary />,
                                    },
                                    {
                                        name: "Reviews",
                                        href: "reviews",
                                        current: false,
                                        component: <CandidateReview />,
                                        disabled: !assessment.completed_at,
                                    },
                                ]}
                                selected={0}
                            />
                        </CandidateAssessmentProvider>
                    </div>
                </div>
            </main>
        </div>
    );
}
