import React, { createContext, useState } from "react";

import { Api } from "./tools";

export const OrgContext = createContext();

export function OrgProvider({ children }) {
    const { data: org, isLoading, error } = Api.useGetOrgQuery({}, {});

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    return <OrgContext.Provider value={{ org }}>{children}</OrgContext.Provider>;
}
