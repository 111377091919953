import React from "react";

import { AssessmentContext } from "./AssessmentProvider";
import { Api, Forms, useContext } from "./tools";

export default function AssessmentInviteLinkCreateForm({ onSuccess }) {
    const { assessment } = useContext(AssessmentContext);
    const [createInviteLink, { isLoading }] = Api.useCreateAssessmentInviteLinkMutation();

    return (
        <Forms.GenericForm
            fields={[
                {
                    name: "name",
                    label: "Name",
                    placeholder: "e.g. Linkedin Campaign",
                    type: "text",
                    required: true,
                },
            ]}
            onSubmit={async (values) => {
                return createInviteLink({
                    id: assessment.id,
                    body: values,
                });
            }}
            submitButtonClassname="w-auto inline-flex"
            onSuccess={(result) => {
                onSuccess?.(result);
            }}
        />
    );
}
