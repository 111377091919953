import { useAudioAnalyzer } from "./useAudioAnalyszer";

export default function AudioDeviceInputLevel({ inputDeviceId }) {
    const { inputLevel } = useAudioAnalyzer(inputDeviceId);

    return (
        <div className="h-2 w-full bg-gray-300 transition-all duration-100">
            <div
                className="h-2 bg-emerald-500 transition-all duration-100"
                style={{ width: `${inputLevel}%`, maxWidth: "100%" }}
            ></div>
        </div>
    );
}
