import {
    Api,
    Buttons,
    Dialog,
    Fields,
    Forms,
    Spinner,
    Utils,
    classNames,
    constants,
    useEffect,
    useParams,
    useState,
} from "./tools";

export default function ModuleConversation() {
    const { id } = useParams();
    const { data: module, isLoading, error } = Api.useGetModuleQuery(id);
    const [updateModuleItem, { isLoading: isUpdating }] = Api.useUpdateModuleItemMutation();
    const [modulePreviewAction, { isLoading: isPerformingAction }] =
        Api.useModulePreviewActionMutation();
    const [moduleGenerateAction, { isLoading: isGenerating }] =
        Api.useModuleGenerateActionMutation();

    const [systemPrompt, setSystemPrompt] = useState("");
    const [botName, setBotName] = useState("");
    const [welcomeMessage, setWelcomeMessage] = useState("");
    const [candidateBrief, setCandidateBrief] = useState("");
    const [defaultModel, setDefaultModel] = useState("gpt-3.5-turbo");
    const [recordingCountdown, setRecordingCountdown] = useState(40);
    const [systemPromptReview, setSystemPromptReview] = useState("");
    const [defaultVoiceModel, setDefaultVoiceModel] = useState("default");
    const [showGenerateSystemPromptDialog, setShowGenerateSystemPromptDialog] = useState(false);
    const [generateSystemPromptInstructions, setGenerateSystemPromptInstructions] = useState("");

    // get the module item thats the google doc, google sheet or google slide
    const conversationModuleItem = module?.items?.find(
        (moduleItem) => moduleItem.module_item_type == module.module_type,
    );

    if (!conversationModuleItem) {
        return (
            <div className="flex w-full flex-col items-center gap-y-4">
                <div className="text-sm italic">Item not found</div>
            </div>
        );
    }

    const content = conversationModuleItem.content;
    useEffect(() => {
        if (content) {
            setSystemPrompt(content.system_prompt);
            setBotName(content.bot_name);
            setWelcomeMessage(content.welcome_message);
            setCandidateBrief(content.candidate_brief);
            setDefaultModel(content.default_model || "gpt-3.5-turbo");
            setRecordingCountdown(content.default_recording_countdown || 40);
            setSystemPromptReview(content.system_prompt_review);
            setDefaultVoiceModel(content.default_voice_model || "default");
        }
    }, [content]);

    return (
        <div className="grid grid-cols-3 gap-6">
            <div className="col-span-1">
                <div className="px-4 sm:px-0">
                    <h3 className="text-lg font-medium leading-6 text-slate-900">Conversation</h3>
                </div>
            </div>
            <div className="col-span-2">
                <div className="relative space-y-6 rounded-md  p-6 shadow">
                    <div className="text-sm italic">Conversation Settings</div>
                    <div className="flex flex-col gap-y-4">
                        <Fields.TextFieldFree
                            label="Bot name"
                            value={botName}
                            onChange={(e) => setBotName(e.target.value)}
                            type="text"
                        />

                        <Fields.SelectFieldFree
                            label="Model"
                            options={[
                                {
                                    label: "gpt-3.5-turbo",
                                    value: "gpt-3.5-turbo",
                                },
                                {
                                    label: "gpt-4",
                                    value: "gpt-4",
                                },
                                {
                                    label: "gpt-4-o",
                                    value: "gpt-4-o",
                                },
                            ]}
                            value={defaultModel}
                            onChange={(e) => {
                                setDefaultModel(e.target.value);
                            }}
                        />

                        <Fields.SelectFieldFree
                            label={
                                <div className="flex items-center justify-between">
                                    <div>Voice Model</div>
                                    <Buttons.Button
                                        loading={isPerformingAction}
                                        onClick={async () => {
                                            const result = await modulePreviewAction({
                                                id: module.id,
                                                action: "voice",
                                                body: {
                                                    miid: conversationModuleItem.id,
                                                    voice_model: defaultVoiceModel,
                                                },
                                                responseType: "blob",
                                            });

                                            if (result.data) {
                                                Utils.playAudio(
                                                    result.data.data,
                                                    result.data.headers["content-type"],
                                                );
                                            }
                                        }}
                                    >
                                        Test Voice
                                    </Buttons.Button>
                                </div>
                            }
                            options={[
                                {
                                    label: "Default (Tracy)",
                                    value: "default",
                                },
                                {
                                    label: "(EL) Natasha",
                                    value: "erVO1bnP06dXhjww3UQ3",
                                },
                                {
                                    label: "(EL) Bria",
                                    value: "kxd6anW1ipQCgEw6xKGd",
                                },
                                {
                                    label: "(EL) Theresa",
                                    value: "Nd4ZdJG8VZsqV0q1wnpl",
                                },
                                {
                                    label: "(EL) Joanne",
                                    value: "WCnBzHU29nLAaNrhl1it",
                                },
                                {
                                    label: "(EL) Ryan",
                                    value: "rU18Fk3uSDhmg5Xh41o4",
                                },
                                {
                                    label: "(EL) Daniel",
                                    value: "onwK4e9ZLuTAKqWW03F9",
                                },
                                {
                                    label: "(EL) Yousef",
                                    value: "PcMZf9NtwONVjCw5JHFG",
                                },
                                {
                                    label: "(EL) Dan",
                                    value: "9F4C8ztpNUmXkdDDbz3J",
                                },
                                {
                                    label: "(Polly) Ruth",
                                    value: "polly",
                                },
                                {
                                    label: "(Polly) Joanna",
                                    value: "Joanna",
                                },
                                {
                                    label: "(Polly) Kendra",
                                    value: "Kendra",
                                },
                                {
                                    label: "(Polly) Stephen",
                                    value: "Stephen",
                                },
                                {
                                    label: "(Polly) Joey",
                                    value: "Joey",
                                },
                                {
                                    label: "(OpenAI) Nova",
                                    value: "nova",
                                },
                                {
                                    label: "(OpenAI) Alloy",
                                    value: "alloy",
                                },
                                {
                                    label: "(OpenAI) Shimmer",
                                    value: "shimmer",
                                },
                                {
                                    label: "(OpenAI) Fable",
                                    value: "fable",
                                },
                                {
                                    label: "(OpenAI) Onyx",
                                    value: "onyx",
                                },
                                {
                                    label: "(OpenAI) Echo",
                                    value: "echo",
                                },
                            ]}
                            value={defaultVoiceModel}
                            onChange={(e) => {
                                setDefaultVoiceModel(e.target.value);
                            }}
                        />

                        <Fields.TextFieldFree
                            label="Initial Welcome Message"
                            placeholder="Hi, my name is Alice! Whats your name?"
                            value={welcomeMessage}
                            onChange={(e) => setWelcomeMessage(e.target.value)}
                            type="textarea"
                            rows="2"
                        />

                        <Fields.TextFieldFree
                            label="Recording Countdown"
                            value={recordingCountdown}
                            onChange={(e) => setRecordingCountdown(e.target.value)}
                            type="number"
                        />

                        <Fields.TextFieldFree
                            label={
                                <div className="space-y-4">
                                    <Fields.Label className="mb-0">System prompt</Fields.Label>
                                    <span
                                        className="cursor-pointer text-xs underline"
                                        onClick={() => {
                                            setShowGenerateSystemPromptDialog(true);
                                        }}
                                    >
                                        Generate System Prompt
                                    </span>
                                </div>
                            }
                            value={systemPrompt}
                            onChange={(e) => setSystemPrompt(e.target.value)}
                            type="textarea"
                            rows="30"
                            toolbar={<Fields.AIToolBar text={candidateBrief} />}
                        />

                        <Dialog
                            size="xl4"
                            isOpen={showGenerateSystemPromptDialog}
                            setIsOpen={setShowGenerateSystemPromptDialog}
                        >
                            <div className="space-y-4">
                                <Fields.TextFieldFree
                                    label="Instructions"
                                    placeholder="Marketing with Google Ads Knowledge"
                                    value={generateSystemPromptInstructions}
                                    type="textarea"
                                    rows="10"
                                    onChange={(e) =>
                                        setGenerateSystemPromptInstructions(e.target.value)
                                    }
                                />
                                <Buttons.Button
                                    loading={isGenerating}
                                    onClick={async () => {
                                        const result = await moduleGenerateAction({
                                            id: module.id,
                                            action: "conversation_system_prompt",
                                            body: {
                                                miid: conversationModuleItem.id,
                                                instructions: generateSystemPromptInstructions,
                                            },
                                        });
                                        if (result.data) {
                                            setSystemPrompt(result.data.prompt);
                                            setShowGenerateSystemPromptDialog(false);
                                        }
                                    }}
                                >
                                    Generate System Prompt
                                </Buttons.Button>
                            </div>
                        </Dialog>

                        <Fields.QuillFieldFree
                            label="Candidate brief"
                            value={candidateBrief}
                            onChange={(v) => {
                                setCandidateBrief(v);
                            }}
                            toolbar={
                                <Fields.AiToolbarGeneric
                                    text={candidateBrief}
                                    actions={[
                                        {
                                            label: "Check for grammar",
                                            prompt: "", // empty prmpt, as we are using the system prompt
                                            system_prompt: "candidate-brief",
                                            parseResponse: (response) => {
                                                return response;
                                            },
                                            renderer: ({ result, processResult, setResult }) => (
                                                <div className="mb-2">
                                                    <div>
                                                        <pre className="w-full whitespace-pre-wrap">
                                                            <code>{result}</code>
                                                        </pre>
                                                    </div>
                                                </div>
                                            ),
                                            model: "gpt-3.5-turbo",
                                        },
                                    ]}
                                />
                            }
                        />

                        <Fields.TextFieldFree
                            label="System prompt review (optional)"
                            labelPopover={
                                <div className="flex w-[500px] flex-col p-5 text-xs">
                                    <div>
                                        This is the system prompt that will be used to review the
                                        candidate. It defaults to:
                                    </div>

                                    <pre className="w-full whitespace-pre-wrap">
                                        {SYSTEM_PROMPT_REVIEW_DEFAULT}
                                    </pre>
                                </div>
                            }
                            value={systemPromptReview}
                            onChange={(e) => setSystemPromptReview(e.target.value)}
                            type="textarea"
                            rows="10"
                            toolbar={<Fields.AIToolBar text={systemPromptReview} />}
                        />

                        <Buttons.Button
                            loading={isUpdating}
                            disabled={isUpdating}
                            className="w-inherit"
                            onClick={() => {
                                updateModuleItem({
                                    id: module.id,
                                    body: {
                                        miid: conversationModuleItem.id,
                                        content: {
                                            ...content,
                                            system_prompt: systemPrompt,
                                            bot_name: botName,
                                            welcome_message: welcomeMessage,
                                            candidate_brief: candidateBrief,
                                            default_model: defaultModel,
                                            default_recording_countdown: recordingCountdown,
                                            system_prompt_review: systemPromptReview,
                                            default_voice_model: defaultVoiceModel,
                                        },
                                    },
                                });
                            }}
                        >
                            Save
                        </Buttons.Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

const SYSTEM_PROMPT_REVIEW_DEFAULT = `

As an expert assistant with a background in psychology, your specialty is evaluating conversations. 
You will be provided with a dialogue between a candidate and an assistant, as well as the brief the candidate followed. 

Your task is to assess the candidate's performance in managing the conversation based on five scoring criteria derived
from the brief. ONLY COME UP WITH AT MOST FIVE CRITERION. You must rate the candidates performance, not the assistant's.

Rate each criterion on a scale of 1 to 10 and offer a 2-3 sentence explanation for your reasoning. 

Be biased in favor of the candidate, but give a score of 0 if they did not provide an answer. 

Instead of "candidate", use the candidates name when explaining your reasoning.

Your response should always be in valid JSON format, structured as follows:

{
    "overall_score": "<your_overall_score>",
    "overall_description": "<your_overall_description>",
    "criteria": [
      {
        "criteria_title": "<your_criteria_brief_title>",
        "criteria_result": "<your_criteria_result_text>",
        "criteria_score": "<the_score_between_1-10>"
      },
      ... (additional criteria objects)
    ]
}

`;
