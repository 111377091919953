import React from "react";

import { updateTokens, useSsoCallbackMutation } from "./store/auth";
import {
    Buttons,
    Spinner,
    useDispatch,
    useEffect,
    useNavigate,
    useSearchParams,
    useState,
} from "./tools";

export default function SSOCallback() {
    const [ssoError, setSsoError] = useState(null);
    const [ssoErrorMessage, setSsoErrorMessage] = useState(null);
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const provider = searchParams.get("provider");
    const code = searchParams.get("code");
    const state = searchParams.get("state");

    const [callback, { isLoading, error }] = useSsoCallbackMutation();

    useEffect(() => {
        const doCallback = async () => {
            const result = await callback({
                body: {
                    code: code,
                    state: state,
                },
            });
            if (result.error) {
                setSsoError(true);
                setSsoErrorMessage(result.error?.data?.detail);
                return;
            }
            if (result.data) {
                const { access, refresh } = result.data;
                const { olid } = result.data;
                if (access && refresh) {
                    // this is a login
                    dispatch(updateTokens({ access, refresh }));
                    navigate("/");
                } else if (olid) {
                    // this is a signup, org lead
                    navigate("/onboarding/" + olid);
                }
            }
        };
        doCallback();
    }, []);

    if (isLoading) {
        return <Spinner />;
    }

    if (ssoError) {
        return (
            <div className="space-y-4 text-sm">
                <p>Oops. That did not work.</p>
                <p>{ssoErrorMessage}</p>
                {(state === "login" && (
                    <Buttons.Button href="/auth/login">Back to Login</Buttons.Button>
                )) ||
                    (state === "register" && (
                        <Buttons.Button href="/auth/register">Back to Register</Buttons.Button>
                    ))}
            </div>
        );
    }

    return (
        <div>
            <Spinner />
        </div>
    );
}
