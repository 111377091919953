import React from "react";

import CandidateReviewModal from "./CandidateReviewModal";
import CactusWorkInProgressImage from "./assets/img/cactus_work_in_progress.png";
import {
    AnimatePresenceGrow,
    Buttons,
    Cards,
    Dialog,
    Disclosures,
    Fields,
    Score,
    Utils,
    classNames,
    constants,
    moment,
    useState,
} from "./tools";

const calculateMQItemsScore = (items) => {
    const totalScore = items.reduce((acc, item) => {
        return acc + item.score;
    }, 0);
    const totalPossibleScore = items.reduce((acc, item) => {
        return acc + item.possible_score;
    }, 0);
    return {
        score: totalScore,
        possibleScore: totalPossibleScore,
    };
};

export default function CandidateReviewModuleGoogle({ module }) {
    const [showReviewDialog, setShowReviewDialog] = useState(false);
    const [reviewFocus, setReviewFocus] = useState(null);

    // in a Google Module, we want to show the Google Doc First, then Multiple Choice Questions, then others

    const googleDocModuleItem = module.items?.find((item) => {
        return module.module_type == item.module_item_type;
    });

    // eventually, we will include the generic items in the review

    // the MQ items
    const multipleChoiceItems = module.items?.filter((item) => {
        return item.module_item_type == constants.MODULE_TYPE_MULTIPLE_CHOICE;
    });

    // the generic items
    const genericItems = module.items?.filter((item) => {
        return constants.MODULE_TYPES_GENERIC.map((mg) => mg.id).includes(item.module_item_type);
    });

    const itemsWithAutoReview = module.items?.filter((item) => {
        return [constants.MODULE_TYPE_MULTIPLE_CHOICE].includes(item.module_item_type);
    });

    let itemsToReview = [googleDocModuleItem, ...genericItems];

    const mqItemsScore =
        multipleChoiceItems?.length != 0 && calculateMQItemsScore(multipleChoiceItems).score;
    const mqItemsPossibleScore =
        multipleChoiceItems?.length != 0 &&
        calculateMQItemsScore(multipleChoiceItems).possibleScore;

    if (multipleChoiceItems?.length != 0) {
        itemsToReview.push({
            id: "mq",
            reviewed_at: moment(),
            module_item_type: constants.MODULE_TYPE_MULTIPLE_CHOICE,
            score: mqItemsScore,
            possible_score: mqItemsPossibleScore,
        });
    }

    const itemsWithOpenReviews = itemsToReview?.filter((item) => {
        return item.reviewed_at == null || item.score == null || item.possible_score == null;
    });
    const itemsWithCompletedReviews = itemsToReview?.filter((item) => {
        return item.reviewed_at != null && item.score != null && item.possible_score != null;
    });

    return (
        <div>
            <Dialog size="full" isOpen={showReviewDialog} setIsOpen={setShowReviewDialog}>
                {reviewFocus && (
                    <CandidateReviewModal
                        restrictHeight
                        cami={reviewFocus}
                        onSuccess={() => {
                            // get the next item to review
                            // const nextItemToReview = itemsWithOpenReviews.find((item) => {
                            //     return item.id != reviewFocus.id;
                            // });
                            // if (nextItemToReview) {
                            //     setReviewFocus(nextItemToReview);
                            // } else {
                            //     // if there is no next item to review, close the modal
                            //     setShowReviewDialog(false);
                            // }
                            setShowReviewDialog(false);
                        }}
                    >
                        {constants.GOOGLE_FILE_TYPES.includes(reviewFocus.module_item_type) && (
                            <iframe
                                className="h-full w-full rounded-sm border-0 pb-8"
                                src={reviewFocus.content?.g_file_url}
                            />
                        )}

                        {reviewFocus.module_item_type == constants.MODULE_TYPE_SCREENSHARE && (
                            <video
                                controls
                                src={constants.S3_DOMAIN + "/" + reviewFocus.answer?.key}
                                className="h-full w-full rounded-md"
                            />
                        )}
                    </CandidateReviewModal>
                )}
            </Dialog>

            <Disclosures.DisclosureUnstyled
                open={itemsWithOpenReviews.length > 0}
                panels={[
                    {
                        title: (
                            <Cards.CardTwoColumns
                                className="bg-white transition-all hover:bg-slate-50"
                                col1={
                                    <div className="flex gap-x-3 text-sm">
                                        <div className="flex-shrink-0">
                                            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-slate-50">
                                                <img
                                                    src={
                                                        constants.MODULE_TYPE_ICONS[
                                                            module.module_type
                                                        ]
                                                    }
                                                    className="h-6 w-6"
                                                />
                                            </div>
                                        </div>
                                        <div className="min-w-0">
                                            <div className="truncate">{module.module.name}</div>
                                            <div className="truncate text-slate-400">
                                                {module.reviewed_at && module.score_average != null
                                                    ? "Review complete"
                                                    : "Needs review"}
                                            </div>
                                        </div>
                                    </div>
                                }
                                col2={
                                    (module.score_average && (
                                        <div>
                                            <div className="mb-1 flex justify-between">
                                                <span className="text-sm">Score</span>
                                                <span className="text-sm">
                                                    {Math.round(module.score_average)}%
                                                </span>
                                            </div>
                                            <div
                                                className={classNames(
                                                    "h-2.5 w-full rounded-full bg-slate-200",
                                                    module.score === 0 && "bg-slate-200",
                                                )}
                                            >
                                                <AnimatePresenceGrow show={true} initial={true}>
                                                    <div
                                                        className={classNames(
                                                            Utils.backgroundColorScorePercentage(
                                                                Math.round(module.score_average),
                                                            ),
                                                            " h-2.5 rounded-full",
                                                        )}
                                                        style={{
                                                            width:
                                                                Math.round(module.score_average) +
                                                                "%",
                                                        }}
                                                    ></div>
                                                </AnimatePresenceGrow>
                                            </div>
                                        </div>
                                    )) || (
                                        <div className="text-sm italic">
                                            The final score will be calculated after all items are
                                            reviewed. Please review the{" "}
                                            {itemsWithOpenReviews.length} open item(s) below.
                                        </div>
                                    )
                                }
                            />
                        ),
                        content: (
                            <div className="bg-white p-5">
                                {module.score != null && module.possible_score != null ? (
                                    <div className="flex flex-col gap-y-4">
                                        {itemsToReview.map((item) => {
                                            return (
                                                <Score.ScoreComponentReview
                                                    key={item.id}
                                                    item={item}
                                                    onClick={(item) => {
                                                        setReviewFocus(item);
                                                        setShowReviewDialog(true);
                                                    }}
                                                />
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className="flex flex-col gap-y-4 ">
                                        {itemsToReview.map((item) => {
                                            return (
                                                <Score.ScoreComponentReview
                                                    key={item.id}
                                                    item={item}
                                                    onClick={(item) => {
                                                        setReviewFocus(item);
                                                        setShowReviewDialog(true);
                                                    }}
                                                />
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        ),
                    },
                ]}
            />
        </div>
    );
}
