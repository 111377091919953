import CandidateExternalContact from "./CandidateExternalContact";
import {
    AnimatePresence,
    ApiCandidate,
    Buttons,
    Dialog,
    Fields,
    classNames,
    constants,
    moment,
    useEffect,
    useParams,
    useState,
} from "./tools";
import { useWarden } from "./useWarden";

export default function CandidateExternalModuleItemGoogleFile() {
    const { cid, caid } = useParams();
    const [answer, setAnswer] = useState([]);
    const [showSubmitDialog, setShowSubmitDialog] = useState(false);

    const {
        data: assessment,
        isLoading,
        error,
    } = ApiCandidate.useGetAssessmentQuery({ cid, caid }, {});

    const [performAction, { isLoading: isPerformingAction, error: performActionError }] =
        ApiCandidate.usePerformAssessmentActionMutation();

    const { runWarden, stopWarden } = useWarden(cid, caid);

    useEffect(() => {
        runWarden();
        return () => {
            stopWarden();
        };
    }, []);

    const current_module_item = assessment.current_module_item;
    const content = current_module_item.content;
    const isFileCreated = content.g_file_url !== null;
    const fileLastModified = (content.g_last_modified && moment(content.g_last_modified)) || null;
    const label = constants.MODULE_TYPE_LABELS[current_module_item.module_item_type];

    return (
        <div className="w-full border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10">
            <Dialog
                withScroll={false}
                size="lg"
                isOpen={showSubmitDialog}
                setIsOpen={setShowSubmitDialog}
            >
                <div className="flex flex-col gap-y-4 text-sm">
                    <div>
                        Are you sure you want to submit your work? You will not be able to change
                        your submission and <strong>immediately lose access to the {label}.</strong>
                    </div>
                    <div>Your last modification to the {label} was on:</div>
                    <div className="bg-slate-100 p-3 ring-slate-300">
                        {fileLastModified ? fileLastModified.format("LLL") : "never"}
                    </div>
                    <div className="flex justify-between">
                        <Buttons.Button onClick={() => setShowSubmitDialog(false)}>
                            Cancel
                        </Buttons.Button>
                        <Buttons.Button
                            disabled={isPerformingAction}
                            loading={isPerformingAction}
                            onClick={() => {
                                performAction({
                                    cid,
                                    caid,
                                    action: "answer",
                                    payload: {
                                        current_module_item_id: current_module_item.id,
                                        answer: content,
                                    },
                                });
                            }}
                            color="lgreen"
                        >
                            Submit Work
                        </Buttons.Button>
                    </div>
                </div>
            </Dialog>

            <div className="grid grid-cols-4 gap-6">
                <div className="col-span-3">
                    <div className="relative space-y-6 rounded-md">
                        <div className="prose-md prose max-w-full">
                            <h3>{label}</h3>
                            <p>
                                We have shared a {label} with you. You will be working directly
                                within the {label} to complete the module.
                            </p>
                            <p>
                                As you work on the assessment, rest assured that your progress will
                                be automatically saved at regular intervals. This feature ensures
                                that your work is securely stored and prevents any loss of data.
                            </p>
                            <p>
                                Once you have successfully completed the assessment module in the
                                Google Doc, please return to this page and click the 'Submit' button
                                to finalize and submit your work. We appreciate your effort and look
                                forward to reviewing your work.
                            </p>
                        </div>
                        <div>
                            {isFileCreated ? (
                                <div className="flex gap-x-4">
                                    <Buttons.Button
                                        className={classNames(
                                            "",
                                            constants.GOOGLE_FILE_COLORS[
                                                current_module_item.module_item_type
                                            ],
                                        )}
                                        target="_blank"
                                        href={content.g_file_url}
                                        color="slate"
                                    >
                                        <img
                                            className="mr-2 h-4 w-4"
                                            src={
                                                constants.MODULE_TYPE_ICONS[
                                                    current_module_item.module_item_type
                                                ]
                                            }
                                        />
                                        <span>Open assessment in {label}s</span>
                                    </Buttons.Button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className="col-span-1">
                    <CandidateExternalContact />
                </div>

                <div className="col-span-3">
                    <div>
                        <Buttons.Button
                            color="lgreen"
                            variant="solid"
                            disabled={isPerformingAction}
                            loading={isPerformingAction}
                            onClick={async () => {
                                const result = await performAction({
                                    cid,
                                    caid,
                                    action: "answer_check_status",
                                    payload: {
                                        current_module_item_id: current_module_item.id,
                                    },
                                });
                                if (result) {
                                    setShowSubmitDialog(true);
                                }
                            }}
                        >
                            Submit Work
                        </Buttons.Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
