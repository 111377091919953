import React from "react";

import { AnimatePresenceGrow, classNames } from "./tools";

const sizeMap = {
    xs: "h-1.5",
    sm: "h-2.5",
    md: "h-4",
    lg: "h-6",
    xl: "h-8",
};

const colorMap = {
    dark: "bg-gray-200",
    blue: "bg-blue-200",
    red: "bg-red-200",
    green: "bg-green-200",
    yellow: "bg-yellow-200",
    indigo: "bg-indigo-200",
    purple: "bg-purple-200",
    emerald: "bg-emerald-200",
};

export function ProgressBar({
    size = "md",
    color = "blue",
    label = "",
    valueLabel,
    value = 0,
    max = 100,
    labelPosition = "outside",
}) {
    const height = sizeMap[size];
    const bg = colorMap[color];
    // make sure value not over max
    const valueClamped = Math.min(value, max);
    const percentage = Math.round((valueClamped / max) * 100);

    return (
        <div className="space-y-2">
            {labelPosition === "outside" && (
                <div className="mb-1 flex items-center justify-between">
                    <span className="text-base font-medium">{label}</span>
                    <span className="text-base font-medium">{valueLabel || `${percentage}%`}</span>
                </div>
            )}
            <div
                className={classNames(
                    "w-full rounded-full bg-gray-200 transition-all duration-75",
                    height,
                )}
            >
                <AnimatePresenceGrow show={true} initial={true}>
                    <div
                        className={classNames("rounded-full", bg, height)}
                        style={{ width: `${percentage}%` }}
                    >
                        {labelPosition === "inside" && (
                            <div className="rounded-full p-0.5 text-center text-xs font-medium leading-none text-blue-100">
                                {valueLabel || `${percentage}%`}
                            </div>
                        )}
                    </div>
                </AnimatePresenceGrow>
            </div>
        </div>
    );
}
