import RexIcon from "./assets/img/rex.svg";
import { Buttons } from "./tools";

export default function ErrorFallback({ error, resetErrorBoundary }) {
    return (
        <div className="flex flex-col items-center justify-center p-20" role="alert">
            <div className="flex max-w-md flex-col gap-y-4 text-center">
                <img src={RexIcon} className="h-20 w-auto" />
                <p className="text-sm italic">"Something" went wrong</p>
                <Buttons.Button onClick={resetErrorBoundary}>Reload</Buttons.Button>
            </div>
        </div>
    );
}
