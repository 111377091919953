import React from "react";

import { CandidateAssessmentContext } from "./CandidateAssessmentProvider";
import {
    Api,
    Buttons,
    Comments,
    Fields,
    Score,
    UserContext,
    moment,
    useContext,
    useState,
} from "./tools";

export default function CandidateReviewModal({
    children,
    restrictHeight = false,
    cami,
    onSuccess,
    possibleScore = 10,
    ...props
}) {
    if (!cami) {
        throw new Error("cami is required");
    }

    const { assessment } = useContext(CandidateAssessmentContext);
    const { user } = useContext(UserContext);

    const [score, setScore] = useState(0);
    const [review, setReview] = useState("");

    const [createReview, { isLoading }] =
        Api.useCreateCandidateAssessmentModuleItemReviewMutation();

    const renderSidebarReviewClosed = () => {
        const { reviewed_at, review_by, review_comment, score, possible_score } = cami;

        return (
            <div className="flex flex-col gap-y-4">
                <div>
                    <h3 className="text-md font-bold">Review Complete</h3>
                </div>
                {review_by && (
                    <div>
                        {(review_comment && (
                            <Comments.Comment
                                createdBy={review_by}
                                createdAt={reviewed_at}
                                content={review_comment}
                            />
                        )) || (
                            <div className="text-xs">
                                Reviewed by {review_by.first_name} on{" "}
                                {moment(reviewed_at).format("MMMM Do YYYY")}
                            </div>
                        )}
                    </div>
                )}
                {score != null && possible_score != null && (
                    <Score.ScoreComponent
                        title="Review Score"
                        description={`Review complete`}
                        score={score}
                        maxScore={possible_score}
                    />
                )}
            </div>
        );
    };

    const renderSidebarReviewOpen = () => {
        return (
            <div className="flex flex-col gap-y-4">
                <div>
                    <h3 className="text-md font-bold">Review</h3>
                    <p className="text-sm">
                        Please review the candidate's work to the right. Once your review is
                        complete, use the form below to submit your review.
                    </p>
                    <p className="mt-3 text-sm font-bold">
                        Reviews are only shared with your hiring team, never with the candidate.
                    </p>
                </div>

                <Fields.ScoreFieldFree onChange={setScore} label="Score" defaultScore={0} />

                <Fields.QuillFieldFree
                    onChange={(val) => {
                        setReview(val);
                    }}
                    label="Notes"
                />

                <div>
                    <Buttons.ButtonWithConfirmation
                        loading={isLoading}
                        onClick={async () => {
                            const result = await createReview({
                                id: assessment.id,
                                body: {
                                    camiid: cami.id,
                                    score,
                                    possible_score: possibleScore,
                                    review_comment: review,
                                },
                            });
                            if (result.error) {
                                console.error(result.error);
                            } else {
                                onSuccess?.();
                            }
                        }}
                        confirm={{
                            okLabel: "Save Review",
                            dialogSize: "md",
                            content: (
                                <div className="flex flex-col gap-y-4">
                                    <div className="prose prose-sm">
                                        <h4 className="text-md font-bold">Are you sure?</h4>
                                        <p className="text-sm">
                                            Once you save this review, you will not be able to edit
                                            it.
                                        </p>
                                    </div>
                                    <div>
                                        <h3 className="text-md font-bold">Score</h3>
                                        <p className="text-sm">{score}</p>
                                    </div>
                                    <div className="space-y-2">
                                        <h3 className="text-md font-bold">Comment</h3>
                                        {(review && (
                                            <Comments.Comment
                                                content={review}
                                                createdBy={user}
                                                createdAt={moment()}
                                            />
                                        )) || <p className="text-sm">Comment is empty.</p>}
                                    </div>
                                </div>
                            ),
                        }}
                        variant="solidXS"
                    >
                        Save Review
                    </Buttons.ButtonWithConfirmation>
                </div>
            </div>
        );
    };

    return (
        <div className="grid grid-cols-12 gap-x-8">
            <div className="sticky top-0 col-span-3 self-start">
                {cami.reviewed_at ? renderSidebarReviewClosed() : renderSidebarReviewOpen()}
            </div>

            <div
                className="col-span-9"
                style={{
                    maxHeight: restrictHeight ? "calc(100vh - 60px)" : "unset",
                    minHeight: restrictHeight ? "calc(100vh - 60px)" : "unset",
                    overflowY: "auto",
                }}
            >
                {children}
            </div>
        </div>
    );
}
