// useQuery.js
import { useState } from "react";

import { useDebounce } from "./tools";

/**
 * useQuery is a custom hook for managing data fetching with pagination, filtering, and debounce search functionality.
 * This hook wraps a given query function with additional logic to handle pagination, filtering, and debouncing of search input.
 * It also provides default options for refetching data when the component is mounted, arguments change, or the component regains focus.
 * @function
 * @param {Function} queryFn - The query function to be used for data fetching. This function should accept two arguments: queryParams (an object containing pagination and filter information) and options (an object containing additional query options).
 * @param {number} page - The current page number for pagination.
 * @param {Object} filters - An object containing filter key-value pairs to be passed as query parameters.
 * @param {Object} [options={}] - An object containing additional options for the query function.
 * @returns {Object} The result of the query function, usually an object containing data and other relevant properties (e.g. isLoading, isError, etc.).
 * @example
 * const { data: candidates, isLoading, isError } = useQuery(fetchItems, currentPage, { search: searchTerm, category: selectedCategory }, { cacheTime: 1000 });
 */
export function useQuery(queryFn, page, filters, options) {
    const debouncedSearch = useDebounce(filters.search, 200);

    const queryParams = {
        page: page,
        ...filters,
    };

    if (debouncedSearch) {
        queryParams.search = debouncedSearch;
    }

    const defaultOptions = {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    };

    return queryFn(queryParams, { ...defaultOptions, ...options });
}
