import React from "react";

import { AnimatePresence, Avatars, Cards, Utils, classNames, moment, useState } from "./tools";

export function Comment({ createdBy, createdAt, content, toolbar, toolbarBottom, className }) {
    const [showToolbar, setShowToolbar] = useState(false);
    return (
        <Cards.Card
            onMouseEnter={() => setShowToolbar(true)}
            onMouseLeave={() => setShowToolbar(false)}
            className={classNames("space-y-3", className)}
        >
            <div className="flex justify-between">
                <div className="flex items-center gap-x-4">
                    <Avatars.Avatar hash={createdBy.d} placeholder={createdBy.initials} />
                    <div className="text-sm">
                        <h4 className="font-bold text-slate-600">{createdBy.first_name}</h4>
                        <div className=" text-slate-500">{moment(createdAt).fromNow()}</div>
                    </div>
                </div>
                <AnimatePresence initial={true} show={showToolbar}>
                    <div>{toolbar}</div>
                </AnimatePresence>
            </div>

            <div
                className="prose prose-sm text-slate-600"
                dangerouslySetInnerHTML={{ __html: Utils.replaceEmptyNewLinesWYSIWYG(content) }}
            />
            {toolbarBottom}
        </Cards.Card>
    );
}
