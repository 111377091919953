import {
    AnimatePresence,
    ApiCandidate,
    Buttons,
    Fields,
    _,
    classNames,
    useEffect,
    useParams,
    useState,
} from "./tools";
import { useWarden } from "./useWarden";

const DEFAULT_RECORDING_COUNTDOWN = 40;

export default function CandidateExternalModuleItemConversationRecorderPush2Talk({
    onStopRecording,
    inputDeviceId,
    isFirstRecording = false,
    defaultRecordingCountdown = DEFAULT_RECORDING_COUNTDOWN,
}) {
    const { cid, caid } = useParams();
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [isRecording, setIsRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const [voiceLevel, setVoiceLevel] = useState(0);

    const [recordingCountdown, setRecordingCountdown] = useState(
        isFirstRecording ? defaultRecordingCountdown * 2 : defaultRecordingCountdown,
    );
    const [inputLevel, setInputLevel] = useState(0);

    const {
        data: assessment,
        isLoading,
        error,
    } = ApiCandidate.useGetAssessmentQuery({ cid, caid }, {});

    const [performAction, { isLoading: isPerformingAction, error: performActionError }] =
        ApiCandidate.usePerformAssessmentActionMutation();

    useEffect(() => {
        async function getMedia() {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: { deviceId: { exact: inputDeviceId } },
            });

            const recorder = new MediaRecorder(stream, { mimeType: "audio/webm;codecs=opus" });
            setMediaRecorder(recorder);
        }
        getMedia();
        return () => {
            if (mediaRecorder) {
                mediaRecorder.stop();
            }
        };
    }, []);

    useEffect(() => {
        if (recordedChunks.length) {
            const audioBlob = new Blob(recordedChunks, { type: "audio/webm" });
            setAudioBlob(audioBlob);
            onStopRecording(audioBlob);
        }
    }, [recordedChunks]);

    useEffect(() => {
        if (mediaRecorder) {
            const interval = setInterval(() => {
                setRecordingCountdown((prev) => {
                    if (prev == 0) {
                        clearInterval(interval);
                        if (mediaRecorder.state === "recording") {
                            mediaRecorder.stop();
                            setIsRecording(false);
                        } else {
                            onStopRecording?.(99);
                        }
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            const handleKeyDown = (e) => {
                if (e.code === "Space" && !isRecording) {
                    e.preventDefault(); // Prevent default scrolling behavior
                    setIsRecording(true);
                    startRecording();
                }
            };

            const handleKeyUp = (e) => {
                if (e.code === "Space" && mediaRecorder.state === "recording") {
                    e.preventDefault(); // Prevent default scrolling behavior
                    // wait 200ms, then stop recording
                    setTimeout(() => {
                        setIsRecording(false);
                        stopRecording();
                    }, 200);
                }
            };

            // Add the event listeners to the window object
            window.addEventListener("keydown", handleKeyDown);
            window.addEventListener("keyup", handleKeyUp);

            // Cleanup event listeners when the component is unmounted
            return () => {
                window.removeEventListener("keydown", handleKeyDown);
                window.removeEventListener("keyup", handleKeyUp);
                clearInterval(interval);
                if (mediaRecorder && mediaRecorder.state === "recording") {
                    mediaRecorder.stop();
                }
            };
        }
    }, [mediaRecorder]);

    const startRecording = () => {
        if (!mediaRecorder) return;
        setIsRecording(true);
        setAudioBlob(null);

        if (mediaRecorder.state === "recording") {
            return;
        }

        let buffer = [];
        mediaRecorder.addEventListener("dataavailable", (event) => {
            if (event?.data?.size > 0) {
                buffer.push(event.data);
            }
        });

        mediaRecorder.addEventListener("stop", (event) => {
            setRecordedChunks(buffer);
        });

        mediaRecorder.start();
    };

    const stopRecording = async () => {
        if (!mediaRecorder) return;
        if (mediaRecorder.state === "recording") {
            mediaRecorder.stop();
        }
        setIsRecording(false);
    };

    return (
        <div className="flex flex-col items-center justify-center gap-y-4 text-center">
            {(isRecording && (
                <div className="font-bold">
                    You are recoding... Release the spacebar to stop recording.
                </div>
            )) || (
                <div>
                    <strong>Press and hold the spacebar</strong> to record. Release to stop
                    recording.
                </div>
            )}

            <div>You have {recordingCountdown} seconds left.</div>
        </div>
    );
}
