import React from "react";

import AssessmentList from "./AssessmentList";
import CandidateList from "./CandidateList";
import TeamStats from "./TeamStats";
import {
    AnimatePresence,
    Api,
    Avatar,
    Avatars,
    Buttons,
    Link,
    Tabs,
    _,
    classNames,
    moment,
    useEffect,
    useNavigate,
    useParams,
    useSearchParams,
    useState,
} from "./tools";

export default function TeamSingle() {
    const { id } = useParams();
    const {
        data: team,
        isLoading,
        error,
    } = Api.useGetTeamQuery(id, {
        refetchOnMountOrArgChange: true,
    });
    const navigate = useNavigate();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    return (
        <div className="flex flex-col">
            <main className="flex-1">
                <div className="relative">
                    <div className="pb-16">
                        <div className="flex justify-between px-4 sm:px-6 md:px-0">
                            <div className="flex items-center gap-x-4">
                                <div>
                                    <Avatars.AvatarTeam size="xl" team={team} />
                                </div>
                                <div>
                                    <div>
                                        <Link className="text-sm text-slate-400" to="/teams">
                                            Teams
                                        </Link>
                                    </div>
                                    <h5
                                        onClick={() => {
                                            // copy current assessment id to clipboard
                                            navigator.clipboard.writeText(team.id);
                                        }}
                                        className="text-3xl font-bold tracking-tight text-emerald-900"
                                    >
                                        {team?.name}
                                    </h5>
                                </div>
                            </div>
                            <div className="flex items-center gap-x-2">
                                <Avatars.AvatarGroup
                                    maxDisplayed={5}
                                    items={team.users?.map((tm) => {
                                        return {
                                            placeholder: tm.initials,
                                            hash: tm.d,
                                            title: tm.full_name,
                                        };
                                    })}
                                />
                            </div>
                        </div>

                        <Tabs
                            persistSearchParams
                            tabs={[
                                {
                                    name: "Candidates",
                                    href: "candidates",
                                    current: true,
                                    component: (
                                        <div className="grid grid-cols-4 gap-x-8">
                                            <div className="col-span-3">
                                                <CandidateList
                                                    title={`${team.name} Candidates`}
                                                    filters={{
                                                        team_id: id,
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-1">
                                                <TeamStats id={team.id} />
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    name: "Assessments",
                                    href: "assessments",
                                    current: false,
                                    component: (
                                        <AssessmentList
                                            title={`${team.name} Assessments`}
                                            filters={{
                                                team_id: id,
                                            }}
                                        />
                                    ),
                                },
                            ]}
                            selected={0}
                        />
                    </div>
                </div>
            </main>
        </div>
    );
}
