// useCandidates.js
import { Api } from "./tools";
import { useQuery } from "./useQuery";

export function useCandidates(page, filters) {
    const {
        data: candidates,
        isLoading,
        error,
    } = useQuery(Api.useGetCandidatesQuery, page, filters);

    return {
        candidates,
        isLoading,
        error,
    };
}
