import { Form, Formik } from "formik";
import { Reorder, useDragControls, useMotionValue } from "framer-motion";

import Appear from "./Appear";
import { TextField } from "./Fields";
import { ModuleListInline, ModuleListInlineSingle } from "./ModuleListInline";
import { useRaisedShadow } from "./useRaisedShadow";

const {
    constants,
    Api,
    useParams,
    Buttons,
    Dialog,
    useEffect,
    useState,
    useNavigate,
    useContext,
    UserContext,
    Utils,
    _,
} = require("./tools");

export default function AssessmentModules({ onSuccess }) {
    const [explain, setExplain] = useState(null);
    const [showModulesDialog, setShowModulesDialog] = useState(false);
    const [items, setItems] = useState([]);

    const { id } = useParams();
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const { data: assessment, isLoading, error } = Api.useGetAssessmentQuery(id);
    const [updateAssessment, { isLoading: isSaving }] = Api.useUpdateAssessmentMutation();
    const [updateAssessmentModules, { isLoading: isSavingModules }] =
        Api.useUpdateAssessmentModulesMutation();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    useEffect(() => {
        setItems(assessment.modules);
    }, [assessment.modules?.length]);

    return (
        <div>
            <Dialog isOpen={showModulesDialog} setIsOpen={setShowModulesDialog}>
                <ModuleListInline
                    ignoreIds={assessment.modules.map((module) => module.module_id)}
                    onClickAdd={async (module) => {
                        const newModules = [
                            ...assessment.modules,
                            {
                                order: assessment.modules.length + 1,
                                module_id: module.id,
                            },
                        ];
                        const result = await updateAssessmentModules({
                            id: assessment.id,
                            body: {
                                modules: newModules,
                            },
                        });
                        setShowModulesDialog(false);
                    }}
                />
            </Dialog>

            <div className="gap-x-12 md:grid md:grid-cols-3">
                <div className="md:col-span-1">
                    <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-slate-900"> Modules </h3>
                        <p className="mt-1 text-sm text-slate-600">
                            Modules are the building blocks of an assessment. An assessment
                            typically consists of 1-3 modules.
                        </p>
                    </div>
                </div>
                <div className="bg-white px-4 py-5 md:col-span-2">
                    <div className="flex gap-x-2">
                        <Buttons.Button
                            onClick={() => {
                                setShowModulesDialog(true);
                            }}
                        >
                            Add Module
                        </Buttons.Button>
                        <Buttons.Button href="/library/modules/create">
                            Create Custom Module
                        </Buttons.Button>
                    </div>

                    <div className="mt-4">
                        <Reorder.Group
                            axis="y"
                            as="div"
                            className="grid grid-cols-1 gap-4"
                            values={items}
                            onReorder={_.throttle((newItems) => {
                                console.log("reorder");
                                setItems(newItems);
                                updateAssessmentModules({
                                    id: assessment.id,
                                    body: {
                                        modules: newItems.map((item, index) => {
                                            return {
                                                ...item,
                                                order: index + 1,
                                            };
                                        }),
                                    },
                                });
                            }, 1000)}
                        >
                            {items.map((module, index) => {
                                return (
                                    <Reorder.Item
                                        key={module.id}
                                        value={module}
                                        as="div"
                                        whileHover={{
                                            scale: 1.03,
                                            boxShadow: "0px 3px 3px rgba(0,0,0,0.15)",
                                        }}
                                        onDrop={(values) => {
                                            console.log("drop");
                                        }}
                                    >
                                        <ModuleListInlineSingle
                                            draggable
                                            module={module.module}
                                            onClickDelete={async (module) => {
                                                const newModules = assessment.modules.filter(
                                                    (m) => m.module.id != module.id,
                                                );
                                                setItems(newModules);
                                                const result = await updateAssessmentModules({
                                                    id: assessment.id,
                                                    body: {
                                                        modules: newModules,
                                                    },
                                                });
                                            }}
                                            onClickEdit={
                                                Utils.isStaff(user) ||
                                                (module.module.module_type !=
                                                    constants.MODULE_TYPE_CONVERSATIONAL &&
                                                    module?.module?.is_custom_module)
                                                    ? (module) => {
                                                          navigate(`/library/modules/${module.id}`);
                                                      }
                                                    : null
                                            }
                                        />
                                    </Reorder.Item>
                                );
                            })}
                        </Reorder.Group>
                    </div>
                </div>
            </div>
        </div>
    );
}
