import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CandidateInviteTutorial from "./CandidateInviteTutorial";
import {
    AnimatePresenceGrow,
    Api,
    Avatar,
    Avatars,
    Buttons,
    Link,
    Spinner,
    Tables,
    Utils,
    classNames,
    moment,
} from "./tools";
import { useCandidates } from "./useCandidates";

function Status({ candidate }) {
    const { latest_assessment } = candidate;

    if (!latest_assessment) {
        return null;
    }

    // complete, reviewed, and scored
    if (
        latest_assessment.completed_at &&
        latest_assessment.reviewed_at &&
        latest_assessment.score_average != null
    ) {
        const score = Math.round(latest_assessment.score_average);
        return (
            <div className="flex items-center">
                <div className="w-full">
                    <div className="mb-1 flex justify-between">
                        <span className="text-sm">Score</span>
                        <span className="text-sm">{score}%</span>
                    </div>
                    <div
                        className={classNames(
                            "h-2.5 w-full rounded-full bg-slate-200",
                            score === 0 && "bg-slate-200",
                        )}
                    >
                        <AnimatePresenceGrow show={true} initial={true}>
                            <div
                                className={classNames(
                                    Utils.backgroundColorScorePercentage(score),
                                    " h-2.5 rounded-full",
                                )}
                                style={{
                                    width: score + "%",
                                }}
                            ></div>
                        </AnimatePresenceGrow>
                    </div>
                </div>
            </div>
        );
    }

    // complete, but not score
    if (latest_assessment.completed_at && !latest_assessment.score) {
        return (
            <div className="flex items-center">
                <div className="mr-2 h-2.5 w-2.5 rounded-full bg-red-300"></div> Needs Review
            </div>
        );
    }

    // started, but not complete
    if (latest_assessment.started_at && !latest_assessment.completed_at) {
        return (
            <div className="flex items-center">
                <div className="mr-2 h-2.5 w-2.5 rounded-full bg-yellow-300"></div> In Progress
            </div>
        );
    }

    // not started
    if (!latest_assessment.started_at) {
        const since = moment(latest_assessment.created_at).fromNow();
        return (
            <div className="flex min-w-0 max-w-[300px] items-center">
                <div className="mr-2 h-2.5 w-2.5 rounded-full bg-gray-300"></div>{" "}
                <div className="truncate">Invited ({since})</div>
            </div>
        );
    }

    return (
        <div className="flex items-center">
            <div className="mr-2 h-2.5 w-2.5 rounded-full bg-slate-300"></div> In Progress
        </div>
    );
}

export default function CandidateList({ filters = {}, title = "Candidates", withSearch = true }) {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(null);

    const updatedFilters = {
        search,
        ...filters,
    };

    const { candidates, isLoading, error } = useCandidates(page, updatedFilters);

    const [deleteCandidate, { isLoading: isDeleting }] = Api.useDeleteCandidateMutation();

    const navigate = useNavigate();

    if (isLoading) {
        return <Spinner />;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    const candidateLink = (candidate) => {
        // if filters include assessment_id
        if (filters.assessment_id) {
            return `/candidates/${candidate.id}?a=${filters.assessment_id}`;
        }
        return `/candidates/${candidate.id}`;
    };

    return (
        <Tables.TablePaginatedServer
            title={title}
            showHeader={false}
            total={candidates?.count}
            goToPage={setPage}
            page={page}
            withSearch={withSearch && setSearch}
            rows={candidates?.results.map((candidate) => {
                return {
                    key: candidate.id,
                    cells: [
                        {
                            key: "name",
                            content: (
                                <Link to={candidateLink(candidate)}>
                                    <div className="flex items-center space-x-3">
                                        <div className="flex-shrink-0">
                                            <Avatar
                                                placeholder={candidate.initials}
                                                hash={candidate.d}
                                            />
                                        </div>
                                        <div>
                                            <div className="text-gray-900">
                                                {candidate.first_name} {candidate.last_name}
                                            </div>
                                            <div className="text-gray-500"></div>
                                        </div>
                                    </div>
                                </Link>
                            ),
                        },
                        {
                            key: "team",
                            content: (
                                <div className="flex items-center space-x-3">
                                    <div className="flex-shrink-0">
                                        <Avatars.AvatarTeam team={candidate.team} />
                                    </div>
                                    <div>
                                        <div className="text-gray-900">{candidate.team.name}</div>
                                    </div>
                                </div>
                            ),
                        },
                        {
                            key: "latest_update",
                            content: <Status candidate={candidate} />,
                        },
                        {
                            key: "actions",
                            className: "text-right",
                            content: (
                                <Buttons.ButtonActionsDropdown
                                    actions={[
                                        {
                                            label: "View",
                                            onClick: () => {
                                                navigate(`/candidates/${candidate.id}`);
                                            },
                                        },
                                        {
                                            label: "Delete",
                                            confirm: {
                                                title: `Delete ${candidate.first_name}?`,
                                                description: `Are you sure you want to delete ${candidate.first_name}? This action cannot be undone.`,
                                            },
                                            onClick: async () => {
                                                const result = await deleteCandidate(candidate.id);
                                            },
                                        },
                                    ]}
                                />
                            ),
                        },
                    ],
                };
            })}
        />
    );
}
