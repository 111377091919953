import React from "react";

import { classNames } from "./tools";

export function Tag({ tag, ...props }) {
    return (
        <span
            title={tag.tag}
            className={classNames(
                "relative flex cursor-pointer flex-wrap items-center justify-between rounded-xl bg-emerald-600 py-2 pl-4 pr-6 text-sm font-medium text-white hover:bg-emerald-500 hover:text-gray-100",
                tag.className,
            )}
            onClick={() => {
                tag.onClick?.(tag);
            }}
        >
            <span className="max-w-[120px] truncate pr-2">{tag.tag}</span>
            {tag.onRemove ? (
                <XMarkIcon
                    onClick={() => tag.onRemove(tag)}
                    className="absolute right-2 ml-2 h-4 w-4"
                />
            ) : null}
        </span>
    );
}

export function TagList({ tags = [], className, ...props }) {
    return (
        <div className={classNames("flex flex-wrap gap-x-2 gap-y-2", className)}>
            {tags.map((tag) => (
                <Tag key={tag.tag} {...props} tag={tag} />
            ))}
        </div>
    );
}
