import LogoNew from "./assets/img/cactus-hire-new.svg";
import {
    AnimatePresence,
    ApiCandidate,
    Buttons,
    Fields,
    useEffect,
    useParams,
    useState,
} from "./tools";
import { useWarden } from "./useWarden";

export default function CandidateExternalModuleItemMQ() {
    const { cid, caid } = useParams();
    const [answer, setAnswer] = useState([]);

    const {
        data: assessment,
        isLoading,
        error,
    } = ApiCandidate.useGetAssessmentQuery({ cid, caid }, {});

    const [performAction, { isLoading: isPerformingAction, error: performActionError }] =
        ApiCandidate.usePerformAssessmentActionMutation();

    const { runWarden, stopWarden } = useWarden(cid, caid);

    useEffect(() => {
        runWarden();
        return () => {
            stopWarden();
        };
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const question = assessment.current_module_item.content.question;
    const possibleAnswers = assessment.current_module_item.content.answers;
    const pca = assessment.current_module_item.content.pca;

    return (
        <div className="w-full border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10">
            <div className="flex flex-col gap-y-5">
                <div className="">
                    <div className="mb-3 text-xl font-semibold text-slate-700">{question}</div>
                    <div className="text-md font-medium text-slate-500">
                        Pick {pca} correct answer(s)
                    </div>
                </div>

                <ul className="w-100 text-md rounded-md border border-gray-200 font-medium text-slate-900 dark:border-slate-600 dark:bg-slate-700 ">
                    {possibleAnswers.map((pa, i) => {
                        return (
                            <li
                                key={i}
                                className="w-full rounded-t-lg border-b border-slate-200 hover:bg-slate-50 dark:border-slate-600"
                            >
                                <div className="flex items-center pl-3">
                                    <input
                                        disabled={
                                            // disable input if pca is number of answers
                                            pca === answer.length &&
                                            !answer.find((a) => a.answer === pa)
                                        }
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setAnswer([
                                                    ...answer,
                                                    {
                                                        answer: pa,
                                                    },
                                                ]);
                                            } else {
                                                setAnswer(answer.filter((a) => a.answer !== pa));
                                            }
                                        }}
                                        id={`answer-${i}`}
                                        type="checkbox"
                                        value={pa}
                                        className="h-4 w-4 rounded border-slate-300 bg-slate-100  text-emerald-600 focus:ring-2 focus:ring-transparent focus:ring-emerald-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-emerald-600 dark:focus:ring-offset-gray-700"
                                    />
                                    <label
                                        htmlFor={`answer-${i}`}
                                        className="ml-4 w-full py-5 pr-8 text-sm font-medium  text-gray-900 dark:text-gray-300"
                                    >
                                        {pa}
                                    </label>
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <div>
                    <Buttons.Button
                        color="lgreen"
                        variant="solid"
                        disabled={isPerformingAction || !answer.length || answer.length !== pca}
                        onClick={async () => {
                            const result = await performAction({
                                cid,
                                caid,
                                action: "answer",
                                payload: {
                                    current_module_item_id: assessment.current_module_item.id,
                                    answer: answer,
                                },
                            });
                            if (result) {
                                // reset answer
                                setAnswer([]);
                                // uncheck all checkboxes
                                const checkboxes =
                                    document.querySelectorAll("input[type=checkbox]");
                                checkboxes.forEach((checkbox) => {
                                    checkbox.checked = false;
                                });
                            }
                        }}
                    >
                        Continue
                    </Buttons.Button>
                </div>
            </div>
        </div>
    );
}
