import React from "react";

import {
    Api,
    Avatars,
    Buttons,
    Fields,
    Form,
    Formik,
    Forms,
    Spinner,
    Tables,
    Yup,
    _,
    classNames,
    constants,
    useState,
} from "./tools";

export default function TeamEditForm({ tid, onSuccess }) {
    const [nonFieldError, setNonFieldError] = React.useState(null);
    const { data: team, isLoading, error } = Api.useGetTeamQuery(tid);
    const {
        data: users,
        isLoading: isLoadingUsers,
        errorUsers,
    } = Api.useGetOrgUsersQuery({ search: null });
    const [updateTeam, { isLoading: isUpdatingTeam, error: errorUpdateTeam }] =
        Api.useUpdateTeamMutation();
    const [
        updateTeamMemberAdd,
        { isLoading: isUpdatingTeamMemberAdd, error: errorUpdateTeamMemberAdd },
    ] = Api.useUpdateTeamMembersAddMutation();
    const [
        updateTeamMemberRemove,
        { isLoading: isUpdatingTeamMemberRemove, error: errorUpdateTeamMemberRemove },
    ] = Api.useUpdateTeamMembersRemoveMutation();

    if (isLoading || isLoadingUsers) {
        return <Spinner size="md" center />;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-y-4">
            <Formik
                initialValues={{ name: team.name }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required("Please enter a team name"),
                })}
                onSubmit={async (values, { setSubmitting, setFieldError }) => {
                    setNonFieldError(null);
                    try {
                        const result = await updateTeam({
                            id: team.id,
                            body: {
                                name: values.name,
                            },
                        });
                        setSubmitting(false);
                        if (result.error) {
                            if (result.error.data.detail) {
                                setNonFieldError(result.error.data.detail);
                            }
                            for (const [key, value] of Object.entries(result.error.data)) {
                                setFieldError(key, value[0]);
                            }
                        } else {
                            onSuccess?.();
                        }
                    } catch (err) {
                        console.log(err);
                        setSubmitting(false);
                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Form className="grid grid-cols-1 gap-y-8">
                        <Fields.TextField type="text" name="name" label="Team name" />
                        <div className="space-y-2">
                            <Buttons.Button
                                type="submit"
                                variant="solid"
                                color="lgreen"
                                className="w-full"
                                disabled={isSubmitting}
                                loading={isUpdatingTeam}
                                loadingMessage="Saving..."
                            >
                                <span>Save</span>
                            </Buttons.Button>

                            {nonFieldError && (
                                <div className="text-sm text-red-600">{nonFieldError}</div>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>

            <div>
                <Tables.TablePaginated
                    showHeader={false}
                    pageSize={5}
                    rows={team.users.map((u) => {
                        return {
                            key: u.id,
                            cells: [
                                {
                                    key: "name",
                                    content: (
                                        <div className="flex items-center space-x-3">
                                            <div className="flex-shrink-0">
                                                <Avatars.Avatar
                                                    placeholder={u.initials}
                                                    hash={u.d}
                                                />
                                            </div>
                                            <div>
                                                <div className="text-slate-900">{u.full_name}</div>
                                                <div className="text-slate-500">
                                                    {constants.USER_ROLE_MAP[u.role]}
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    key: "actions",
                                    content: (
                                        <div className="flex items-center justify-end gap-x-4">
                                            <Buttons.ButtonWithConfirmation
                                                loading={isUpdatingTeamMemberRemove}
                                                onClick={async () => {
                                                    const { result } = updateTeamMemberRemove({
                                                        id: team.id,
                                                        body: {
                                                            uid: u.id,
                                                        },
                                                    });
                                                }}
                                                confirm={{
                                                    title: "Remove User",
                                                    description: `Are you sure you want to remove ${u.full_name} from ${team.name}?`,
                                                    confirmLabel: "Remove User",
                                                }}
                                            >
                                                Remove from Team
                                            </Buttons.ButtonWithConfirmation>
                                        </div>
                                    ),
                                },
                            ],
                        };
                    })}
                />
            </div>
            <div>
                <Tables.TablePaginated
                    showHeader={false}
                    pageSize={5}
                    rows={users
                        .filter((u) => !team.users.some((tu) => tu.id === u.id))
                        .map((u) => {
                            return {
                                key: u.id,
                                cells: [
                                    {
                                        key: "name",
                                        content: (
                                            <div className="flex items-center space-x-3">
                                                <div className="flex-shrink-0">
                                                    <Avatars.Avatar
                                                        placeholder={u.initials}
                                                        hash={u.d}
                                                    />
                                                </div>
                                                <div>
                                                    <div className="text-slate-900">
                                                        {u.full_name}
                                                    </div>
                                                    <div className="text-slate-500">
                                                        {constants.USER_ROLE_MAP[u.role]}
                                                    </div>
                                                </div>
                                            </div>
                                        ),
                                    },
                                    {
                                        key: "actions",
                                        content: (
                                            <div className="flex items-center justify-end gap-x-4">
                                                <Buttons.ButtonWithConfirmation
                                                    onClick={async () => {
                                                        const { result } = updateTeamMemberAdd({
                                                            id: team.id,
                                                            body: {
                                                                uid: u.id,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    Add to team
                                                </Buttons.ButtonWithConfirmation>
                                            </div>
                                        ),
                                    },
                                ],
                            };
                        })}
                />
            </div>
        </div>
    );
}
