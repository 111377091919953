import { CheckBadgeIcon } from "@heroicons/react/24/outline";

import { CandidateAssessmentContext } from "./CandidateAssessmentProvider";
import C2Image from "./assets/img/c2.png";
import R1Image from "./assets/img/r1.png";
import { Badges, Buttons, classNames, useContext } from "./tools";

// there are four scores, 1 not scored yet, 2 passed, 3 failed, 4 attention

const displayScore = (score) => {
    switch (score) {
        case 1:
            return <Badges.Badge svgClassName="fill-slate-300">Not scored yet</Badges.Badge>;
        case 2:
            return <Badges.Badge svgClassName="fill-emerald-300">Pass</Badges.Badge>;
        case 3:
            return <Badges.Badge svgClassName="fill-red-300">Fail</Badges.Badge>;
        case 4:
            return <Badges.Badge svgClassName="fill-yellow-300">Attention</Badges.Badge>;
        default:
            return <Badges.Badge svgClassName="fill-slate-300">Not scored yet</Badges.Badge>;
    }
};

export default function CandidateSummaryWardenScore({}) {
    const { assessment } = useContext(CandidateAssessmentContext);

    if (!assessment.warden_score) {
        return null;
    }

    return (
        <div className="flex h-full flex-col gap-y-2">
            <div className="flex h-full items-center justify-between">
                <div className="flex items-center gap-x-3">
                    <div className="flex-grow-0">
                        <img
                            src={C2Image}
                            className="h-9 w-9 rounded-md grayscale hover:ring-yellow-600"
                        />
                    </div>
                    <div>
                        <h3 className="text-sm font-bold leading-4 text-gray-500">SpikyWarden </h3>
                        <p className="inline text-xs leading-3 text-gray-500">Enabled</p>{" "}
                        <CheckBadgeIcon className="inline h-4 w-4 text-emerald-600" />
                    </div>
                </div>
                <div className="flex items-center">{displayScore(assessment.warden_score)}</div>
            </div>
        </div>
    );
}
