import { PauseIcon, PlayIcon, VideoCameraIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useRef, useState } from "react";

import { Buttons } from "./tools";

const ScreenShareSelector = ({
    onStartScreenShare,
    onStopScreenShare,
    onNotSupported,
    inputDeviceId,
    onPermissionError,
    onSomeOtherError,
}) => {
    const [isSharing, setIsSharing] = useState(false);
    const [screenSharingStream, setScreenSharingStream] = useState(null);
    const recorderRef = useRef(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);

    const canIUse = () => {
        return navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
    };

    const startScreenSharing = async () => {
        if (!canIUse()) {
            onNotSupported?.();
            return;
        }

        try {
            const displayMediaOptions = {
                video: {
                    cursor: "always",
                },
                audio: false,
            };
            const stream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);

            const audioConstraints = {
                audio: true,
                ...(inputDeviceId ? { deviceId: { ideal: inputDeviceId } } : {}),
            };

            const audioStream = await navigator.mediaDevices.getUserMedia(audioConstraints);

            audioStream.getAudioTracks().forEach((track) => {
                stream.addTrack(track);
            });

            onStartScreenShare?.(stream);
            setScreenSharingStream(stream);
            setIsSharing(true);

            const recorder = new MediaRecorder(stream);
            const chunks = [];
            recorder.ondataavailable = (e) => chunks.push(e.data);

            const videoTrack = stream.getVideoTracks()[0];
            videoTrack.onended = () => {
                recorder.stop();
            };

            recorder.onstop = (e) => {
                const videoBlob = new Blob(chunks, { type: "video/webm" });
                onStopScreenShare?.(videoBlob);
                setIsSharing(false);
            };

            recorder.start();
            setMediaRecorder(recorder);
        } catch (err) {
            console.error("Error starting screen sharing:", err);
            if (err.name === "NotAllowedError" || err.name === "PermissionDeniedError") {
                onPermissionError?.(err);
            } else {
                onSomeOtherError?.(err);
            }
        }
    };

    const stopScreenSharing = () => {
        if (!screenSharingStream || !mediaRecorder) return;

        const videoTrack = screenSharingStream.getVideoTracks()[0];
        videoTrack.stop();

        if (mediaRecorder.state === "recording") {
            mediaRecorder.stop();
        }

        // get stream and tracks from screen sharing, then stop them
        const tracks = screenSharingStream.getTracks();
        tracks.forEach((track) => track.stop());
    };

    useEffect(() => {
        return () => {
            if (isSharing || mediaRecorder?.state === "recording") {
                stopScreenSharing();
            }
        };
    }, [isSharing]);

    return (
        <div className="div">
            {isSharing ? (
                <Buttons.Button color="slater" onClick={stopScreenSharing}>
                    <PauseIcon className="mr-2 h-3 w-3" />
                    Stop Recording
                </Buttons.Button>
            ) : (
                <Buttons.Button color="slater" onClick={startScreenSharing}>
                    <VideoCameraIcon className="mr-2 h-3 w-3" />
                    Share screen and Start Recording
                </Buttons.Button>
            )}
        </div>
    );
};

export default ScreenShareSelector;
