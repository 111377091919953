import { useRef } from "react";

import { Buttons, Fields, Spinner, classNames, useEffect, useState } from "./tools";

const VideoPreview = ({ stream }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
        }
    }, [stream]);

    if (!stream) {
        return null;
    }
    return <video className="-scale-x-100" ref={videoRef} autoPlay />;
};

export default function WardenVideoSetup({
    onNotSupported,
    onNoDevicesFound,
    onPermissionError,
    onSomeOtherError,
    onComplete,
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [devices, setDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState(null);
    const [mediaStream, setMediaStream] = useState(null);

    const enumerateDevicesAndSetState = async (stream) => {
        // enmu devices and set state
        const userDevices = await navigator.mediaDevices.enumerateDevices();
        const filteredDevices = userDevices
            .filter((ud) => ud.deviceId && ud.label)
            .filter((ud) => ud.kind === "videoinput")
            .map((device) => {
                return {
                    id: device.deviceId,
                    label: device.label,
                    kind: device.kind,
                };
            });
        setDevices(filteredDevices);
        setIsLoading(false);
        setMediaStream(stream);
        // get device id from stream
        try {
            const track = stream.getVideoTracks()[0];
            const deviceId = track.getSettings().deviceId;
            setSelectedDeviceId(deviceId);
        } catch (err) {
            // handle err
        }
    };

    const getUserMediaByDeviceId = async (deviceId) => {
        const constraints = {
            video: {
                deviceId: {
                    exact: deviceId,
                },
            },
        };
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            setMediaStream(stream);
        } catch (err) {
            // handle herr
        }
    };

    const getUserMedia = async () => {
        const constraints = {
            video: true,
        };
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            enumerateDevicesAndSetState(stream);
        } catch (err) {
            switch (err.name) {
                case "NotFoundError":
                    onNoDevicesFound(err);
                    break;
                case "SecurityError":
                case "PermissionDeniedError":
                case "NotAllowedError":
                    onPermissionError(err);
                    break;
                default:
                    onSomeOtherError(err);
                    break;
            }
        }
    };

    const canIUse = () => {
        return (
            navigator.mediaDevices &&
            navigator.mediaDevices.getUserMedia &&
            navigator.mediaDevices.enumerateDevices
        );
    };

    useEffect(() => {
        if (!canIUse()) {
            onNotSupported();
        } else {
            getUserMedia();
        }
        // cleanup
        return () => {
            if (mediaStream) {
                mediaStream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
        };
    }, []);

    useEffect(() => {
        if (selectedDeviceId) {
            getUserMediaByDeviceId(selectedDeviceId);
        }
    }, [selectedDeviceId]);

    if (isLoading) {
        return (
            <div className="flex h-32 flex-col items-center justify-center gap-y-4">
                <div className="text-center text-slate-900">
                    Loading ... Requesting access to camera.
                </div>
                <div>
                    <Spinner />
                </div>
            </div>
        );
    }

    return (
        <div className="grid grid-cols-2 gap-x-4">
            <div className="devices-list col-span-1">
                <div className="grid">
                    <Fields.SelectFieldFree
                        label="Select Device"
                        value={selectedDeviceId}
                        onChange={(e) => {
                            setSelectedDeviceId(e.target.value);
                        }}
                        options={devices.map((device) => {
                            return {
                                value: device.id,
                                label: device.label,
                            };
                        })}
                    />
                </div>
                <div className="mt-3">
                    <Buttons.Button
                        color="lgreen"
                        onClick={() => {
                            onComplete(selectedDeviceId);
                            // turn of stream
                            if (mediaStream) {
                                mediaStream.getTracks().forEach((track) => {
                                    track.stop();
                                });
                            }
                        }}
                    >
                        Use Camera
                    </Buttons.Button>
                </div>
            </div>

            <div className="col-span-1">
                <div className="h-72 overflow-hidden rounded-md bg-slate-200">
                    <VideoPreview stream={mediaStream} />
                </div>
            </div>
        </div>
    );
}
