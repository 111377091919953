import {
    Api,
    Buttons,
    Fields,
    Form,
    Formik,
    Forms,
    Spinner,
    UserContext,
    useContext,
    useNavigate,
    useState,
} from "./tools";

export default function CandidateCreateFormDemo({ assessmentId, onSuccess }) {
    const {
        data: assessment,
        isLoading,
        isFetching,
        error,
    } = Api.useGetAssessmentQuery(assessmentId);

    const { user } = useContext(UserContext);

    const [createCandidate, { isLoading: isSaving }] = Api.useCreateCandidateMutation();
    const navigate = useNavigate();

    if (isFetching || !assessment) {
        return <Spinner />;
    }

    return (
        <Forms.GenericForm
            initialValues={{
                assessment: assessmentId,
                reviewers: [user.id],
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
                is_demo_invite: true,
            }}
            fields={[
                {
                    name: "first_name",
                    label: "First name",
                    type: "text",
                },
                {
                    name: "last_name",
                    label: "Last name",
                    type: "text",
                },
                {
                    name: "email",
                    label: "Email",
                    type: "email",
                },
            ]}
            onSuccess={(result) => {
                onSuccess?.(result);
            }}
            submitButtonLabel="Send Test Invite"
            onSubmit={async (values) => {
                return createCandidate({
                    ...values,
                });
            }}
        />
    );
}
