import React from "react";
import ReactJson from "react-json-view";

import { Api, Buttons, Fields, constants } from "./tools";

export default function AIToolsMQItems({ processResult, module, items }) {
    const [aiToolsGenerate, { isLoading }] = Api.useAiToolsGenerateMutation();
    const [action, setAction] = React.useState("");
    const [model, setModel] = React.useState("gpt-3.5-turbo");

    const [result, setResult] = React.useState("");
    const [prompt, setPrompt] = React.useState(
        `As a ${module.name} expert, generate 3 multiple-choice questions.`,
    );
    const [expectedResponseFormat, setExpectedResponseFormat] = React.useState(
        `The response should be an array of objects, each object should have the following keys: order, question, explanation, answers. The answers key should be an array of objects, each object should have the following keys: answer, correct. The correct key should be a boolean.`,
    );
    const [example, setExample] = React.useState(
        `Example, disregard the content: [{"order":1,"question":"In social media marketing, what is the purpose of analytics and reporting?","explanation":"The purpose of analytics and reporting in social media marketing is to provide insight into how a campaign is performing. This can help drive the social media marketing strategy moving forward.","answers":[{"answer":"Understand what people think about a brand","correct":false},{"answer":"Provide insight into how a campaign is performing","correct":true},{"answer":"Have a consistent presence on social media","correct":false},{"answer":"Learn what content resonates best with an audience","correct":false}]}]`,
    );
    const [showAdvanced, setShowAdvanced] = React.useState(false);
    const [jsonResult, setJsonResult] = React.useState(null);

    return (
        <div className="grid grid-cols-1 gap-y-4">
            <div className="flex">
                <Buttons.Button
                    onClick={() => {
                        const exampleString =
                            "Current items:" +
                            JSON.stringify(
                                items
                                    .filter(
                                        (item) =>
                                            item.module_item_type ===
                                            constants.MODULE_TYPE_MULTIPLE_CHOICE,
                                    )
                                    .slice(0, model == "gpt-4" ? 6 : 3)
                                    .map((item) => {
                                        return {
                                            question: item.question,
                                            order: item.order,
                                            explanation: item.explanation,
                                            answers: item.answers,
                                        };
                                    }),
                            );

                        setPrompt(
                            `As a ${module.name} expert, generate 3 supplemental multiple-choice questions.`,
                        );
                        setExpectedResponseFormat(
                            `The response should be an array of objects, each object should have the following keys: order, question, explanation, answers. The answers key should be an array of objects, each object should have the following keys: answer, correct. The correct key should be a boolean.`,
                        );
                        setExample(exampleString);
                    }}
                    color="slate"
                >
                    Generate Similar Items
                </Buttons.Button>
            </div>

            <hr />

            <Fields.TextFieldFree
                value={prompt}
                type="textarea"
                rows="5"
                label="Prompt"
                name="prompt"
                placeholder="Enter prompt here..."
                explain=""
                onChange={(e) => {
                    setPrompt(e.target.value);
                }}
                toolbar={
                    <div>
                        <Buttons.Button
                            variant="solidXS"
                            loading={isLoading}
                            onClick={async () => {
                                const p = `List 5 skills that are required to be a ${module.name} expert. The list should be unordered. Be as specific as possible. Do not include skills that are required for all jobs. For example, "communication" is not a skill that is required for this job. List skills that are specific to this job, and don't include broad skills. You can list skills that require specific tools or software.`;
                                const result = await aiToolsGenerate({
                                    prompt: p,
                                });
                                setPrompt(
                                    prompt +
                                        "\nThe following skills should be covered:" +
                                        result.data?.text +
                                        "\n",
                                );
                            }}
                            color="slate"
                        >
                            Add Context (Skills, etc.)
                        </Buttons.Button>
                    </div>
                }
            />

            {showAdvanced && (
                <>
                    <div>
                        <Fields.SelectFieldFree
                            label="Model"
                            name="model"
                            value={model}
                            onChange={(e) => {
                                setModel(e.target.value);
                            }}
                            options={[
                                {
                                    label: "GPT-3.5 Turbo",
                                    value: "gpt-3.5-turbo",
                                },
                                {
                                    label: "GPT-4",
                                    value: "gpt-4",
                                },
                            ]}
                        />
                    </div>

                    <div>
                        <Fields.TextFieldFree
                            type="textarea"
                            rows="5"
                            label="Expected Response Format"
                            name="expectedResponseFormat"
                            placeholder="Enter the format here.."
                            explain=""
                            value={expectedResponseFormat}
                            onChange={(e) => {
                                setExpectedResponseFormat(e.target.value);
                            }}
                        />
                    </div>
                    <div>
                        <Fields.TextFieldFree
                            type="textarea"
                            rows="5"
                            label="Example"
                            name="expectedResponseFormat"
                            placeholder="Provide an example..."
                            explain=""
                            value={example}
                            onChange={(e) => {
                                setExample(e.target.value);
                            }}
                        />
                    </div>
                </>
            )}

            <div className="flex gap-x-4">
                <Buttons.Button
                    loading={isLoading && action === "generate"}
                    loadingMessage="Generating..."
                    color="slate"
                    onClick={async () => {
                        setResult("");
                        setJsonResult(null);
                        setAction("generate");
                        const result = await aiToolsGenerate({
                            prompt: `${prompt} ${example} \n ${expectedResponseFormat}`,
                            model: model,
                        });
                        setResult(result.data?.text);
                        try {
                            const jR = JSON.parse(result.data?.text);
                            setJsonResult(jR);
                        } catch (e) {
                            setJsonResult(null);
                        }
                    }}
                >
                    Generate
                </Buttons.Button>
                <Buttons.Button
                    color="slate"
                    onClick={() => {
                        setShowAdvanced(!showAdvanced);
                    }}
                >
                    {(showAdvanced && "Hide") || "Show"} Advanced Settings
                </Buttons.Button>
                <div className="ml-auto flex items-center align-middle text-xs font-bold italic">
                    {prompt.length + example.length + expectedResponseFormat.length}/4000 tokens
                </div>
            </div>
            {result && (
                <div className="grid grid-cols-1 space-y-2 bg-slate-100 p-5">
                    {(jsonResult && (
                        <div className="">
                            <div className="text-bold mb-2 text-xs text-emerald-500">
                                Valid JSON
                            </div>
                            <ReactJson collapsed src={jsonResult} />
                            <div className="mt-2">
                                <Buttons.Button
                                    color="lgreen"
                                    onClick={() => {
                                        //navigator.clipboard.writeText(result);
                                        processResult?.(
                                            jsonResult,
                                            `${prompt} ${example} \n ${expectedResponseFormat}`,
                                        );
                                    }}
                                >
                                    Use Result
                                </Buttons.Button>
                            </div>
                        </div>
                    )) || (
                        <div>
                            <div className="text-bold text-xs italic text-red-300">
                                Response is not valid JSON.
                            </div>
                            <div className="text-xs italic">{result}</div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
