import React from "react";

import candidateExternalUseSkip from "./candidateExternalUseSkip";

export default function CandidateExternalSkip() {
    const Skip = candidateExternalUseSkip();
    return (
        <div className="flex justify-end">
            <Skip />
        </div>
    );
}
