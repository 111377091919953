import React, { createContext, useState } from "react";

import { Api } from "./tools";

export const AssessmentContext = createContext();

export function AssessmentProvider({ aid, children }) {
    const {
        data: assessment,
        isLoading,
        error,
    } = Api.useGetAssessmentQuery(aid, {
        refetchOnMountOrArgChange: true,
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    return (
        <AssessmentContext.Provider value={{ assessment }}>{children}</AssessmentContext.Provider>
    );
}
