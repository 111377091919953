export default function NotFound() {
    return (
        <div className="mx-auto grid w-1/3 grid-cols-1 gap-y-3 p-10 text-center">
            <div>
                <h1 className="text-4xl font-bold">404</h1>
                <p className="text-lg">Page not found</p>
            </div>
        </div>
    );
}
