import { AnimatePresence as AP, motion } from "framer-motion";

export const AnimatePresence = ({ children, show, initial = false, ...props }) => {
    return (
        <AP initial={initial}>
            {show && (
                <motion.div
                    {...props}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    {children}
                </motion.div>
            )}
        </AP>
    );
};

export const AnimatePresenceSlideUp = ({ children, show, initial = false, ...props }) => {
    return (
        <AP initial={initial}>
            {show && (
                <motion.div
                    {...props}
                    initial={{ opacity: 0, y: 300 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 100 }}
                >
                    {children}
                </motion.div>
            )}
        </AP>
    );
};

export const AnimatePresenceGrow = ({ children, show, initial = false, ...props }) => {
    return (
        <AP initial={initial}>
            {show && (
                <motion.div
                    {...props}
                    initial={{ opacity: 1, width: 0 }}
                    animate={{
                        opacity: 1,
                        width: "100%",
                        transition: { duration: 0.5 },
                    }}
                    exit={{ opacity: 0, width: 0 }}
                >
                    {children}
                </motion.div>
            )}
        </AP>
    );
};
