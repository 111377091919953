import { combineReducers, configureStore, isPlain } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import localForage from "localforage";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistStore } from "redux-persist";

import { API_REDUCER_KEY, apiSlice } from "./api";
import {
    API_CANDIDATE_PERSIST_REDUCER_KEY,
    API_CANDIDATE_REDUCER_KEY,
    apiCandidatePersistReducer,
    apiCandidatePersistSlice,
    apiCandidateSlice,
} from "./apiCandidate";
import {
    AUTH_API_REDUCER_KEY,
    RESET_STATE_ACTION_TYPE,
    authApi,
    authReducer,
    authSlice,
    unauthenticatedMiddleware,
} from "./auth";

const reducers = {
    [authSlice.name]: authReducer,
    [AUTH_API_REDUCER_KEY]: authApi.reducer,
    [API_REDUCER_KEY]: apiSlice.reducer,
    [API_CANDIDATE_REDUCER_KEY]: apiCandidateSlice.reducer,
    [API_CANDIDATE_PERSIST_REDUCER_KEY]: apiCandidatePersistReducer,
};

const combinedReducer = combineReducers(reducers);

export const rootReducer = (state, action) => {
    if (action.type === RESET_STATE_ACTION_TYPE) {
        localForage.clear();
        state = {};
    }
    return combinedReducer(state, action);
};

function isSerializable(value, path) {
    const isNonSerializableBlob = value instanceof Blob;
    if (isNonSerializableBlob) {
        return true;
    }
    return isPlain(value);
}

export const store = configureStore({
    reducer: rootReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                isSerializable: isSerializable,
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                    "api.modulePreviewAction",
                ],
            },
        }).concat(authApi.middleware, apiSlice.middleware, apiCandidateSlice.middleware),
});

export const persistor = persistStore(store);

// necessary for onFocus refetching
setupListeners(store.dispatch);
