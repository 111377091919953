import {
    BookOpenIcon,
    ClipboardDocumentIcon,
    ClipboardDocumentListIcon,
    SparklesIcon,
} from "@heroicons/react/24/outline";
import React from "react";

import AssessmentCreateModalContent from "./AssessmentCreateModalContent";
import Empty6 from "./assets/img/empty6.png";
import { Api, Dialog, Link, Spinner, useNavigate } from "./tools";

export default function AssessmentCreateModal({ isOpen, setIsOpen, size = "xl7" }) {
    const navigate = useNavigate();
    return (
        <Dialog
            withPadding={false}
            withScroll={false}
            size={size}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <div className="flex items-center justify-center">
                <div className="grid max-w-7xl grid-cols-2 gap-x-16">
                    <div className="col-span-1 space-y-6 p-10">
                        <div className="prose prose-sm">
                            <h2 className="text-3xl font-bold tracking-tight text-slate-900">
                                Create an assessment
                            </h2>

                            <p>
                                Creating an assessment is the first step to inviting candidates to
                                Cactus. You have several options to choose from:
                            </p>
                        </div>

                        <AssessmentCreateModalContent className="text-sm" />
                    </div>

                    <div
                        style={{
                            backgroundImage: `url(${Empty6})`,
                        }}
                        className="col-span-1 bg-cover bg-center"
                    />
                </div>
            </div>
        </Dialog>
    );
}
