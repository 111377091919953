import Intercom from "./Intercom";
import { OrgLimitsProvider } from "./OrgLimitsProvider";
import SidebarMain from "./SidebarMain";
import { UserProvider } from "./UserProvider";

export default function Layout() {
    return (
        <UserProvider>
            <OrgLimitsProvider>
                <div className="min-h-full">
                    <SidebarMain />
                </div>

                <Intercom />
            </OrgLimitsProvider>
        </UserProvider>
    );
}
