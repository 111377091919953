import React from "react";

import { CandidateAssessmentContext } from "./CandidateAssessmentProvider";
import CactusWorkInProgressImage from "./assets/img/cactus_work_in_progress.png";
import { Buttons, useContext } from "./tools";

export default function CandidateSummaryWIP({}) {
    const { assessment } = useContext(CandidateAssessmentContext);
    return (
        <div className="flex h-full flex-col items-center justify-center">
            <div className="flex max-w-[50%] flex-col items-center justify-center gap-y-4 text-center">
                <img src={CactusWorkInProgressImage} className="h-32 animate-bounce duration-300" />
                <h2 className="font-semibold text-slate-600">Assessment in progress</h2>
                <div className="text-sm leading-6">
                    Your candidate is currently taking the assessment. Please check back later. We
                    will notify you when the assessment is complete.
                </div>
                <Buttons.Button
                    color="slater"
                    target="_blank"
                    href={`/c/${assessment.candidate_id}/a/${assessment.id}`}
                    variant="solidXS"
                >
                    View Candidate URL
                </Buttons.Button>
            </div>
        </div>
    );
}
