import React from "react";

import Logo from "./assets/img/logo.svg";

export default function RegisterVerifyEmail() {
    return (
        <div className="prose-md prose">
            <div>
                <img src={Logo} className="h-8 w-auto animate-pulse" />
            </div>
            <h2> Email Verification Sent </h2>
            <p>
                We've just sent you an email with a verification link. Please check your inbox and
                click on the link to complete the account verification process.
            </p>
        </div>
    );
}
