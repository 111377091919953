import CandidateInviteTutorial from "./CandidateInviteTutorial";
import CandidateList from "./CandidateList";
import { OrgLimitsContext, useContext } from "./tools";

export default function Candidates() {
    const { limits } = useContext(OrgLimitsContext);

    if (!limits.has_candidates) {
        return <CandidateInviteTutorial />;
    }

    return (
        <div className="">
            <CandidateList />
        </div>
    );
}
