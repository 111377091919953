import React from "react";

import SSOForm from "./SSOForm";
import {
    Api,
    Buttons,
    Cards,
    Pills,
    Spinner,
    UserContext,
    classNames,
    constants,
    useContext,
    useEffect,
    useSearchParams,
} from "./tools";

export default function AccountUserSSO() {
    const { user } = useContext(UserContext);
    const [ssoAction, { isLoading, error }] = Api.useCurrentUserSSOActionMutation();
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code");

    useEffect(() => {
        const linkCode = async () => {
            const result = await ssoAction({ body: { action: "link_with_code", code: code } });
            searchParams.delete("code");
            searchParams.delete("state");
            setSearchParams(searchParams);
        };

        if (code) {
            linkCode();
        }
    }, [code]);

    const _idp = user.idp && constants.IDP_MAP[user.idp];

    return (
        <div className="flex-col gap-y-4 space-y-4">
            <Pills.Pill>Single Sign-On (SSO)</Pills.Pill>
            <Cards.CardOneColumn className="bg-white">
                <div className="space-y-8 p-3">
                    <div className="prose prose-sm">
                        <h5 className="font-bold">Login with SSO</h5>
                        <p>
                            Easily login with just one click by connecting your account to your
                            organization's SSO provider. Simplify your login process and enjoy a
                            seamless and secure authentication experience.
                        </p>
                    </div>
                    {(user.idp && (
                        <div>
                            <div>
                                <Buttons.ButtonWithConfirmation
                                    color="white"
                                    confirm={{
                                        title: "Disconnect from SSO",
                                        content: (
                                            <div className="prose prose-sm text-slate-700">
                                                <h5 className="font-bold tracking-tight">
                                                    Disconnect {_idp.name} from {user.email}
                                                </h5>
                                                <p>
                                                    You will no longer be able to login with your
                                                    SSO provider. You will need to use your email
                                                    and password to login.
                                                </p>
                                            </div>
                                        ),
                                        okLabel: "Disconnect",
                                        confirmBtnColor: "lred",
                                    }}
                                    className="shadow-md"
                                    onClick={async () => {
                                        const result = await ssoAction({
                                            body: { action: "unlink" },
                                        });

                                        if (result.error) {
                                            console.log(result.error);
                                        }
                                    }}
                                >
                                    <img src={_idp.icon} className="mr-2 h-4 w-4" />
                                    Disconnect from {_idp.name}
                                </Buttons.ButtonWithConfirmation>
                            </div>
                        </div>
                    )) || (
                        <>
                            {(isLoading && <Spinner />) || (
                                <SSOForm
                                    actionLabel="Connect with"
                                    className={"flex gap-x-2"}
                                    onClick={async (provider) => {
                                        const result = await ssoAction({
                                            body: { provider, action: "link" },
                                        });
                                        const url = result.data?.url;
                                        if (url) {
                                            window.location.href = url;
                                        }
                                    }}
                                />
                            )}
                        </>
                    )}
                </div>
            </Cards.CardOneColumn>
        </div>
    );
}
