import { Api, Forms, constants } from "./tools";

export default function UserCreateForm({ onSuccess }) {
    return (
        <div className="flex flex-col gap-y-4">
            <Forms.GenericForm
                onSuccess={onSuccess}
                submitButtonLabel="Invite User"
                fields={[
                    {
                        name: "email",
                        label: "Email",
                        type: "text",
                    },
                    {
                        name: "team_id",
                        label: "Team",
                        type: "team",
                        mine: false,
                    },
                    {
                        name: "role",
                        label: "Role",
                        type: "select",
                        options: constants.USER_ROLES,
                        initial: 1,
                    },
                ]}
                mutation={Api.useCreateOrgUserMutation}
            />
        </div>
    );
}
