import { HandRaisedIcon } from "@heroicons/react/20/solid";

import AliceImage from "./assets/img/alice.svg";
import { Avatar, Buttons, Tabs, Utils, classNames, constants, useState } from "./tools";

export function Card({ children, className, ...props }) {
    // if className contains bg-*, then dont add bg-slate-50
    const bgClass = className?.match(/bg-/) ? "" : "bg-slate-50";
    return (
        <div className={classNames("p-5 shadow-md", bgClass, className)} {...props}>
            {children}
        </div>
    );
}

export function CardOneColumn({ children, className, ...props }) {
    return (
        <div
            className={classNames(
                "rounded-md p-3 shadow-md ring-1 ring-black ring-opacity-5",
                className,
            )}
            {...props}
        >
            {children}
        </div>
    );
}

export function CardTwoColumns({ col1, col2, className }) {
    return (
        <div className={classNames("flex flex-col gap-y-5", className)}>
            <div className="w-100 grid grid-cols-2 items-center gap-x-8 rounded-md  p-5 shadow-md">
                <div className="col-span-1">
                    <div className="flex flex-col gap-y-2">{col1}</div>
                </div>
                <div className="col-span-1">
                    <div className="flex flex-col gap-y-2">{col2}</div>
                </div>
            </div>
        </div>
    );
}

export function CardForm({ col1, col2, className }) {
    return (
        <div className="grid grid-cols-3 gap-6">
            <div className="col-span-1">
                <div>{col1 ? col1 : null}</div>
            </div>
            <div className="col-span-2">
                <div className="rounded-md shadow">
                    <div className="space-y-6 bg-white p-5">{col2 ? col2 : null}</div>
                </div>
            </div>
        </div>
    );
}

export function CardFormGeneric({ title, subtitle, tabs = [], className }) {
    const renderTabs = () => {
        const t = tabs.map((tab) => {
            return {
                ...tab,
                component: (
                    <div className="grid grid-cols-3 gap-8">
                        <div className="col-span-1">
                            <div className="space-y-2 px-4 sm:px-0">
                                {tab.title && (
                                    <h3 className="text-lg font-medium leading-6 text-slate-900">
                                        {tab.title}
                                    </h3>
                                )}

                                {typeof tab.subtitle === "string" ? (
                                    <p className="mt-1 text-sm text-slate-600">{tab.subtitle}</p>
                                ) : (
                                    <>{tab.subtitle}</>
                                )}
                            </div>
                        </div>

                        <div className="col-span-2">
                            <div className="rounded-md shadow">
                                <div className="space-y-6 bg-white p-5">{tab.content}</div>
                            </div>
                        </div>
                    </div>
                ),
            };
        });
        return <Tabs tabs={t} />;
    };
    return (
        <div className="flex flex-col">
            <main className="flex-1">
                <div className="relative">
                    <div className="pb-16">
                        <div className="px-4 sm:px-6 md:px-0">
                            <h5 className="text-3xl font-bold tracking-tight text-emerald-900">
                                {title}
                            </h5>
                            <p className="mt-3 text-slate-500">{subtitle ? subtitle : null}</p>
                        </div>
                        {renderTabs()}
                    </div>
                </div>
            </main>
        </div>
    );
}

export function CardAssessment({ assessment, ...props }) {
    return (
        <Card>
            <div className="flex flex-col gap-y-5"></div>
        </Card>
    );
}

export function CardCactusLibraryItem({
    cactusLibraryItem,
    onClickAdd,
    onClickDelete,
    draggable = false,
    toolbar = null,
    ...props
}) {
    const [expanded, setExpanded] = useState(false);
    const [showScrollHint, setShowScrollHint] = useState(true);
    return (
        <div
            className={classNames(
                "relative overflow-hidden rounded-md  shadow-md hover:shadow-md",
                draggable ? "cursor-move" : "",
            )}
        >
            <div className="grid grid-cols-3 bg-blue-200 p-5">
                <div className="col-span-2 space-y-4">
                    <p
                        onClick={() => {
                            navigator.clipboard.writeText(cactusLibraryItem.id);
                        }}
                        className="truncate text-xl font-semibold text-slate-600"
                    >
                        {cactusLibraryItem.name}
                    </p>

                    <div className="flex gap-x-2 gap-y-2">
                        {cactusLibraryItem.tags.slice(0, 8).map((tag) => (
                            <span
                                key={tag}
                                title={tag}
                                className="max-w-[120px] cursor-pointer flex-wrap items-center justify-between truncate rounded-xl bg-blue-400 py-2 px-4 text-xs font-medium text-white hover:bg-blue-500 hover:text-gray-100 "
                            >
                                {tag}
                            </span>
                        ))}
                    </div>
                </div>
                <div className="col-span-1">{toolbar}</div>
            </div>

            <div className={classNames("overflow-hidden bg-slate-50 p-5", expanded ? "" : "h-64 ")}>
                <div className="grid grid-cols-2 space-x-8">
                    <div>
                        <div
                            onClick={() => {
                                setExpanded(!expanded);
                            }}
                            onMouseEnter={() => {
                                !expanded && setShowScrollHint(true);
                            }}
                            onMouseLeave={() => {
                                setShowScrollHint(false);
                            }}
                            className="prose prose-sm cursor-pointer hover:bg-slate-100"
                            dangerouslySetInnerHTML={{
                                __html: Utils.replaceEmptyNewLinesWYSIWYG(
                                    cactusLibraryItem.description,
                                ),
                            }}
                        />
                        {showScrollHint && (
                            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-md bg-slate-50 p-2 shadow-md">
                                <div className="flex items-center gap-x-2 text-xs">
                                    <span>Click to read more</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="top-0 space-y-4 self-start">
                        <div className="text-sm text-slate-500">Modules</div>
                        {cactusLibraryItem.modules.map((module) => (
                            <div key={module.id}>
                                <div className="flex gap-x-4 rounded-md bg-slate-200 p-3">
                                    <img
                                        src={constants.MODULE_TYPE_ICONS[module.module.module_type]}
                                        className="h-6 w-6"
                                    />
                                    <p className="truncate text-sm font-medium text-slate-600">
                                        {constants.MODULE_TYPE_LABELS[module.module.module_type]}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex gap-x-2 bg-blue-50 p-5">
                {onClickAdd && (
                    <Buttons.Button
                        onClick={() => {
                            onClickAdd?.(module);
                        }}
                        color="lblue"
                    >
                        Use Assessment
                    </Buttons.Button>
                )}
            </div>
        </div>
    );
}

const ChatBubble = ({ role, content, user }) => {
    const alignment = classNames(
        role == "user" ? "justify-end text-left" : "justify-start text-left",
    );

    const bgColor = classNames(role == "user" ? "bg-slate-200" : "bg-emerald-200");

    return (
        <div className={classNames("flex", alignment, "mb-2")}>
            <div
                style={{
                    maxWidth: "60%",
                }}
                className={classNames("")}
            >
                <div className="flex items-center gap-x-4">
                    <div className="flex flex-shrink-0">
                        {role == "user" ? (
                            <Avatar
                                className="bg-slate-200"
                                placeholder={user.initials}
                                hash={user.d}
                            />
                        ) : (
                            <Avatar src={AliceImage} />
                        )}
                    </div>
                    <div className={classNames(bgColor, "rounded-md p-3")}>{content}</div>
                </div>
            </div>
        </div>
    );
};

export function Conversation({ conversation, user }) {
    return (
        <div className="flex flex-col gap-y-6">
            {conversation
                .filter((m) => m.role != "system")
                .map((message, index) => (
                    <ChatBubble
                        key={index}
                        role={message.role}
                        content={message.content}
                        user={user}
                    />
                ))}
        </div>
    );
}
