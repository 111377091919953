import { StarIcon } from "@heroicons/react/20/solid";
import React from "react";

import { classNames } from "./tools";

export const StarRating = ({ rating, maxRating = 5, onClick, className }) => {
    return (
        <div className="flex">
            {[...Array(maxRating)].map((star, index) => {
                return (
                    <StarIcon
                        key={index}
                        className={classNames(
                            rating > index ? "text-yellow-400" : "text-gray-200",
                            "h-5 w-5 flex-shrink-0",
                            className,
                        )}
                        aria-hidden="true"
                        onClick={() => onClick?.(index + 1)}
                    />
                );
            })}
        </div>
    );
};
