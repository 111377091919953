import React from "react";

import { AssessmentContext } from "./AssessmentProvider";
import CactusLibraryItemEditForm from "./CactusLibraryItemEditForm";
import { Api, Buttons, Cards, Fields, Forms, useContext } from "./tools";

export default function AssessmentStaff() {
    const { assessment } = useContext(AssessmentContext);

    const [staffAction, { isLoading, error }] = Api.useAssessmentStaffActionMutation();

    const renderCol2 = () => {
        const { library_item } = assessment;
        if (library_item) {
            return (
                <div className="space-y-4">
                    <div>
                        <CactusLibraryItemEditForm cliid={library_item.id} />
                    </div>
                </div>
            );
        } else {
            return (
                <div className="space-y-4">
                    <div className="prose-md prose">
                        <p>Create a library item for this assessment.</p>
                    </div>
                    <div>
                        <Buttons.Button
                            onClick={async () => {
                                const { result } = await staffAction({
                                    id: assessment.id,
                                    action: "create_library_item",
                                    body: {},
                                });
                            }}
                        >
                            Create library item
                        </Buttons.Button>
                    </div>
                </div>
            );
        }
    };

    return (
        <div>
            <Cards.CardForm
                col1={
                    <div>
                        <h3 className="text-lg font-medium leading-6 text-slate-900">
                            Staff Actions
                        </h3>
                        <p className="mt-1 text-sm text-slate-600">
                            Actions that can be performed by staff.
                        </p>
                    </div>
                }
                col2={renderCol2()}
            />
        </div>
    );
}
