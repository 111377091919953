import { CandidateExternalContext } from "./CandidateExternalProvider";
import { ApiCandidate, Buttons, useContext } from "./tools";

const candidateExternalUseSkip = () => {
    const { assessment } = useContext(CandidateExternalContext);

    const [performAction, { isLoading: isPerformingAction, error: performActionError }] =
        ApiCandidate.usePerformAssessmentActionMutation();

    const cid = assessment.candidate_id;
    const caid = assessment.id;

    const Skip = () => (
        <Buttons.ButtonWithConfirmation
            color="transparent"
            className="py-0 px-0 text-xs"
            confirm={{
                title: "Are you sure?",
                description: "You will not be able to change your answer later.",
            }}
            onClick={() => {
                performAction({
                    cid,
                    caid,
                    action: "skip",
                    payload: {
                        current_module_item_id: assessment.current_module_item.id,
                    },
                });
            }}
        >
            Skip Module
        </Buttons.ButtonWithConfirmation>
    );

    return Skip;
};

export default candidateExternalUseSkip;
