import React from "react";

import ChromeImage from "./assets/img/chrome.svg";
import EdgeImage from "./assets/img/edge.png";
import FirefoxImage from "./assets/img/firefox.svg";
import { Buttons, Fields, classNames, constants, useState } from "./tools";

export default function CandidateExternalChrome({}) {
    return (
        <div className="grid grid-cols-4 gap-8 border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10">
            <div className="col-span-3 flex flex-col gap-y-4">
                <div className="prose">
                    <h3>Unsupported Browser</h3>
                    <p className="">
                        For an optimal experience with this assignment, please install the free{" "}
                        <strong>Chrome</strong>, <strong>Edge</strong>, or <strong>Firefox</strong>{" "}
                        browser.
                    </p>
                </div>

                <div className="flex gap-x-2">
                    <Buttons.Button
                        variant="solidXS"
                        color="slater"
                        onClick={() => {
                            window.open("https://www.google.com/chrome/");
                        }}
                    >
                        <img src={ChromeImage} alt="Chrome Logo" className="mr-2 h-4 w-4" />
                        <span>Install Chrome</span>
                    </Buttons.Button>

                    <Buttons.Button
                        variant="solidXS"
                        color="slater"
                        onClick={() => {
                            window.open("https://www.microsoft.com/en-gb/edge/download");
                        }}
                    >
                        <img src={EdgeImage} alt="Edge Logo" className="mr-2 h-4 w-4" />
                        <span>Install Edge</span>
                    </Buttons.Button>

                    <Buttons.Button
                        variant="solidXS"
                        color="slater"
                        onClick={() => {
                            window.open("https://www.mozilla.org/en-US/firefox/new/");
                        }}
                    >
                        <img src={FirefoxImage} alt="Firefox Logo" className="mr-2 h-4 w-4" />
                        <span>Install Firefox</span>
                    </Buttons.Button>
                </div>
            </div>
        </div>
    );
}
