import MQPreview from "./MQPreview";
import { Api, Buttons, classNames, useParams, useState } from "./tools";
import { Spinner } from "./tools";

export default function ModulePreviewMQ() {
    const [explain, setExplain] = useState(null);

    const { id } = useParams();

    const { data: module, isLoading, error } = Api.useGetModuleQuery(id);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="col-span-2">
                <MQPreview
                    items={module?.items?.map((item) => {
                        return {
                            ...item.content,
                        };
                    })}
                />
            </div>
        </div>
    );
}
