import AudioDeviceSelector from "./AudioDeviceSelector";
import CandidateExternalChrome from "./CandidateExternalChrome";
import CandidateExternalModuleItemConversationRecorderPush2Talk from "./CandidateExternalModuleItemConversationRecorderPush2talk";
import AliceImage from "./assets/img/alice.svg";
import AvocadoImage from "./assets/img/avocado.svg";
import Chime from "./assets/sound/chime.mp3";
import LogoffSound from "./assets/sound/logoff.mp3";
import {
    AnimatePresence,
    AnimatePresenceSlideUp,
    ApiCandidate,
    Avatars,
    Buttons,
    CodeMirror,
    Fields,
    Spinner,
    Timer,
    Utils,
    _,
    classNames,
    moment,
    useEffect,
    useParams,
    useState,
} from "./tools";
import { useS3Uploader } from "./useS3Uploader";
import { useWarden } from "./useWarden";

const IS_LOCALHOST = window.location.hostname === "localhost";
const UPLOAD_AUDIO_TO_S3 = true;
const AUDIO_RESPONSE_ENABLED = true;
const STOP_AFTER_FIRST_MESSAGE = false;
const ALWAYS_RESPOND_TEXT = false;
const BRIEF_TIMEOUT = 5 * 60 * 1000; // 5 minutes

const BOT_STATUS_WAITING = 1;
const BOT_STATUS_JOINING = 2;
const BOT_STATUS_JOINED = 3;
const BOT_STATUS_LEFT = 4;
const BOT_STATUS_THINKING = 5;
const BOT_STATUS_SPEAKING = 6;

const USER_STATUS_WAITING = 1;
const USER_STATUS_JOINING = 2;
const USER_STATUS_JOINED = 3;
const USER_STATUS_LEFT = 4;
const USER_STATUS_THINKING = 5;
const USER_STATUS_SPEAKING = 6;
const USER_STATUS_TRANSCRIPTION_ERROR = 7;

const CONVERSATION_STATUS_BRIEF = 1;
const CONVERSATION_STATUS_IN_PROGRESS = 2;
const CONVERSATION_STATUS_OVER = 3;

const SPEECH_MODEL_DEFAULT = "default";
const SPEECH_MODEL_POLLY = "polly";

const RETURN_CONVERSATION_MESSAGE =
    "The user has returned to the conversation. The conversation will continue from where it left off. Reiterate the conversation up to this point. Start with 'Welcome back'.";

export default function CandidateExternalModuleItemConversation() {
    const { cid, caid } = useParams();
    const [answer, setAnswer] = useState([]);
    const [startRecording, setStartRecording] = useState(false);
    const [messages, setMessages] = useState([]);
    const [botStatus, setBotStatus] = useState(BOT_STATUS_WAITING);
    const [userStatus, setUserStatus] = useState(USER_STATUS_WAITING);
    const [audioSetupComplete, setAudioSetupComplete] = useState(false);
    const [showAudioSetup, setShowAudioSetup] = useState(false);
    const [inputDeviceId, setInputDeviceId] = useState(null);
    const [outputDeviceId, setOutputDeviceId] = useState("default");
    const [showChromeWarning, setShowChromeWarning] = useState(false);
    const [audioSetupError, setAudioSetupError] = useState(null);
    const [showSpeakClearly, setShowSpeakClearly] = useState(false);
    const [conversationStatus, setConversationStatus] = useState(null);
    const [notes, setNotes] = useState("");
    const [speechModel, setSpeechModel] = useState(
        IS_LOCALHOST ? SPEECH_MODEL_DEFAULT : SPEECH_MODEL_DEFAULT,
    );
    const {
        data: assessment,
        isLoading,
        error,
    } = ApiCandidate.useGetAssessmentQuery({ cid, caid }, {});

    const [performAction, { isLoading: isPerformingAction, error: performActionError }] =
        ApiCandidate.usePerformAssessmentActionMutation();
    const { error: uploadError, startUpload } = useS3Uploader({
        cid,
        caid,
    });

    const { runWarden, stopWarden } = useWarden(cid, caid);

    useEffect(() => {
        if (!Utils.canSafelyPlayAndRecordMedia()) {
            setShowChromeWarning(true);
        }
    }, []);

    useEffect(() => {
        runWarden();
        return () => {
            stopWarden();
        };
    }, []);

    useEffect(() => {
        if (messages.length >= 1) {
            // start countdown
            const lastMessage = messages[messages.length - 1];
            // is last message by system?
            if (lastMessage.role === "assistant" && STOP_AFTER_FIRST_MESSAGE != true) {
                if (lastMessage.content.includes("I didn't understand that.")) {
                    setShowSpeakClearly(true);
                }
                // check if Good bye is in the conten
                const regex =
                    /\b(good\s*bye|bye|have\s+a?\s+nice\s+day|it\s+was\s+good\s+talking\s+to\s+you|have\s+a\s+good\s+day)\b[\s!?,.;:-]/i;

                if (regex.test(lastMessage.content)) {
                    // set bot status to left
                    setBotStatus(BOT_STATUS_LEFT);
                    const audio = newAudioWithSinkId(LogoffSound);
                    audio.play();
                    setTimeout(() => {
                        endConversation();
                    }, 6000);

                    return;
                }

                if (ALWAYS_RESPOND_TEXT) {
                    setUserStatus(USER_STATUS_TRANSCRIPTION_ERROR);
                    return;
                }

                // 30 seconds from now
                setStartRecording(true);
                setUserStatus(USER_STATUS_SPEAKING);
            }
        }
    }, [messages.length]);

    const newAudioWithSinkId = (url) => {
        const audio = new Audio(url);
        if (typeof audio.setSinkId === "function") {
            audio.setSinkId(outputDeviceId);
        }
        return audio;
    };

    const requestWithAudioResponse = async (apiUrl, options) => {
        return new Promise(async (resolve, reject) => {
            try {
                // Fetch the audio stream from the API
                const response = await fetch(apiUrl, options);
                // Check if the response is ok
                if (!response.ok) {
                    reject(new Error(`HTTP error! status: ${response.status}`));
                }

                // Get the complete Blob from the response
                const audioBlob = await response.blob();
                setBotStatus(BOT_STATUS_SPEAKING);

                // Create a URL from the Blob
                const url = URL.createObjectURL(audioBlob);
                // Create an audio element and set its src to the created URL
                const audioElement = newAudioWithSinkId(url);

                // Play the audio
                audioElement.play();
                audioElement.onended = () => {
                    console.log("Audio playback ended");
                    if (UPLOAD_AUDIO_TO_S3) {
                        sendAudioToBackend(audioBlob, "audio/assistant");
                    }
                    resolve();
                };
            } catch (error) {
                console.error("Error streaming audio:", error);
                setSpeechModel(SPEECH_MODEL_POLLY);
                reject(error);
            }
        });
    };

    const requestWithAudioResponseStream = async (apiUrl, options) => {
        return new Promise(async (resolve, reject) => {
            try {
                // Fetch the audio stream from the API
                const response = await fetch(apiUrl, options);
                // Check if the response is ok
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                // Set bot status to speaking
                setBotStatus(BOT_STATUS_SPEAKING);

                // Handle streaming if supported
                if (window.MediaSource) {
                    let mediaSource = new MediaSource();
                    let chunks = []; // This array will hold our audio data chunks
                    const audioElement = new Audio();
                    audioElement.src = URL.createObjectURL(mediaSource);

                    mediaSource.addEventListener("sourceopen", () => {
                        let sourceBuffer = mediaSource.addSourceBuffer("audio/mpeg"); // Use the correct MIME type for your audio
                        const reader = response.body.getReader();

                        // Function to handle reading and appending chunks
                        function appendNextChunk() {
                            reader
                                .read()
                                .then(({ done, value }) => {
                                    if (done) {
                                        mediaSource.endOfStream();
                                        audioElement.play();
                                    } else {
                                        sourceBuffer.appendBuffer(value);
                                        chunks.push(value);
                                    }
                                })
                                .catch((e) => {
                                    reject(e);
                                });
                        }

                        // Listen for 'updateend' to append the next chunk
                        sourceBuffer.addEventListener("updateend", appendNextChunk);

                        // Start the process
                        appendNextChunk();
                    });

                    // Resolve the promise when audio playback has ended
                    audioElement.onended = () => {
                        console.log("Audio playback ended");
                        const audioBlob = new Blob(chunks, { type: "audio/webm" });
                        if (UPLOAD_AUDIO_TO_S3) {
                            sendAudioToBackend(audioBlob, "audio/assistant");
                        }
                        resolve();
                    };
                } else {
                    // Fallback if MediaSource API is not supported
                    const audioBlob = await response.blob();
                    const audioElement = new Audio(URL.createObjectURL(audioBlob));

                    audioElement.play();
                    audioElement.onended = () => {
                        console.log("Audio playback ended");
                        if (UPLOAD_AUDIO_TO_S3) {
                            sendAudioToBackend(audioBlob, "audio/assistant");
                        }
                        resolve();
                    };
                }
            } catch (error) {
                console.error("Error streaming audio:", error);
                setSpeechModel(SPEECH_MODEL_POLLY);
                reject(error);
            }
        });
    };

    const transcribeAudio = async (audioBlob) => {
        const formData = new FormData();
        formData.append("audio_file", audioBlob, "audio.webm");
        try {
            const response = await fetch(
                `/api/tom/c/${cid}/a/${caid}?action=conversation_transcribe`,
                {
                    method: "POST",
                    body: formData,
                },
            );

            const data = await response.json();
            const transcript = data.transcript;
            return transcript;
        } catch (error) {
            console.error(error);
            return { text: "TRANSCRIPTION_ERROR" };
        }
    };

    const sendAudioToBackend = async (
        audioBlob,
        folder = "audio/candidate",
        fileName = "audio.webm",
        contentType = "audio/webm",
    ) => {
        startUpload(audioBlob, fileName, {
            folder,
            contentType,
        });
    };

    const onStopRecording = async (audioBlob) => {
        setStartRecording(false);
        setShowSpeakClearly(false);
        setUserStatus(USER_STATUS_THINKING);
        // 99 is timeout without any audio
        if (!audioBlob || audioBlob == 99) {
            newMessage("I'm sorry, I don't know how to answer that.");
            return;
        }
        // in the background, upload to s3
        if (UPLOAD_AUDIO_TO_S3) {
            try {
                sendAudioToBackend(audioBlob);
            } catch (error) {
                console.error(error);
            }
        }
        const transcript = await transcribeAudio(audioBlob);
        if (!transcript || transcript.text === "TRANSCRIPTION_ERROR") {
            setUserStatus(USER_STATUS_TRANSCRIPTION_ERROR);
        } else if (!transcript.text) {
            // its empty
            setUserStatus(USER_STATUS_TRANSCRIPTION_ERROR);
        } else {
            newMessage(transcript.text);
        }
    };

    const playSystemAudio = async (history, model = SPEECH_MODEL_DEFAULT) => {
        if (speechModel === SPEECH_MODEL_POLLY) {
            model = SPEECH_MODEL_POLLY;
        }
        if (AUDIO_RESPONSE_ENABLED) {
            try {
                await requestWithAudioResponseStream(
                    `/api/tom/c/${cid}/a/${caid}?action=conversation_speech&model=${model}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            current_module_item_id: assessment.current_module_item.id,
                        }),
                    },
                );
            } catch (error) {
                // if the error is 502, then we need to switch to polly
                if (error.status >= 500) {
                    setSpeechModel(SPEECH_MODEL_POLLY);
                    await playSystemAudio(history, SPEECH_MODEL_POLLY);
                }
            }
        }
        setBotStatus(BOT_STATUS_WAITING);
    };

    const startConversation = async () => {
        let conversation = assessment?.current_module_item?.answer?.conversation || [];
        setBotStatus(BOT_STATUS_JOINING);
        setConversationStatus(CONVERSATION_STATUS_IN_PROGRESS);

        if (conversation.length != 0) {
            // who was the last person to speak?
            const lastMessage = _.last(conversation);
            if (lastMessage.content != RETURN_CONVERSATION_MESSAGE) {
                conversation = [
                    ...conversation,
                    {
                        role: "system",
                        content: RETURN_CONVERSATION_MESSAGE,
                    },
                ];
            }
        }

        const result = await performAction({
            cid,
            caid,
            action: "conversation",
            payload: {
                current_module_item_id: assessment.current_module_item.id,
                history: conversation,
            },
            tom: true,
        });

        const audio = newAudioWithSinkId(Chime);
        audio.play();

        // once the chime stopped playing, play the system audio
        audio.onended = async () => {
            setBotStatus(BOT_STATUS_JOINED);
            _.delay(async () => {
                await playSystemAudio(result.data.messages);
                setMessages(result.data.messages);
            }, 2000);
        };
    };

    const endConversation = async () => {
        setBotStatus(BOT_STATUS_LEFT);
        setConversationStatus(CONVERSATION_STATUS_OVER);

        _.delay(async () => {
            const result = await performAction({
                cid,
                caid,
                action: "answer",
                payload: {
                    current_module_item_id: assessment.current_module_item.id,
                    answer: {
                        conversation: messages,
                        notes: notes,
                    },
                },
            });
        }, 2000);
    };

    const newMessage = async (content) => {
        const messagesNew = [
            ...messages,
            {
                role: "user",
                content: content,
                created_at: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
            },
        ];
        setMessages(messagesNew);
        setUserStatus(USER_STATUS_WAITING);
        setBotStatus(BOT_STATUS_THINKING);
        try {
            const result = await performAction({
                cid,
                caid,
                action: "conversation",
                payload: {
                    current_module_item_id: assessment.current_module_item.id,
                    history: messagesNew,
                },
                tom: true,
            });
            await playSystemAudio(result.data.messages);
            setMessages(result.data.messages);
        } catch (error) {
            console.error(error);
        }
    };

    const stripCodeSnippets = (text) => {
        if (!text) {
            return null;
        }
        const codeSnippetRegex = /```(?:javascript)?([\s\S]*?)```/g;
        const parts = text.split(codeSnippetRegex);

        return parts
            .map((part, index) => {
                if (index % 2 === 1) {
                    return null; // Ignore the code snippets
                } else {
                    return part;
                }
            })
            .join(""); // Join the remaining parts without code snippets
    };

    const parseTextAndIncludeCodeMirror = (text) => {
        if (!text) {
            return null;
        }
        const codeSnippetRegex = /```(?:javascript)?([\s\S]*?)```/g;
        const parts = text.split(codeSnippetRegex);

        return parts.map((part, index) => {
            if (index % 2 === 1) {
                return (
                    <CodeMirror.CodeMirror
                        className="text-md my-3 text-left"
                        key={index}
                        value={part}
                        height="auto"
                        extensions={[CodeMirror.javascript({ jsx: true })]}
                        theme={"dark"}
                        readOnly={true}
                        autoFocus={false}
                        options={{
                            lineNumbers: true,
                        }}
                    />
                );
            } else {
                return <p key={index}>{part}</p>;
            }
        });
    };

    // RENDER STUFF

    const current_module_item = assessment.current_module_item;
    const content = current_module_item.content;
    const { bot_name, default_recording_countdown } = content;

    if (showChromeWarning) {
        return (
            <div className="flex h-full flex-col items-center justify-center">
                <CandidateExternalChrome />
            </div>
        );
    }

    if (conversationStatus == CONVERSATION_STATUS_BRIEF) {
        return (
            <div className="flex flex-col gap-y-8 border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10">
                <div className="grid grid-cols-6 gap-x-8">
                    <div className="col-span-4 max-h-[400px] overflow-y-scroll p-5">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: content.candidate_brief?.replace(/<p><br><\/p>/g, ""),
                            }}
                            className="prose prose-sm"
                        ></div>
                    </div>
                    <div className="col-span-2 space-y-4">
                        <Fields.QuillFieldFree
                            className="w-full"
                            label="Notes"
                            placeholder="Add your notes..."
                            type="textarea"
                            rows="10"
                            value={notes}
                            onChange={(v) => {
                                setNotes(v);
                            }}
                        />
                        <div className="text-xs font-bold">
                            Utilize the notes box to capture any significant information from the
                            brief. You will have access to these notes during the call for easy
                            reference.
                        </div>
                    </div>
                </div>
                <div className="flex w-1/2 flex-col  gap-y-4 p-5">
                    <div>
                        <Timer.TimerProgressBar
                            onTimeout={() => {
                                if (conversationStatus == CONVERSATION_STATUS_BRIEF) {
                                    startConversation();
                                }
                            }}
                            timeoutMilliseconds={BRIEF_TIMEOUT}
                        />
                    </div>
                    <div className="text-xs leading-5">
                        You have <span className="font-bold">5 minutes</span> to read this brief and
                        take notes, after which we will start the call with {bot_name}.
                    </div>
                    <div>
                        <Buttons.Button
                            className=""
                            variant="solid"
                            color="lgreen"
                            onClick={async () => {
                                startConversation();
                            }}
                        >
                            Join Call
                        </Buttons.Button>
                    </div>
                </div>
            </div>
        );
    }

    if (conversationStatus == CONVERSATION_STATUS_OVER) {
        return (
            <AnimatePresenceSlideUp initial={true} show={true}>
                <div className="flex h-full flex-col items-center justify-center gap-y-4 border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10">
                    <div>Conversation over. Thank you for your time.</div>
                    <div>
                        <Spinner size="md" />
                    </div>
                </div>
            </AnimatePresenceSlideUp>
        );
    }

    // show mic setup etc here
    if (messages.length == 0 && botStatus == BOT_STATUS_WAITING) {
        const conversation = assessment?.current_module_item?.answer?.conversation;

        return (
            <div className="flex h-full flex-col items-center justify-center border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10">
                <div className="grid grid-cols-2 gap-x-8">
                    <div className="flex flex-col gap-y-4">
                        <div className="prose">
                            <h3>Conversation with {bot_name}</h3>
                            <p>
                                In this module, we are simulating a call with {bot_name}. You will
                                be able to answer using your microphone, just like a real call. A
                                camera is not required.
                            </p>

                            <p>
                                After clicking the "Setup Audio" button below, a prompt will appear
                                requesting permission to access your microphone.
                            </p>

                            {audioSetupComplete && (
                                <p>
                                    Once you are done with the setup, click the "Start Module"
                                    button to read the brief.
                                </p>
                            )}
                        </div>
                        <div>
                            {(audioSetupComplete && (
                                <>
                                    {(!conversation && (
                                        // this is a new conversation
                                        <Buttons.Button
                                            color="lgreen"
                                            onClick={async () => {
                                                setConversationStatus(CONVERSATION_STATUS_BRIEF);
                                            }}
                                        >
                                            Start Module
                                        </Buttons.Button>
                                    )) || (
                                        // we have a conversation
                                        <Buttons.ButtonWithConfirmation
                                            color="lgreen"
                                            onClick={async () => {
                                                startConversation();
                                            }}
                                            confirm={{
                                                title: "Continue Conversation",
                                                description:
                                                    "You have already started this conversation. Do you want to continue?",
                                                okLabel: "Continue Conversation",
                                            }}
                                        >
                                            Continue Conversation
                                        </Buttons.ButtonWithConfirmation>
                                    )}
                                </>
                            )) || (
                                <div className="space-y-4">
                                    {audioSetupError && (
                                        <div className="text-red-500">{audioSetupError}</div>
                                    )}
                                    <div className="flex gap-x-4">
                                        <Buttons.Button
                                            color="slate"
                                            className="bg-slate-200"
                                            onClick={async () => {
                                                setShowAudioSetup(true);
                                            }}
                                        >
                                            Setup Audio
                                        </Buttons.Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        {(showAudioSetup && (
                            <AudioDeviceSelector
                                onSelectInputDevice={(deviceId) => {
                                    setInputDeviceId(deviceId);
                                }}
                                onSelectOutputDevice={(deviceId) => {
                                    setOutputDeviceId(deviceId);
                                }}
                                onSetupComplete={() => {
                                    setAudioSetupComplete(true);
                                }}
                                onNoDevicesFound={() => {
                                    setShowAudioSetup(false);
                                    setAudioSetupError(
                                        "Error: No devices found. Please check your microphone and speakers and try again.",
                                    );
                                }}
                                onPermissionError={() => {
                                    setShowAudioSetup(false);
                                    setAudioSetupError(
                                        "Error: Permission to access microphone denied. Please check your browser settings and try again. ",
                                    );
                                }}
                                onSomeOtherError={() => {
                                    setShowAudioSetup(false);
                                    setAudioSetupError(
                                        "Error: Something went wrong. Please try again.",
                                    );
                                }}
                                onNotSupported={() => {
                                    setShowAudioSetup(false);
                                    setShowChromeWarning(true);
                                }}
                            />
                        )) || (
                            <div className="flex h-full flex-col items-center justify-center">
                                <AnimatePresence show={true} initial={true}>
                                    <img src={AliceImage} className="h-48" />
                                </AnimatePresence>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    const userMessages = messages.filter((message) => message.role === "user");
    const latestUserMessage = userMessages[userMessages.length - 1];
    const systemMessages = messages.filter((message) => message.role === "assistant");
    const latestSystemMessage = systemMessages[systemMessages.length - 1];

    return (
        <div>
            <div className="flex flex-col gap-y-4 border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10 transition-all">
                {showSpeakClearly && (
                    <div className="flex flex-col items-center justify-center bg-orange-200 p-5 text-center">
                        It looks like we have trouble understanding you. Please try to slightly
                        increase the volume of your voice and speak more clearly.
                    </div>
                )}

                <div className="flex items-end justify-end">
                    <Buttons.Button
                        color="lorange"
                        variant="solidXS"
                        onClick={() => {
                            endConversation();
                        }}
                    >
                        End Call
                    </Buttons.Button>
                </div>
                <div className="grid grid-cols-2 gap-x-4">
                    <div
                        className={classNames(
                            "col-span-1 flex items-center justify-center bg-blue-100 p-20 transition-all",
                            userStatus == USER_STATUS_SPEAKING && "bg-blue-300",
                        )}
                    >
                        <div className="flex flex-col gap-y-4 text-center">
                            <div className="flex flex-col items-center justify-center gap-y-2">
                                <Avatars.Avatar
                                    size="lg"
                                    placeholder={assessment.initials}
                                    hash={assessment.d}
                                />
                                <div>{assessment.first_name}</div>
                            </div>

                            <div className="flex max-h-[650px] min-h-[150px] flex-col items-center justify-center gap-y-4 text-center">
                                {userStatus == USER_STATUS_THINKING && (
                                    <div>
                                        <Spinner />
                                    </div>
                                )}
                                {(userStatus == USER_STATUS_SPEAKING && <div></div>) || (
                                    <div>{latestUserMessage?.content}</div>
                                )}

                                {userStatus == USER_STATUS_TRANSCRIPTION_ERROR && (
                                    <div className="flex flex-col gap-y-4">
                                        <div className="text-xs">
                                            Sorry, we couldn't understand what you said. Please try
                                            typing your response.
                                        </div>
                                        <Fields.TextFieldFree
                                            rows={3}
                                            type="textarea"
                                            placeholder="Please type your response here"
                                            onChange={(e) => {
                                                setAnswer(e.target.value);
                                            }}
                                        />
                                        <Buttons.Button
                                            color="blue"
                                            className="w-full"
                                            onClick={() => {
                                                newMessage(answer);
                                            }}
                                        >
                                            Send Text Response
                                        </Buttons.Button>
                                    </div>
                                )}

                                {startRecording && (
                                    <CandidateExternalModuleItemConversationRecorderPush2Talk
                                        inputDeviceId={inputDeviceId}
                                        onStopRecording={onStopRecording}
                                        isFirstRecording={messages.length === 1}
                                        defaultRecordingCountdown={default_recording_countdown}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        className={classNames(
                            "col-span-1 flex items-center justify-center bg-blue-100 p-20 transition-all",
                            botStatus == BOT_STATUS_SPEAKING && "animate-pulse bg-blue-300",
                        )}
                    >
                        <div className="flex flex-col gap-y-4 text-center">
                            <div className="flex flex-col items-center justify-center gap-y-2">
                                <img src={AliceImage} className="h-16 w-16 rounded-full" />
                                <div>{bot_name}</div>
                            </div>

                            <div className="flex max-h-[650px] min-h-[150px] flex-col items-center justify-center gap-y-4 text-center">
                                {botStatus == BOT_STATUS_THINKING && (
                                    <div>
                                        <Spinner />
                                    </div>
                                )}
                                <div>
                                    {botStatus == BOT_STATUS_JOINING && (
                                        <div>Waiting for {bot_name} to join...</div>
                                    )}
                                    {botStatus == BOT_STATUS_JOINED && (
                                        <div>{bot_name} joined the call</div>
                                    )}
                                    {botStatus == BOT_STATUS_LEFT && (
                                        <div className="font-bold text-red-300">
                                            {bot_name} left the call
                                        </div>
                                    )}
                                    {(botStatus == BOT_STATUS_SPEAKING && (
                                        <div>{bot_name} is speaking...</div>
                                    )) || (
                                        <div className="">
                                            {parseTextAndIncludeCodeMirror(
                                                latestSystemMessage?.content,
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col bg-slate-100 p-5">
                    <div className="flex flex-col gap-y-4">
                        <Fields.Label>Your notes</Fields.Label>
                        {notes && (
                            <div
                                className="prose prose-sm"
                                dangerouslySetInnerHTML={{ __html: notes }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
