import React from "react";

import { updateTokens, useGetUserInviteQuery, useUserInviteMutation } from "./store/auth";
import {
    Buttons,
    Forms,
    Spinner,
    useDispatch,
    useEffect,
    useNavigate,
    useParams,
    useSearchParams,
    useState,
} from "./tools";

export default function UserInvite() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { uiid } = useParams();

    const { data: userInviteData, isLoading, error } = useGetUserInviteQuery(uiid);

    const [userInvite, { isLoading: isLoadingInvite, error: errorInvite }] =
        useUserInviteMutation();

    if (isLoading) {
        return <Spinner center size="md" />;
    }

    return (
        <div className="flex flex-col gap-y-4">
            <div className="prose-md prose">
                <h4>You have been invited to join {userInviteData?.org?.name} on Cactus</h4>
                <p>Let's get you set up with an account.</p>
            </div>
            <div>
                <Forms.GenericForm
                    initialValues={{
                        uiid: uiid,
                        email: userInviteData?.email,
                        first_name: "",
                        last_name: "",
                        password: "",
                    }}
                    mutation={useUserInviteMutation}
                    fields={[
                        {
                            name: "email",
                            label: "Email",
                            type: "email",
                            required: true,
                            disabled: true,
                            className: "opacity-8",
                        },
                        {
                            name: "first_name",
                            label: "First Name",
                            type: "text",
                            required: true,
                        },
                        {
                            name: "last_name",
                            label: "Last Name",
                            type: "text",
                            required: true,
                        },
                        {
                            name: "password",
                            label: "Password",
                            type: "password",
                            required: true,
                            showStrengthIndicator: true,
                        },
                    ]}
                    onSuccess={(result) => {
                        if (result.data) {
                            const { access, refresh } = result.data;
                            if (access && refresh) {
                                // this is a login
                                dispatch(updateTokens({ access, refresh }));
                                navigate("/");
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
}
