import LogoNew from "./assets/img/cactus-hire-new.svg";
import { AnimatePresence, ApiCandidate, Buttons, Fields, useParams, useState } from "./tools";

export default function CandidateExternalWelcome() {
    const { cid, caid } = useParams();
    const [email, setEmail] = useState("");
    const [showVerifyCode, setShowVerifyCode] = useState(false);
    const [verifyCode, setVerifyCode] = useState("");

    const {
        data: assessment,
        isLoading,
        error,
    } = ApiCandidate.useGetAssessmentQuery({ cid, caid }, {});

    const [performAction, { isLoading: isPerformingAction, error: performActionError }] =
        ApiCandidate.usePerformAssessmentActionMutation();

    return (
        <>
            <div className="border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10">
                <div className="grid grid-cols-3 gap-x-10">
                    <div className="col-span-3 grid gap-y-4">
                        {showVerifyCode ? (
                            <>
                                <p className="lg:prose-md prose prose-slate">
                                    We have sent a verification code to your email address. Please
                                    enter it below.
                                </p>
                                <div className="w-md-1/2  w-full">
                                    <Fields.VerifyCodeFieldFree
                                        error={performActionError?.data?.detail}
                                        onChange={(v) => {
                                            setVerifyCode(v);
                                        }}
                                    />
                                </div>
                                <div>
                                    <Buttons.Button
                                        color="lgreen"
                                        loading={isPerformingAction}
                                        onClick={async () => {
                                            const { data } = await performAction({
                                                cid,
                                                caid,
                                                action: "verify_email_code",
                                                payload: { code: verifyCode },
                                            });
                                        }}
                                    >
                                        Continue
                                    </Buttons.Button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="lg:prose-md prose prose-slate">
                                    <h1>Hello, {assessment.first_name}!</h1>
                                    <p>
                                        You have been invited to participate in an assessment at{" "}
                                        <b className="text-emerald-"> {assessment.org.name}.</b>
                                    </p>
                                    <p>
                                        Please confirm your email address and we'll get you started.
                                    </p>
                                </div>
                                <div className="w-1/2">
                                    <Fields.TextFieldFree
                                        error={performActionError?.data?.detail}
                                        label="Email address"
                                        value={email}
                                        type="email"
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                    />
                                </div>
                                <div>
                                    <Buttons.Button
                                        color="lgreen"
                                        loading={isPerformingAction}
                                        onClick={async () => {
                                            const { data, error } = await performAction({
                                                cid,
                                                caid,
                                                action: "verify_email",
                                                payload: { email: email },
                                            });
                                            if (!error) {
                                                setShowVerifyCode(true);
                                            }
                                        }}
                                    >
                                        Continue
                                    </Buttons.Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
