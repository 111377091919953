import axios from "axios";
import { useRef, useState } from "react";

import { ApiCandidate, Utils, _ } from "./tools";

export function useS3Uploader({ cid, caid }) {
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(null);
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(0);

    const [performAction, { isLoading: isPerformingAction, error: performActionError }] =
        ApiCandidate.usePerformAssessmentActionMutation();

    const throttledSetProgress = useRef(
        _.throttle((percentage) => {
            setProgress(percentage);
        }, 200),
    ).current;

    const startUpload = async (blob, fileName, options = {}) => {
        const { folder = "media/candidate", contentType = blob ? blob.type : "audio/webm" } =
            options;

        try {
            setUploading(true);
            const result = await performAction({
                cid,
                caid,
                action: "signed_url",
                payload: {
                    file_name: fileName,
                    content_type: contentType,
                    folder: folder,
                },
            });
            const proxy = Utils.isLocalhost() ? "http://localhost:30080/" : "";
            const signedUrl = result?.data?.signed_url;

            await axios.put(proxy + signedUrl, blob, {
                headers: {
                    "Content-Type": contentType,
                },
                onUploadProgress: (event) => {
                    const percentage = Math.round((event.loaded * 100) / event.total);
                    throttledSetProgress(percentage);
                },
            });

            setUploaded({
                object_key: result?.data?.object_key,
            });
        } catch (err) {
            setError(err);
        } finally {
            setUploading(false);
        }
    };

    return { uploading, uploaded, error, progress, startUpload };
}
