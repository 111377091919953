import { Api, Cards, Charts, HeroSolidIcons, Spinner, Stats } from "./tools";

export default function TeamStats({ id }) {
    const {
        data: stats,
        isLoading,
        error,
    } = Api.useGetTeamStatsQuery(id, {
        refetchOnFocus: true,
    });

    if (isLoading) {
        return <Spinner center />;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    const renderFunnel = () => {
        const { funnel_stats } = stats;
        // if created is 0, then don't show the component
        if (funnel_stats?.created === 0) {
            return null;
        }
        const funnelData = [
            {
                id: "step_invited",
                value: funnel_stats?.created,
                label: "Invited",
                color: "#10B981",
            },
            {
                id: "step_started",
                value: funnel_stats?.started,
                label: "Started",
                color: "#34D399",
            },
            {
                id: "step_completed",
                value: funnel_stats?.completed,
                label: "Completed",
                color: "#6EE7B7",
            },
            {
                id: "step_reviewed",
                value: funnel_stats?.reviewed,
                label: "Reviewed",
                color: "#A7F3D0",
            },
        ];

        // remove 0 values from the funnel data
        const filteredFunnelData = funnelData.filter((d) => d.value > 0);

        // if we can't display at least 3 steps, we don't display the funnel chart
        const showFunnelChart = filteredFunnelData.length >= 3;

        return (
            <Cards.CardOneColumn className="bg-slate-50 ">
                <Charts.Chart
                    variant="Funnel"
                    title="Assessment Funnel"
                    subtitle={`Last 30 days`}
                    data={showFunnelChart ? filteredFunnelData : []}
                    //colors={{ scheme: "blues" }}
                    colors={(d) => d.color}
                    direction="horizontal"
                    motionConfig="gentle"
                    // use emerald-100 to 900
                    borderColor={{ from: "color", modifiers: [["darker", 0.3]] }}
                    borderWidth={2}
                    enableBeforeSeparators={false}
                    download={true}
                />
            </Cards.CardOneColumn>
        );
    };

    const renderAssessmentsCreated = () => {
        const { ca_last_30_days, ca_last_30_days_change } = stats;
        // if any are 0, then don't show the component
        if (ca_last_30_days === 0 || ca_last_30_days_change === 0) {
            return null;
        }

        return (
            <Stats.StatNumberComponent
                title="Candidates Invited"
                subtitle="Last 30 days"
                value={ca_last_30_days}
                change={`${ca_last_30_days_change}%`}
                changeType={ca_last_30_days_change >= 0 ? "increase" : "decrease"}
            />
        );
    };

    return (
        <div className="flex flex-col gap-y-4">
            <div>{renderAssessmentsCreated()}</div>
            <div>{renderFunnel()}</div>
        </div>
    );
}
