import { CheckBadgeIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

import { OrgContext } from "./OrgProvider";
import SSOForm from "./SSOForm";
import SSOImage from "./assets/img/sso.png";
import {
    Api,
    Buttons,
    Disclosures,
    Fields,
    Pills,
    classNames,
    constants,
    useContext,
    useState,
} from "./tools";

export default function AccountSSO() {
    const { org } = useContext(OrgContext);
    const [orgDomain, setOrgDomain] = useState(null);
    const [nonFieldError, setNonFieldError] = useState(null);
    const [updateOrg, { isLoading: isUpdating }] = Api.useUpdateOrgMutation();

    const _idp = org.idp && constants.IDP_MAP[org.idp];

    return (
        <div className="flex-col gap-y-4 space-y-4">
            <Pills.Pill>Single Sign-On (SSO)</Pills.Pill>
            <div className="space-y-4">
                {nonFieldError && (
                    <div className="border-l-4 border-red-400 bg-red-50 p-4">
                        <div className="text-sm text-red-700">{nonFieldError}</div>
                    </div>
                )}

                {(_idp && (
                    <div className="grid grid-cols-2 gap-x-8 gap-y-8 rounded-md bg-white p-5 shadow-md ring-1 ring-slate-200">
                        <div className="col-span-2">
                            <div className="flex gap-x-3">
                                <img src={_idp.icon} className="h-4 w-4" />
                                <span className="text-xs font-semibold text-slate-700">
                                    {_idp.name}
                                </span>
                                <CheckBadgeIcon className="h-4 w-4 text-green-500" />
                            </div>
                        </div>

                        <div className="col-span-1">
                            <div className="space-y-4">
                                <Fields.TextFieldFree
                                    label="Email Domain"
                                    labelPopover={
                                        <div className="prose prose-sm w-60 p-3">
                                            <p>
                                                This is the email domain that your team will use to
                                                log in to Cactus.
                                            </p>
                                            <p>
                                                Users will be able to log in with their email
                                                address and password, and be automatically
                                                provisioned and added to your organization.
                                            </p>
                                        </div>
                                    }
                                    onChange={(e) => {
                                        setOrgDomain(e.target.value);
                                    }}
                                    defaultValue={org.org_domain}
                                />
                                {orgDomain && orgDomain != org.org_domain && (
                                    <Buttons.Button
                                        onClick={async () => {
                                            const result = await updateOrg({
                                                org_domain: orgDomain,
                                            });
                                            if (result.error) {
                                                setNonFieldError(result.error?.data?.org_domain);
                                            }
                                        }}
                                    >
                                        Save
                                    </Buttons.Button>
                                )}
                                <div className="gap-y-4">
                                    <hr className="mb-3 border-slate-200" />
                                    <Buttons.ButtonWithConfirmation
                                        confirm={{
                                            title: "Disconnect from SSO",
                                            content: (
                                                <div className="prose prose-sm text-slate-700">
                                                    <h5 className="font-bold tracking-tight">
                                                        Disconnect {_idp.name} from {org.name}
                                                    </h5>
                                                    <p>
                                                        Are you sure you want to disconnect from{" "}
                                                        {_idp.name}? Your team will no longer be
                                                        able to log in with their SSO credentials.
                                                    </p>
                                                </div>
                                            ),
                                            okLabel: "Disconnect",
                                            confirmBtnColor: "lred",
                                        }}
                                        className="bg-transparent text-xs"
                                        onClick={async () => {
                                            const result = await updateOrg({
                                                idp: null,
                                            });
                                            if (result.error) {
                                                setNonFieldError(result.error?.data?.idp);
                                            }
                                        }}
                                    >
                                        <img src={_idp.icon} className="mr-2 h-4 w-4" />
                                        Disconnect from {_idp.name}
                                    </Buttons.ButtonWithConfirmation>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="space-y-4">
                                <Fields.SelectFieldFree
                                    label="Default Role"
                                    labelPopover={
                                        <div className="prose prose-sm w-48 p-3">
                                            <p>
                                                New users will be assigned this role automatically
                                                when they are provisioned.
                                            </p>
                                        </div>
                                    }
                                    defaultValue={org.sso_default_role}
                                    options={[...constants.USER_ROLES]}
                                    onChange={async (e) => {
                                        const result = await updateOrg({
                                            sso_default_role: e.target.value,
                                        });
                                        if (result.error) {
                                            setNonFieldError(result.error?.data?.sso_default_role);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )) || (
                    <SSOForm
                        className={"flex gap-x-4"}
                        actionLabel="Setup SSO"
                        onClick={(idp) => {
                            updateOrg({
                                idp: idp,
                            });
                        }}
                    />
                )}

                <Disclosures.DisclosureUnstyled
                    panels={[
                        {
                            title: (
                                <div className="flex items-center gap-x-2">
                                    <h5 className="text-xs font-semibold text-slate-700">
                                        Enterprise SSO Options
                                    </h5>
                                    <span className="ml-2 text-xs text-slate-400">Read more</span>{" "}
                                    <ChevronDownIcon className="h-3 w-4" />
                                </div>
                            ),
                            content: (
                                <div className="grid grid-cols-4 gap-x-8 rounded-md bg-white p-5 shadow-md">
                                    <div className="prose prose-sm col-span-2">
                                        <h5 className="text-xs font-semibold text-slate-700">
                                            Enterprise SSO Options
                                        </h5>
                                        <p className="mt-5">
                                            For Enterprise plans, we offer integration with 20+
                                            identity providers, including Okta, Azure, and OneLogin.
                                        </p>
                                        <p>Please reach out to our support team to get started.</p>
                                        <Buttons.ButtonSupport />
                                    </div>
                                    <div className="col-span-2">
                                        <img src={SSOImage} alt="SSO" className="w-full" />
                                    </div>
                                </div>
                            ),
                        },
                    ]}
                />
            </div>
        </div>
    );
}
