import React from "react";

import UserCreateBulkForm from "./UserCreateBulkForm";
import UserCreateForm from "./UserCreateForm";
import { UserContext } from "./tools";
import {
    Api,
    Avatars,
    Buttons,
    Dialog,
    Pills,
    Spinner,
    Tables,
    constants,
    useContext,
    useDebounce,
    useState,
} from "./tools";

export default function AccountUsers() {
    const [search, setSearch] = useState(null);
    const [useBulkInvite, setUseBulkInvite] = useState(false);
    const [showInviteUser, setShowInviteUser] = useState(false);
    const debouncedSearch = useDebounce(search, 200);
    const { user } = useContext(UserContext);

    const {
        data: users,
        isLoading,
        error,
        refetch: getUsers,
    } = Api.useGetOrgUsersQuery(
        { search: debouncedSearch },
        {
            refetchOnFocus: true,
        },
    );

    const { data: usersInvites, isLoading: isLoadingInvites } = Api.useGetOrgUsersInvitesQuery({
        search: debouncedSearch,
    });

    const [deleteUserInvite, { isLoading: isDeletingInvite }] =
        Api.useDeleteOrgUsersInviteMutation();

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <div className="flex flex-col gap-y-4">
            <Dialog size="lg" isOpen={showInviteUser} setIsOpen={setShowInviteUser}>
                <div className="space-y-4">
                    <div>
                        {useBulkInvite ? (
                            <UserCreateBulkForm onSuccess={() => setShowInviteUser(false)} />
                        ) : (
                            <UserCreateForm onSuccess={() => setShowInviteUser(false)} />
                        )}
                    </div>
                    <div>
                        <p className="text-xs italic text-slate-500">
                            You may use the bulk invite feature to invite multiple users at once.{" "}
                            <span
                                onClick={() => {
                                    setUseBulkInvite(!useBulkInvite);
                                }}
                                className="cursor-pointer text-slate-900 underline"
                            >
                                {useBulkInvite ? "Use single invite" : "Use bulk invite"}
                            </span>
                        </p>
                    </div>
                </div>
            </Dialog>

            <div className="flex items-center justify-between">
                <Pills.Pill>Users</Pills.Pill>
                <Buttons.Button
                    variant="solidXS"
                    color="slater"
                    onClick={() => {
                        setShowInviteUser(true);
                    }}
                >
                    Add User
                </Buttons.Button>
            </div>

            <Tables.TablePaginated
                showHeader={false}
                pageSize={5}
                rows={users.map((u) => {
                    return {
                        key: u.id,
                        cells: [
                            {
                                key: "name",
                                content: (
                                    <div className="flex items-center space-x-3">
                                        <div className="flex-shrink-0">
                                            <Avatars.Avatar placeholder={u.initials} hash={u.d} />
                                        </div>
                                        <div>
                                            <div
                                                className="text-slate-900"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(u.id);
                                                }}
                                            >
                                                {u.full_name}
                                            </div>
                                            <div className="text-slate-500">
                                                {constants.USER_ROLE_MAP[u.role]}
                                            </div>
                                        </div>
                                    </div>
                                ),
                            },
                            {
                                key: "actions",
                                content: (
                                    <div className="flex items-center justify-end gap-x-4">
                                        {user.id != u.id && (
                                            <Buttons.ButtonActionsDropdown
                                                actions={[
                                                    {
                                                        label: "Remove User",
                                                        onClick: () => {
                                                            console.log("delete user");
                                                        },
                                                        confirm: {
                                                            title: "Remove User",
                                                            description: `Are you sure you want to remove ${u.full_name}? This action cannot be undone. 
                                                       They will no longer be able to access your organization.`,
                                                            confirmLabel: "Remove User",
                                                        },
                                                    },
                                                ]}
                                                ellipsis
                                            />
                                        )}
                                    </div>
                                ),
                            },
                        ],
                    };
                })}
            />

            <Tables.TablePaginated
                headers={[{ key: "name", content: "Name" }]}
                showHeader={false}
                pageSize={5}
                rows={usersInvites?.map((u) => {
                    return {
                        key: u.id,
                        className: "opacity-50",
                        cells: [
                            {
                                key: "name",
                                content: (
                                    <div className="flex items-center space-x-3">
                                        <div className="flex-shrink-0">
                                            <Avatars.Avatar
                                                placeholder={u.email.substring(0, 2)}
                                                hash={u.d}
                                            />
                                        </div>
                                        <div>
                                            <div className="text-slate-900">{u.email}</div>
                                            <div className="text-slate-500">
                                                {constants.USER_ROLE_MAP[u.role]}
                                            </div>
                                        </div>
                                    </div>
                                ),
                            },
                            {
                                key: "actions",
                                className: "text-right",
                                content: (
                                    <Buttons.Button
                                        loading={isDeletingInvite}
                                        onClick={() => {
                                            deleteUserInvite(u.id);
                                        }}
                                        variant="solidXS"
                                        color="slate"
                                    >
                                        Revoke
                                    </Buttons.Button>
                                ),
                            },
                        ],
                    };
                })}
            />
        </div>
    );
}
