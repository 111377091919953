import React, { createContext, useState } from "react";

import { Api, Spinner } from "./tools";

export const UserContext = createContext();

export function UserProvider({ children }) {
    const { data: user, isLoading, error } = Api.useGetCurrentUserQuery({}, {});

    if (isLoading) {
        return <Spinner center />;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>;
}
