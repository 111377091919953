import React from "react";

import {
    Api,
    Buttons,
    Disclosures,
    UserContext,
    Utils,
    classNames,
    constants,
    useContext,
} from "./tools";

function validateModuleItem(mi) {
    let errors = {};
    // check if we have at least 2 answers
    if (mi.answers.length < 2) {
        errors.answers = "Needs at least 2 answers";
    }
    // check if we have at least 1 correct answer
    if (mi.answers.filter((a) => a.correct).length < 1) {
        errors.answers = "Needs at least 1 correct answer";
    }

    // add a "string" property to errors for easy consumption
    errors.string = Object.values(errors).join(", ");
    errors.has_errors = errors.string.length > 0;

    return errors;
}

export default function MQPreview({ items = [] }) {
    return (
        <div className="grid grid-cols-1 gap-y-4 text-sm">
            {items.map((item, index) => {
                return (
                    <Disclosures.Disclosure
                        key={index}
                        open={item.new}
                        panels={[
                            {
                                title: item.question
                                    ? `${item.question} ${
                                          validateModuleItem(item).has_errors
                                              ? `(${validateModuleItem(item).string})`
                                              : ""
                                      }`
                                    : "Untitled",
                                bgColor: item.ai_tool
                                    ? "bg-purple-100"
                                    : validateModuleItem(item).has_errors
                                    ? "bg-orange-100"
                                    : "bg-slate-50",

                                content: (
                                    <div
                                        key={index}
                                        id={`item-${index}`}
                                        className={classNames(
                                            item.ai_tool ? "bg-purple-100" : "bg-slate-100",
                                            " border-1 grid grid-cols-1 gap-y-4 border-slate-600 p-5",
                                        )}
                                    >
                                        <div>{item.question}</div>

                                        <div className="grid grid-cols-1 gap-y-4 p-5">
                                            {item.answers.map((answer, answerIndex) => {
                                                return (
                                                    <Disclosures.Disclosure
                                                        key={answerIndex}
                                                        open={answer.new}
                                                        panels={[
                                                            {
                                                                title: answer.answer || "Untitled",
                                                                bgColor: answer.correct
                                                                    ? "bg-green-100"
                                                                    : "bg-slate-50",
                                                                content: (
                                                                    <div className="">
                                                                        {answer.answer}
                                                                        <div className="flex items-center justify-between">
                                                                            <div className="mt-3 flex items-center">
                                                                                <input
                                                                                    id={`items[${index}].answers[${answerIndex}].correct`}
                                                                                    name={`items[${index}].answers[${answerIndex}].correct`}
                                                                                    type="checkbox"
                                                                                    checked={
                                                                                        answer.correct
                                                                                    }
                                                                                    className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                                                                                />
                                                                                <label
                                                                                    htmlFor={`items[${index}].answers[${answerIndex}].correct`}
                                                                                    className="ml-3 block text-sm font-medium text-gray-700"
                                                                                >
                                                                                    Correct
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ),
                                                            },
                                                        ]}
                                                    />
                                                );
                                            })}
                                        </div>

                                        <div>{item.explanation}</div>
                                    </div>
                                ),
                            },
                        ]}
                    />
                );
            })}
        </div>
    );
}
