import { Link, NavLink, Outlet, useParams } from "react-router-dom";

import { classNames, useSearchParams } from "./tools";

export default function LayoutCandidateExternal() {
    const [searchParams, setSearchParams] = useSearchParams();
    const isDemo = searchParams.get("is_demo");
    return (
        <div>
            <div
                className={classNames(
                    "grid h-screen items-center",
                    isDemo ? "bg-transparent" : "bg-slate-50",
                )}
            >
                <div className="mx-auto w-full max-w-7xl">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}
