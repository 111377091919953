import ModuleConversation from "./ModuleConversation";
import ModuleItemExternalContent from "./ModuleItemExternalContent";
import ModuleItems from "./ModuleItems";
import ModuleItemsGeneric from "./ModuleItemsGeneric";
import { Api, UserContext, Utils, constants, useContext, useParams } from "./tools";

/* 

    A module is a collection of module items. A module item has a type, and a type-specific payload.
    For example, a module item of type "google doc" has a payload of a google doc item, and n module 
    items of type "multiple choice" have a payload of a multiple choice item.

*/
export default function ModuleContent() {
    const { id } = useParams();
    const {
        data: module,
        isLoading,
        error,
    } = Api.useGetModuleQuery(id, {
        refetchOnMountOrArgChange: true,
    });

    const { user } = useContext(UserContext);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (constants.GOOGLE_FILE_TYPES.includes(module.module_type)) {
        // we know with this module type that there will be google doc item
        return (
            <div className="flex flex-col gap-y-16">
                <div>
                    <ModuleItemExternalContent />
                </div>

                <div>
                    <ModuleItems />
                </div>
                <div>
                    <ModuleItemsGeneric />
                </div>
            </div>
        );
    }

    if (module.module_type == constants.MODULE_TYPE_MULTIPLE_CHOICE) {
        return (
            <div className="flex flex-col gap-y-16">
                <div>
                    <ModuleItems />
                </div>
            </div>
        );
    }

    if (module.module_type == constants.MODULE_TYPE_CONVERSATIONAL) {
        if (!Utils.isStaff(user)) {
            return (
                <div className="flex flex-col gap-y-16">
                    <div>Not available for this module type.</div>
                </div>
            );
        }
        return (
            <div className="flex flex-col gap-y-16">
                <div>
                    <ModuleConversation />
                </div>
            </div>
        );
    }

    if (constants.MODULE_TYPES_GENERIC.map((mg) => mg.id).includes(module.module_type)) {
        return (
            <div className="flex flex-col gap-y-16">
                <div>
                    <ModuleItemsGeneric
                        availableModuleTypes={[
                            ...constants.MODULE_TYPES.filter((m) => m.id == module.module_type),
                        ]}
                    />
                </div>
            </div>
        );
    }

    return <div>Unknown module type</div>;
}
