import AliceIcon from "./assets/img/alice.svg";
import AvocadoIcon from "./assets/img/avocado.svg";
import DocsIcon from "./assets/img/docs.svg";
import DrawingIcon from "./assets/img/drawing.svg";
import FormsIcon from "./assets/img/forms.svg";
import GoogleLogo from "./assets/img/google.svg";
import MicrosoftLogo from "./assets/img/microsoft.svg";
import MQIcon from "./assets/img/mq-icon.svg";
import SalesIcon from "./assets/img/sales.svg";
import SheetsIcon from "./assets/img/sheets.svg";
import SlidesIcon from "./assets/img/slides.svg";
import VideoIcon from "./assets/img/video.svg";

export const MODULE_TYPE_MULTIPLE_CHOICE = 1;
export const MODULE_TYPE_GOOGLE_DOC = 2;
export const MODULE_TYPE_GOOGLE_SHEET = 3;
export const MODULE_TYPE_GOOGLE_SLIDE = 4;
export const MODULE_TYPE_GOOGLE_FORM = 5;
export const MODULE_TYPE_GOOGLE_DRAWING = 6;
export const MODULE_TYPE_VIDEO = 7;
export const MODULE_TYPE_SCREENSHARE = 8;
export const MODULE_TYPE_CONVERSATIONAL = 9;

export const GOOGLE_FILE_TYPES = [
    MODULE_TYPE_GOOGLE_DOC,
    MODULE_TYPE_GOOGLE_SHEET,
    MODULE_TYPE_GOOGLE_SLIDE,
    MODULE_TYPE_GOOGLE_FORM,
    MODULE_TYPE_GOOGLE_DRAWING,
];

export const MODULE_TYPE_LABELS = {
    [MODULE_TYPE_MULTIPLE_CHOICE]: "Multiple Choice",
    [MODULE_TYPE_GOOGLE_DOC]: "Google Doc",
    [MODULE_TYPE_GOOGLE_SHEET]: "Google Sheet",
    [MODULE_TYPE_GOOGLE_SLIDE]: "Google Slide",
    [MODULE_TYPE_GOOGLE_FORM]: "Google Form",
    [MODULE_TYPE_GOOGLE_DRAWING]: "Google Drawing",
    [MODULE_TYPE_VIDEO]: "Video",
    [MODULE_TYPE_SCREENSHARE]: "Screen Share",
    [MODULE_TYPE_CONVERSATIONAL]: "Conversation",
};

export const MODULE_TYPE_ICONS = {
    [MODULE_TYPE_MULTIPLE_CHOICE]: MQIcon,
    [MODULE_TYPE_GOOGLE_DOC]: DocsIcon,
    [MODULE_TYPE_GOOGLE_SHEET]: SheetsIcon,
    [MODULE_TYPE_GOOGLE_SLIDE]: SlidesIcon,
    [MODULE_TYPE_GOOGLE_FORM]: FormsIcon,
    [MODULE_TYPE_GOOGLE_DRAWING]: DrawingIcon,
    [MODULE_TYPE_VIDEO]: VideoIcon,
    [MODULE_TYPE_SCREENSHARE]: VideoIcon,
    [MODULE_TYPE_CONVERSATIONAL]: AliceIcon,
};

export const MODULE_TYPES = [
    {
        id: MODULE_TYPE_MULTIPLE_CHOICE,
        label: MODULE_TYPE_LABELS[MODULE_TYPE_MULTIPLE_CHOICE],
        icon: MODULE_TYPE_ICONS[MODULE_TYPE_MULTIPLE_CHOICE],
        description: "Multiple choice questions for quick screening",
        canCreateCustom: true,
    },
    {
        id: MODULE_TYPE_CONVERSATIONAL,
        label: MODULE_TYPE_LABELS[MODULE_TYPE_CONVERSATIONAL],
        icon: MODULE_TYPE_ICONS[MODULE_TYPE_CONVERSATIONAL],
        description: "AI-powered interview simulations",
    },
    {
        id: MODULE_TYPE_GOOGLE_DOC,
        label: MODULE_TYPE_LABELS[MODULE_TYPE_GOOGLE_DOC],
        icon: MODULE_TYPE_ICONS[MODULE_TYPE_GOOGLE_DOC],
        description: "Google Docs for case studies and other written exercises",
        canCreateCustom: true,
    },
    {
        id: MODULE_TYPE_GOOGLE_SHEET,
        label: MODULE_TYPE_LABELS[MODULE_TYPE_GOOGLE_SHEET],
        icon: MODULE_TYPE_ICONS[MODULE_TYPE_GOOGLE_SHEET],
        description: "Google Sheets for data analysis and modeling",
        canCreateCustom: true,
    },
    {
        id: MODULE_TYPE_GOOGLE_SLIDE,
        label: MODULE_TYPE_LABELS[MODULE_TYPE_GOOGLE_SLIDE],
        icon: MODULE_TYPE_ICONS[MODULE_TYPE_GOOGLE_SLIDE],
        description: "Google Slides for presentations and pitch decks",
        canCreateCustom: true,
    },
    {
        id: MODULE_TYPE_GOOGLE_FORM,
        label: MODULE_TYPE_LABELS[MODULE_TYPE_GOOGLE_FORM],
        icon: MODULE_TYPE_ICONS[MODULE_TYPE_GOOGLE_FORM],
        description: "Google Forms for surveys and other data collection",
        canCreateCustom: true,
    },
    {
        id: MODULE_TYPE_GOOGLE_DRAWING,
        label: MODULE_TYPE_LABELS[MODULE_TYPE_GOOGLE_DRAWING],
        icon: MODULE_TYPE_ICONS[MODULE_TYPE_GOOGLE_DRAWING],
        description: "Google Drawings for diagrams and other visualizations",
        canCreateCustom: true,
    },
    {
        id: MODULE_TYPE_VIDEO,
        label: MODULE_TYPE_LABELS[MODULE_TYPE_VIDEO],
        icon: MODULE_TYPE_ICONS[MODULE_TYPE_VIDEO],
        description: "Video for recorded interviews and presentations",
    },
    {
        id: MODULE_TYPE_SCREENSHARE,
        label: MODULE_TYPE_LABELS[MODULE_TYPE_SCREENSHARE],
        icon: MODULE_TYPE_ICONS[MODULE_TYPE_SCREENSHARE],
        description: "Screen share for a live walkthrough of a project or demo",
        canCreateCustom: true,
    },
];

export const MODULE_TYPES_GENERIC = [
    ...MODULE_TYPES.filter((moduleType) =>
        [MODULE_TYPE_VIDEO, MODULE_TYPE_SCREENSHARE].includes(moduleType.id),
    ),
];

export const GOOGLE_FILE_ICONS = {
    [MODULE_TYPE_GOOGLE_DOC]: DocsIcon,
    [MODULE_TYPE_GOOGLE_SHEET]: SheetsIcon,
    [MODULE_TYPE_GOOGLE_SLIDE]: SlidesIcon,
    [MODULE_TYPE_GOOGLE_FORM]: SheetsIcon,
    [MODULE_TYPE_GOOGLE_DRAWING]: DrawingIcon,
};

export const GOOGLE_FILE_COLORS = {
    [MODULE_TYPE_GOOGLE_DOC]: "bg-blue-100",
    [MODULE_TYPE_GOOGLE_SHEET]: "bg-green-100",
    [MODULE_TYPE_GOOGLE_SLIDE]: "bg-yellow-100",
    [MODULE_TYPE_GOOGLE_FORM]: "bg-red-100",
    [MODULE_TYPE_GOOGLE_DRAWING]: "bg-orange-100",
};

const HELP_TEXT_MULTIPLE_CHOICE = `
The questions will be presented in a multiple-choice
format, with several answer options to choose from for
each question. You'll need to carefully read each
question and all of the answer options before selecting
the best answer. Be sure to pay close attention to any
details or keywords that may help you identify the
correct answer.`;

const HELP_TEXT_GOOGLE_FILE = `
You will be given access to a Google File. `;

const HELP_TEXT_CONVERSATIONAL = `
We will simulate a conversation.
`;

export const MODULE_TYPE_HELP_TEXT_CANDIDATE = {
    [MODULE_TYPE_MULTIPLE_CHOICE]: HELP_TEXT_MULTIPLE_CHOICE,
    [MODULE_TYPE_GOOGLE_DOC]: HELP_TEXT_GOOGLE_FILE,
    [MODULE_TYPE_GOOGLE_SHEET]: HELP_TEXT_GOOGLE_FILE,
    [MODULE_TYPE_GOOGLE_SLIDE]: HELP_TEXT_GOOGLE_FILE,
    [MODULE_TYPE_GOOGLE_FORM]: HELP_TEXT_GOOGLE_FILE,
    [MODULE_TYPE_GOOGLE_DRAWING]: HELP_TEXT_GOOGLE_FILE,
    [MODULE_TYPE_CONVERSATIONAL]: HELP_TEXT_CONVERSATIONAL,
};

export const DEFAULT_PAGE_SIZE = 10;

export const S3_DOMAIN =
    process.env.NODE_ENV === "production"
        ? "https://cactushire-prod.s3.us-west-1.amazonaws.com"
        : "https://cactushire-dev.s3.us-west-1.amazonaws.com";

export const IDP_MAP = {
    GoogleOAuth: {
        name: "Google OAuth",
        icon: GoogleLogo,
    },
    MicrosoftOAuth: {
        name: "Microsoft OAuth",
        icon: MicrosoftLogo,
    },
};

export const USER_ROLES = [
    {
        label: "Admin",
        value: 1,
    },
    {
        label: "Hiring Manager",
        value: 2,
    },
    {
        label: "Reviewer",
        value: 3,
    },
];

export const USER_ROLE_ADMIN = 1;
export const USER_ROLE_HIRING_MANAGER = 2;
export const USER_ROLE_REVIEWER = 3;

export const USER_ROLE_MAP = {
    [USER_ROLE_ADMIN]: "Admin",
    [USER_ROLE_HIRING_MANAGER]: "Hiring Manager",
    [USER_ROLE_REVIEWER]: "Reviewer",
};

export const ASSESSMENT_CATEGORIES = [
    { id: "all", name: "All", icon: SalesIcon },
    { id: "seo", name: "SEO", icon: SalesIcon },
    { id: "marketing", name: "Marketing", icon: SalesIcon },
    { id: "sales", name: "Sales", icon: SalesIcon },
    { id: "product", name: "Product", icon: SalesIcon },
    { id: "design", name: "Design", icon: SalesIcon },
    { id: "operations", name: "Operations", icon: SalesIcon },
    { id: "customer_support", name: "Customer Support", icon: SalesIcon },
    { id: "finance", name: "Finance", icon: SalesIcon },
    { id: "hr", name: "HR", icon: SalesIcon },
    { id: "legal", name: "Legal", icon: SalesIcon },
    { id: "management", name: "Management", icon: SalesIcon },
    { id: "other", name: "Other", icon: SalesIcon },
];

export const TAILWIND_DEFAULT_COLORS = [
    "rose-200",
    "pink-200",
    "fuchsia-200",
    "purple-200",
    "violet-200",
    "indigo-200",
    "blue-200",
    "cyan-200",
    "sky-200",
    "teal-200",
    "emerald-200",
    "green-200",
    "lime-200",
    "yellow-200",
    "amber-200",
    "orange-200",
    "red-200",
    "gray-200",
    "slate-200",
    "zinc-200",
];

export const TAILWIND_COLORS_TO_HEX = {
    "rose-200": "#fecdd3",
    "pink-200": "#fed7e2",
    "fuchsia-200": "#e0a7fc",
    "purple-200": "#dec3ff",
    "violet-200": "#cdaefe",
    "indigo-200": "#bda7fe",
    "blue-200": "#a8d4fe",
    "light-blue-200": "#7dd3fc",
    "cyan-200": "#3dd5f3",
    "sky-200": "#86e4f9",
    "teal-200": "#64d5ca",
    "emerald-200": "#6eeb83",
    "green-200": "#a0e1a7",
    "lime-200": "#b2f59e",
    "yellow-200": "#e4f88d",
    "amber-200": "#ffe27a",
    "orange-200": "#fcd34d",
    "red-200": "#fed7d7",
    "gray-200": "#e5e7eb",
    "slate-200": "#d1d5db",
    "zinc-200": "#9ca3af",
    "emerald-500": "#10b981",
    "emerald-700": "#047857",
    "slate-500": "#64748b",
    "slate-700": "#374151",
};

export const HEX_TO_TAILWIND_COLORS = {
    "#fecdd3": "rose-200",
    "#fed7e2": "pink-200",
    "#e0a7fc": "fuchsia-200",
    "#dec3ff": "purple-200",
    "#cdaefe": "violet-200",
    "#bda7fe": "indigo-200",
    "#a8d4fe": "blue-200",
    "#7dd3fc": "light-blue-200",
    "#3dd5f3": "cyan-200",
    "#86e4f9": "sky-200",
    "#64d5ca": "teal-200",
    "#6eeb83": "emerald-200",
    "#a0e1a7": "green-200",
    "#b2f59e": "lime-200",
    "#e4f88d": "yellow-200",
    "#ffe27a": "amber-200",
    "#fcd34d": "orange-200",
    "#fed7d7": "red-200",
    "#e5e7eb": "gray-200",
    "#d1d5db": "slate-200",
    "#9ca3af": "zinc-200",
    "#10b981": "emerald-500",
    "#047857": "emerald-700",
    "#64748b": "slate-500",
    "#374151": "slate-700",
    "#fbbf24": "yellow-500",
    "#d97706": "yellow-600",
    "#b45309": "yellow-700",
};

export const DEFAULT_BUTTON_COLOR = "slate";

export const TASK_TYPE_REVIEW_ASSESSMENT = 1;

export const SKIP_REASON_CANDIDATE = 1;
export const SKIP_REASON_TIME_LIMIT = 2;
