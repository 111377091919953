import React, { useEffect, useRef, useState } from "react";

import { Buttons, Spinner } from "./tools";

const VideoPreview = ({ stream, videoBlob, withDownloadButton = false }) => {
    const videoRef = useRef();
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    useEffect(() => {
        if (videoRef.current) {
            if (stream) {
                videoRef.current.srcObject = stream;
            } else if (videoBlob) {
                const objectURL = URL.createObjectURL(videoBlob);
                videoRef.current.src = objectURL;

                const handleCanPlayThrough = () => {
                    if (videoRef.current.readyState >= 3) {
                        setIsVideoLoaded(true);
                    }
                };

                videoRef.current.addEventListener("canplaythrough", handleCanPlayThrough);

                // Release the object URL and remove the event listener when the component unmounts
                return () => {
                    URL.revokeObjectURL(objectURL);
                    videoRef?.current?.removeEventListener("canplaythrough", handleCanPlayThrough);
                };
            }
        }
    }, [stream, videoBlob]);

    const handleDownload = () => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(videoBlob);
        const fileType = videoBlob.type.split("/")[1];
        link.download = `video.${fileType}`;
        link.click();
        URL.revokeObjectURL(link.href);
    };

    return (
        <div className="">
            {videoBlob && !isVideoLoaded && (
                <div className="flex h-40 items-center justify-center">
                    <Spinner />
                </div>
            )}
            <div className="space-y-4">
                <div className="aspect-w-16 aspect-h-9">
                    <video
                        className="h-full w-full object-cover object-center"
                        ref={videoRef}
                        autoPlay={!!stream}
                        muted={!!stream}
                        playsInline
                        controls={!!videoBlob}
                        style={{
                            display: stream || isVideoLoaded ? "block" : "none",
                        }}
                    />
                </div>

                {videoBlob && withDownloadButton && (
                    <div className="flex justify-end">
                        <Buttons.Button onClick={handleDownload}>Download</Buttons.Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VideoPreview;
