import { Api } from "./tools";
import { useQuery } from "./useQuery";

export function useAssessmentMagics(page, filters) {
    const {
        data: assessmentMagics,
        isLoading,
        error,
    } = useQuery(Api.useGetAssessmentMagicsQuery, page, filters);

    return {
        assessmentMagics,
        isLoading,
        error,
    };
}
