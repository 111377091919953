import React from "react";

import {
    AnimatePresence,
    ApiCandidate,
    Buttons,
    Fields,
    Forms,
    constants,
    useNavigate,
    useParams,
    useSearchParams,
} from "./tools";

export default function CandidateExternalLink() {
    const { lid, aid } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const isDemo = searchParams.get("is_demo");

    const {
        data: assessmentLink,
        isLoading,
        error,
    } = ApiCandidate.useGetAssessmentByLinkQuery({ lid, aid }, {});

    const [assessmentLinkAction, { isLoading: isActionLoading, error: actionError }] =
        ApiCandidate.usePerformAssessmentLinkActionMutation();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="grid items-center gap-x-24 gap-y-4 border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10 sm:grid-cols-2">
            <div className="col-span-1">
                <div className="prose-md prose">
                    {assessmentLink?.org?.logo ? (
                        <img
                            src={assessmentLink?.org?.logo}
                            className="h-8"
                            alt={assessmentLink?.org?.name}
                        />
                    ) : null}
                    <h3>Assessment Invitation</h3>
                    <p>
                        You have been invited to take an assessment for {assessmentLink?.org?.name}.
                        Please fill out the form to get started.
                    </p>
                    <p>
                        If you have already started the assessment, you can return to your
                        assessment by clicking the link in your email.
                    </p>
                </div>
            </div>

            <div className="col-span-1 rounded-md bg-slate-100 p-5 shadow-md">
                <Forms.GenericForm
                    submitButtonClassname="align-start"
                    submitButtonLabel="View Assessment"
                    submitButtonColor="lgreen"
                    fields={[
                        {
                            name: "first_name",
                            label: "First Name",
                            type: "text",
                            required: true,
                        },
                        {
                            name: "last_name",
                            label: "Last Name",
                            type: "text",
                            required: true,
                        },
                        {
                            name: "email",
                            label: "Email",
                            type: "email",
                            required: true,
                        },
                    ]}
                    onSubmit={async (values) => {
                        return assessmentLinkAction({
                            lid: lid,
                            aid: aid,
                            action: "create",
                            payload: {
                                lid: lid,
                                aid: aid,
                                ...values,
                                is_demo: isDemo,
                            },
                        });
                    }}
                    onSuccess={(result) => {
                        navigate(`/c/${result?.data?.cid}/a/${result?.data?.caid}`);
                    }}
                />
            </div>
        </div>
    );
}
