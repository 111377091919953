import { Buttons } from "./tools";

export default function Upgrade() {
    return (
        <div className="prose-md prose">
            <h1 className="font-bold tracking-tight text-emerald-900">
                Upgrade your Cactus Account
            </h1>
            <p>Please reach out to your account manager to upgrade your account.</p>
            <p>
                <Buttons.ButtonSupport>Message Tracy</Buttons.ButtonSupport>
            </p>
        </div>
    );
}
