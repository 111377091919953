import {
    AcademicCapIcon,
    ChevronDownIcon,
    ChevronUpDownIcon,
    ChevronUpIcon,
    DocumentTextIcon,
} from "@heroicons/react/20/solid";
import { ClockIcon } from "@heroicons/react/24/solid";

import CandidateExternalContact from "./CandidateExternalContact";
import LogoNew from "./assets/img/cactus-hire-new.svg";
import {
    AnimatePresence,
    ApiCandidate,
    Buttons,
    Fields,
    constants,
    useEffect,
    useParams,
    useState,
} from "./tools";

function CandidateExternalPrepareModule({ module }) {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <div className="grid cursor-pointer grid-cols-6 rounded-md bg-slate-50 shadow transition-all  hover:bg-slate-100">
            <div className="col-span-6">
                <div className="block">
                    <div className="flex items-center px-4 py-4 sm:px-6">
                        <div
                            className="flex min-w-0 flex-1 items-center"
                            onClick={() => {
                                setShowDetails(!showDetails);
                            }}
                        >
                            <div className="flex-shrink-0">
                                <div className="rounded-md bg-slate-200 p-5">
                                    <img
                                        src={constants.MODULE_TYPE_ICONS[module.module_type]}
                                        className="h-6 w-6"
                                    />
                                </div>
                            </div>
                            <div className="min-w-0 flex-1 px-8 md:grid md:grid-cols-4 md:gap-4">
                                <div>
                                    <p className="text-sm text-slate-900">Type</p>
                                    <p className="mt-2 flex items-center text-sm text-slate-500">
                                        <span className="truncate">
                                            {constants.MODULE_TYPE_LABELS[module.module_type]}
                                        </span>
                                    </p>
                                </div>
                                <div className="hidden md:block">
                                    <div>
                                        <p className="text-sm text-slate-900">Time limit</p>
                                        <p className="mt-2 flex items-center text-sm text-slate-500">
                                            {module.time_limit} minutes
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {showDetails ? (
                                <ChevronUpIcon
                                    className="h-5 w-5 text-slate-400"
                                    aria-hidden="true"
                                />
                            ) : (
                                <ChevronDownIcon
                                    className="h-5 w-5 text-slate-400"
                                    aria-hidden="true"
                                />
                            )}
                        </div>
                    </div>
                    <AnimatePresence show={showDetails} initial={true}>
                        <div className="prose prose-slate w-full max-w-full p-3">
                            {constants.MODULE_TYPE_HELP_TEXT_CANDIDATE[module.module_type]}
                        </div>
                    </AnimatePresence>
                </div>
            </div>
        </div>
    );
}

export default function CandidateExternalPrepare() {
    const { cid, caid } = useParams();

    const {
        data: assessment,
        isLoading,
        error,
    } = ApiCandidate.useGetAssessmentQuery({ cid, caid }, {});

    const [performAction, { isLoading: isPerformingAction, error: performActionError }] =
        ApiCandidate.usePerformAssessmentActionMutation();

    return (
        <>
            <div className="border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10">
                <div className="grid grid-cols-4 gap-x-10">
                    <div className="col-span-3 grid gap-y-4">
                        <div className="lg:prose-md prose prose-slate max-w-full">
                            <h1>Let's get started</h1>
                            <p>
                                You will be asked to complete a series of tasks. This assignment
                                includes <b>{assessment.modules.length}</b> module(s). You will work
                                through each module in the order they are presented. You may take
                                breaks between modules, but you will not be able to return to a
                                module once you have completed it.
                            </p>
                        </div>
                        <div className="my-8 grid gap-y-4">
                            {assessment.modules.map((module) => (
                                <CandidateExternalPrepareModule key={module.id} module={module} />
                            ))}
                        </div>
                        <div className="lg:prose-md prose prose-slate max-w-full">
                            <p>
                                Click the button below to start the assessment. The first module
                                will start immediately.
                            </p>
                            {/* Here we check if we need access to microphone, camera etc */}
                        </div>
                        <div>
                            <Buttons.Button
                                color="lgreen"
                                disabled={isPerformingAction}
                                loading={isPerformingAction}
                                onClick={async () => {
                                    // need to launch confirm modal
                                    await performAction({
                                        cid,
                                        caid,
                                        action: "start",
                                    });
                                }}
                            >
                                Start Assessment
                            </Buttons.Button>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <CandidateExternalContact assessment={assessment} />
                    </div>
                </div>
            </div>
        </>
    );
}
