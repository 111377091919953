import { UserGroupIcon } from "@heroicons/react/24/solid";

import { Api, Buttons, Fields, Form, Formik, Spinner, useNavigate, useState } from "./tools";

export default function CandidateCreateForm({ assessmentId, onSuccess }) {
    const {
        data: assessment,
        isLoading,
        isFetching,
        error,
    } = Api.useGetAssessmentQuery(assessmentId);

    const [createCandidate, { isLoading: isSaving }] = Api.useCreateCandidateMutation();
    const navigate = useNavigate();

    if (isFetching || !assessment) {
        return <Spinner />;
    }

    return (
        <Formik
            initialValues={{ first_name: "", last_name: "", email: "" }}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
                const result = await createCandidate({
                    ...values,
                    assessment: assessmentId,
                });
                setSubmitting(false);
                if (result.error) {
                    console.log(result.error);
                    for (const [key, value] of Object.entries(result.error.data)) {
                        setFieldError(key, value[0]);
                    }
                } else {
                    onSuccess?.(result.data);
                    navigate(`/candidates/${result.data.id}`);
                }
            }}
        >
            <Form className="grid grid-cols-2 gap-x-8">
                <div className="col-span-1 flex flex-col gap-y-8">
                    <Fields.TextField name="first_name" label="First name" />
                    <Fields.TextField name="last_name" label="Last name" />
                    <Fields.TextField type="email" name="email" label="Email" />
                    <Buttons.Button
                        loading={isSaving}
                        loadingMessage="Inviting candidate..."
                        type="submit"
                        variant="solid"
                        color="lgreen"
                        className="w-full"
                    >
                        <div className="max-w-[90%] truncate">
                            Invite candidate to {assessment?.name}
                        </div>
                    </Buttons.Button>
                </div>
                <div className="col-span-1 flex flex-col gap-y-8">
                    <Fields.ListBoxReviewers
                        position="bottomLeft"
                        labelPopover={
                            <div className="z-20 flex w-96 p-5 text-xs">
                                <div className="flex flex-col gap-y-2">
                                    <h3 className="text-md font-semibold">Adding reviewers</h3>
                                    <p>
                                        Reviewers will be prompted to review the candidate's
                                        answers. They will receive notifications along the
                                        candidates assessment journey.
                                    </p>
                                    <p>
                                        You can only add reviewers that are part of an assessments
                                        team.
                                    </p>
                                </div>
                            </div>
                        }
                        team={assessment.team}
                    />
                </div>
            </Form>
        </Formik>
    );
}
