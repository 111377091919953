import { Dialog, Switch, Tab, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

import AssessmentMagiclist from "./AssessmentMagicList";
import {
    AnimatePresence,
    Api,
    Avatars,
    Buttons,
    Cards,
    Fields,
    Form,
    Formik,
    Forms,
    Link,
    Tabs,
    classNames,
    useNavigate,
} from "./tools";

export default function AssessmentCreateMagic() {
    const [explain, setExplain] = useState(null);
    const navigate = useNavigate();

    return (
        <Cards.CardFormGeneric
            title="Magic Assessment Builder"
            subtitle="Create a role-specific assessment in minutes using our AI-powered assessment builder."
            tabs={[
                {
                    name: "General",
                    subtitle: (
                        <div className="prose prose-sm">
                            <p>Create a role-specific assessment in minutes</p>

                            <p>
                                The assessment builder works best if you enter a job post URL from
                                your own website, instead of a third-party job board.
                            </p>
                        </div>
                    ),
                    href: "general",
                    content: (
                        <div>
                            <Forms.GenericForm
                                fields={[
                                    {
                                        name: "url",
                                        label: "Job Post URL",
                                        placeholder:
                                            "e.g. https://www.cactushire.com/jobs/seo-specialist",
                                        type: "text",
                                        required: true,
                                    },
                                ]}
                                mutation={Api.useCreateAssessmentMagicMutation}
                                submitButtonClassname="w-auto inline-flex"
                                onSuccess={(result) => {
                                    navigate(
                                        `/library/assessments/create/magic/${result?.data?.id}`,
                                    );
                                }}
                            />
                        </div>
                    ),
                },
                {
                    name: "History",
                    href: "history",
                    content: <AssessmentMagiclist />,
                },
            ]}
        />
    );
}
