import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const useCallbackOnSearchParamMatch = (paramObject, callback, cleanSearchParams = true) => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const isMatch = Object.keys(paramObject).every((key) => {
            const value = paramObject[key];
            return searchParams.get(key) === String(value);
        });

        if (isMatch) {
            callback();
            if (cleanSearchParams) {
                // remove the matching search params
                const newSearchParams = new URLSearchParams(searchParams.toString());
                Object.keys(paramObject).forEach((key) => newSearchParams.delete(key));
                setSearchParams(newSearchParams);
            }
        }
    }, [searchParams.toString()]); // re-run effect if search changes
};

export default useCallbackOnSearchParamMatch;
