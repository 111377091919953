import React from "react";
import * as Yup from "yup";

import {
    Api,
    Buttons,
    Fields,
    Form,
    Formik,
    classNames,
    constants,
    useDebounce,
    useEffect,
    useRef,
    useState,
} from "./tools";

export function GenericForm({
    mutation,
    fields,
    initialValues,
    loadingMessage = "Loading...",
    submitButtonLabel = "Submit",
    submitButtonClassname = "w-full",
    submitButtonColor = constants.DEFAULT_BUTTON_COLOR,
    onSubmit,
    onCancel,
    onSuccess,
    onError,
    formClassName,
    children,
    autosaveDelay = 2000,
    autosave = false,
    autosaveOnValueChange = false,
    showSubmitButton = true,
}) {
    if (!mutation && !onSubmit) {
        throw new Error("You must provide a mutation or onSubmit function");
    }

    if (!initialValues) {
        initialValues = fields.reduce((acc, field) => {
            acc[field.name] = field.initial || "";
            return acc;
        }, {});
    }

    const [mutate, { isLoading, error, data }] = mutation?.() || [null, {}];
    const firstRender = useRef(true);

    const handleResponse = (result, { setSubmitting, setFieldError, resetForm }) => {
        if (result.error) {
            for (const [key, value] of Object.entries(result.error.data)) {
                setFieldError(key, value[0]);
            }
            onError?.(result, { setSubmitting, setFieldError, resetForm });
        } else {
            onSuccess?.(result, { setSubmitting, setFieldError, resetForm });
        }
    };

    const performSubmit = async (values, { setSubmitting, setFieldError, resetForm }) => {
        // if mutate, else onSubmit
        setSubmitting(true);
        if (mutation) {
            const result = await mutate({
                ...values,
            });
            handleResponse(result, { setSubmitting, setFieldError, resetForm });
        } else {
            const result = await onSubmit(values, { setSubmitting, setFieldError, resetForm });
            handleResponse(result, { setSubmitting, setFieldError, resetForm });
        }
    };

    return (
        <Formik
            initialValues={{ ...initialValues }}
            validationSchema={Yup.object().shape(
                fields.reduce((acc, field) => {
                    if (
                        (field.type === "text" ||
                            field.type === "textarea" ||
                            field.type === "password" ||
                            field.type === "email") &&
                        field.required
                    ) {
                        acc[field.name] = Yup.string().required("Required");
                    }
                    if (field.type === "team" && field.required) {
                        acc[field.name] = Yup.number().required("Required");
                    }
                    if (field.type === "quill" && field.required) {
                        acc[field.name] = Yup.string().required("Required");
                    }
                    if (field.type === "stars" && field.required) {
                        acc[field.name] = Yup.number().required("Required");
                    }

                    if (field.type === "recommend" && field.required) {
                        acc[field.name] = Yup.boolean().required("Required");
                    }

                    return acc;
                }, {}),
            )}
            onSubmit={performSubmit}
        >
            {({ values, isSubmitting, submitForm }) => {
                if (autosave) {
                    // If autosave on value change is enabled
                    if (autosaveOnValueChange) {
                        useEffect(() => {
                            if (!isSubmitting && !firstRender.current) {
                                submitForm();
                            }
                            firstRender.current = false; // After the first render, set this to false
                        }, [values, submitForm]);
                    } else {
                        // Otherwise, use the debounced values
                        const debouncedValues = useDebounce(values, autosaveDelay);

                        useEffect(() => {
                            if (!isSubmitting) {
                                submitForm();
                            }
                        }, [debouncedValues, submitForm]);
                    }
                }
                return (
                    <Form className="relative grid grid-cols-1 gap-x-8">
                        <div className={classNames("col-span-1 space-y-8 ", formClassName)}>
                            {fields.map((field) => {
                                if (
                                    ["text", "email", "password", "number", "textarea"].includes(
                                        field.type,
                                    )
                                ) {
                                    return (
                                        <Fields.TextField
                                            type={field.type}
                                            key={field.name}
                                            {...field}
                                        />
                                    );
                                }

                                if (field.type === "checkbox") {
                                    return <Fields.CheckBoxField key={field.name} {...field} />;
                                }

                                if (field.type === "toggle") {
                                    return <Fields.ToggleField key={field.name} {...field} />;
                                }

                                if (field.type === "quill") {
                                    return <Fields.QuillField key={field.name} {...field} />;
                                }

                                if (field.type === "select") {
                                    return <Fields.SelectField key={field.name} {...field} />;
                                }

                                if (field.type === "select_box") {
                                    return <Fields.ListBox key={field.name} {...field} />;
                                }

                                if (field.type === "tags") {
                                    return <Fields.TagsField key={field.name} {...field} />;
                                }

                                if (field.type === "team") {
                                    return (
                                        <Fields.ListBoxTeams
                                            key={field.name}
                                            name={field.name}
                                            label={field.label}
                                            {...field}
                                        />
                                    );
                                }

                                if (field.type === "stars") {
                                    return <Fields.StarRatingField key={field.name} {...field} />;
                                }

                                if (field.type === "thumbs") {
                                    return <Fields.ThumbsField key={field.name} {...field} />;
                                }
                            })}
                            {showSubmitButton && (
                                <Buttons.Button
                                    loading={isSubmitting}
                                    loadingMessage={loadingMessage}
                                    type="submit"
                                    variant="solid"
                                    color={submitButtonColor}
                                    className={classNames("", submitButtonClassname)}
                                >
                                    {submitButtonLabel}
                                </Buttons.Button>
                            )}
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
