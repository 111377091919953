import { isChrome, isEdge, isFirefox } from "@braintree/browser-detection";
import { useEffect, useState } from "react";

import { CodeMirror, _, constants } from "./tools";

export function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay], // Only re-call effect if value or delay changes
    );
    return debouncedValue;
}

// Redx Toolkit Query Helper Functions
export function providesList(result, tag) {
    return result
        ? [{ type: tag, id: "LIST" }, ...result.results?.map(({ id }) => ({ type: tag, id }))]
        : [{ type: tag, id: "LIST" }];
}

export function providesListUnpaginated(result, tag) {
    return result
        ? [{ type: tag, id: "LIST" }, ...result.map(({ id }) => ({ type: tag, id }))]
        : [{ type: tag, id: "LIST" }];
}

export function invalidatesList(tag) {
    return [{ type: tag, id: "LIST" }];
}

export function provideTagById(tag, id) {
    return [{ type: tag, id }];
}

export function invalidateTagById(tag, id) {
    return [{ type: tag, id }];
}

export function isSafari() {
    const userAgent = navigator.userAgent;
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
    return isSafari && !isEdge();
}

export function isLocalhost() {
    return window.location.hostname === "localhost";
}

export function isDev() {
    return process.env.NODE_ENV === "development";
}

export function isProd() {
    return process.env.NODE_ENV === "production";
}

export function stripHTMLTags(inputString) {
    const regex = /(<([^>]+)>)/gi;
    return inputString.replace(regex, "");
}

export function canIUseMediaRecorder() {
    return (
        typeof MediaRecorder !== "undefined" &&
        typeof MediaRecorder.isTypeSupported === "function" &&
        MediaRecorder.isTypeSupported("video/webm")
    );
}

export function canSafelyPlayAndRecordMedia() {
    return canIUseMediaRecorder() && (isChrome() || isEdge() || isFirefox());
}

export function calculateScorePercentage(score, maxScore) {
    if (score === 0 || maxScore === 0) {
        return 0;
    }
    return Math.floor((score / maxScore) * 100);
}

export function backgroundColorScore(score, maxScore) {
    const percentage = calculateScorePercentage(score, maxScore);
    if (percentage >= 70) {
        return "bg-emerald-300";
    } else if (percentage >= 50) {
        return "bg-yellow-300";
    } else if (percentage >= 30) {
        return "bg-orange-200";
    } else {
        return "bg-red-300";
    }
}

export function backgroundColorScorePercentage(percentage) {
    if (percentage >= 70) {
        return "bg-emerald-300";
    } else if (percentage >= 50) {
        return "bg-yellow-300";
    } else if (percentage >= 30) {
        return "bg-orange-200";
    } else {
        return "bg-red-300";
    }
}

export function replaceEmptyNewLinesWYSIWYG(content) {
    return content?.replace(/<p><br><\/p>/g, "");
}

export function playAudio(data, mimeType) {
    const blob = new Blob([data], { type: mimeType });
    const audio = new Audio(URL.createObjectURL(blob));
    audio.play();
}

export function stripCodeSnippets(text) {
    if (!text) {
        return null;
    }
    const codeSnippetRegex = /```(?:javascript)?([\s\S]*?)```/g;
    const parts = text.split(codeSnippetRegex);

    return parts
        .map((part, index) => {
            if (index % 2 === 1) {
                return null; // Ignore the code snippets
            } else {
                return part;
            }
        })
        .join(""); // Join the remaining parts without code snippets
}

export function parseTextAndIncludeCodeMirror(text) {
    if (!text) {
        return null;
    }
    const codeSnippetRegex = /```(?:javascript)?([\s\S]*?)```/g;
    const parts = text.split(codeSnippetRegex);

    return parts.map((part, index) => {
        if (index % 2 === 1) {
            return (
                <CodeMirror.CodeMirror
                    className="text-md my-3 text-left"
                    key={index}
                    value={part}
                    height="auto"
                    extensions={[CodeMirror.javascript({ jsx: true })]}
                    theme={"dark"}
                    readOnly={true}
                    autoFocus={false}
                    options={{
                        lineNumbers: true,
                    }}
                />
            );
        } else {
            return <p key={index}>{part}</p>;
        }
    });
}

export function generateRandomString(length = 12) {
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()-_=+[{]}|;:,<.>/?`~";
    return _.times(length, () => _.sample(characters)).join("");
}

export function isAdmin(user) {
    return user.role == constants.USER_ROLE_ADMIN;
}

export function isStaff(user) {
    return user._is == true;
}

export { isChrome, isEdge, isFirefox };
