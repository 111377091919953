import { AcademicCapIcon, CheckBadgeIcon, DocumentTextIcon } from "@heroicons/react/20/solid";

import CandidateExternalContact from "./CandidateExternalContact";
import WardenVideoSetup from "./WardenVideoSetup";
import LogoNew from "./assets/img/cactus-hire-new.svg";
import {
    AnimatePresence,
    ApiCandidate,
    Buttons,
    Fields,
    useEffect,
    useParams,
    useState,
} from "./tools";
import {
    WARDEN_EVENT_VIDEO_NOT_SUPPORTED,
    WARDEN_EVENT_VIDEO_NO_DEVICES,
    WARDEN_EVENT_VIDEO_NO_PERMISSIONS,
    WARDEN_EVENT_VIDEO_SETUP_COMPLETED,
    WARDEN_EVENT_VIDEO_SETUP_SKIPPED,
    useWarden,
} from "./useWarden";

export default function CandidateExternalWarden() {
    const [wardenSetupComplete, setWardenSetupComplete] = useState(false);
    const [showWardenVideoSetup, setShowWardenVideoSetup] = useState(false);
    const [wardenInstructions, setWardenInstructions] = useState(false);
    const [isSkipable, setIsSkipable] = useState(false);
    const { cid, caid } = useParams();
    const { warden, updateWardenDevice, isWardenVideoReady, sendWardenEvent } = useWarden(
        cid,
        caid,
    );

    const {
        data: assessment,
        isLoading,
        error,
    } = ApiCandidate.useGetAssessmentQuery({ cid, caid }, {});

    const [performAction, { isLoading: isPerformingAction, error: performActionError }] =
        ApiCandidate.usePerformAssessmentActionMutation();

    return (
        <>
            <div className="border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10">
                <div className="grid grid-cols-4 gap-x-10">
                    <div className="col-span-3 grid gap-y-4">
                        <div className="lg:prose-md prose prose-slate max-w-full">
                            <h1>Setup</h1>
                            <p>
                                We use SpikyWarden to ensure that everyone is evaluated fairly and
                                accurately.
                            </p>
                            <p>
                                SpikyWarden requires access to your camera during the assessment,
                                which can seem a bit invasive, but we want to assure you that we
                                take your privacy and data security very seriously. We only use your
                                camera to monitor your activity during the assessment, and we never
                                capture or record any other personal information. Your data is only
                                stored for the duration of the assessment, and is deleted
                                immediately after the assessment is completed.
                            </p>
                            <p>
                                We believe that everyone deserves a fair and equal opportunity to
                                showcase their skills and abilities, and that's why we use
                                SpikyWarden to prevent cheating and ensure that all work is original
                                and free from plagiarism.
                            </p>
                            <p>
                                Click the button below to setup your camera and continue with the
                                assessment.
                            </p>
                        </div>
                        <div>
                            {wardenInstructions && (
                                <div className="mb-4 rounded-md bg-orange-100 p-6">
                                    {wardenInstructions}
                                </div>
                            )}

                            {showWardenVideoSetup && (
                                <WardenVideoSetup
                                    onPermissionError={(err) => {
                                        console.log("Permission error", err);
                                        // Here the user has denied access to the camera, show help message and ask to try again
                                        setWardenInstructions(PERMISSION_SETUP_HELP);
                                        setShowWardenVideoSetup(false); // Reset the setup
                                        setIsSkipable(true); // Allow the user to skip the setup
                                    }}
                                    onSomeOtherError={(err) => {
                                        console.log("Some other error", err);
                                        sendWardenEvent({
                                            type: WARDEN_EVENT_VIDEO_NOT_SUPPORTED,
                                        });
                                        setWardenSetupComplete(true);
                                        setShowWardenVideoSetup(false);
                                    }}
                                    onNoDevicesFound={() => {
                                        sendWardenEvent({
                                            type: WARDEN_EVENT_VIDEO_NO_DEVICES,
                                        });
                                        setWardenSetupComplete(true);
                                        setShowWardenVideoSetup(false);
                                    }}
                                    onNotSupported={() => {
                                        sendWardenEvent({
                                            type: WARDEN_EVENT_VIDEO_NOT_SUPPORTED,
                                        });
                                        setWardenSetupComplete(true);
                                        setShowWardenVideoSetup(false);
                                    }}
                                    onComplete={(deviceId) => {
                                        updateWardenDevice(deviceId);
                                        setWardenSetupComplete(true);
                                        setShowWardenVideoSetup(false);
                                        setIsSkipable(false);
                                    }}
                                />
                            )}

                            {(wardenSetupComplete && (
                                <div className="text-sm text-emerald-500">
                                    <AnimatePresence show="1" initial={true}>
                                        Setup Complete <CheckBadgeIcon className="inline h-5 w-5" />
                                    </AnimatePresence>
                                </div>
                            )) ||
                                (!showWardenVideoSetup && (
                                    <Buttons.Button
                                        onClick={() => {
                                            setWardenInstructions(null);
                                            setShowWardenVideoSetup(true);
                                        }}
                                    >
                                        Setup Camera
                                    </Buttons.Button>
                                ))}
                        </div>

                        <div className="flex gap-x-2">
                            <Buttons.Button
                                color="lgreen"
                                disabled={wardenSetupComplete === false}
                                loading={isPerformingAction}
                                onClick={async () => {
                                    sendWardenEvent({
                                        event_type: WARDEN_EVENT_VIDEO_SETUP_COMPLETED,
                                    });
                                    const { data } = await performAction({
                                        cid,
                                        caid,
                                        action: "complete_warden_setup",
                                    });
                                }}
                            >
                                Continue
                            </Buttons.Button>
                            {isSkipable && (
                                <Buttons.Button
                                    className="ml-auto"
                                    color="slate"
                                    onClick={async () => {
                                        sendWardenEvent({
                                            event_type: WARDEN_EVENT_VIDEO_SETUP_SKIPPED,
                                        });
                                        const { data } = await performAction({
                                            cid,
                                            caid,
                                            action: "complete_warden_setup",
                                        });
                                    }}
                                >
                                    Skip
                                </Buttons.Button>
                            )}
                        </div>
                    </div>
                    <div className="col-span-1">
                        <CandidateExternalContact assessment={assessment} />
                    </div>
                </div>
            </div>
        </>
    );
}

const PERMISSION_SETUP_HELP = (
    <div className="lg:prose-md prose prose-slate">
        <h2>Permission Error</h2>
        <h5>
            SpikyWarden received a permission error when trying to access your camera. Please follow
            the instructions below to grant SpikyWarden access to your camera.
        </h5>

        <h2>Google Chrome</h2>
        <ol>
            <li>
                Open Google Chrome and click on the three-dot menu icon in the upper-right corner.
            </li>
            <li>Select "Settings" from the dropdown menu.</li>
            <li>Scroll down and click on "Privacy and Security" in the left-hand menu.</li>
            <li>Click on "Site Settings."</li>
            <li>
                Scroll down to "Permissions" and click on "Camera" to see which sites have
                permission to access your camera.
            </li>
            <li>
                To allow a site to access your camera, click on the site's name and select "Allow"
                from the dropdown menu.
            </li>
        </ol>
        <h2>Mozilla Firefox</h2>
        <ol>
            <li>
                Open Mozilla Firefox and click on the three-line menu icon in the upper-right
                corner.
            </li>
            <li>Select "Options" from the dropdown menu.</li>
            <li>Click on "Privacy & Security" in the left-hand menu.</li>
            <li>Scroll down to "Permissions" and click on "Settings" next to "Camera."</li>
            <li>
                To allow a site to access your camera, type in the site's URL and click on the
                "Allow" button.
            </li>
        </ol>
        <h2>Microsoft Edge</h2>
        <ol>
            <li>
                Open Microsoft Edge and click on the three-dot menu icon in the upper-right corner.
            </li>
            <li>Select "Settings" from the dropdown menu.</li>
            <li>Click on "Privacy, search, and services" in the left-hand menu.</li>
            <li>Click on "Site permissions" under the "Permissions" section.</li>
            <li>Click on "Camera" to see which sites have permission to access your camera.</li>
            <li>
                To allow a site to access your camera, click on the site's name and select "Allow"
                from the dropdown menu.
            </li>
        </ol>
        <p>
            Once you have set the permissions, please click the "Setup Camera" button to try again.
        </p>
    </div>
);
