import React from "react";

import CandidateExternalContact from "./CandidateExternalContact";
import { OrgContext } from "./OrgProvider";
import AcmeLogo from "./assets/img/acme.svg";
import FriendHRPersonImage from "./assets/img/friendly_hr_person.png";
import { Api, Buttons, Fields, Forms, Pills, Popovers, Utils, useContext } from "./tools";
import { useS3UploaderOrg } from "./useS3UploaderOrg";

export default function AccountBranding() {
    const [updateOrg, { isLoading: isUpdating }] = Api.useUpdateOrgMutation();
    const { org } = useContext(OrgContext);

    const {
        error: uploadError,
        startUpload,
        progress: uploadProgress,
        uploading,
    } = useS3UploaderOrg({});

    return (
        <div className="flex flex-col gap-y-4">
            <div className="flex items-center justify-between">
                <Pills.Pill>Branding</Pills.Pill>
                <Buttons.Button color="slater">Preview</Buttons.Button>
            </div>
            <div className="rounded-md bg-white p-5 shadow-md">
                <div className="grid grid-cols-5 gap-x-4">
                    <div className="col-span-1">
                        <Fields.ColorPickerFieldFree
                            label="Primary Brand Color"
                            value={org.accent_color}
                            onChange={(hex) => {
                                updateOrg({
                                    accent_color: hex,
                                });
                            }}
                        />
                    </div>
                    <div className="col-span-1 grid grid-cols-2">
                        <div className="relative col-span-2">
                            <div className="flex items-center justify-between">
                                <Popovers.PopoverLabel label="Contact Image">
                                    <div className="bg-white p-3 text-sm">
                                        This is displayed on the candidate-facing assessment page.
                                    </div>
                                </Popovers.PopoverLabel>
                                {org.contact_image && (
                                    <span
                                        className="mb-3 cursor-pointer text-xs uppercase"
                                        onClick={() => {
                                            updateOrg({
                                                contact_image: null,
                                            });
                                        }}
                                    >
                                        Reset
                                    </span>
                                )}
                            </div>
                            {org.contact_image ? (
                                <div
                                    className="flex h-32 flex-col items-center justify-end rounded-md"
                                    style={{
                                        backgroundColor: org.accent_color,
                                    }}
                                >
                                    <img
                                        className="h-full"
                                        src={org.contact_image}
                                        alt="Contact Image"
                                    />
                                </div>
                            ) : (
                                <Fields.UploadZoneFree
                                    className="h-32"
                                    onFileDrop={async (file) => {
                                        const form = new FormData();
                                        form.append("contact_image", file);
                                        await updateOrg(form, true);
                                    }}
                                    previewClassName="h-full"
                                />
                            )}
                        </div>
                        {/* <div className="col-span-1">
                            <Forms.GenericForm
                                initialValues={{
                                    contact_name: org.contact_name,
                                    contact_email: org.contact_email,
                                }}
                                mutation={Api.useUpdateOrgMutation}
                                fields={[
                                    {
                                        name: "contact_name",
                                        label: "Contact Name",
                                        type: "text",
                                    },
                                    {
                                        name: "contact_email",
                                        label: "Contact Email",
                                        type: "email",
                                    },
                                ]}
                            />
                        </div> */}
                    </div>
                    <div className="col-span-2">
                        <div className="flex items-center justify-between">
                            <Popovers.PopoverLabel label="Logo">
                                <div className="bg-white p-3 text-sm">
                                    This is displayed on the candidate-facing assessment page.
                                </div>
                            </Popovers.PopoverLabel>
                            {org.logo && (
                                <span
                                    className="mb-3 cursor-pointer text-xs uppercase"
                                    onClick={() => {
                                        updateOrg({
                                            logo: null,
                                        });
                                    }}
                                >
                                    Reset
                                </span>
                            )}
                        </div>

                        {org.logo ? (
                            <div className="flex h-32 items-center justify-center rounded-md bg-slate-50">
                                <img className="h-1/2" src={org.logo} alt="Logo" />
                            </div>
                        ) : (
                            <Fields.UploadZoneFree
                                className="h-32"
                                onFileDrop={async (file) => {
                                    const form = new FormData();
                                    form.append("logo", file);
                                    await updateOrg(form, true);
                                }}
                                previewClassName="h-1/2"
                            />
                        )}
                    </div>
                    <div className="col-span-4"></div>
                </div>
            </div>
        </div>
    );
}
