import { Disclosure as HeadlessDisclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";

import { AnimatePresence, classNames } from "./tools";

export function Disclosure({ panels = [], open = false }) {
    return (
        <HeadlessDisclosure defaultOpen={open}>
            {({ open }) => (
                <>
                    {panels.map((panel, index) => (
                        <Fragment key={index}>
                            <HeadlessDisclosure.Button
                                className={classNames(
                                    panel.bgColor || "bg-slate-50",
                                    "flex items-center justify-between p-3 text-left text-xs text-slate-600 ring-1",
                                    open ? "ring-2 ring-slate-400" : "",
                                )}
                            >
                                {panel.title}
                                <ChevronRightIcon
                                    className={`${
                                        open ? "rotate-90 transform" : ""
                                    } h-5 w-5 text-emerald-500`}
                                />
                            </HeadlessDisclosure.Button>
                            <HeadlessDisclosure.Panel className="text-gray-500">
                                {panel.content}
                            </HeadlessDisclosure.Panel>
                        </Fragment>
                    ))}
                </>
            )}
        </HeadlessDisclosure>
    );
}

export function DisclosureUnstyled({ panels = [], open = false }) {
    return (
        <HeadlessDisclosure defaultOpen={open}>
            {({ open }) => (
                <>
                    {panels.map((panel, index) => (
                        <Fragment key={index}>
                            <HeadlessDisclosure.Button
                                as="div"
                                className={classNames(panel.btnClassName, "cursor-pointer")}
                            >
                                {panel.title}
                            </HeadlessDisclosure.Button>
                            <AnimatePresence show={open} initial={true}>
                                <HeadlessDisclosure.Panel
                                    as="div"
                                    className={classNames(panel.panelClassName, "")}
                                >
                                    {panel.content}
                                </HeadlessDisclosure.Panel>
                            </AnimatePresence>
                        </Fragment>
                    ))}
                </>
            )}
        </HeadlessDisclosure>
    );
}
