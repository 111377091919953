import React, { useEffect, useRef } from "react";

import AudioDeviceTest from "./AudioDeviceTest";
import Chime from "./assets/sound/chime.mp3";
import { Buttons, Fields, Utils, classNames, constants, useState } from "./tools";

const AudioDeviceSelector = ({
    onSelectInputDevice,
    onSelectOutputDevice,
    onNoDevicesFound,
    onPermissionError,
    onSomeOtherError,
    onNotSupported,
    onSetupComplete,
    onlyShowAudioInput,
    withConfirm,
}) => {
    const [devices, setDevices] = useState([]);
    const [selectedInputDevice, setSelectedInputDevice] = useState(null);
    const [selectedOutputDevice, setSelectedOutputDevice] = useState(null);

    const getUserMedia = async () => {
        const constraints = {
            audio: true,
            video: false,
        };
        try {
            // we do this, because we want to get the devices before we get the stream
            await navigator.mediaDevices.getUserMedia(constraints);
            navigator.mediaDevices.enumerateDevices(constraints).then((devices) => {
                let devs = devices.filter(
                    (device) =>
                        device.label != "" &&
                        (device.kind === "audioinput" || device.kind === "audiooutput"),
                );
                if (Utils.isSafari() || Utils.isFirefox()) {
                    // we don't have access to the output device, so add a Defalt System Speaker
                    devs.push({
                        deviceId: "Default",
                        groupId: "Default",
                        kind: "audiooutput",
                        label: "Default System Speaker",
                    });
                }
                setDevices(devs);

                // set default input device
                const defaultInputDevice = devs.find(
                    (device) => device.kind === "audioinput" && device.label != "",
                );
                if (defaultInputDevice) {
                    setSelectedInputDevice(defaultInputDevice.deviceId);
                    onSelectInputDevice?.(defaultInputDevice.deviceId);
                }

                // set default output device
                const defaultOutputDevice = devs.find(
                    (device) => device.kind === "audiooutput" && device.label != "",
                );
                if (defaultOutputDevice) {
                    setSelectedOutputDevice(defaultOutputDevice.deviceId);
                    onSelectOutputDevice?.(defaultOutputDevice.deviceId);
                }
            });
        } catch (err) {
            switch (err.name) {
                case "NotFoundError":
                    onNoDevicesFound?.(err);
                    break;
                case "SecurityError":
                case "PermissionDeniedError":
                case "NotAllowedError":
                    onPermissionError?.(err);
                    break;
                default:
                    onSomeOtherError?.(err);
                    break;
            }
        }
    };

    const canIUse = () => {
        return (
            navigator.mediaDevices &&
            navigator.mediaDevices.getUserMedia &&
            navigator.mediaDevices.enumerateDevices
        );
    };

    useEffect(() => {
        if (!canIUse()) {
            onNotSupported?.();
        } else {
            getUserMedia();
        }
    }, []);

    useEffect(() => {
        if (selectedInputDevice) {
            onSelectInputDevice?.(selectedInputDevice);
        }
    }, [selectedInputDevice]);

    useEffect(() => {
        if (selectedOutputDevice) {
            onSelectOutputDevice?.(selectedOutputDevice);
        }
    }, [selectedOutputDevice]);

    useEffect(() => {
        // if we have both, mark setup as complete
        if (!withConfirm && selectedInputDevice && selectedOutputDevice) {
            onSetupComplete?.();
        }
    }, [selectedInputDevice, selectedOutputDevice]);

    const testAudioOutput = async (deviceId) => {
        // Create an audio context
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();

        // Load the MP3 sound file
        const response = await fetch(Chime);
        const arrayBuffer = await response.arrayBuffer();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        // Create an audio source node and set its buffer to the loaded sound
        const source = audioContext.createBufferSource();
        source.buffer = audioBuffer;

        // Create a destination (output) node using the specified deviceId
        const destination = audioContext.createMediaStreamDestination();
        const audio = new Audio();
        if (typeof audio.setSinkId === "function") {
            audio
                .setSinkId(deviceId)
                .then(() => {
                    audio.srcObject = destination.stream;
                    audio.play();
                })
                .catch((error) => {
                    console.error("Error setting output device:", error);
                });
        } else {
            audio.srcObject = destination.stream;
            audio.play();
        }

        // Connect the source to the destination and start playback
        source.connect(destination);
        source.start(0);
    };

    if (!selectedInputDevice) {
        return <div>Loading...</div>;
    }

    return (
        <div className="flex flex-col gap-y-4 bg-slate-100 p-5">
            <div className="flex justify-end">
                <Buttons.Button color="slater" onClick={getUserMedia}>
                    Refresh
                </Buttons.Button>
            </div>

            <Fields.SelectFieldFree
                label="Microphone"
                options={devices
                    .filter((device) => device.kind === "audioinput")
                    .map((device) => ({
                        label: device.label,
                        value: device.deviceId,
                    }))}
                onChange={(e) => setSelectedInputDevice(e.target.value)}
            />

            <div className="">
                <div>
                    <AudioDeviceTest
                        inputDeviceId={selectedInputDevice}
                        outputDeviceId={selectedOutputDevice}
                    />
                </div>
            </div>

            {onlyShowAudioInput ? null : (
                <>
                    <Fields.SelectFieldFree
                        label="Speaker"
                        options={devices
                            .filter((device) => device.kind === "audiooutput")
                            .map((device) => ({
                                label: device.label,
                                value: device.deviceId,
                            }))}
                        onChange={(e) => setSelectedOutputDevice(e.target.value)}
                    />

                    <div className="flex justify-between">
                        <Buttons.Button
                            className="bg-slate-200"
                            color="slate"
                            variant="solidXS"
                            onClick={() => {
                                testAudioOutput(selectedOutputDevice);
                            }}
                        >
                            Test Speaker
                        </Buttons.Button>
                    </div>
                </>
            )}

            {withConfirm && (
                <div>
                    <Buttons.Button
                        onClick={() => {
                            onSetupComplete?.(selectedInputDevice, selectedOutputDevice);
                        }}
                        color="lgreen"
                    >
                        Continue
                    </Buttons.Button>
                </div>
            )}
        </div>
    );
};

export default AudioDeviceSelector;
