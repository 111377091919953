import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { AnimatePresence, motion } from "framer-motion";
import { Fragment, useEffect } from "react";

import { Appear, Buttons, classNames, useRef, useState } from "./tools";

const DIALOG_SIZES = {
    sm: "max-w-sm",
    md: "max-w-md",
    lg: "max-w-lg w-full",
    xl2: "max-w-2xl w-full",
    xl4: "max-w-4xl w-full",
    xl7: "max-w-7xl w-full",
    full: "h-screen w-full",
};

const ANIMATION_STYLES = {
    opacityAndScale: {
        initial: {
            opacity: 0,
            scale: 0.75,
        },
        animate: {
            opacity: 1,
            scale: 1,
            transition: {
                ease: "easeOut",
                duration: 0.15,
            },
        },
        exit: {
            opacity: 0,
            scale: 0.75,
            transition: {
                ease: "easeIn",
                duration: 0.15,
            },
        },
    },
    opacity: {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
            transition: {
                ease: "easeOut",
                duration: 0.15,
            },
        },
        exit: {
            opacity: 0,
            transition: {
                ease: "easeIn",
                duration: 0.15,
            },
        },
    },
};

export function Dialog({
    children,
    isOpen,
    setIsOpen,
    size = "full",
    animate = "opacity",
    dialogPanelClassName = "",
    initialFocus = true,
    withScroll = true,
    withPadding = true,
}) {
    const [showCloseButton, setShowCloseButton] = useState(true);
    const dialogPanelRef = useRef();
    const [needScroll, setNeedScroll] = useState(false);

    // we need a scrollbar if dialogPanelRef is taller than the window
    const determineScroll = () => {
        // dont need scroll if it's hidden
        if (!isOpen) return;
        const s = dialogPanelRef.current?.offsetHeight > window.innerHeight;
        setNeedScroll(s);
    };

    useEffect(() => {
        if (isOpen) {
            determineScroll();
        }
    }, [isOpen]);

    return (
        <AnimatePresence initial={true}>
            {isOpen && (
                <HeadlessDialog
                    initialFocus={initialFocus}
                    static
                    className="relative z-10"
                    as={motion.div}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    open={isOpen}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                >
                    {/* Backdrop */}
                    <div className={classNames("fixed inset-0 bg-black bg-opacity-25")} />

                    <div
                        className={classNames(
                            "fixed inset-0",
                            needScroll ? "overflow-y-scroll" : "overflow-hidden-maybe",
                        )}
                        onMouseEnter={() => setShowCloseButton(true)}
                        onMouseLeave={() => setShowCloseButton(false)}
                    >
                        <div className="relative flex min-h-full flex-col items-center justify-center p-5">
                            <div
                                className={classNames(
                                    "absolute right-0 top-0",
                                    showCloseButton ? "visible" : "invisible",
                                )}
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                            >
                                <XMarkIcon className="h-6 w-6 cursor-pointer rounded-bl-lg bg-slate-50 text-gray-300" />
                            </div>
                            <HeadlessDialog.Panel
                                ref={dialogPanelRef}
                                as={motion.div}
                                initial={{
                                    opacity: 0,
                                    scale: 1,
                                }}
                                {...ANIMATION_STYLES[animate]}
                                className={classNames(
                                    DIALOG_SIZES[size],
                                    "transform rounded-md bg-white align-middle shadow-xl transition-all",
                                    withScroll ? "overflow-y-scroll" : "overflow-hidden-maybe",
                                    withPadding ? "p-5" : "",
                                    dialogPanelClassName,
                                )}
                            >
                                {children}
                            </HeadlessDialog.Panel>
                        </div>
                    </div>
                </HeadlessDialog>
            )}
        </AnimatePresence>
    );
}
