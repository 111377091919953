import React from "react";

import { authApi } from "./store/auth";
import { Buttons, Forms, useParams, useState } from "./tools";

export default function ResetPasswordConfirm() {
    const [success, setSuccess] = useState(false);
    const [nonFieldError, setNonFieldError] = useState(null);
    const { token } = useParams();

    if (success) {
        return (
            <div className="space-y-4">
                <div className="prose-md prose">
                    <h1>Reset Password</h1>
                    <p>Your password has been reset. You may now sign in.</p>
                </div>
                <Buttons.Button color="lgreen" href="/auth/login">
                    Sign In
                </Buttons.Button>
            </div>
        );
    }

    return (
        <div className="space-y-4">
            {nonFieldError && (
                <div className="relative rounded py-3 text-sm text-red-700">{nonFieldError}</div>
            )}
            <div className="prose-md prose">
                <h1>Reset Password</h1>
                <p>Enter your new password.</p>
            </div>
            <div>
                <Forms.GenericForm
                    initialValues={{
                        action: "reset_confirm",
                        uprr: token,
                        new_password: "",
                    }}
                    fields={[
                        {
                            label: "Password",
                            name: "new_password",
                            type: "password",
                            showStrengthIndicator: true,
                        },
                    ]}
                    mutation={authApi.useResetPasswordMutation}
                    onSuccess={() => {
                        setSuccess(true);
                    }}
                    onError={(err) => {
                        console.log(err);
                        setNonFieldError(err?.data?.uprr?.[0] || "An unknown error occurred.");
                    }}
                />
            </div>
        </div>
    );
}
