import React from "react";

import { Api, Buttons, OrgLimitsContext, Progress, Spinner, useContext } from "./tools";

export default function AccountSeats() {
    const { limits } = useContext(OrgLimitsContext);
    const {
        data: users,
        isLoading,
        error,
        refetch: getUsers,
    } = Api.useGetOrgUsersQuery(
        { search: null },
        {
            refetchOnFocus: true,
        },
    );

    if (isLoading) {
        return <Spinner />;
    }

    const seats = users?.length || 0;
    const maxSeats = limits?.seats || 5;

    const showUpgrade = seats >= maxSeats;

    return (
        <div className="space-y-2">
            <div className="flex items-center justify-between">
                <div>Seats</div>
                {showUpgrade && (
                    <Buttons.Button variant="solidXS" color="slater" href="/upgrade">
                        Upgrade
                    </Buttons.Button>
                )}
            </div>
            <div className="flex h-24 flex-col justify-between">
                <div className="text-xl font-semibold tracking-wider">
                    {seats}/{maxSeats}
                </div>
                <Progress.ProgressBar
                    color="emerald"
                    size="xs"
                    value={seats >= maxSeats ? maxSeats : seats}
                    max={maxSeats}
                    labelPosition="hidden"
                />
            </div>
        </div>
    );
}
