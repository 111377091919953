import { createSlice } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import localForage from "localforage";
import { persistReducer } from "redux-persist";

import {
    invalidateTagById,
    invalidatesList,
    provideTagById,
    providesList,
    providesListUnpaginated,
} from "../utils";

export const API_CANDIDATE_PERSIST_REDUCER_KEY = "apiCandidatePersist";
const initialState = {
    warden: {
        deviceId: null,
        ip: null,
        location: null,
        userAgent: null,
    },
};
export const apiCandidatePersistSlice = createSlice({
    name: API_CANDIDATE_PERSIST_REDUCER_KEY,
    initialState,
    reducers: {
        updateWardenDevice(state, action) {
            state.warden.deviceId = action.payload.deviceId;
        },
    },
});

export const apiCandidatePersistReducer = persistReducer(
    {
        key: "rtk:candidate",
        storage: localForage,
        whitelist: ["warden"],
    },
    apiCandidatePersistSlice.reducer,
);

export const API_CANDIDATE_REDUCER_KEY = "apiCandidate";
export const apiCandidateSlice = createApi({
    reducerPath: API_CANDIDATE_REDUCER_KEY,
    baseQuery: fetchBaseQuery({
        baseUrl: "/api",
    }),
    tagTypes: ["Assessment", "AssessmentLink"],
    endpoints: (builder) => ({
        getAssessment: builder.query({
            query: ({ cid, caid }) => ({
                url: `c/${cid}/a/${caid}`,
                method: "GET",
            }),
            providesTags: (result, error, { caid }) => provideTagById("Assessment", caid),
        }),
        getAssessmentByLink: builder.query({
            query: ({ aid, lid }) => ({
                url: `l/${lid}/a/${aid}`,
                method: "GET",
            }),
            providesTags: (result, error, { lid }) => provideTagById("AssessmentLink", lid),
        }),
        performAssessmentAction: builder.mutation({
            query: ({ cid, caid, action, payload, tom = false }) => {
                return {
                    url: `${tom ? "tom/" : ""}c/${cid}/a/${caid}`,
                    method: "POST",
                    params: { action },
                    body: { ...payload },
                };
            },
            invalidatesTags: (result, error, { caid, invalidate = true }) => {
                if (invalidate) {
                    return invalidateTagById("Assessment", caid);
                }
            },
        }),
        performAssessmentLinkAction: builder.mutation({
            query: ({ aid, lid, action, payload }) => {
                return {
                    url: `l/${lid}/a/${aid}`,
                    method: "POST",
                    params: { action },
                    body: { ...payload },
                };
            },
            invalidatesTags: (result, error, { lid }) => {
                return invalidateTagById("AssessmentLink", lid);
            },
        }),
    }),
});

export const {
    useGetAssessmentQuery,
    usePerformAssessmentActionMutation,
    useGetAssessmentByLinkQuery,
    usePerformAssessmentLinkActionMutation,
} = apiCandidateSlice;
export const { updateWardenDevice } = apiCandidatePersistSlice.actions;
export const selectWarden = (state) => state[API_CANDIDATE_PERSIST_REDUCER_KEY].warden;
