import { Dialog, Switch, Tab, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

import {
    AnimatePresence,
    Api,
    Avatars,
    Buttons,
    Fields,
    Form,
    Formik,
    Link,
    Tabs,
    classNames,
    useNavigate,
} from "./tools";

export default function AssessmentCreate() {
    const [explain, setExplain] = useState(null);
    const navigate = useNavigate();

    const [createAssessment, { isLoading }] = Api.useCreateAssessmentMutation();

    return (
        <div className="flex flex-col">
            <main className="flex-1">
                <div className="relative">
                    <div className="pb-16">
                        <div className="px-4 sm:px-6 md:px-0">
                            <h5 className="text-3xl font-bold tracking-tight text-emerald-900">
                                Create new assessment
                            </h5>
                            <p className="mt-3 text-slate-500">
                                This wizard will guide you through the process of creating a new
                                assessment.
                            </p>
                        </div>

                        <Tabs
                            tabs={[
                                {
                                    name: "General",
                                    href: "#",
                                    current: true,
                                    component: (
                                        <div className="bg-slate-50 p-5 px-4">
                                            <div className="md:grid md:grid-cols-3 md:gap-6">
                                                <div className="md:col-span-1">
                                                    <div className="px-4 sm:px-0">
                                                        <h3 className="text-lg font-medium leading-6 text-slate-900">
                                                            General
                                                        </h3>
                                                        <p className="mt-1 text-sm text-slate-600">
                                                            General information about the
                                                            assessment.
                                                        </p>

                                                        <AnimatePresence show={explain != ""}>
                                                            <p className="border-1 border-dotted bg-slate-50 py-5 text-sm italic text-slate-500">
                                                                {explain}
                                                            </p>
                                                        </AnimatePresence>
                                                    </div>
                                                </div>
                                                <div className="mt-5 md:col-span-2 md:mt-0">
                                                    <div className="shadow sm:rounded-md">
                                                        <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                                                            <Formik
                                                                initialValues={{
                                                                    name: "",
                                                                }}
                                                                onSubmit={async (
                                                                    values,
                                                                    {
                                                                        setSubmitting,
                                                                        setFieldError,
                                                                    },
                                                                ) => {
                                                                    console.log(values);
                                                                    const result =
                                                                        await createAssessment({
                                                                            name: values.name,
                                                                            team_id: values.team_id,
                                                                        });
                                                                    setSubmitting(false);
                                                                    if (result.error) {
                                                                        for (const [
                                                                            key,
                                                                            value,
                                                                        ] of Object.entries(
                                                                            result.error.data,
                                                                        )) {
                                                                            setFieldError(
                                                                                key,
                                                                                value[0],
                                                                            );
                                                                        }
                                                                    } else {
                                                                        navigate(
                                                                            `/library/assessments/${result.data?.id}/edit?tab=modules`,
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                {({ isSubmitting }) => {
                                                                    return (
                                                                        <Form className="grid grid-cols-1 gap-y-8">
                                                                            <Fields.TextField
                                                                                type="text"
                                                                                name="name"
                                                                                label="Assessment name"
                                                                                onFocus={() => {
                                                                                    setExplain(
                                                                                        EXPLAIN_NAME,
                                                                                    );
                                                                                }}
                                                                                onBlur={() => {
                                                                                    setExplain("");
                                                                                }}
                                                                            />

                                                                            <Fields.ListBoxTeams name="team_id" />

                                                                            <div>
                                                                                <Buttons.Button
                                                                                    type="submit"
                                                                                    variant="solid"
                                                                                    color="lgreen"
                                                                                    disabled={
                                                                                        isLoading
                                                                                    }
                                                                                    loading={
                                                                                        isLoading
                                                                                    }
                                                                                    loadingMessage="Saving..."
                                                                                >
                                                                                    <span>
                                                                                        Save
                                                                                    </span>
                                                                                </Buttons.Button>
                                                                            </div>
                                                                        </Form>
                                                                    );
                                                                }}
                                                            </Formik>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </div>
                </div>
            </main>
        </div>
    );
}

export const EXPLAIN_NAME = (
    <>
        This will be visible on the assessment page and the email invitation.
        <br />
        <br />
        It is recommended to use a name that does not contain too much information about the
        assessment.
        <br />
        <br />
        Example: Accountant M&A 2023
    </>
);
