import { CheckBadgeIcon, CheckIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Link, NavLink } from "react-router-dom";

import { Api, Avatars, Buttons, Fields, classNames, constants } from "./tools";
import { useTasks } from "./useTasks";

export default function TasksListSidebar() {
    const { tasks, isLoading, error } = useTasks(
        1,
        {},
        {
            refetchOnMountOrArgChange: false,
            refetchOnFocus: false,
        },
    );

    const [completeTask, { isLoading: isCompletingTask, error: completeTaskError }] =
        Api.useCompleteCurrentUserTaskMutation();

    if (isLoading) {
        return null;
    }

    const renderTask = (task) => {
        switch (task.task_type) {
            case constants.TASK_TYPE_REVIEW_ASSESSMENT:
                const assessment = task.task_target;
                const candidate = assessment.candidate;
                return (
                    <NavLink
                        to={`/candidates/${candidate.id}?a=${assessment.id}&tab=reviews`}
                        className={({ isActive }) => {
                            return classNames(
                                "text-gray-700 hover:bg-gray-50 hover:text-emerald-600",
                                "group z-10 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                            );
                        }}
                    >
                        <div className="flex w-full items-center justify-between text-xs">
                            <div className="flex w-full gap-x-4 truncate">
                                <div className="flex-shrink-0">
                                    <Avatars.Avatar
                                        size="sm"
                                        hash={candidate.d}
                                        placeholder={candidate.initials}
                                    />
                                </div>
                                <div>
                                    <div className="text-slate-900">
                                        <span className="text-slate-500">Review</span>{" "}
                                        {candidate.first_name}
                                    </div>
                                    <div className="text-slate-500">
                                        <span>{assessment.assessment.name}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="z-20 ml-auto flex items-center justify-center">
                                <Buttons.Button
                                    color="transparent"
                                    className="py-0 px-0"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        completeTask(task.id);
                                    }}
                                >
                                    <CheckIcon className="h-4 w-4 text-emerald-600" />
                                </Buttons.Button>
                            </div>
                        </div>
                    </NavLink>
                );
            // case constants.TASK_TYPE_REVIEW_INTERVIEW:
            //     return null;
        }
    };

    if (tasks.count == 0) {
        return null;
    }

    return (
        <li>
            <Link to="/tasks" className="text-xs font-semibold leading-6 text-gray-400">
                Tasks
            </Link>
            <ul role="list" className="-mx-2 mt-2 space-y-1">
                {tasks?.results?.slice(0, 5).map((task) => (
                    <li key={task.id} className="w-full cursor-default">
                        {renderTask(task)}
                    </li>
                ))}
            </ul>
        </li>
    );
}
