import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Appear from "./Appear";
import GhibliImage5 from "./assets/img/ghibli5.png";
import { useDeleteModuleMutation, useGetModulesQuery } from "./store/api";
import { Link, classNames } from "./tools";

const { Api, useParams, Buttons, Fields, useDebounce, constants, Pagination } = require("./tools");

export function ModuleListInline({ onClickAdd, ignoreIds = [] }) {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 500);

    const {
        data: modules,
        isLoading,
        error,
        refetch: getModules,
    } = useGetModulesQuery(
        { page: page, search: debouncedSearch },
        {
            refetchOnMountOrArgChange: true,
            refetchOnFocus: true,
        },
    );

    return (
        <div className="grid grid-cols-12 gap-x-12 bg-white">
            <div className="col-span-12 pb-20">
                <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-3 flex items-center justify-between">
                        <div className="text-sm font-medium text-slate-900">
                            <Fields.TextFieldFree
                                className="w-full"
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                                placeholder="Search..."
                            />
                        </div>

                        <div className="flex items-center gap-x-4">
                            <Buttons.Button href="/library/modules/create">
                                Create Module
                            </Buttons.Button>
                        </div>
                    </div>
                    {modules?.results
                        .filter((module) => !ignoreIds.includes(module.id))
                        .map((module) => (
                            <ModuleListInlineSingle
                                onClickAdd={onClickAdd}
                                key={module.id}
                                module={module}
                            />
                        ))}
                    <div className="col-span-full">
                        <Pagination.PaginationGeneric
                            page={page}
                            setPage={setPage}
                            count={modules?.count}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ModuleListInlineSingle({
    module,
    onClickAdd,
    onClickDelete,
    onClickEdit,
    draggable = false,
}) {
    return (
        <div
            className={classNames(
                "relative overflow-hidden rounded-md bg-indigo-200 px-4 pt-5 pb-12 shadow-md hover:shadow-md sm:px-6 sm:pt-6",
                draggable ? "cursor-move" : "",
            )}
        >
            <div>
                <div className="absolute rounded-md bg-slate-200 p-3">
                    <img
                        src={constants.MODULE_TYPE_ICONS[module.module_type]}
                        className="h-6 w-6"
                    />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-slate-600">
                    {constants.MODULE_TYPE_LABELS[module.module_type]}
                </p>
            </div>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <div className="truncate pb-6 text-xl font-semibold text-slate-700">
                    {module.name}
                </div>

                <div className="absolute inset-x-0 bottom-0 bg-slate-50 px-4 py-4">
                    <div className="flex gap-x-2">
                        {onClickAdd && (
                            <Buttons.Button
                                onClick={() => {
                                    onClickAdd?.(module);
                                }}
                                color="slate"
                            >
                                Add to Assessment
                            </Buttons.Button>
                        )}
                        {onClickDelete && (
                            <Buttons.Button
                                className="bg-slate-200"
                                onClick={() => {
                                    onClickDelete?.(module);
                                }}
                                color="slate"
                            >
                                Remove from Assessment
                            </Buttons.Button>
                        )}
                        {onClickEdit && (
                            <Buttons.Button
                                className="bg-slate-200"
                                onClick={() => {
                                    onClickEdit?.(module);
                                }}
                                color="slate"
                            >
                                Edit Module
                            </Buttons.Button>
                        )}
                    </div>
                </div>
            </dd>
        </div>
    );
}
