import AssessmentList from "./AssessmentList";
import DashboardNewAccount from "./DashboardNewAccount";
import DashboardStats from "./DashboardStats";
import {
    AnimatePresence,
    Api,
    UserContext,
    classNames,
    useContext,
    useNavigate,
    useState,
} from "./tools";

export default function Dashboard() {
    const { user } = useContext(UserContext);

    return (
        <div className="space-y-8">
            <DashboardNewAccount />
        </div>
    );
}
