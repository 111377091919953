import React from "react";

import LogoNew from "./assets/img/cactus-hire-new.svg";
import { authApi, authSlice } from "./store/auth";
import {
    Api,
    Buttons,
    Fields,
    Form,
    Formik,
    Spinner,
    Utils,
    Yup,
    classNames,
    useDispatch,
    useNavigate,
    useParams,
    useState,
} from "./tools";

function StepOrgDetails({ onAdvance, orgLead }) {
    const [nonFieldError, setNonFieldError] = useState(null);
    const { olid } = useParams();
    const [register, { isLoading, error }] = authApi.useRegisterMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isSSORegister = orgLead?.idp != null;

    return (
        <div className="space-y-8">
            <div className="prose prose-sm">
                <h2>Welcome to Cactus</h2>
                <p>You're one minute away from finishing your set up.</p>
            </div>

            <Formik
                initialValues={{
                    org_name: "",
                    first_name: orgLead.first_name || "",
                    last_name: orgLead.last_name || "",
                    password: isSSORegister ? Utils.generateRandomString() : "",
                }}
                validationSchema={Yup.object().shape({
                    org_name: Yup.string()
                        .required("Please enter your organization name")
                        .min(3, "Organization name must be at least 3 characters")
                        .max(100, "Organization name must be less than 100 characters")
                        .matches(
                            /^(?!.*test.*)(?!.*demo.*)/i,
                            "Please enter a valid organization name",
                        ),

                    first_name: Yup.string().required("Please enter your first name"),
                    last_name: Yup.string().required("Please enter your last name"),
                    password: Yup.string().required("Please enter your password"),
                })}
                onSubmit={async (values, { setSubmitting, setFieldError }) => {
                    setNonFieldError(null);
                    try {
                        const result = await register({
                            action: "verify_and_create_org",
                            olid: olid,
                            org_name: values.org_name,
                            first_name: values.first_name,
                            last_name: values.last_name,
                            password: values.password,
                        });
                        setSubmitting(false);
                        if (result.error) {
                            if (result.error.data.detail) {
                                setNonFieldError(result.error.data.detail);
                            }
                            for (const [key, value] of Object.entries(result.error.data)) {
                                setFieldError(key, value[0]);
                            }
                        }
                        if (result.data) {
                            dispatch(authSlice.actions.updateTokens(result.data));
                            onAdvance?.();
                            navigate("/onboarding/continue");
                        }
                    } catch (err) {
                        console.log(err);
                        setSubmitting(false);
                    }
                }}
            >
                <Form className="grid grid-cols-2 gap-y-8 gap-x-6">
                    <Fields.TextField
                        className="col-span-full"
                        id="org_name"
                        type="text"
                        name="org_name"
                        label="Organization name"
                    />
                    <Fields.TextField
                        label="First name"
                        id="first_name"
                        name="first_name"
                        type="text"
                        autoComplete="given-name"
                        required
                    />
                    <Fields.TextField
                        label="Last name"
                        id="last_name"
                        name="last_name"
                        type="text"
                        autoComplete="family-name"
                        required
                    />

                    {!isSSORegister && (
                        <Fields.TextField
                            type="password"
                            name="password"
                            label="Password"
                            showStrengthIndicator={true}
                        />
                    )}

                    <div className="col-span-full space-y-2">
                        <Buttons.Button
                            type="submit"
                            variant="solid"
                            color="lgreen"
                            loading={isLoading}
                        >
                            <span>Continue</span>
                        </Buttons.Button>

                        {nonFieldError && (
                            <div className="text-sm text-red-600">{nonFieldError}</div>
                        )}
                    </div>
                </Form>
            </Formik>
        </div>
    );
}

const steps = [
    {
        id: "Step 1",
        name: "Organization",
        status: "current",
        component: StepOrgDetails,
    },
    { id: "Step 2", name: "SSO", status: "upcoming", component: null },
    { id: "Step 3", name: "Team", status: "upcoming", component: null },
    { id: "Step 4", name: "Welcome", status: "upcoming", component: null },
];

export default function Onboarding() {
    const { olid } = useParams();
    const [step, setStep] = useState(0);
    const navigate = useNavigate();
    const params = useParams();

    const { data: orgLead, isLoading, error } = authApi.useGetOrgLeadQuery(params.olid);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const current = steps[step];
    const CurrentComponent = current.component;

    return (
        <div>
            <div className="grid h-screen items-center  bg-slate-50">
                <div className="mx-auto w-full max-w-4xl space-y-8 border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10">
                    <div>
                        <img src={LogoNew} alt="Cactus Hire" className="h-6" />
                    </div>
                    <div>{CurrentComponent && <CurrentComponent orgLead={orgLead} />}</div>
                    <nav aria-label="Progress">
                        <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
                            {steps.map((step, i) => (
                                <li key={step.name} className="md:flex-1">
                                    <div
                                        className={classNames(
                                            "group flex flex-col border-l-4 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4",
                                            step.status === "complete"
                                                ? "border-emerald-300 opacity-60 group-hover:border-emerald-300"
                                                : step.status === "current"
                                                ? "border-emerald-300"
                                                : "border-slate-200 opacity-60 group-hover:border-slate-300",
                                        )}
                                        aria-current={
                                            step.status === "current" ? "step" : undefined
                                        }
                                    >
                                        <span
                                            className={classNames(
                                                "text-sm font-medium",
                                                step.status === "complete"
                                                    ? "text-emerald-300 opacity-60 group-hover:text-emerald-300"
                                                    : step.status === "current"
                                                    ? "text-emerald-300"
                                                    : "text-slate-500 opacity-60 group-hover:text-slate-700",
                                            )}
                                        >
                                            {step.name}
                                        </span>
                                    </div>
                                </li>
                            ))}
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    );
}
