import axios from "axios";
import { useRef, useState } from "react";

import { Api, Utils, _ } from "./tools";

export function useS3UploaderOrg() {
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(null);
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(0);

    const [createOrgSignedURL, { isLoading, error: createOrgSignedURLError }] =
        Api.useCreateOrgSignedURLMutation();

    const throttledSetProgress = useRef(
        _.throttle((percentage) => {
            setProgress(percentage);
        }, 200),
    ).current;

    const startUpload = async (blob, folder, fileName, options = {}) => {
        const {
            contentType = blob ? blob.type : "text/plain",
            acl = "private",
            action = "put_object",
        } = options;

        try {
            setUploading(true);
            const result = await createOrgSignedURL({
                file_name: fileName,
                content_type: contentType,
                folder: folder,
                acl: acl,
                action: action,
            });
            const proxy = Utils.isLocalhost() ? "http://localhost:30080/" : "";
            const signedUrl = result?.data?.signed_url;

            await axios.put(proxy + signedUrl, blob, {
                headers: {
                    "Content-Type": contentType,
                },
                onUploadProgress: (event) => {
                    const percentage = Math.round((event.loaded * 100) / event.total);
                    throttledSetProgress(percentage);
                },
            });

            setUploaded({
                object_key: result?.data?.object_key,
            });

            return result?.data?.object_key;
        } catch (err) {
            setError(err);
        } finally {
            setUploading(false);
        }
    };

    return { uploading, uploaded, error, progress, startUpload };
}
