import { CandidateAssessmentContext } from "./CandidateAssessmentProvider";
import CandidateReviewModules from "./CandidateReviewModules";
import CandidateReviewWIP from "./CandidateReviewWIP";
import { Pills, useContext } from "./tools";

export default function CandidateReview({}) {
    const { assessment } = useContext(CandidateAssessmentContext);

    return (
        <div>
            <div className="grid grid-cols-4 gap-x-4">
                <div style={{ minHeight: 500 }} className="col-span-3 bg-slate-50 p-5 shadow-md">
                    {assessment.completed_at ? <CandidateReviewModules /> : <CandidateReviewWIP />}
                </div>

                <div className="col-span-1 grid gap-y-4">
                    <div style={{ minHeight: 200 }} className="bg-slate-50 p-5 shadow-md"></div>
                </div>
            </div>
        </div>
    );
}
