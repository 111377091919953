import { Api, Forms, classNames } from "./tools";

export default function UserCreateBulkForm({ onSuccess, submitButtonClassname, withAddTeamLink }) {
    return (
        <Forms.GenericForm
            submitButtonClassname={classNames("", submitButtonClassname)}
            onSuccess={onSuccess}
            submitButtonLabel="Invite Users"
            fields={[
                {
                    name: "emails_bulk",
                    label: "Email (one per line)",
                    type: "textarea",
                    rows: 5,
                },
                {
                    name: "team_id",
                    label: "Team",
                    type: "team",
                    withAddTeamLink: withAddTeamLink,
                },
            ]}
            mutation={Api.useCreateOrgUserMutation}
        />
    );
}
