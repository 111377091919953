import { Cards, Pills, classNames } from "./tools";

export function StatComponent({ icon: Icon, title, subtitle, value, change, changeType }) {
    return (
        <Cards.Card className="p-3">
            <div className="flex items-center space-x-4">
                {Icon && (
                    <div className="flex">
                        <div className="flex flex-1 rounded-md bg-emerald-200 p-3">
                            <Icon className="h-10 w-10 text-emerald-600" aria-hidden="true" />
                        </div>
                    </div>
                )}

                <div className="flex flex-1 flex-col justify-between">
                    <p className="truncate text-sm font-medium text-slate-500">{title}</p>
                    {subtitle && (
                        <p className="truncate text-xs font-medium text-slate-400">{subtitle}</p>
                    )}

                    <div className="flex items-center gap-x-2">
                        <div className="text-2xl font-semibold text-slate-900">{value}</div>
                        <div
                            className={`ml-2 flex items-baseline text-sm font-semibold ${
                                changeType === "increase" ? "text-green-600" : "text-red-600"
                            }`}
                        >
                            {changeType === "increase" ? "" : ""}
                            {change}
                        </div>
                    </div>
                </div>
            </div>
        </Cards.Card>
    );
}

export function StatNumberComponent({ title, subtitle, value, change, changeType }) {
    return (
        <Cards.Card className="p-3">
            <div className="flex items-center space-x-4">
                <div className="flex">
                    <div className="flex flex-1 items-center justify-center rounded-lg border border-slate-300 bg-slate-100 p-3">
                        <div className="text-2xl font-semibold text-slate-900">{value}</div>
                    </div>
                </div>

                <div className="flex flex-1 flex-col justify-between">
                    <p className="truncate text-sm font-medium text-slate-500">{title}</p>
                    {subtitle && (
                        <p className="truncate text-xs font-medium text-slate-400">{subtitle}</p>
                    )}

                    <div className="mt-1 flex items-center gap-x-2">
                        <div
                            className={`flex items-baseline text-xs font-semibold ${
                                changeType === "increase" ? "text-green-600" : "text-red-600"
                            }`}
                        >
                            {changeType === "increase" ? "" : ""}
                            {change}
                        </div>
                    </div>
                </div>
            </div>
        </Cards.Card>
    );
}
