import React from "react";

import { Api, Buttons, Forms, classNames } from "./tools";

export default function TeamCreateForm({ onSuccess }) {
    return (
        <Forms.GenericForm
            onSuccess={onSuccess}
            submitButtonLabel="Create Team"
            fields={[
                {
                    name: "name",
                    label: "Team name",
                    type: "text",
                },
            ]}
            mutation={Api.useCreateTeamMutation}
        />
    );
}
