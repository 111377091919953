import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Appear from "./Appear";
import { ButtonActionsDropdown } from "./Button";
import { Popover } from "./Popover";
import { useCreateModuleMutation, useDeleteModuleMutation, useGetModulesQuery } from "./store/api";
import {
    Avatars,
    Link,
    Tables,
    UserContext,
    Utils,
    constants,
    useContext,
    useDebounce,
} from "./tools";

export default function ModulesList() {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(null);
    const debouncedSearch = useDebounce(search, 200);
    const { user } = useContext(UserContext);
    const {
        data: modules,
        isLoading,
        error,
        refetch: getModules,
    } = useGetModulesQuery(
        { page: page, search: debouncedSearch },
        {
            refetchOnMountOrArgChange: true,
            refetchOnFocus: true,
        },
    );

    const [deleteModule, { isLoading: isDeleting }] = useDeleteModuleMutation();
    const [createModule, { isLoading: isCreating }] = useCreateModuleMutation();
    const navigate = useNavigate();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    const getActions = (module) => {
        const actions = [
            {
                label: "Delete",
                confirm: {
                    title: "Delete module",
                    description: `Are you sure you want to delete ${module.name}? This action cannot be undone.`,
                },
                onClick: async () => {
                    await deleteModule(module.id);
                },
            },
        ];

        if (Utils.isStaff(user) || module.module_type != constants.MODULE_TYPE_CONVERSATIONAL) {
            actions.unshift({
                label: "Edit",
                onClick: () => {
                    navigate(`/library/modules/${module.id}`);
                },
            });
        }
        return <ButtonActionsDropdown actions={actions} />;
    };

    return (
        <Tables.TablePaginatedServer
            title="Modules"
            showHeader={false}
            total={modules?.count}
            goToPage={setPage}
            page={page}
            withSearch={setSearch}
            rows={modules?.results.map((module) => {
                return {
                    key: module.id,
                    cells: [
                        {
                            key: "name",
                            content: (
                                <Link to={`/library/modules/${module.id}`}>
                                    <div className="flex items-center space-x-3">
                                        <div className="flex-shrink-0">
                                            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-slate-50">
                                                <img
                                                    className="h-6 w-6"
                                                    src={
                                                        constants.MODULE_TYPE_ICONS[
                                                            module.module_type
                                                        ]
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text-gray-900">{module.name}</div>
                                            <div className="text-gray-500">
                                                {constants.MODULE_TYPE_LABELS[module.module_type]}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ),
                        },
                        {
                            key: "team",
                            content: (
                                <div className="flex items-center space-x-3">
                                    <div className="flex-shrink-0">
                                        <Avatars.AvatarTeam team={module.team} />
                                    </div>
                                    <div>
                                        <div className="text-gray-900">{module.team.name}</div>
                                    </div>
                                </div>
                            ),
                        },
                        {
                            key: "actions",
                            className: "text-right",
                            content: getActions(module),
                        },
                    ],
                };
            })}
        />
    );
}
