import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    AnimatePresenceGrow,
    Api,
    Avatar,
    Avatars,
    Buttons,
    Dialog,
    Fields,
    Link,
    Spinner,
    Tables,
    Utils,
    classNames,
    constants,
    moment,
} from "./tools";
import { useTasks } from "./useTasks";

export default function TasksList({ filters = {}, title = "Tasks", withSearch = false }) {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(null);

    const updatedFilters = {
        search,
        ...filters,
    };
    const { tasks, isLoading, error } = useTasks(page, updatedFilters);

    const [completeTask, { isLoading: isCompletingTask, error: completeTaskError }] =
        Api.useCompleteCurrentUserTaskMutation();

    if (isLoading) {
        return null;
    }

    const renderTask = (task) => {
        switch (task.task_type) {
            case constants.TASK_TYPE_REVIEW_ASSESSMENT:
                const assessment = task.task_target;
                const candidate = assessment.candidate;
                return (
                    <Link
                        to={`/candidates/${candidate.id}?a=${assessment.id}&tab=reviews`}
                        className={classNames(
                            "text-gray-700 hover:bg-gray-50 hover:text-emerald-600",
                            "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                        )}
                    >
                        <div className="flex w-full items-center justify-between text-xs">
                            <div className="flex w-full gap-x-4 truncate">
                                <div className="flex-shrink-0">
                                    <Avatars.Avatar
                                        size="sm"
                                        hash={candidate.d}
                                        placeholder={candidate.initials}
                                    />
                                </div>
                                <div>
                                    <div className="text-slate-900">
                                        <span className="text-slate-500">Review</span>{" "}
                                        {candidate.first_name}
                                    </div>
                                    <div className="text-slate-500">
                                        <span>{assessment.assessment.name}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                );
            // case constants.TASK_TYPE_REVIEW_INTERVIEW:
            //     return null;
        }
    };

    if (tasks.count == 0) {
        return (
            <div className="prose-md prose">
                <h3>You are all caught up!</h3>
                <p>No tasks to complete.</p>
            </div>
        );
    }

    return (
        <Tables.TablePaginatedServer
            title={title}
            showHeader={false}
            total={tasks?.count}
            goToPage={setPage}
            page={page}
            withSearch={withSearch && setSearch}
            rows={tasks?.results.map((task) => {
                return {
                    key: task.id,
                    cells: [
                        {
                            key: "name",
                            content: renderTask(task),
                        },
                        {
                            key: "actions",
                            className: "flex justify-end",
                            content: (
                                <div className="flex justify-end gap-x-2">
                                    <Buttons.Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            completeTask(task.id);
                                        }}
                                    >
                                        Mark as complete
                                    </Buttons.Button>
                                </div>
                            ),
                        },
                    ],
                };
            })}
        />
    );
}
