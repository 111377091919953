import { AssessmentContext } from "./AssessmentProvider";
import CandidateList from "./CandidateList";
import {
    AnimatePresence,
    Api,
    Buttons,
    Cards,
    Charts,
    Dialog,
    Tab,
    _,
    constants,
    useContext,
    useEffect,
    useNavigate,
    useParams,
    useSearchParams,
    useState,
} from "./tools";

export default function AssessmentSummary() {
    const { assessment } = useContext(AssessmentContext);

    const {
        data: stats,
        isLoading: isLoadingAssessmentStatsFunnel,
        error: errorAssessmentStatsFunnel,
    } = Api.useGetAssessmentStatsFunnelQuery(
        {
            aid: assessment.id,
            range: "90days",
        },
        {
            refetchOnMountOrArgChange: true,
        },
    );

    const {
        data: statsScatterPlot,
        isLoading: isLoadingAssessmentStatsScatterPlot,
        error: errorAssessmentStatsScatterPlot,
    } = Api.useGetAssessmentStatsScatterPlotQuery(
        {
            aid: assessment.id,
            range: "90days",
        },
        {
            refetchOnMountOrArgChange: true,
        },
    );

    if (isLoadingAssessmentStatsFunnel || isLoadingAssessmentStatsScatterPlot) {
        return <div>Loading...</div>;
    }

    if (errorAssessmentStatsFunnel || errorAssessmentStatsScatterPlot) {
        const error = errorAssessmentStatsFunnel || errorAssessmentStatsScatterPlot;
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    // Transform the stats data to match the expected structure for the funnel chart
    const funnelData = [
        { id: "step_invited", value: stats.created, label: "Invited", color: "#10B981" },
        { id: "step_started", value: stats.started, label: "Started", color: "#34D399" },
        { id: "step_completed", value: stats.completed, label: "Completed", color: "#6EE7B7" },
        { id: "step_reviewed", value: stats.reviewed, label: "Reviewed", color: "#A7F3D0" },
    ];

    // remove 0 values from the funnel data
    const filteredFunnelData = funnelData.filter((item) => item.value > 0);

    // if we can't display at least 3 steps, we don't display the funnel chart
    const showFunnelChart = filteredFunnelData.length >= 3;

    // Transform the stats data to match the expected structure for the scatter plot chart
    const scatterPlotData = statsScatterPlot.map((item) => ({
        "Score Category": _.capitalize(
            item.score_category.replace(/_/g, " ").replace("gte", ">=").replace("lte", "=<"),
        ),
        Candidates: item.count,
    }));

    scatterPlotData.forEach((item) => {
        const score = parseInt(item["Score Category"].match(/\d+/)[0]);
        if (score >= 90) {
            item.color = constants.TAILWIND_COLORS_TO_HEX["emerald-200"];
        } else if (score >= 70) {
            item.color = constants.TAILWIND_COLORS_TO_HEX["green-200"];
        } else if (score >= 40) {
            item.color = constants.TAILWIND_COLORS_TO_HEX["yellow-200"];
        } else if (score >= 30) {
            item.color = constants.TAILWIND_COLORS_TO_HEX["yellow-200"];
        } else if (score >= 20) {
            item.color = constants.TAILWIND_COLORS_TO_HEX["red-200"];
        } else if (score >= 10) {
            item.color = constants.TAILWIND_COLORS_TO_HEX["red-200"];
        } else {
            item.color = constants.TAILWIND_COLORS_TO_HEX["red-200"];
        }
    });

    // now we also sort by score
    scatterPlotData.sort((a, b) => {
        const aScore = parseInt(a["Score Category"].match(/\d+/)[0]);
        const bScore = parseInt(b["Score Category"].match(/\d+/)[0]);
        return aScore - bScore;
    });

    return (
        <div>
            <div className="grid grid-cols-4 gap-x-4">
                <Cards.CardOneColumn
                    style={{ minHeight: 500 }}
                    className="col-span-3 bg-slate-50 shadow-md"
                >
                    <CandidateList
                        filters={{
                            assessment_id: assessment.id,
                        }}
                    />
                </Cards.CardOneColumn>

                <div className="col-span-1 grid gap-y-4 self-start">
                    <Cards.CardOneColumn className="bg-slate-50 ">
                        <Charts.Chart
                            variant="Funnel"
                            title="Assessment Funnel"
                            subtitle={`Last 90 days`}
                            data={showFunnelChart ? filteredFunnelData : []}
                            //colors={{ scheme: "blues" }}
                            colors={(d) => d.color}
                            direction="horizontal"
                            motionConfig="gentle"
                            // use emerald-100 to 900
                            borderColor={{ from: "color", modifiers: [["darker", 0.3]] }}
                            borderWidth={2}
                            enableBeforeSeparators={false}
                            download={true}
                        />
                    </Cards.CardOneColumn>

                    <Cards.CardOneColumn className="bg-slate-50 ">
                        <Charts.Chart
                            variant="Bar"
                            title="Score Distribution"
                            subtitle={`Last 90 days`}
                            data={scatterPlotData}
                            // colors={{ scheme: "pastel2" }}
                            colors={(d) => {
                                return d?.data?.color;
                            }}
                            motionConfig="gentle"
                            indexBy="Score Category"
                            keys={["Candidates"]}
                            layout={"vertical"}
                            download={true}
                            enableLabel={false}

                            // xScale={{ type: "point" }}
                            // yScale={{ type: "linear", min: "auto", max: "auto" }}
                        />
                    </Cards.CardOneColumn>
                </div>
            </div>
        </div>
    );
}
