import AccountIntegrations from "./AccountIntegrations";
import AccountOrg from "./AccountOrg";
import AccountUser from "./AccountUser";
import { OrgProvider } from "./OrgProvider";
import {
    AnimatePresence,
    Api,
    Buttons,
    Link,
    Tab,
    Tabs,
    UserContext,
    classNames,
    useContext,
    useNavigate,
    useParams,
    useState,
} from "./tools";

export default function Account() {
    const { user } = useContext(UserContext);

    return (
        <div className="flex flex-col">
            <main className="flex-1">
                <div className="relative">
                    <div className="pb-16">
                        <div className="flex justify-between px-4 sm:px-6 md:px-0">
                            <div className="space-y-1">
                                <h5 className="text-3xl font-bold tracking-tight text-emerald-900">
                                    Account
                                </h5>
                                <h6 className="text-sm text-slate-500">{user.org?.name}</h6>
                            </div>
                            <div className="flex items-center gap-x-2 self-start">
                                <Buttons.ButtonSupport>Customer Support</Buttons.ButtonSupport>
                            </div>
                        </div>
                        <OrgProvider>
                            <Tabs
                                selected={0}
                                tabs={[
                                    {
                                        name: "Organization",
                                        href: "org",
                                        current: true,
                                        component: <AccountOrg />,
                                    },
                                    {
                                        name: "Your Account",
                                        href: "user",
                                        current: false,
                                        component: <AccountUser />,
                                    },
                                    // { name: "Billing", href: "billing", current: false },
                                    {
                                        name: "Integrations",
                                        href: "integrations",
                                        current: false,
                                        component: <AccountIntegrations />,
                                    },
                                    // { name: "Reports", href: "reports", current: false },
                                ]}
                            />
                        </OrgProvider>
                    </div>
                </div>
            </main>
        </div>
    );
}
