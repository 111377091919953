import React from "react";

import { Buttons } from "./tools";

export const PaginationGeneric = ({ page, setPage, count, pageSize = 10 }) => {
    if (!count) {
        return null;
    }
    const totalPages = Math.ceil(count / pageSize);

    const previousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const nextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    return (
        <div className="flex gap-x-4">
            <Buttons.Button
                color="slate"
                variant="solidXS"
                onClick={previousPage}
                disabled={page === 1}
            >
                Previous
            </Buttons.Button>
            <Buttons.Button
                color="slate"
                variant="solidXS"
                onClick={nextPage}
                disabled={page === totalPages}
            >
                Next
            </Buttons.Button>
        </div>
    );
};
