import React from "react";

import { resetStateAction } from "./store/auth";
import { Buttons, useDispatch, useNavigate } from "./tools";

export default function OnboardingVerify() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <div className="flex h-screen items-center justify-center">
            <div className="prose-md prose">
                <h1>You're on the waitlist</h1>
                <p>
                    Thank you for signing up. Due to overwhelming demand, we're currently onboarding
                    users gradually. Rest assured, we'll notify you as soon as a member of our team
                    verifies your account.
                </p>
                <p>
                    Want to expedite the process? Drop us an email at:{" "}
                    <a href="mailto:hello@cactushire.com">hello@cactushire.com</a>.
                </p>
                <p>Thanks for your patience and understanding. </p>
                <p>The Cactus Team</p>

                <Buttons.Button
                    onClick={() => {
                        dispatch(resetStateAction());
                        navigate("/");
                    }}
                >
                    Sign out
                </Buttons.Button>
            </div>
        </div>
    );
}
