import React from "react";

import AssessmentList from "./AssessmentList";
import CandidateList from "./CandidateList";
import { classNames, useSearchParams } from "./tools";

export default function Search() {
    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get("q");

    return (
        <div className="space-y-8">
            <CandidateList
                title={`Candidates matching "${search}"`}
                withSearch={false}
                filters={{
                    search,
                }}
            />
            <AssessmentList
                title={`Assessments matching "${search}"`}
                withSearch={false}
                filters={{
                    search,
                }}
            />
        </div>
    );
}
