import React from "react";

import TeamCreateForm from "./TeamCreateForm";
import TeamEditForm from "./TeamEditForm";
import {
    Api,
    Avatars,
    Buttons,
    Dialog,
    Pills,
    Spinner,
    Tables,
    classNames,
    useContext,
    useDebounce,
    useNavigate,
    useState,
} from "./tools";

export default function AccountTeams() {
    const [showCreateTeam, setShowCreateTeam] = useState(false);
    const [showEditTeam, setShowEditTeam] = useState(false);
    const [search, setSearch] = useState(null);
    const debouncedSearch = useDebounce(search, 200);
    const [deleteTeam, { isLoading: isDeletingTeam, error: errorDeleteTeam }] =
        Api.useDeleteTeamMutation();
    const {
        data: teams,
        isLoading,
        isFetching,
        error,
        refetch: getTeams,
    } = Api.useGetTeamsQuery(
        { search: debouncedSearch },
        {
            refetchOnFocus: true,
        },
    );

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <div className="flex flex-col gap-y-4">
            <Dialog size="lg" isOpen={showCreateTeam} setIsOpen={setShowCreateTeam}>
                <div className="space-y-4">
                    <TeamCreateForm onSuccess={() => setShowCreateTeam(false)} />
                </div>
            </Dialog>
            <Dialog size="lg" isOpen={showEditTeam != false} setIsOpen={setShowEditTeam}>
                <TeamEditForm tid={showEditTeam} onSuccess={() => setShowEditTeam(false)} />
            </Dialog>

            <div className="flex items-center justify-between">
                <Pills.Pill>Teams</Pills.Pill>
                <Buttons.Button
                    variant="solidXS"
                    color="slater"
                    onClick={() => {
                        setShowCreateTeam(true);
                    }}
                >
                    Add Team
                </Buttons.Button>
            </div>

            <Tables.TablePaginated
                showHeader={false}
                pageSize={5}
                rows={teams.map((t) => {
                    return {
                        key: t.id,
                        cells: [
                            {
                                key: "name",
                                content: (
                                    <div className="flex items-center space-x-3">
                                        <div className="flex-shrink-0">
                                            <Avatars.AvatarTeam team={t} />
                                        </div>
                                        <div className="text-sm">
                                            <div className="text-slate-600">{t.name}</div>
                                            <div className="text-slate-400">
                                                {t.users.length} member(s)
                                            </div>
                                        </div>
                                    </div>
                                ),
                            },
                            {
                                key: "members",
                                classNames: "flex justify-end",
                                content: (
                                    <div className="flex items-center justify-end gap-x-4">
                                        <Avatars.AvatarGroup
                                            maxDisplay={5}
                                            size="xs"
                                            items={t.users.map((u) => {
                                                return {
                                                    hash: u.d,
                                                    placeholder: u.initials,
                                                    popoverPosition: "top",
                                                    popoverContent: (
                                                        <div className="w-56 space-y-4 bg-white p-3 text-xs">
                                                            {u.full_name}
                                                        </div>
                                                    ),
                                                    popoverHover: false,
                                                };
                                            })}
                                        />
                                        <Buttons.ButtonActionsDropdown
                                            actions={[
                                                {
                                                    label: "Edit Team",
                                                    onClick: () => {
                                                        setShowEditTeam(t.id);
                                                    },
                                                },
                                                {
                                                    label: "Delete Team",
                                                    onClick: async () => {
                                                        const { result } = deleteTeam(t.id);
                                                    },
                                                    confirm: {
                                                        title: "Delete team",
                                                        description: `Are you sure you want to delete ${t.name}? This action can not be undone.`,
                                                        confirmBtnColor: "lred",
                                                        okLabel: "Delete team",
                                                    },
                                                },
                                            ]}
                                            ellipsis
                                        />
                                    </div>
                                ),
                            },
                        ],
                    };
                })}
            />
        </div>
    );
}
