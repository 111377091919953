import { useEffect, useRef, useState } from "react";

export function useAudioAnalyzer(inputDevice) {
    const [inputLevel, setInputLevel] = useState(0);
    const audioContextInput = useRef(new AudioContext());
    const animationFrameId = useRef(null);
    const delay = 100; // Add a delay of 100ms between updates

    useEffect(() => {
        if (!inputDevice) {
            // If inputDevice is not defined, do not run the effect
            return;
        }

        let cleanup;

        navigator.mediaDevices
            .getUserMedia({
                audio: { deviceId: { exact: inputDevice } },
            })
            .then((stream) => {
                const audioSource = audioContextInput.current.createMediaStreamSource(stream);
                audioContextInput.current.resume();

                const analyser = audioContextInput.current.createAnalyser();
                analyser.smoothingTimeConstant = 0.8;
                analyser.fftSize = 1024;
                audioSource.connect(analyser);

                const updateAudioLevel = () => {
                    const dataArray = new Uint8Array(analyser.frequencyBinCount);
                    analyser.getByteFrequencyData(dataArray);
                    let value = 0;
                    for (let i = 0; i < dataArray.length; i++) {
                        value += dataArray[i];
                    }
                    setInputLevel(value / dataArray.length);
                    // Use setTimeout to add a delay between requestAnimationFrame calls
                    setTimeout(() => {
                        animationFrameId.current = requestAnimationFrame(updateAudioLevel);
                    }, delay);
                };

                updateAudioLevel();

                cleanup = () => {
                    if (animationFrameId.current) {
                        cancelAnimationFrame(animationFrameId.current);
                    }
                    audioSource.disconnect(analyser);
                    stream.getTracks().forEach((track) => track.stop());
                };
            })
            .catch((err) => {
                console.error("Error testing audio input:", err);
            });

        return () => {
            if (typeof cleanup === "function") {
                cleanup();
            }
        };
    }, [inputDevice]);

    return { inputLevel };
}
