import AssessmentList from "./AssessmentList";
import LibraryCactus from "./LibraryCactus";
import ModuleList from "./ModuleList";
import {
    AnimatePresence,
    Api,
    Buttons,
    Link,
    Tabs,
    _,
    classNames,
    moment,
    useEffect,
    useNavigate,
    useParams,
    useSearchParams,
    useState,
} from "./tools";

export default function Assessments() {
    return (
        <div className="flex flex-col">
            <main className="flex-1">
                <div className="relative">
                    <div className="pb-16">
                        <div className="flex justify-between px-4 sm:px-6 md:px-0">
                            <div>
                                <h5 className="text-3xl font-bold tracking-tight text-emerald-900">
                                    Assessments
                                </h5>
                            </div>
                            <div className="flex items-center gap-x-2">
                                <Buttons.Button href="/library/cactus" color="lindigo">
                                    Cactus Library
                                </Buttons.Button>
                                <Buttons.Button href="/library/assessments/create" color="slate">
                                    Create Assessment
                                </Buttons.Button>
                                <Buttons.Button href="/library/modules/create" color="slate">
                                    Create Module
                                </Buttons.Button>
                            </div>
                        </div>
                        <Tabs
                            persistSearchParams
                            tabs={[
                                {
                                    name: "Assessments",
                                    href: "assessments",
                                    current: true,
                                    component: <AssessmentList />,
                                },
                                {
                                    name: "Modules",
                                    href: "modules",
                                    current: true,
                                    component: <ModuleList />,
                                },
                                {
                                    name: "Cactus Library",
                                    href: "cactus",
                                    current: false,
                                    component: <LibraryCactus />,
                                },
                            ]}
                            selected={0}
                        />
                    </div>
                </div>
            </main>
        </div>
    );
}
