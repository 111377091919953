import { AcademicCapIcon, DocumentTextIcon } from "@heroicons/react/20/solid";

import CandidateExternalContact from "./CandidateExternalContact";
import LogoNew from "./assets/img/cactus-hire-new.svg";
import { AnimatePresence, ApiCandidate, Buttons, Fields, useParams, useState } from "./tools";

export default function CandidateExternalHonorCode() {
    const { cid, caid } = useParams();

    const {
        data: assessment,
        isLoading,
        error,
    } = ApiCandidate.useGetAssessmentQuery({ cid, caid }, {});

    const [performAction, { isLoading: isPerformingAction, error: performActionError }] =
        ApiCandidate.usePerformAssessmentActionMutation();

    return (
        <>
            <div className="border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10">
                <div className="grid grid-cols-4 gap-x-10">
                    <div className="col-span-3 grid gap-y-4">
                        <div className="lg:prose-md prose prose-slate max-w-full">
                            <h1>Honor Code</h1>
                            <p>
                                Please read the following honor code and click the button below to
                                agree to it.
                            </p>

                            <div className="grid grid-cols-6 space-x-4">
                                <div className="col-span-1 flex items-center justify-center">
                                    <div className="rounded-md bg-emerald-200 p-5">
                                        <AcademicCapIcon className="h-10 w-10" />
                                    </div>
                                </div>
                                <div className="col-span-4">
                                    <p>
                                        I promise to uphold the highest standards of integrity while
                                        completing my assessment on Cactus. I won't copy from any
                                        other source, including the internet, books, or other
                                        candidates. I'll always submit my own original work. I won't
                                        work with anyone else on the assessment, or use any
                                        unauthorized aids or resources while completing the
                                        assessment.
                                    </p>
                                    <p></p>
                                </div>
                            </div>
                            <div className="grid grid-cols-6 space-x-4">
                                <div className="col-span-1 flex items-center justify-center">
                                    <div className="rounded-md bg-emerald-200 p-5">
                                        <DocumentTextIcon className="h-10 w-10" />
                                    </div>
                                </div>
                                <div className="col-span-4">
                                    <p>
                                        I understand that the work I produce as part of this
                                        assessment is my own intellectual property and that I retain
                                        all rights to that content. However, I agree to grant Cactus
                                        and{" "}
                                        <b className="font-weight-bold">{assessment.org.name}</b> a
                                        non-exclusive, perpetual, irrevocable, worldwide,
                                        royalty-free license for the purpose of assessing my work.
                                    </p>
                                    <p></p>
                                </div>
                            </div>

                            <p>
                                I agree to the honor code and understand that if I violate it, I may
                                be disqualified from the assessment and/or from future assessments.
                            </p>
                        </div>
                        <div>
                            <Buttons.Button
                                loading={isPerformingAction}
                                color="lgreen"
                                onClick={async () => {
                                    const { data } = await performAction({
                                        cid,
                                        caid,
                                        action: "accept_honor_code",
                                    });
                                }}
                            >
                                Accept Honor Code
                            </Buttons.Button>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <CandidateExternalContact assessment={assessment} />
                    </div>
                </div>
            </div>
        </>
    );
}
