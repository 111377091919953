import Cookies from "js-cookie";
import { useEffect, useState } from "react";

export const useUTMParams = () => {
    const [utmParams, setUtmParams] = useState({});

    useEffect(() => {
        const data = Cookies.get("utm_data");

        if (data) {
            setUtmParams(JSON.parse(data));
        }
    }, []);

    return utmParams;
};
