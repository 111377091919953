import { SparklesIcon } from "@heroicons/react/20/solid";
import React from "react";

import C2Image from "./assets/img/c2.png";
import CactusTransparentImage from "./assets/img/cactus-transparent.png";
import GhibliImage10 from "./assets/img/ghibli10.png";
import { Buttons, Dialog } from "./tools";

const features = [
    {
        name: "Complete Library Access:",
        description:
            "Unlock the full potential of our extensive library. As a bonus, you'll receive 1 assessment credit to kickstart your experience.",
        icon: CactusTransparentImage,
    },
    {
        name: "Magic Assessment Builder:",
        description:
            "Craft 2 bespoke assessments tailor-made for your unique job posts using our innovative tool, the Magic Assessment Builder.",
        icon: CactusTransparentImage,
    },
    {
        name: "Candidate Invites:",
        description:
            "Extend an invitation to 3 candidates of your choice to undertake your assessments. (Friendly reminder: self-invitations don't count!)",
        icon: CactusTransparentImage,
    },
    {
        name: "Team Collaboration:",
        description:
            "Amplify the power of teamwork. Invite up to 3 team members to collaborate, review, and analyze the results together. Dive into the journey of discovery and growth as a collective!",
        icon: CactusTransparentImage,
    },
];

export default function TrialPromotionalBanner({ isOpen, setIsOpen }) {
    return (
        <div>
            <Dialog
                withPadding={false}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                size="xl7"
                withScroll={false}
            >
                <div className="grid grid-cols-2">
                    <div className="col-span-1 space-y-12 p-10">
                        <div className="prose prose-sm">
                            <h2 className="text-3xl font-bold tracking-tight text-slate-900">
                                Your Cactus Trial Has Started
                            </h2>
                            <p className="">
                                Congratulations on starting your journey with us! Your free trial is
                                now active, ready for you to explore and discover. Your trial
                                includes the following:
                            </p>
                            <dl className="mt-10 max-w-xl space-y-8 lg:max-w-none">
                                {features.map((feature) => (
                                    <div key={feature.name} className="relative pl-9">
                                        <dt className="inline font-semibold text-slate-900">
                                            <img
                                                src={feature.icon}
                                                width="24"
                                                height="24"
                                                alt={feature.name}
                                                className="absolute left-1 top-1 h-5 w-5 text-emerald-600"
                                            />
                                            {feature.name}
                                        </dt>{" "}
                                        <dd className="inline">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                        <div className="flex gap-x-2">
                            <Buttons.Button
                                variant="solid"
                                color="lgreen"
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                            >
                                Get Started
                            </Buttons.Button>
                            <Buttons.ButtonSupport color="transparent" className="no-uppercase">
                                Have questions? Please reach out.
                            </Buttons.ButtonSupport>
                        </div>
                    </div>
                    <div
                        style={{
                            backgroundImage: `url(${C2Image})`,
                        }}
                        className="col-span-1 h-full bg-cover bg-center"
                    ></div>
                </div>
            </Dialog>
        </div>
    );
}
