import { AcademicCapIcon, DocumentTextIcon } from "@heroicons/react/20/solid";

import CandidateExternalContact from "./CandidateExternalContact";
import LogoNew from "./assets/img/cactus-hire-new.svg";
import {
    AnimatePresenceSlideUp,
    ApiCandidate,
    Buttons,
    Fields,
    Forms,
    useParams,
    useState,
} from "./tools";

export default function CandidateExternalComplete() {
    const { cid, caid } = useParams();
    const [feedback, setFeedback] = useState("");

    const {
        data: assessment,
        isLoading,
        error,
    } = ApiCandidate.useGetAssessmentQuery({ cid, caid }, {});

    const [performAction, { isLoading: isPerformingAction, error: performActionError }] =
        ApiCandidate.usePerformAssessmentActionMutation();

    return (
        <AnimatePresenceSlideUp initial={true} show={true}>
            <div className="border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10">
                <div className="grid grid-cols-4 gap-x-10">
                    <div className="col-span-3 grid gap-y-4">
                        <div className="lg:prose-md prose prose-slate max-w-full">
                            <h1>Congrats!</h1>
                            <p>
                                <strong>You have completed the assessment. </strong>
                                Please provide feedback below. This will help us improve our process
                                and the quality of our assessments.
                            </p>
                        </div>

                        {(assessment.candidate_feedback && (
                            <div className="lg:prose-md prose prose-slate max-w-full">
                                Feedback submitted.
                            </div>
                        )) || (
                            <>
                                <div className="">
                                    <Fields.QuillFieldFree
                                        label="Feedback"
                                        value={feedback}
                                        onChange={setFeedback}
                                    />
                                </div>
                                <div>
                                    <Buttons.Button
                                        loading={isPerformingAction}
                                        onClick={async () => {
                                            const { data } = await performAction({
                                                cid,
                                                caid,
                                                action: "submit_feedback",
                                                payload: { feedback },
                                            });
                                        }}
                                    >
                                        Submit Feedback
                                    </Buttons.Button>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="col-span-1">
                        <CandidateExternalContact />
                    </div>
                </div>
            </div>
        </AnimatePresenceSlideUp>
    );
}
