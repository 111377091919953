import { AnimatePresenceGrow, Buttons, Utils, classNames, constants } from "./tools";

export function ScoreComponent({ title, description, score, maxScore }) {
    return (
        <div className="flex flex-col gap-y-8">
            <div className="w-100 grid grid-cols-2 items-center gap-x-8 rounded-md bg-white p-5 shadow-md">
                <div className="col-span-1 text-sm">
                    <div className="flex gap-x-3">
                        <div className="flex min-w-0 flex-col gap-y-2">
                            <div className="">{title}</div>
                            <div className=" text-slate-600">{description}</div>
                        </div>
                    </div>
                </div>
                <div className="col-span-1">
                    <div className="mb-1 flex justify-between">
                        <span className="text-sm">Score</span>
                        <span className="text-sm">
                            {Utils.calculateScorePercentage(score, maxScore)}%
                        </span>
                    </div>
                    <div
                        className={classNames(
                            "h-2.5 w-full rounded-full bg-slate-200",
                            score == 0 && "bg-slate-200",
                        )}
                    >
                        <AnimatePresenceGrow show={true} initial={true}>
                            <div
                                className={classNames(
                                    Utils.backgroundColorScore(score, maxScore),
                                    " h-2.5 rounded-full",
                                )}
                                style={{
                                    width: Utils.calculateScorePercentage(score, maxScore) + "%",
                                }}
                            ></div>
                        </AnimatePresenceGrow>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ScoreComponentReview({ item, onClick }) {
    const renderAction = () => {
        if (item.skipped_at) {
            if (item.skip_reason == constants.SKIP_REASON_CANDIDATE) {
                return (
                    <div className="text-sm italic text-slate-400">Candidate skipped this item</div>
                );
            }
            return <div className="text-sm italic text-slate-400">Skipped</div>;
        }

        if (item.module_item_type != constants.MODULE_TYPE_MULTIPLE_CHOICE) {
            return (
                <div
                    onClick={() => {
                        onClick?.(item);
                    }}
                    className="cursor-pointer text-sm font-bold text-slate-600"
                >
                    View
                </div>
            );
        }

        return <div className="text-sm text-slate-400">Automatically scored</div>;
    };

    return (
        <div
            key={item.id}
            className="grid grid-cols-2 items-center gap-x-8 rounded-md p-5 shadow-md"
        >
            <div className="flex min-h-0 items-center gap-x-3">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-slate-50">
                    <img
                        src={constants.MODULE_TYPE_ICONS[item.module_item_type]}
                        className="h-6 w-6"
                    />
                </div>
                <div className="truncate text-sm">
                    <div>{constants.MODULE_TYPE_LABELS[item.module_item_type]}</div>
                    {renderAction()}
                </div>
            </div>

            {item.reviewed_at && item.score != null && item.possible_score != null ? (
                <div className="space-y-1">
                    <div className="flex justify-between">
                        <span className="text-sm">Score</span>
                        <span className="text-sm">
                            {Utils.calculateScorePercentage(item.score, item.possible_score)}%
                        </span>
                    </div>
                    <div
                        className={classNames(
                            "h-2.5 w-full rounded-full bg-slate-200",
                            item.score == 0 && "bg-slate-200",
                        )}
                    >
                        <AnimatePresenceGrow show={true} initial={true}>
                            <div
                                className={classNames(
                                    Utils.backgroundColorScore(item.score, item.possible_score),
                                    " h-2.5 rounded-full",
                                )}
                                style={{
                                    width:
                                        Utils.calculateScorePercentage(
                                            item.score,
                                            item.possible_score,
                                        ) + "%",
                                }}
                            ></div>
                        </AnimatePresenceGrow>
                    </div>
                </div>
            ) : (
                <Buttons.Button
                    variant="solidXS"
                    color="lblue"
                    onClick={() => {
                        onClick?.(item);
                    }}
                >
                    <span>Review </span>
                </Buttons.Button>
            )}
        </div>
    );
}
