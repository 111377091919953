import { Listbox as ListboxHeadless, Transition } from "@headlessui/react";
import { Switch } from "@headlessui/react";
import {
    BoltIcon,
    CheckBadgeIcon,
    CheckIcon,
    ChevronUpDownIcon,
    EllipsisVerticalIcon,
    HandRaisedIcon,
    RocketLaunchIcon,
    XMarkIcon,
} from "@heroicons/react/20/solid";
import { HandThumbDownIcon, HandThumbUpIcon } from "@heroicons/react/24/outline";
import { connect as formikConnect, useField } from "formik";
import "quill-paste-smart";
import { Fragment, useEffect, useState } from "react";
import PinField from "react-pin-field";
import ReactQuill from "react-quill";

import TeamCreateForm from "./TeamCreateForm";
import {
    Api,
    Avatars,
    Buttons,
    Dialog,
    Link,
    Popovers,
    ReactJson,
    Spinner,
    UserContext,
    Utils,
    classNames,
    constants,
    motion,
    useContext,
    useRef,
} from "./tools";

const formClasses =
    "font-mono block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-zinc-950 placeholder-zinc-400 focus:border-emerald-500 focus:bg-white focus:outline-none focus:ring-emerald-500 sm:text-sm";

export function Label({ id, children, className }) {
    return (
        <label
            htmlFor={id}
            className={classNames("mb-3 block text-sm font-medium text-gray-700", className)}
        >
            {children}
        </label>
    );
}

export function TextField({
    label,
    labelPopover = null,
    type = "text",
    className = "",
    showStrengthIndicator = false, // for password
    ...props
}) {
    const [field, meta, helpers] = useField(props);
    return (
        <div className={className}>
            {label && (
                <>
                    {labelPopover ? (
                        <Popovers.PopoverLabel label={label}>{labelPopover}</Popovers.PopoverLabel>
                    ) : (
                        <Label>{label}</Label>
                    )}
                </>
            )}
            {type === "textarea" ? (
                <textarea
                    {...field}
                    {...props}
                    className={classNames(formClasses, "resize-none")}
                />
            ) : (
                <>
                    <input type={type} {...field} {...props} className={formClasses} />
                    {type === "password" && showStrengthIndicator && (
                        <PasswordStrengthIndicator password={field.value} />
                    )}
                </>
            )}

            {meta.touched && meta.error ? (
                <p className="mt-2 text-sm text-red-600"> {meta.error} </p>
            ) : null}
        </div>
    );
}

function PasswordStrengthIndicator({ password }) {
    const [strength, setStrength] = useState(0);

    useEffect(() => {
        const calculateStrength = (password) => {
            let strength = 0;
            if (password.length >= 6) strength++;
            if (/[a-z]/.test(password) && /[A-Z]/.test(password)) strength++;
            if (/\d/.test(password) && /[@$!%*#?&]/.test(password)) strength++;
            return strength;
        };

        setStrength(calculateStrength(password));
    }, [password]);

    if (!password) {
        return null;
    }

    const strengthText = strength <= 1 ? "Poor" : strength <= 2 ? "Okayish" : "Great";

    return (
        <div className="mt-3 w-full">
            <div className="h-2 w-full bg-slate-200">
                <div
                    className={`h-2 ${
                        strength <= 1
                            ? "bg-red-300"
                            : strength <= 2
                            ? "bg-yellow-300"
                            : "bg-green-300"
                    } transition-all duration-500`}
                    style={{ width: `${(strength * 100) / 3}%` }}
                />
            </div>
            <div className="mt-2 flex justify-between ">
                <div>
                    <Popovers.PopoverGeneric
                        hover={false}
                        position="top"
                        className="min-w-[270px]"
                        trigger={
                            <span className="text-xs font-semibold uppercase text-slate-400 focus:ring-0 focus:ring-offset-0">
                                Password Strength
                            </span>
                        }
                        content={
                            <div className="p-5">
                                <ul className="list-inside list-disc text-xs">
                                    <li>At least 9 characters long</li>
                                    <li>Contains lowercase letters</li>
                                    <li>Contains uppercase letters</li>
                                    <li>Contains digits</li>
                                    <li>Contains special characters (@$!%*#?&)</li>
                                    <li>No repeating characters</li>
                                    <li>No common patterns (e.g., 123, abc, password)</li>
                                </ul>
                            </div>
                        }
                    />
                </div>
                <div className="text-xs font-semibold uppercase text-slate-400">{strengthText}</div>
            </div>
        </div>
    );
}

export function CheckBoxField({
    label,
    labelSecondary,
    labelPopover = null,
    className = "",
    error = null,
    ...props
}) {
    const [field, meta, helpers] = useField(props);
    const { name } = field;

    return (
        <div className={classNames("relative flex items-start", className)}>
            <div className="flex h-6 items-center">
                <input
                    id={name}
                    type="checkbox"
                    {...field}
                    {...props}
                    className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                />
            </div>
            <div className="ml-3 text-sm leading-6">
                {label && (
                    <>
                        {labelPopover ? (
                            <Popovers.PopoverLabel label={label}>
                                {labelPopover}
                            </Popovers.PopoverLabel>
                        ) : (
                            <Label id={name} className="mb-0">
                                {label}
                            </Label>
                        )}
                    </>
                )}
                {labelSecondary && <p className="text-slate-500">{labelSecondary}</p>}

                {meta.touched && meta.error ? (
                    <p className="mt-2 text-sm text-red-600"> {meta.error} </p>
                ) : null}
            </div>
        </div>
    );
}

export function CheckBoxFree({ label, labelSecondary, className = "", ...props }) {
    return (
        <div className={classNames("relative flex items-start", className)}>
            <div className="flex h-6 items-center">
                <input
                    type="checkbox"
                    {...props}
                    className="h-4 w-4 rounded border-gray-300 text-emerald-600 hover:ring-emerald-500 focus:ring-emerald-500"
                />
            </div>
            {label || labelSecondary ? (
                <div className="ml-3 text-sm leading-6">
                    {label && <Label className="mb-0">{label}</Label>}
                    {labelSecondary && <p className="text-slate-500">{labelSecondary}</p>}
                </div>
            ) : null}
        </div>
    );
}

export function ToggleField({ label, labelSecondary, className = "", ...props }) {
    const [field, meta, helpers] = useField(props);

    const handleToggle = () => {
        helpers.setValue(!field.value);
    };

    return (
        <div>
            <Switch.Group as="div" className={classNames("flex items-center gap-x-4", className)}>
                <Switch
                    checked={field.value}
                    onChange={handleToggle}
                    className={classNames(
                        field.value ? "bg-emerald-600" : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-emerald-600 focus:ring-offset-2",
                    )}
                >
                    <span
                        aria-hidden="true"
                        className={classNames(
                            field.value ? "translate-x-5" : "translate-x-0",
                            "pointer-events-none flex h-5 w-5 transform items-center rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                        )}
                    />
                </Switch>
                <Switch.Label as="div" className="space-y-2 text-sm">
                    {label && <div className="font-medium text-gray-900">{label}</div>}
                    {labelSecondary && <div className="text-gray-500">{labelSecondary}</div>}
                </Switch.Label>
            </Switch.Group>
            {meta.touched && meta.error ? (
                <p className="mt-2 text-sm text-red-600">{meta.error}</p>
            ) : null}
        </div>
    );
}

export function StarRatingField({
    label,
    labelSecondary,
    maxRating = 5,
    className = "",
    ...props
}) {
    const [field, meta, helpers] = useField(props);

    const handleRating = (newRating) => {
        helpers.setValue(newRating);
    };

    return (
        <div>
            {label && <Label>{label}</Label>}
            <div className={classNames("", className)}>
                <Ratings.StarRating
                    rating={field.value}
                    maxRating={maxRating}
                    className={classNames(
                        "relative mr-2 inline-flex h-6 w-6 flex-shrink-0 cursor-pointer transition-colors duration-200 ease-in-out",
                    )}
                    onClick={handleRating}
                />
            </div>
            {meta.touched && meta.error ? (
                <p className="mt-2 text-sm text-red-600">{meta.error}</p>
            ) : null}
        </div>
    );
}

export function TextFieldFree({
    label,
    labelPopover = null,
    type = "text",
    className = "",
    explain = "",
    explainOnFocus = null,
    error = null,
    toolbar = null,
    ...props
}) {
    const [showToolbar, setShowToolbar] = useState(false);

    return (
        <div className={className}>
            {label && (
                <div className="align-center flex items-center justify-between">
                    {labelPopover ? (
                        <Popovers.PopoverLabel label={label}>
                            <div>{labelPopover}</div>
                        </Popovers.PopoverLabel>
                    ) : (
                        <Label>{label}</Label>
                    )}

                    {toolbar ? (
                        <div>
                            <Buttons.Button
                                className="mb-3"
                                color="lpurple"
                                variant="solidXS"
                                onClick={() => {
                                    setShowToolbar(!showToolbar);
                                }}
                            >
                                <BoltIcon className="h-4 w-4" />
                            </Buttons.Button>
                        </div>
                    ) : null}
                </div>
            )}

            {type === "textarea" ? (
                <textarea
                    onFocus={() => {
                        explain && explainOnFocus && explainOnFocus(explain);
                    }}
                    onBlur={() => {
                        explain && explainOnFocus && explainOnFocus("");
                    }}
                    {...props}
                    className={classNames(formClasses, "resize-none")}
                />
            ) : (
                <input
                    onFocus={() => {
                        explain && explainOnFocus && explainOnFocus(explain);
                    }}
                    onBlur={() => {
                        explain && explainOnFocus && explainOnFocus("");
                    }}
                    type={type}
                    {...props}
                    className={formClasses}
                />
            )}

            {error ? <p className="mt-2 text-sm text-red-600"> {error} </p> : null}

            {toolbar && showToolbar ? <div className="mt-2">{toolbar}</div> : null}
        </div>
    );
}

export function QuillField({ label, labelBottom, className = "", ...props }) {
    const [field, meta, helpers] = useField(props);

    return (
        <div className={className}>
            {label && <Label>{label}</Label>}
            <div className="">
                <ReactQuill
                    {...props}
                    {...field}
                    onChange={(v, _delta, source) => {
                        // only update formik if the change is from the user, prevents modal bug
                        if (source === "user") {
                            helpers.setValue(v);
                        }
                    }}
                    onBlur={() => {
                        helpers.setTouched(true);
                    }}
                    ref={props.innerRef}
                    theme="snow"
                    modules={{
                        toolbar: [
                            ["bold", "italic"],
                            ["link", "code"],
                            [
                                {
                                    list: "ordered",
                                },
                                {
                                    list: "bullet",
                                },
                            ],
                        ],
                    }}
                />
            </div>
            {labelBottom && <small className="relative top-1">{labelBottom}</small>}

            {meta.touched && meta.error ? (
                <p className="mt-2 text-sm text-red-600"> {meta.error} </p>
            ) : null}
        </div>
    );
}

export function TagsField({ label, className = "", autoCompleteOptions = [], ...props }) {
    const [field, meta, helpers] = useField(props);
    const { setValue } = helpers;

    const [tags, setTags] = useState([]);

    const handleAddTag = (tagInput) => {
        const tagList = tagInput.split(",").map((tag) => tag.trim());
        const newTags = [...tags];

        tagList.forEach((tag) => {
            const tagLower = tag.toLowerCase();
            if (!newTags.some((existingTag) => existingTag.toLowerCase() === tagLower)) {
                newTags.push(tag);
            }
        });

        setTags(newTags);
        setValue(newTags);
    };

    const handleRemoveTag = (tag) => {
        const newTags = tags.filter((t) => t !== tag);
        setTags(newTags);
        setValue(newTags);
    };

    useEffect(() => {
        if (Array.isArray(field.value)) {
            setTags(field.value);
        }
    }, [field.value]);

    return (
        <div className={classNames("", className)}>
            <div className="space-y-4">
                <TextFieldFree
                    placeholder="Enter tag and press enter..."
                    label={label}
                    type="text"
                    list={autoCompleteOptions.length ? "autocomplete-options" : undefined}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            handleAddTag(e.target.value);
                            e.target.value = "";
                        }
                    }}
                />
                {autoCompleteOptions.length != 0 && (
                    <datalist id="autocomplete-options">
                        {autoCompleteOptions.map((option, index) => (
                            <option key={index} value={option} />
                        ))}
                    </datalist>
                )}
                <div className="flex gap-x-2">
                    {tags.map((tag) => (
                        <span
                            key={tag}
                            title={tag}
                            className="relative flex cursor-pointer flex-wrap items-center justify-between rounded-xl bg-emerald-600 py-2 pl-4 pr-6 text-sm font-medium text-white hover:bg-emerald-500 hover:text-gray-100 "
                        >
                            <span className="max-w-[120px] truncate pr-2">{tag}</span>
                            <XMarkIcon
                                onClick={() => handleRemoveTag(tag)}
                                className="absolute right-2 ml-2 h-4 w-4"
                            />
                        </span>
                    ))}
                </div>
            </div>

            {meta.touched && meta.error ? (
                <p className="mt-2 text-sm text-red-600"> {meta.error} </p>
            ) : null}
        </div>
    );
}

export function ScoreFieldFree({
    label,
    className = "",
    onChange,
    minScore = 0,
    maxScore = 10,
    defaultScore = 5,
    ...props
}) {
    const [score, setScore] = useState(defaultScore);
    const progressBarRef = useRef(null);
    const [isMouseDown, setIsMouseDown] = useState(false);

    const handleClick = (e) => {
        if (progressBarRef.current) {
            const rect = progressBarRef.current.getBoundingClientRect();
            const progressWidth = e.clientX - rect.left;
            const percentage = Math.min(Math.max((progressWidth / rect.width) * 100, 0), 100);
            const newScore = Math.round((percentage / 100) * (maxScore - minScore) + minScore);
            setScore(newScore);
            if (onChange) {
                onChange(newScore);
            }
        }
    };

    const handleMouseMove = (e) => {
        if (isMouseDown && progressBarRef.current) {
            const rect = progressBarRef.current.getBoundingClientRect();
            const progressWidth = e.clientX - rect.left;
            const percentage = Math.min(Math.max((progressWidth / rect.width) * 100, 0), 100);
            const newScore = Math.round((percentage / 100) * (maxScore - minScore) + minScore);
            setScore(newScore);
            if (onChange) {
                onChange(newScore);
            }
        }
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
    };

    const handleMouseDown = () => {
        setIsMouseDown(true);
    };

    const pulseAnimation = {
        pulse: { scale: 1.4, transition: { duration: 0.2, yoyo: Infinity } },
        rest: { scale: 1 },
    };

    const renderIndicators = () => {
        const indicatorCount = maxScore - minScore;
        const indicators = [];
        for (let i = 0; i <= indicatorCount; i++) {
            const percentage = (i / indicatorCount) * 100;
            indicators.push(
                <div
                    key={i}
                    className="absolute z-10 h-2.5 w-0.5 bg-white opacity-90"
                    style={{ left: `${percentage}%` }}
                ></div>,
            );
        }
        return indicators;
    };

    return (
        <div className={className}>
            <div className="flex justify-between">
                <div>{label && <Label>{label}</Label>}</div>

                <div className="">
                    <motion.span
                        className="text-sm font-medium text-slate-700 dark:text-white"
                        animate={score >= 8 ? "pulse" : "rest"}
                        variants={pulseAnimation}
                    >
                        {score}/{maxScore}
                    </motion.span>
                </div>
            </div>

            <div
                className={classNames(
                    "relative h-2.5 w-full cursor-pointer rounded-full bg-slate-200",
                )}
                ref={progressBarRef}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseUp}
                onClick={handleClick}
            >
                <div
                    className={classNames(
                        "absolute h-2.5 cursor-pointer rounded-full transition-all",
                        Utils.backgroundColorScore(score, maxScore),
                    )}
                    style={{
                        width: `${Utils.calculateScorePercentage(score, maxScore)}%`,
                    }}
                ></div>
                {renderIndicators()}
            </div>
        </div>
    );
}

export function QuillFieldFree({
    label,
    className = "",
    labelPopover = null,
    toolbar = null,
    containerClassName = "",
    quillClassName = "",
    error = null,
    ...props
}) {
    const [showToolbar, setShowToolbar] = useState(false);

    return (
        <div>
            {label && (
                <div className="align-center flex items-center justify-between">
                    {labelPopover ? (
                        <Popovers.PopoverLabel label={label}>
                            <div>Hello</div>
                        </Popovers.PopoverLabel>
                    ) : (
                        <Label>{label}</Label>
                    )}

                    {toolbar ? (
                        <div>
                            <Buttons.Button
                                className="mb-3"
                                color="lpurple"
                                variant="solidXS"
                                onClick={() => {
                                    setShowToolbar(!showToolbar);
                                }}
                            >
                                <BoltIcon className="h-4 w-4" />
                            </Buttons.Button>
                        </div>
                    ) : null}
                </div>
            )}

            <div className={containerClassName}>
                <ReactQuill
                    {...props}
                    theme="snow"
                    modules={{
                        toolbar: [
                            ["bold", "italic"],
                            ["link", "code"],
                            [
                                {
                                    list: "ordered",
                                },
                                {
                                    list: "bullet",
                                },
                            ],
                        ],
                    }}
                    className={classNames(quillClassName, "")}
                />
            </div>
            {error ? <p className="mt-2 text-sm text-red-600"> {error} </p> : null}
            {toolbar && showToolbar ? <div className="mt-2">{toolbar}</div> : null}
        </div>
    );
}

export function VerifyCodeFieldFree({ label, className = "", error = null, ...props }) {
    return (
        <div className={className}>
            {label && <Label>{label}</Label>}
            <div className="flex flex-wrap gap-x-1">
                <PinField
                    {...props}
                    length={5}
                    className={classNames(formClasses, "w-10 text-center")}
                />
            </div>
            {error ? <p className="mt-2 text-sm text-red-600"> {error} </p> : null}
        </div>
    );
}

export function AIToolBar({ text, processResult }) {
    const { user } = useContext(UserContext);
    if (!Utils.isStaff(user)) {
        return null;
    }
    const [aiToolsGenerate, { isLoading }] = Api.useAiToolsGenerateMutation();
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [action, setAction] = useState("");
    const format = 'Return a valid JSON array of objects, use this schema: [{"text": "Content."}].';
    const prompt = `Rephrase the following input. Give me 3 versions. ${format}.\n\n`;
    const promptShorten = `Shorten the following input. Give me 3 versions. ${format}.\n\n`;

    const requestWithPrompt = async (prompt, action) => {
        setResult(null);
        setAction(action);
        setError(null);
        const result = await aiToolsGenerate({ prompt: prompt, model: "gpt-4" });
        try {
            const jR = JSON.parse(result.data?.text);
            setResult(jR);
        } catch (e) {
            setError(e);
        }
    };

    return (
        <div className="grid grid-cols-1 gap-y-3">
            <div className="flex gap-x-2">
                <Buttons.Button
                    color="slate"
                    className="bg-slate-200"
                    variant="solidXS"
                    onClick={async () => {
                        requestWithPrompt(`${prompt}${text}`, "rephrase");
                    }}
                    loading={isLoading && action === "rephrase"}
                >
                    Rephrase
                </Buttons.Button>
                <Buttons.Button
                    color="slate"
                    className="bg-slate-200"
                    variant="solidXS"
                    onClick={async () => {
                        requestWithPrompt(`${promptShorten}${text}`, "shorten");
                    }}
                    loading={isLoading && action === "shorten"}
                >
                    Shorten
                </Buttons.Button>
            </div>
            {result && (
                <div className="bg-slate-100 p-5 text-xs italic">
                    {result.map((r) => (
                        <div key={r.text} className="mb-2">
                            <div className="text-slate-500">
                                {r.text}
                                <strong
                                    className="ml-2 cursor-pointer text-emerald-900"
                                    onClick={() => {
                                        processResult(r.text);
                                        setResult(null);
                                    }}
                                >
                                    Use this
                                </strong>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {error && (
                <div className="bg-slate-100 p-5 text-xs italic">
                    <div className="text-red-300">
                        <div>
                            <strong className="ml-2">{error.message}</strong>
                        </div>
                        <Buttons.Button
                            className={"mt-2"}
                            onClick={() => {
                                if (action === "rephrase") {
                                    requestWithPrompt(`${prompt}${text}`, "rephrase");
                                } else if (action === "shorten") {
                                    requestWithPrompt(`${promptShorten}${text}`, "shorten");
                                }
                            }}
                            variant="solidXS"
                        >
                            Try again
                        </Buttons.Button>
                    </div>
                </div>
            )}
        </div>
    );
}

/**
 * AiToolbarGeneric is a reusable component that provides a toolbar with various AI-powered actions
 * to process and generate text based on the input. It uses the GPT-4 model to process text and
 * display results, allowing the user to choose from generated options.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.text - The input text to process.
 * @param {Function} props.processResult - The callback function to process and use the selected result.
 * @param {Array<Object>} props.actions - An array of action objects defining the AI-powered actions available in the toolbar.
 * @param {string} props.actions.label - The display label for the action button.
 * @param {string} [props.actions.model="gpt-4"] - The AI model to use for text generation.
 * @param {string} props.actions.prompt - The prompt to use for text generation, with optional "{format}" placeholder for formatting.
 * @param {string} props.actions.format - The format to replace the "{format}" placeholder in the prompt.
 * @param {string} props.actions.system_prompt - The system prompt to use for text generation.
 * @param {Function} [props.actions.parseResponse] - Optional custom function to parse the generated response.
 * @param {Function} [props.actions.renderer] - Optional custom renderer function for the generated result.
 *
 * @returns {JSX.Element} The rendered AI Toolbar component.
 */
export function AiToolbarGeneric({ text, processResult, actions = [] }) {
    const { user } = useContext(UserContext);
    if (!Utils.isStaff(user)) {
        return null;
    }
    const [aiToolsGenerate, { isLoading }] = Api.useAiToolsGenerateMutation();
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [action, setAction] = useState("");
    const [showDialog, setShowDialog] = useState(false);
    const [dynamicPrompt, setDynamicPrompt] = useState("");

    const requestWithPrompt = async (actionItem) => {
        setResult(null);
        setAction(actionItem.label);
        setError(null);
        const prompt = actionItem.prompt?.replace("{format}", actionItem.format) + text;
        const result = await aiToolsGenerate({
            prompt: prompt,
            model: actionItem.model || "gpt-4",
            system_prompt: actionItem.system_prompt,
        });
        try {
            const parsedResult = actionItem.parseResponse
                ? actionItem.parseResponse(result.data?.text)
                : JSON.parse(result.data?.text);
            setResult(parsedResult);
        } catch (e) {
            console.log("error", e);
            setError(e);
        }
    };

    const handleDialogSubmit = () => {
        const customPromptAction = actions.find((a) => a.label === "Custom Prompt");
        const customPromptActionWithPrompt = {
            ...customPromptAction,
            prompt:
                "Instructions: " +
                dynamicPrompt +
                "." +
                "\nExpected Response Format: {format} \nInput Text: ",
        };
        requestWithPrompt(customPromptActionWithPrompt);
        setDynamicPrompt("");
        setShowDialog(false);
    };

    const defaultRenderer = (r) => (
        <div key={r.text} className="mb-2">
            <div className="text-slate-500">
                {r.text}
                <strong
                    className="ml-2 cursor-pointer text-emerald-900"
                    onClick={() => {
                        processResult(r.text);
                        setResult(null);
                    }}
                >
                    Use this
                </strong>
            </div>
        </div>
    );

    // add custom prompt action
    actions.push({
        label: "Custom Prompt",
        format: "Return JSON object with 'response' property, which is your response.",
        handleClick: () => {
            setShowDialog(true);
        },
        renderer: ({ result, processResult, setResult }) => {
            return (
                <div className=" bg-slate-100 p-5">
                    <div className="space-y-4">
                        <div className="font-bold">Response</div>
                        <div>{renderHTMLOrText(result?.response)}</div>
                        <pre className="w-full whitespace-pre-wrap text-xs">
                            <ReactJson collapsed src={result} />
                        </pre>
                    </div>
                </div>
            );
        },
    });

    const renderHTMLOrText = (text) => {
        if (text && text.includes && text.includes("<")) {
            return <div className="text-md" dangerouslySetInnerHTML={{ __html: text }} />;
        } else {
            return <pre className="w-full whitespace-pre-wrap">{text}</pre>;
        }
    };

    const currentAction = actions.find((a) => a.label === action);
    const CustomRenderer = currentAction?.renderer;

    return (
        <div className="grid grid-cols-1 gap-y-4">
            {showDialog && (
                <Dialog withScroll={false} size="lg" isOpen={showDialog} setIsOpen={setShowDialog}>
                    <form onSubmit={handleDialogSubmit} className="space-y-4">
                        <TextFieldFree
                            rows={5}
                            type="textarea"
                            label="Prompt"
                            name="prompt"
                            value={dynamicPrompt}
                            onChange={(e) => setDynamicPrompt(e.target.value)}
                        />
                        <Buttons.Button color="slater" type="submit">
                            Submit
                        </Buttons.Button>
                    </form>
                    <div className="mt-3 bg-slate-50 p-5 text-xs">
                        <div className="font-bold">Context</div>
                        <div>{renderHTMLOrText(text)}</div>
                    </div>
                </Dialog>
            )}
            <div className="flex gap-x-2">
                {actions.map((actionItem) => (
                    <Buttons.Button
                        key={actionItem.label}
                        color="slate"
                        className="bg-slate-200"
                        variant="solidXS"
                        onClick={() => {
                            if (actionItem.handleClick) {
                                actionItem.handleClick();
                            } else {
                                requestWithPrompt(actionItem);
                            }
                        }}
                        loading={isLoading && action === actionItem.label}
                    >
                        {actionItem.label}
                    </Buttons.Button>
                ))}
            </div>
            {result && (
                <div className="bg-slate-100 p-5">
                    {CustomRenderer ? (
                        <CustomRenderer
                            result={result}
                            processResult={processResult}
                            setResult={setResult}
                        />
                    ) : (
                        result.map((r) => defaultRenderer(r))
                    )}
                </div>
            )}
            {error && (
                <div className="bg-slate-100 p-5 text-xs italic">
                    <div className="text-red-300">
                        <div>
                            <strong className="ml-2">{error.message}</strong>
                        </div>
                        <Buttons.Button
                            className={"mt-2"}
                            onClick={() => {
                                const actionItem = actions.find((a) => a.label === action);
                                if (actionItem) {
                                    requestWithPrompt(actionItem);
                                }
                            }}
                            variant="solidXS"
                        >
                            Try again
                        </Buttons.Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export function SelectField({ id, label, className = "", options = [], ...props }) {
    const [field, meta, helpers] = useField(props);

    return (
        <div className={className}>
            {label && <Label id={id}>{label}</Label>}
            <select {...field} id={id} {...props} className={classNames(formClasses, "pr-8")}>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
}

export function SelectFieldFree({
    id,
    label,
    className = "",
    options = [],
    labelPopover,
    ...props
}) {
    return (
        <div className={className}>
            {labelPopover ? (
                <Popovers.PopoverLabel label={label}>
                    <div>{labelPopover}</div>
                </Popovers.PopoverLabel>
            ) : (
                <Label>{label}</Label>
            )}

            <select id={id} {...props} className={classNames(formClasses, "pr-8")}>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
}

export function ListBox({ label, options = [], ...props }) {
    const [selected, setSelected] = useState(null);
    const [field, meta, helpers] = useField(props);

    useEffect(() => {
        if (options.length === 0) {
            return;
        }

        // set selected if value is set
        if (field.value) {
            const selectedOption = options.find((o) => o.value === field.value);
            if (selectedOption) {
                setSelected(selectedOption);
            }
        } else {
            setSelected(options[0]);
            helpers.setValue(options[0].value);
        }
    }, []);

    if (!selected) {
        return null;
    }

    return (
        <div className="w-full">
            {label && <Label>{label}</Label>}
            <ListboxHeadless
                value={selected}
                onChange={(v) => {
                    setSelected(v);
                    helpers.setValue(v.value);
                }}
            >
                <div className="relative">
                    <ListboxHeadless.Button className="w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-left text-sm text-gray-900 focus:outline-none ">
                        <div className="flex items-center space-x-3">
                            {selected.icon && <div className="flex-shrink-0">{selected.icon}</div>}
                            <div>
                                <div className="text-gray-900">{selected.label}</div>
                            </div>
                        </div>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </ListboxHeadless.Button>
                    <Transition
                        as={Fragment}
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <ListboxHeadless.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {options.map((option) => (
                                <ListboxHeadless.Option
                                    key={option.label}
                                    className={({ active }) =>
                                        `relative z-20 cursor-pointer select-none py-3 pl-2 pr-4 ${
                                            active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                                        }`
                                    }
                                    value={option}
                                >
                                    {({ selected }) => (
                                        <div className="flex items-center space-x-3">
                                            {option.icon && (
                                                <div className="flex-shrink-0">{option.icon}</div>
                                            )}

                                            <div>
                                                <div className="text-gray-900">{option.label}</div>
                                            </div>
                                        </div>
                                    )}
                                </ListboxHeadless.Option>
                            ))}
                        </ListboxHeadless.Options>
                    </Transition>
                </div>
            </ListboxHeadless>
        </div>
    );
}

export function ListBoxMultiple({ label, labelPopover, options = [], ...props }) {
    const [selected, setSelected] = useState([]);
    const [field, meta, helpers] = useField(props);

    const setSel = (v) => {
        setSelected(v);
        helpers.setValue(v.map((s) => s.value));
    };

    return (
        <div className="w-full">
            {label && (
                <>
                    {labelPopover ? (
                        <Popovers.PopoverLabel {...props} label={label}>
                            {labelPopover}
                        </Popovers.PopoverLabel>
                    ) : (
                        <Label>{label}</Label>
                    )}
                </>
            )}
            <ListboxHeadless
                horizontal
                value={selected}
                onChange={(v) => {
                    setSel(v);
                }}
                multiple
            >
                <div className="relative">
                    <ListboxHeadless.Button className="w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-1 py-2 text-left text-sm text-gray-900 focus:outline-none ">
                        <div className="flex space-x-2 overflow-y-scroll">
                            {selected.length != 0 ? (
                                selected.map((s) => {
                                    return (
                                        <div
                                            key={s.id || s.label}
                                            onClick={() => {
                                                setSel(
                                                    selected.filter(
                                                        (item) => item.value !== s.value,
                                                    ),
                                                );
                                            }}
                                            className="flex items-center gap-x-3 rounded-md bg-blue-100 px-3 py-1"
                                        >
                                            {s.icon && (
                                                <div className="flex-shrink-0">{s.icon}</div>
                                            )}
                                            <div className="flex-shrink-0">
                                                <div className="text-xs text-gray-900">
                                                    {s.label}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="px-1 text-gray-400">Select reviewers</div>
                            )}
                        </div>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <EllipsisVerticalIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </ListboxHeadless.Button>
                    <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <ListboxHeadless.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {options
                                .filter(
                                    (
                                        o, // filter if its already in selected
                                    ) => !selected.some((s) => s.value === o.value),
                                )
                                .map((option) => (
                                    <ListboxHeadless.Option
                                        className={({ active }) =>
                                            `relative cursor-pointer select-none py-2 pl-4 pr-4 ${
                                                active
                                                    ? "bg-amber-100 text-amber-900"
                                                    : "text-gray-900"
                                            }`
                                        }
                                        key={option.id || option.label}
                                        value={option}
                                    >
                                        <div className="flex items-center space-x-3">
                                            {option.icon && (
                                                <div className="flex-shrink-0">{option.icon}</div>
                                            )}

                                            <div>
                                                <div className="text-gray-900">{option.label}</div>
                                            </div>
                                        </div>
                                    </ListboxHeadless.Option>
                                ))}
                        </ListboxHeadless.Options>
                    </Transition>
                </div>
            </ListboxHeadless>
            {meta.error ? <p className="mt-2 text-sm text-red-600"> {meta.error} </p> : null}
        </div>
    );
}

export function ListBoxTeams({ mine = true, withAddTeamLink = true, ...props }) {
    const {
        data: teams,
        isLoading: isLoadingTeams,
        error,
    } = mine ? Api.useGetCurrentUserTeamsQuery() : Api.useGetTeamsQuery({ search: null });
    const [showDialog, setShowDialog] = useState(false);

    if (isLoadingTeams) {
        return <Spinner center size="md" />;
    }

    if (error) {
        return <div>Unable to load teams</div>;
    }

    if (!teams?.length) {
        return <div>No teams to display</div>;
    }

    const options = teams?.map((team) => ({
        label: team.name,
        value: team.id,
        icon: <Avatars.AvatarTeam size="xs" team={team} />,
    }));

    const label = (withAddTeamLink && (
        <div className="flex items-center justify-between">
            <Label className="mb-0">Team</Label>
            <Buttons.Button
                color="transparent"
                variant="solidXS"
                onClick={() => {
                    setShowDialog(true);
                }}
            >
                Add Team
            </Buttons.Button>
            <Dialog withScroll={false} isOpen={showDialog} setIsOpen={setShowDialog} size="md">
                <TeamCreateForm onSuccess={() => setShowDialog(false)} />
            </Dialog>
        </div>
    )) || <Label className="mb-0">Team</Label>;

    return <ListBox options={options} {...props} label={label} />;
}

export function ListBoxReviewers({ team, ...props }) {
    return (
        <ListBoxMultiple
            name="reviewers"
            label="Reviewers"
            {...props}
            options={team?.users?.map((user) => {
                return {
                    value: user.id,
                    label: user.first_name,
                    id: user.id,
                    icon: (
                        <Avatars.Avatar
                            size="xs"
                            className="relative z-30 ring-2 ring-white"
                            placeholder={user.initials}
                            hash={user.d}
                        />
                    ),
                };
            })}
        />
    );
}

export function ColorPickerFieldFree({
    className,
    label,
    colors = constants.TAILWIND_DEFAULT_COLORS,
    onChange,
    value,
}) {
    const handleClick = (color) => {
        const hexCode = constants.TAILWIND_COLORS_TO_HEX[color] || color;
        onChange(hexCode);
    };

    // e.g. gray-200 = gray-500
    const darkerTailwind = (color) => {
        const [name, shade] = color.split("-");
        const darkerShade = parseInt(shade) + 300;
        return `${name}-${darkerShade}`;
    };

    const hexToTailwind = (hex) => {
        const tailwindColor = Object.entries(constants.TAILWIND_COLORS_TO_HEX).find(
            ([, v]) => v === hex,
        )?.[0];
        return tailwindColor;
    };

    return (
        <div className={classNames("", className)}>
            {label && <Label>{label}</Label>}
            <div className={classNames("flex flex-wrap gap-2")}>
                {colors.map((color, index) => (
                    <button
                        key={index}
                        className={classNames(
                            "flex h-6 w-6 items-center justify-center rounded-md",
                            `bg-${color}`,
                        )}
                        onClick={() => handleClick(color)}
                    >
                        {value && color == hexToTailwind(value) && (
                            <CheckIcon className="h-3 w-3 text-white shadow-sm" />
                        )}
                    </button>
                ))}
            </div>
        </div>
    );
}

export function ThumbsField({ label, labelSecondary, className = "", ...props }) {
    const [field, meta, helpers] = useField(props);

    const handleThumbUp = () => {
        helpers.setValue(true);
    };

    const handleThumbDown = () => {
        helpers.setValue(false);
    };

    return (
        <div>
            <div className={classNames("flex cursor-pointer items-center gap-x-4", className)}>
                <HandThumbUpIcon
                    onClick={handleThumbUp}
                    className={classNames(
                        "h-6 w-6",
                        field.value === true ? "text-green-600" : "text-gray-300",
                    )}
                />
                <HandThumbDownIcon
                    onClick={handleThumbDown}
                    className={classNames(
                        "h-6 w-6",
                        field.value === false ? "text-red-600" : "text-gray-300",
                    )}
                />
                <div className="space-y-1 text-sm">
                    {label && <div className="font-medium text-gray-900">{label}</div>}
                    {labelSecondary && <div className="text-gray-500">{labelSecondary}</div>}
                </div>
            </div>
            {meta.touched && meta.error ? (
                <p className="mt-2 text-sm text-red-600">{meta.error}</p>
            ) : null}
        </div>
    );
}

export function UploadZoneFree({
    label,
    className = "",
    previewClassName = "h-8",
    message = "Click or drag to upload",
    acceptedFiles = ["png", "jpg", "jpeg", "svg"],
    labelPopover,
    onFileDrop,
    multiFile = false,
    isUploading = false,
    ...props
}) {
    const [isHovering, setIsHovering] = useState(false);
    const [filePreviews, setFilePreviews] = useState([]);
    const [error, setError] = useState(null);
    const fileInputRef = useRef();

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsHovering(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsHovering(false);
    };

    const isFileTypeAccepted = (fileType) => {
        // filetype is something like "image/png" or "image/svg+xml"
        // remove +xml
        fileType = fileType.split("/")[1];
        fileType = fileType.split("+")[0];
        return acceptedFiles.includes(fileType);
    };

    const handleClick = () => {
        if (!isUploading) {
            fileInputRef.current.click();
        }
    };

    const handleFiles = (files) => {
        setError(null);
        const validFiles = files.filter((file) => isFileTypeAccepted(file.type));
        if (multiFile) {
            // not implemented
        } else {
            // valid files empty?
            if (!validFiles.length) {
                setError("Invalid file type");
                return;
            }

            onFileDrop?.(validFiles[0]);
            // Generate previews
            const previews = validFiles.map((file) => {
                if (file.type.startsWith("image/")) {
                    return URL.createObjectURL(file);
                } else {
                    return file.name;
                }
            });

            setFilePreviews(previews);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsHovering(false);
        const files = Array.from(e.dataTransfer.files);
        handleFiles(files);
    };

    const handleChange = (e) => {
        e.preventDefault();
        const files = Array.from(e.target.files);
        handleFiles(files);
    };

    const formClasses =
        "border border-gray-300 border-dotted rounded focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent p-2 cursor-pointer flex items-center justify-center";

    const uploadFieldClasses = classNames(formClasses, isHovering && "bg-blue-200", className);

    return (
        <div>
            {labelPopover ? (
                <Popovers.PopoverLabel label={label}>
                    <div>{labelPopover}</div>
                </Popovers.PopoverLabel>
            ) : (
                <>{label && <Label>{label}</Label>}</>
            )}

            <div
                {...props}
                className={classNames(uploadFieldClasses)}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={handleClick}
            >
                {error ? (
                    <p className="text-center text-sm text-red-500">{error}</p>
                ) : isUploading ? (
                    <Spinner />
                ) : filePreviews.length > 0 ? (
                    filePreviews.map((preview, index) =>
                        preview.startsWith("blob:") ? (
                            <img
                                key={index}
                                src={preview}
                                alt="Preview"
                                className={classNames(previewClassName)}
                            />
                        ) : (
                            <p key={index}>{preview}</p>
                        ),
                    )
                ) : (
                    <p className="text-center text-sm">{message}</p>
                )}
            </div>
            <input
                type="file"
                className="hidden"
                accept={acceptedFiles.map((ext) => `.${ext}`).join(", ")}
                multiple={multiFile}
                ref={fileInputRef}
                onChange={handleChange}
            />
        </div>
    );
}
