import React, { useEffect, useRef, useState } from "react";

import { moment } from "./tools";

export const TimerProgressBar = ({ timeoutMilliseconds, timeout, onTimeout }) => {
    const [timer, setTimer] = useState(
        timeoutMilliseconds / 1000 || moment.duration(timeout).asSeconds(),
    );
    const intervalRef = useRef(null);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer - 1 <= 0) {
                    clearInterval(intervalRef.current);
                    onTimeout && onTimeout();
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const progress =
        (timer / (timeoutMilliseconds / 1000 || moment.duration(timeout).asSeconds())) * 100;

    let color = "bg-red-300";
    if (progress > 80) {
        color = "bg-emerald-300";
    } else if (progress > 20) {
        color = "bg-emerald-300";
    }

    return (
        <div>
            <div className="mb-1 flex justify-between">
                <span className="text-sm font-medium text-slate-700">Time Remaining</span>
                <span className="text-sm font-medium text-slate-700">
                    {moment.utc(moment.duration(timer, "seconds").asMilliseconds()).format("mm:ss")}
                </span>
            </div>
            <div className="h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700">
                <div
                    className={`${color} h-2.5 rounded-full`}
                    style={{ width: `${progress}%` }}
                ></div>
            </div>
        </div>
    );
};
