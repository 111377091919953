import React, { forwardRef } from "react";

import CatImage from "./assets/img/cat.png";
import DogImage from "./assets/img/dog.png";
import LlamaImage from "./assets/img/llama.png";
import PhoenixImage from "./assets/img/phoenix.png";
import { Popovers, classNames } from "./tools";

const SIZE_MAP = {
    xs: "h-4 w-4",
    s: "h-6 w-6",
    sm: "h-8 w-8",
    md: "h-12 w-12",
    lg: "h-16 w-16",
    xl: "h-20 w-20",
};

const SIZE_MAP_FONT = {
    xs: "text-xs",
    s: "text-xs",
    sm: "text-sm",
    md: "text-base",
    lg: "text-lg",
};

function getSrcString(hash, src, placeholder, color, textColor) {
    if (src) {
        return src;
    } else if (hash) {
        return `https://www.gravatar.com/avatar/${hash}?r=pg&d=https%3A%2F%2Fui-avatars.com%2Fapi%2F/${placeholder}/128/${color}/${textColor}/2/0.33`;
    }
    return null;
}

function renderPlaceholderDiv(size, className, placeholder) {
    return (
        <div
            className={classNames(
                SIZE_MAP[size],
                className,
                "flex cursor-default items-center justify-center rounded-lg border hover:border-emerald-600",
            )}
        >
            <span className={classNames(SIZE_MAP_FONT[size], "font-medium text-gray-500")}>
                {placeholder}
            </span>
        </div>
    );
}

const AvatarContent = React.forwardRef(({ srcString, placeholder, size, className }, ref) => {
    const content = srcString ? (
        <img
            className={classNames(
                SIZE_MAP[size],
                className,
                "rounded-lg border hover:border-emerald-600",
            )}
            src={srcString}
            alt={placeholder || ""}
        />
    ) : (
        renderPlaceholderDiv(size, className, placeholder)
    );
    return <div ref={ref}>{content}</div>;
});

export function Avatar({
    hash,
    placeholder = "",
    src,
    color = "F3F4F6", // slate-50
    // color = "BFDBFE", // blue-50
    textColor = "047857",
    size = "sm",
    className = "",
    popoverContent = null,
    popoverPosition = "top",
    popoverHover = false,
    ...props
}) {
    if (!hash && !src && !placeholder) {
        console.log("No hash, src, or placeholder provided to Avatar");
        return null;
    }

    const srcString = getSrcString(hash, src, placeholder, color, textColor);

    const avatarContent = (
        <AvatarContent
            srcString={srcString}
            placeholder={placeholder}
            size={size}
            className={className}
        />
    );

    return popoverContent ? (
        <Popovers.PopoverGeneric
            position={popoverPosition}
            trigger={avatarContent}
            content={popoverContent}
            hover={popoverHover}
        />
    ) : (
        avatarContent
    );
}

export function AvatarTeam({
    team,
    size = "sm",
    className = "",
    //  bgColor = "bg-gray-100",
    bgColor = "bg-indigo-100",
    ...props
}) {
    const images = {
        cat: CatImage,
        llama: LlamaImage,
        phoenix: PhoenixImage,
        dog: DogImage,
    };

    if (team.icon === "initials") {
        return (
            <div
                className={classNames(
                    SIZE_MAP[size],
                    "flex items-center justify-center rounded-lg border hover:border-emerald-600",
                    className,
                    bgColor,
                )}
            >
                <span className={classNames(SIZE_MAP_FONT[size], " font-medium text-gray-500")}>
                    {team.name[0].toUpperCase()}
                </span>
            </div>
        );
    }

    return (
        <img
            className={classNames(SIZE_MAP[size], " rounded-lg border hover:border-emerald-600")}
            src={images[team.icon]}
            alt=""
        />
    );
}

export function AvatarGroup({ items, size = "sm", maxDisplay = 3, condensed = false, onClick }) {
    if (!items || items.length === 0) {
        return null;
    }

    const avatarsToDisplay = Math.min(maxDisplay, items.length);
    const extraAvatars = items.length > maxDisplay ? items.length - maxDisplay : 0;

    return (
        <div className={classNames("flex", condensed ? "-space-x-1" : "gap-x-2")}>
            {items.slice(0, avatarsToDisplay).map((item, index) => (
                <Avatar
                    onClick={() => {
                        onClick?.(item);
                    }}
                    key={index}
                    {...item}
                    size={size}
                />
            ))}
            {extraAvatars > 0 && renderPlaceholderDiv(size, "bg-gray-100", `+${extraAvatars}`)}
        </div>
    );
}
