import { Form, Formik } from "formik";
import React, { useState } from "react";

import Appear from "./Appear";
import { EXPLAIN_NAME } from "./AssessmentCreate";
import { Button } from "./Button";
import { TextField } from "./Fields";
import { Api, Forms, useParams } from "./tools";

export default function AssessmentGeneral({ onSuccess }) {
    const [explain, setExplain] = useState(null);

    const { id } = useParams();
    const { data: assessment, isLoading, error } = Api.useGetAssessmentQuery(id);
    const [updateAssessment, { isLoading: isSaving }] = Api.useUpdateAssessmentMutation();

    return (
        <div>
            <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                    <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-slate-900"> General </h3>
                        <p className="mt-1 text-sm text-slate-600">
                            General information about the assessment.
                        </p>
                    </div>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                    <div className="shadow sm:rounded-md">
                        <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <Forms.GenericForm
                                initialValues={{
                                    name: assessment.name,
                                    team_id: assessment.team.id,
                                }}
                                submitButtonClassname="self-start"
                                submitButtonLabel="Save"
                                submitButtonColor="lgreen"
                                fields={[
                                    {
                                        name: "name",
                                        label: "Assessment name",
                                        type: "text",
                                        placeholder: "Name",
                                        required: true,
                                        explain: EXPLAIN_NAME,
                                    },
                                    {
                                        name: "team_id",
                                        label: "Team",
                                        type: "team",
                                    },
                                ]}
                                onSubmit={async (values) => {
                                    return updateAssessment({
                                        id: assessment.id,
                                        body: {
                                            ...values,
                                        },
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
