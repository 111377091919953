import React, { createContext, useCallback, useContext, useEffect, useState } from "react";

import { Buttons, Dialog, classNames } from "./tools";

const ConfirmContext = createContext();

export const useConfirm = () => {
    const context = useContext(ConfirmContext);

    if (!context) {
        throw new Error("useConfirm must be used within a ConfirmProvider");
    }

    return context;
};

export const ConfirmProvider = ({ children }) => {
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [resolveFn, setResolveFn] = useState(null);
    const [rejectFn, setRejectFn] = useState(null);
    const [confirmOptions, setConfirmOptions] = useState({
        title: "",
        description: "",
        okLabel: "Confirm",
        cancelLabel: "Cancel",
    });

    const openConfirm = useCallback((options) => {
        const {
            title = "",
            description = "",
            okLabel = "Confirm",
            cancelLabel = "Cancel",
            content = null,
            showButtons = true,
            dialogSize = "sm",
            confirmBtnColor = "lgreen",
        } = options;
        setConfirmOptions({
            title,
            description,
            okLabel,
            cancelLabel,
            content,
            showButtons,
            dialogSize,
            confirmBtnColor,
        });
        setIsConfirmOpen(true);
    }, []);

    const closeConfirm = useCallback(() => {
        rejectFn?.();
        setIsConfirmOpen(false);
    }, []);

    const confirm = useCallback(
        (options) =>
            new Promise((resolve, reject) => {
                setResolveFn(() => resolve);
                setRejectFn(() => reject);
                openConfirm(options);
            }),
        [openConfirm],
    );

    useEffect(() => {
        if (!isConfirmOpen) {
            setConfirmOptions({
                title: "",
                description: "",
                okLabel: "Confirm",
                cancelLabel: "Cancel",
            });
        }
    }, [isConfirmOpen]);

    const ConfirmDialog = () => (
        <Dialog
            dialogPanelClassName="overflow-y-auto"
            withScroll={false}
            size={confirmOptions.dialogSize}
            isOpen={isConfirmOpen}
            setIsOpen={setIsConfirmOpen}
            onClose={() => {
                closeConfirm();
            }}
        >
            {confirmOptions.content ? (
                // React.cloneElement(confirmOptions.content, { resolve: resolveFn, reject: rejectFn })
                React.cloneElement(confirmOptions.content)
            ) : (
                <div className="space-y-2">
                    {confirmOptions.title && (
                        <h2 className="text-lg font-semibold">{confirmOptions.title}</h2>
                    )}
                    {confirmOptions.description && (
                        <p className="text-sm">{confirmOptions.description}</p>
                    )}
                </div>
            )}
            {confirmOptions.showButtons && (
                <div className="mt-8 flex justify-end gap-x-2">
                    <Buttons.Button
                        color="slater"
                        onClick={() => {
                            rejectFn?.();
                            closeConfirm();
                        }}
                    >
                        {confirmOptions.cancelLabel}
                    </Buttons.Button>
                    <Buttons.Button
                        color={confirmOptions.confirmBtnColor}
                        onClick={() => {
                            resolveFn?.();
                            closeConfirm();
                        }}
                    >
                        {confirmOptions.okLabel}
                    </Buttons.Button>
                </div>
            )}
        </Dialog>
    );

    return (
        <ConfirmContext.Provider value={confirm}>
            {children}
            <ConfirmDialog />
        </ConfirmContext.Provider>
    );
};
