import React from "react";

import { Pills } from "./tools";

export default function AssessmentReports() {
    return (
        <div>
            <div className="text-md flex h-96 items-center justify-center text-center">
                <div className="max-w-md space-y-4">
                    <Pills.Pill>Reports</Pills.Pill>
                    <div className="text-md">
                        Reports are not available for this assessment. Please check back later.
                    </div>
                </div>
            </div>
        </div>
    );
}
