import React, { createContext, useState } from "react";

import { Api } from "./tools";

export const CandidateAssessmentContext = createContext();

export function CandidateAssessmentProvider({ caid, candidate, children }) {
    const {
        data: assessment,
        isLoading,
        error,
    } = Api.useGetCandidateAssessmentQuery(caid, {
        refetchOnMountOrArgChange: true,
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div>
                Error: {error.status} {error.detail}
            </div>
        );
    }

    return (
        <CandidateAssessmentContext.Provider value={{ assessment, candidate }}>
            {children}
        </CandidateAssessmentContext.Provider>
    );
}
