import { Popover as HeadlessPopover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { AcademicCapIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Fragment, forwardRef, useRef } from "react";
import React from "react";

import { Button, RefButton } from "./Button";
import { Fields, classNames, motion } from "./tools";

const popOverPositions = {
    top: {
        origin: "bottom-[140%]",
        transform: "translate-x-[-50%]",
    },
    right: {
        origin: "left-[100%]",
        transform: "translate-y-[-50%]",
    },
    bottom: {
        origin: "top-[100%]",
        transform: "translate-x-[-50%]",
    },
    bottomLeft: {
        origin: "right-[100%]",
        transform: "translate-y-[-30%] ",
    },
    left: {
        origin: "right-[100%]",
        transform: "translate-y-[-50%]",
    },
};

export function Popover({
    label = "Actions",
    buttonVariant = "solidXS",
    buttonColor = "slate",
    children,
}) {
    return (
        <HeadlessPopover className="relative">
            {({ open }) => (
                <>
                    <HeadlessPopover.Button
                        as={RefButton}
                        variant={buttonVariant}
                        color={buttonColor}
                    >
                        <span>{label}</span>
                        <ChevronDownIcon className="ml-2 h-5 w-5" aria-hidden="true" />
                    </HeadlessPopover.Button>
                    <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        transition={{ duration: 0.2, ease: "easeOut" }}
                    >
                        <HeadlessPopover.Panel
                            focus={false}
                            className="absolute left-1/2 z-50 mt-3 -translate-x-1/2 transform"
                        >
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                                    {children}
                                </div>
                            </div>
                        </HeadlessPopover.Panel>
                    </motion.div>
                </>
            )}
        </HeadlessPopover>
    );
}

export function PopoverLabel({
    id,
    label,
    position = "top",
    className,
    showIcon = true,
    hover = false,
    children,
}) {
    const triggerRef = useRef();
    const timeOutRef = useRef();
    const duration = 120;

    const handleEnter = (isOpen) => {
        if (!hover) {
            return;
        }
        clearTimeout(timeOutRef.current);
        !isOpen && triggerRef.current?.click();
    };

    const handleLeave = (isOpen) => {
        if (!hover) {
            return;
        }
        timeOutRef.current = setTimeout(() => {
            isOpen && triggerRef.current?.click();
        }, duration);
    };

    return (
        <HeadlessPopover className="relative">
            {({ open }) => (
                <>
                    <HeadlessPopover.Button
                        ref={triggerRef}
                        onMouseEnter={() => handleEnter(open)}
                        onMouseLeave={() => handleLeave(open)}
                        className={classNames(
                            "mb-3 flex items-center gap-x-2 text-sm font-medium text-gray-700",
                            className,
                        )}
                    >
                        {label}{" "}
                        {showIcon && (
                            <QuestionMarkCircleIcon
                                className="inline h-4 w-4 opacity-60"
                                aria-hidden="true"
                            />
                        )}
                    </HeadlessPopover.Button>
                    <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        transition={{ duration: 0.2, ease: "easeOut" }}
                    >
                        <HeadlessPopover.Panel
                            onMouseEnter={() => handleEnter(open)}
                            onMouseLeave={() => handleLeave(open)}
                            focus={false}
                            className={classNames(
                                "absolute z-50 bg-white",
                                popOverPositions[position].origin,
                            )}
                        >
                            <div
                                className={classNames(
                                    "transform overflow-hidden rounded-md bg-white shadow-md ring-1 ring-slate-600 ring-opacity-5",
                                    popOverPositions[position].transform,
                                )}
                            >
                                {children}
                            </div>
                        </HeadlessPopover.Panel>
                    </motion.div>
                </>
            )}
        </HeadlessPopover>
    );
}

export function PopoverGeneric({ position = "top", hover = false, className, trigger, content }) {
    const triggerRef = useRef();
    const timeOutRef = useRef();
    const duration = 240;

    const handleEnter = (isOpen) => {
        if (!hover) {
            return;
        }
        clearTimeout(timeOutRef.current);
        !isOpen && triggerRef.current?.click();
    };

    const handleLeave = (isOpen) => {
        if (!hover) {
            return;
        }
        timeOutRef.current = setTimeout(() => {
            isOpen && triggerRef.current?.click();
        }, duration);
    };

    return (
        <HeadlessPopover className={classNames("relative", className)}>
            {({ open }) => (
                <>
                    <HeadlessPopover.Button
                        //   as={Fragment}
                        ref={triggerRef}
                        onMouseEnter={() => handleEnter(open)}
                        onMouseLeave={() => handleLeave(open)}
                        className="inline-block cursor-pointer focus:ring-0 focus:ring-offset-0"
                    >
                        {trigger}
                    </HeadlessPopover.Button>

                    <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        transition={{ duration: 0.2, ease: "easeOut" }}
                    >
                        <HeadlessPopover.Panel
                            onMouseEnter={() => handleEnter(open)}
                            onMouseLeave={() => handleLeave(open)}
                            focus={false}
                            className={classNames(
                                "absolute z-50 bg-white",
                                popOverPositions[position].origin,
                            )}
                        >
                            <div
                                className={classNames(
                                    "transform overflow-hidden rounded-md bg-white shadow-md ring-1 ring-slate-600 ring-opacity-5",
                                    popOverPositions[position].transform,
                                )}
                            >
                                {content}
                            </div>
                        </HeadlessPopover.Panel>
                    </motion.div>
                </>
            )}
        </HeadlessPopover>
    );
}
