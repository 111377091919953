import { useDispatch, useSelector } from "react-redux";

import { ApiCandidate, moment, useCallback, useEffect, useRef, useState } from "./tools";

const WARDEN_ENABLED = window.location.hostname !== "localhost";
//const WARDEN_ENABLED = true;

export function useWarden(cid, caid) {
    const warden = useSelector(ApiCandidate.selectWarden);
    const dispatch = useDispatch();
    const [videoElem, setVideoElem] = useState(null);
    const videoElemRef = useRef(null);

    const updateWardenDevice = (deviceId) => {
        dispatch(ApiCandidate.updateWardenDevice({ deviceId }));
    };

    const [performAction, { isLoading: isPerformingAction, error: performActionError }] =
        ApiCandidate.usePerformAssessmentActionMutation();

    const setupVideo = async () => {
        if (!warden.deviceId) {
            return;
        }
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: {
                        exact: warden.deviceId,
                    },
                },
            });
            const videoElement = document.createElement("video");
            videoElement.srcObject = stream;
            videoElement.play();
            setVideoElem(videoElement);
            videoElemRef.current = videoElement;
        } catch (err) {
            console.log(err);
        }
    };

    const takeScreenshot = () => {
        if (!videoElem) {
            return;
        }
        const track = videoElem.srcObject.getVideoTracks()[0];
        const { width, height } = track.getSettings();
        const canvas = document.createElement("canvas");
        canvas.width = width || 640;
        canvas.height = height || 480;
        const ctx = canvas.getContext("2d");
        ctx.translate(width, 0);
        ctx.scale(-1, 1);
        ctx.drawImage(videoElem, 0, 0, canvas.width, canvas.height);
        const data = canvas.toDataURL("image/png", 1.0);

        sendWardenEvent({
            event_type: WARDEN_EVENT_CANDIDATE_SCREENSHOT,
            event_data: {
                f: data.split(",")[1],
            },
        });
    };

    const isWardenVideoReady = () => {
        return warden.deviceId !== null;
    };

    const sendWardenEvent = async (payload) => {
        // e.g. { event_type: CANDIDATE_LOST_FOCUS, event_data: { file: file }
        return performAction({
            cid,
            caid,
            action: "warden_event",
            payload: payload,
            invalidate: false,
        });
    };

    let lastLostFocus = null;
    const handleVisibilityChange = useCallback((e) => {
        if (document.visibilityState === "hidden") {
            sendWardenEvent({
                event_type: WARDEN_EVENT_CANDIDATE_LOST_FOCUS,
            });
            lastLostFocus = moment();
        } else {
            sendWardenEvent({
                event_type: WARDEN_EVENT_CANDIDATE_GAINED_FOCUS,
                event_data: {
                    duration: lastLostFocus ? moment().diff(lastLostFocus, "milliseconds") : null,
                },
            });
        }
    }, []);

    const handleCopy = useCallback((e) => {
        sendWardenEvent({
            event_type: WARDEN_EVENT_CANDIDATE_COPY_PASTE,
            event_data: {
                type: "copy",
                data: document.getSelection().toString(),
            },
        });
    }, []);

    const handlePaste = useCallback((e) => {
        sendWardenEvent({
            event_type: WARDEN_EVENT_CANDIDATE_COPY_PASTE,
            event_data: {
                type: "paste",
                data: e.clipboardData.getData("text/plain"),
            },
        });
    }, []);

    let interval;
    const runWarden = async () => {
        console.log("running warden...");
        if (!WARDEN_ENABLED) {
            console.log("Warden disabled, not running warden...");
            return;
        }
        await setupVideo();

        // TODO: add event listener for warden events
        // e.g. every time the window loses focus, send a loose focus event
        // e.g. every time the window gains focus, send a gain focus event
        document.addEventListener("visibilitychange", handleVisibilityChange);
        // add event listener for copy paste
        document.addEventListener("copy", handleCopy);
        document.addEventListener("paste", handlePaste);
        takeScreenshot();
        interval = setInterval(() => {
            takeScreenshot();
        }, 30000);
    };

    const stopWarden = () => {
        console.log("stopping warden...");

        document.removeEventListener("visibilitychange", handleVisibilityChange);
        document.removeEventListener("copy", handleCopy);
        document.removeEventListener("paste", handlePaste);
        clearInterval(interval);

        if (videoElemRef.current) {
            const tracks = videoElemRef.current.srcObject?.getVideoTracks();
            if (tracks) {
                tracks.forEach((track) => {
                    track.stop();
                });
            }

            videoElemRef.current.remove();
            videoElemRef.current = null;
        }
    };

    return {
        warden,
        updateWardenDevice,
        takeScreenshot,
        isWardenVideoReady,
        sendWardenEvent,
        runWarden,
        stopWarden,
    };
}

const WARDEN_EVENT_CANDIDATE_LOST_FOCUS = 1;
const WARDEN_EVENT_CANDIDATE_GAINED_FOCUS = 2;
const WARDEN_EVENT_CANDIDATE_COPY_PASTE = 3;
const WARDEN_EVENT_CANDIDATE_SCREENSHOT = 4;
const WARDEN_EVENT_VIDEO_NOT_SUPPORTED = 5;
const WARDEN_EVENT_VIDEO_NO_DEVICES = 6;
const WARDEN_EVENT_VIDEO_NO_PERMISSIONS = 7;
const WARDEN_EVENT_GENERAL_ERROR = 8;
const WARDEN_EVENT_VIDEO_SETUP_COMPLETED = 9;
const WARDEN_EVENT_VIDEO_SETUP_SKIPPED = 10;

export {
    WARDEN_EVENT_CANDIDATE_LOST_FOCUS,
    WARDEN_EVENT_CANDIDATE_GAINED_FOCUS,
    WARDEN_EVENT_CANDIDATE_COPY_PASTE,
    WARDEN_EVENT_CANDIDATE_SCREENSHOT,
    WARDEN_EVENT_VIDEO_NOT_SUPPORTED,
    WARDEN_EVENT_VIDEO_NO_DEVICES,
    WARDEN_EVENT_VIDEO_NO_PERMISSIONS,
    WARDEN_EVENT_GENERAL_ERROR,
    WARDEN_EVENT_VIDEO_SETUP_COMPLETED,
    WARDEN_EVENT_VIDEO_SETUP_SKIPPED,
};
