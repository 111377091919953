import { Link, NavLink, Outlet, useParams } from "react-router-dom";

import GhibliImage1 from "./assets/img/ghibli1.png";
import GhibliImage2 from "./assets/img/ghibli2.png";
import GhibliImage3 from "./assets/img/ghibli3.png";
import GhibliImage4 from "./assets/img/ghibli4.png";
import GhibliImage5 from "./assets/img/ghibli5.png";
import GhibliImage6 from "./assets/img/ghibli6.png";
import GhibliImage7 from "./assets/img/ghibli7.png";
import GhibliImage8 from "./assets/img/ghibli8.png";
import GhibliImage10 from "./assets/img/ghibli10.png";

const randomImage = () => {
    const images = [
        GhibliImage1,
        GhibliImage2,
        GhibliImage3,
        GhibliImage4,
        GhibliImage5,
        GhibliImage6,
        GhibliImage7,
        GhibliImage10,
    ];
    const randomImage = images[Math.floor(Math.random() * images.length)];
    return randomImage;
};

export default function LayoutAuth() {
    return (
        <>
            <div className="relative flex min-h-full justify-center md:px-12 lg:px-0">
                <div className="relative z-10 flex flex-1 flex-col bg-white py-10 px-4 shadow-2xl sm:justify-center md:flex-none md:px-28">
                    <div className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
                        <Outlet />
                    </div>
                </div>
                <div className="hidden sm:contents lg:relative lg:block lg:flex-1">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src={randomImage()}
                        alt=""
                    />
                </div>
            </div>
        </>
    );
}
