import { CheckBadgeIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import React from "react";

import AccountUsers from "./AccountUsers";
import SSOForm from "./SSOForm";
import UserCreateBulkForm from "./UserCreateBulkForm";
import { UserProvider } from "./UserProvider";
import LogoNew from "./assets/img/cactus-hire-new.svg";
import MagicLinkLogo from "./assets/img/magic-link.png";
import SSOImage from "./assets/img/sso.png";
import { authApi, authSlice } from "./store/auth";
import {
    Api,
    Buttons,
    Disclosures,
    Fields,
    Form,
    Formik,
    Forms,
    Spinner,
    UserContext,
    Yup,
    _,
    classNames,
    constants,
    moment,
    useContext,
    useDispatch,
    useNavigate,
    useParams,
    useState,
} from "./tools";

function OnboardingNav({
    step,
    canSkip,
    onBack,
    onContinue,
    continueLabel = "Continue",
    btnColor = "lgreen",
    onSkip,
}) {
    return (
        <div className="flex justify-between">
            <Buttons.Button onClick={onContinue && onContinue} color={btnColor}>
                {continueLabel}
            </Buttons.Button>
        </div>
    );
}

function StepSSO({ onContinue, org }) {
    const [orgDomain, setOrgDomain] = useState(null);
    const [nonFieldError, setNonFieldError] = useState(null);
    const [updateOrg, { isLoading: isUpdating }] = Api.useUpdateOrgMutation();

    const _idp = org.idp && constants.IDP_MAP[org.idp];

    return (
        <div className="flex min-h-[400px] flex-col justify-between gap-y-4">
            <div className="space-y-4">
                <div className="prose prose-sm">
                    <h3 className="font-semibold">Set up Single-Sign-On for Your Organization</h3>
                    <p>
                        Streamline your team's access and improve security with Single Sign-On
                        (SSO). All plans include seamless integration with Google and Microsoft SSO,
                        providing a secure and convenient passwordless login experience.
                    </p>
                </div>

                <Disclosures.DisclosureUnstyled
                    panels={[
                        {
                            title: (
                                <div className="flex items-center gap-x-2">
                                    <h5 className="text-xs font-semibold text-slate-700">
                                        Enterprise SSO Options
                                    </h5>
                                    <span className="ml-2 text-xs text-slate-400">Read more</span>{" "}
                                    <ChevronDownIcon className="h-3 w-4" />
                                </div>
                            ),
                            content: (
                                <div className="grid grid-cols-4 gap-x-8 rounded-md p-5 shadow-md">
                                    <div className="prose prose-sm col-span-2">
                                        <h5 className="font-semibold">Enterprise SSO Options</h5>
                                        <p className="mt-5">
                                            For Enterprise plans, we offer integration with 20+
                                            identity providers, including Okta, Azure, and OneLogin.
                                            Please reach out to our support team to get started.
                                        </p>
                                    </div>
                                    <div className="col-span-2">
                                        <img src={SSOImage} alt="SSO" className="w-full" />
                                    </div>
                                </div>
                            ),
                        },
                    ]}
                />

                {nonFieldError && (
                    <div className="border-l-4 border-red-400 bg-red-50 p-4">
                        <div className="text-sm text-red-700">{nonFieldError}</div>
                    </div>
                )}

                {(_idp && (
                    <div className="grid grid-cols-2 gap-x-8 gap-y-8 rounded-md p-5 ring-1 ring-slate-200">
                        <div className="col-span-2">
                            <div className="flex gap-x-3">
                                <img src={_idp.icon} className="h-4 w-4" />
                                <span className="text-xs font-semibold text-slate-700">
                                    {_idp.name}
                                </span>
                                <CheckBadgeIcon className="h-4 w-4 text-green-500" />
                            </div>
                        </div>

                        <div className="col-span-1">
                            <div className="space-y-4">
                                <Fields.TextFieldFree
                                    label="Email Domain"
                                    onChange={(e) => {
                                        setOrgDomain(e.target.value);
                                    }}
                                    defaultValue={org.org_domain}
                                />
                                {orgDomain && orgDomain != org.org_domain && (
                                    <Buttons.Button
                                        onClick={async () => {
                                            const result = await updateOrg({
                                                org_domain: orgDomain,
                                            });
                                            if (result.error) {
                                                setNonFieldError(result.error?.data?.org_domain);
                                            }
                                        }}
                                    >
                                        Save
                                    </Buttons.Button>
                                )}
                                <div className="prose prose-sm">
                                    <p>
                                        This is the email domain that your team will use to log in
                                        to Cactus. Users will be able to log in with their email
                                        address and password, and be automatically provisioned and
                                        added to your organization.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="space-y-4">
                                <Fields.SelectFieldFree
                                    label="Default Role"
                                    defaultValue={org.sso_default_role}
                                    options={[...constants.USER_ROLES]}
                                    onChange={async (e) => {
                                        const result = await updateOrg({
                                            sso_default_role: e.target.value,
                                        });
                                        if (result.error) {
                                            setNonFieldError(result.error?.data?.sso_default_role);
                                        }
                                    }}
                                />
                                <div className="prose prose-sm">
                                    <p>
                                        New users will be automatically assigned this role when they
                                        are provisioned.
                                    </p>
                                </div>
                                <div className="gap-y-4">
                                    <hr className="mb-3 border-slate-200" />
                                    <Buttons.Button
                                        className="bg-transparent text-xs"
                                        onClick={async () => {
                                            const result = await updateOrg({
                                                idp: null,
                                            });
                                            if (result.error) {
                                                setNonFieldError(result.error?.data?.idp);
                                            }
                                        }}
                                    >
                                        <img src={_idp.icon} className="mr-2 h-4 w-4" />
                                        Disconnect from {_idp.name}
                                    </Buttons.Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )) || (
                    <SSOForm
                        className={"flex gap-x-4"}
                        actionLabel="Setup SSO"
                        onClick={(idp) => {
                            updateOrg({
                                idp: idp,
                            });
                        }}
                    />
                )}
            </div>
            <OnboardingNav
                onContinue={onContinue}
                continueLabel={_idp ? "Continue" : "skip and set up later"}
                btnColor={_idp ? "lgreen" : "slater"}
            />
        </div>
    );
}

function StepTeam({ onContinue }) {
    const [inviteSuccess, setInviteSuccess] = useState(false);

    return (
        <div className="flex min-h-[400px] flex-col justify-between gap-y-4">
            <div className="grid grid-cols-2 gap-x-4">
                <div className="prose prose-sm">
                    <h3 className="font-semibold">Add Your Team </h3>
                    <p>
                        Cactus works best when your entire team is on board. Use the bulk invite
                        feature to effortessly invite your team members.
                    </p>
                </div>
                <div>
                    {(inviteSuccess && <AccountUsers />) || (
                        <UserCreateBulkForm
                            onSuccess={() => {
                                setInviteSuccess(true);
                            }}
                            submitButtonClassname="w-full"
                            withAddTeamLink={true}
                        />
                    )}
                </div>
            </div>
            <OnboardingNav
                onContinue={onContinue}
                continueLabel={inviteSuccess ? "Continue" : "Skip and set up later"}
                btnColor={inviteSuccess ? "lgreen" : "slater"}
            />
        </div>
    );
}

function StepWelcome({ org }) {
    const [nonFieldError, setNonFieldError] = useState(null);
    const [updateOrg, { isLoading: isUpdating }] = Api.useUpdateOrgMutation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <div className="space-y-4">
            <div className="prose prose-sm w-full max-w-full">
                <h3 className="font-semibold">A tailored experience awaits you.</h3>
                <p>
                    Help us tailor your Cactus experience by providing information about your
                    organization. This will allow us to personalize our recommendations and support,
                    ensuring you get the most out of our platform.
                </p>
            </div>
            <Forms.GenericForm
                initialValues={{
                    onboarding_survey_role: org.onboarding_survey_role || 1,
                    onboarding_survey_company_size: org.onboarding_survey_company_size || 1,
                    onboarding_survey_hiring_goals: org.onboarding_survey_hiring_goals || 1,
                    onboarding_survey_other: org.onboarding_survey_other || "",
                    onboarding_survey_marketing: org.onboarding_survey_marketing,
                }}
                onSuccess={async () => {
                    const result = await updateOrg({
                        onboarding_completed_at: moment(),
                    });
                    if (result.error) {
                        setNonFieldError(result.error?.data?.onboarding_completed_at);
                    } else {
                        // manually update the org in the cache
                        dispatch(Api.util.resetApiState("getCurrentUser"));
                        navigate("/?trial=true");
                    }
                }}
                submitButtonLabel="Start Using Cactus"
                fields={[
                    {
                        name: "onboarding_survey_role",
                        label: "Role",
                        type: "select",
                        options: [
                            { label: "Owner / Founder", value: 1 },
                            { label: "C-Level Executive (CEO, CFO, etc.)", value: 2 },
                            { label: "VP / Director", value: 3 },
                            { label: "Manager / Team Lead", value: 4 },
                            { label: "Individual Contributor", value: 5 },
                            { label: "Other", value: 6 },
                        ],
                    },
                    {
                        name: "onboarding_survey_company_size",
                        label: "Company Size",
                        type: "select",
                        options: [
                            { label: "1-10", value: 1 },
                            { label: "11-50", value: 2 },
                            { label: "51-200", value: 3 },
                            { label: "201-500", value: 4 },
                            { label: "501-1000", value: 5 },
                            { label: "1001-5000", value: 6 },
                            { label: "5001-10000", value: 7 },
                            { label: "10001+", value: 8 },
                        ],
                    },
                    {
                        name: "onboarding_survey_hiring_goals",
                        label: "Hiring Goals (yearly)",
                        type: "select",
                        options: [
                            { label: "1-10", value: 1 },
                            { label: "11-50", value: 2 },
                            { label: "51-200", value: 3 },
                            { label: "201+", value: 4 },
                        ],
                    },
                    {
                        name: "onboarding_survey_other",
                        label: "What are you hoping to achieve with Cactus? How can we best support you?",
                        type: "textarea",
                    },
                    {
                        name: "onboarding_survey_marketing",
                        label: "Would you like to receive useful tips and updates about Cactus?",
                        labelSecondary:
                            "Rest assured, we respect your inbox and will never send spam.",
                        type: "toggle",
                    },
                ]}
                mutation={Api.useUpdateOrgMutation}
            />
        </div>
    );
}

const steps = [
    {
        id: "Step 1",
        name: "Organization",
        component: null,
    },
    { id: "Step 2", name: "SSO", component: StepSSO },
    { id: "Step 3", name: "Team", component: StepTeam },
    { id: "Step 4", name: "Welcome", component: StepWelcome },
];

export default function OnboardingContinue() {
    const [step, setStep] = useState(1);
    const navigate = useNavigate();

    const [register, { isLoading, error }] = authApi.useRegisterMutation();

    const current = steps[step];
    const CurrentComponent = current.component;

    const {
        data: org,
        isLoading: isLoadingOrg,
        error: errorOrg,
    } = Api.useGetOrgQuery({
        refetchOnMountOrArgChange: true,
    });

    if (isLoading || isLoadingOrg) {
        return <Spinner />;
    }

    return (
        <UserProvider>
            <div>
                <div className="grid h-screen items-center bg-slate-50">
                    <div className="mx-auto w-full max-w-4xl space-y-8 border-2 border-b-8 border-slate-100 border-b-emerald-300 bg-white p-10">
                        <div className="min-h-[400px] space-y-8">
                            <div>
                                <img src={LogoNew} alt="Cactus Hire" className="h-6" />
                            </div>
                            <div>
                                {CurrentComponent && (
                                    <CurrentComponent
                                        org={org}
                                        onContinue={() => {
                                            setStep((cur) => {
                                                return cur + 1;
                                            });
                                        }}
                                    />
                                )}
                            </div>
                        </div>

                        <nav aria-label="Progress">
                            <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
                                {steps.map((step, i) => (
                                    <li key={step.name} className="md:flex-1">
                                        <div
                                            className={classNames(
                                                "group flex flex-col border-l-4 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4",
                                                steps.indexOf(current) < step
                                                    ? "border-emerald-300 opacity-60 group-hover:border-emerald-300"
                                                    : step.id == current.id
                                                    ? "border-emerald-300"
                                                    : "border-slate-200 opacity-60 group-hover:border-slate-300",
                                            )}
                                        >
                                            <span
                                                className={classNames(
                                                    "text-sm font-medium",
                                                    steps.indexOf(current) < step
                                                        ? "text-emerald-300 opacity-60 group-hover:text-emerald-300"
                                                        : step.id == current.id
                                                        ? "text-emerald-300"
                                                        : "text-slate-500 opacity-60 group-hover:text-slate-700",
                                                )}
                                            >
                                                {step.name}
                                            </span>
                                        </div>
                                    </li>
                                ))}
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </UserProvider>
    );
}
